/* tslint:disable */
/* eslint-disable */
/**
 * Armasuisse Dashboard Web Application API
 * API definitions for Armasuisse Dashboard Web Application
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { ProjectFunctionBase } from '../models';
// @ts-ignore
import { ProjectFunctionBaseResponse } from '../models';
/**
 * ProjectFunctionsApi - axios parameter creator
 * @export
 */
export const ProjectFunctionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new Project Function and returns newly created Project Function.
         * @summary Creates new Project Function.
         * @param {ProjectFunctionBase} [projectFunctionBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectFunction: async (projectFunctionBase?: ProjectFunctionBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ProjectFunctions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectFunctionBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Project Function.
         * @summary Delete Project Function.
         * @param {number} entityID Project Function identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectFunction: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteProjectFunction', 'entityID', entityID)
            const localVarPath = `/ProjectFunctions/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Project Function.
         * @summary Get Project Function details.
         * @param {number} entityID Project Function identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectFunctionDetails: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getProjectFunctionDetails', 'entityID', entityID)
            const localVarPath = `/ProjectFunctions/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Project Functions.
         * @summary Get filtered list of Project Functions.
         * @param {string} [search] Search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectFunctionList: async (search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ProjectFunctions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Project Function and returns the modified Project Function.
         * @summary Edit Project Function.
         * @param {number} entityID Project Function identifier
         * @param {ProjectFunctionBase} [projectFunctionBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectFunction: async (entityID: number, projectFunctionBase?: ProjectFunctionBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateProjectFunction', 'entityID', entityID)
            const localVarPath = `/ProjectFunctions/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectFunctionBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectFunctionsApi - functional programming interface
 * @export
 */
export const ProjectFunctionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectFunctionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new Project Function and returns newly created Project Function.
         * @summary Creates new Project Function.
         * @param {ProjectFunctionBase} [projectFunctionBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProjectFunction(projectFunctionBase?: ProjectFunctionBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectFunctionBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProjectFunction(projectFunctionBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Project Function.
         * @summary Delete Project Function.
         * @param {number} entityID Project Function identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectFunction(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectFunction(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Project Function.
         * @summary Get Project Function details.
         * @param {number} entityID Project Function identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectFunctionDetails(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectFunctionBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectFunctionDetails(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Project Functions.
         * @summary Get filtered list of Project Functions.
         * @param {string} [search] Search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectFunctionList(search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectFunctionBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectFunctionList(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Project Function and returns the modified Project Function.
         * @summary Edit Project Function.
         * @param {number} entityID Project Function identifier
         * @param {ProjectFunctionBase} [projectFunctionBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectFunction(entityID: number, projectFunctionBase?: ProjectFunctionBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectFunctionBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectFunction(entityID, projectFunctionBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectFunctionsApi - factory interface
 * @export
 */
export const ProjectFunctionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectFunctionsApiFp(configuration)
    return {
        /**
         * Creates new Project Function and returns newly created Project Function.
         * @summary Creates new Project Function.
         * @param {ProjectFunctionBase} [projectFunctionBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectFunction(projectFunctionBase?: ProjectFunctionBase, options?: any): AxiosPromise<ProjectFunctionBaseResponse> {
            return localVarFp.addProjectFunction(projectFunctionBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Project Function.
         * @summary Delete Project Function.
         * @param {number} entityID Project Function identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectFunction(entityID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectFunction(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Project Function.
         * @summary Get Project Function details.
         * @param {number} entityID Project Function identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectFunctionDetails(entityID: number, options?: any): AxiosPromise<ProjectFunctionBaseResponse> {
            return localVarFp.getProjectFunctionDetails(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Project Functions.
         * @summary Get filtered list of Project Functions.
         * @param {string} [search] Search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectFunctionList(search?: string, options?: any): AxiosPromise<Array<ProjectFunctionBaseResponse>> {
            return localVarFp.getProjectFunctionList(search, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Project Function and returns the modified Project Function.
         * @summary Edit Project Function.
         * @param {number} entityID Project Function identifier
         * @param {ProjectFunctionBase} [projectFunctionBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectFunction(entityID: number, projectFunctionBase?: ProjectFunctionBase, options?: any): AxiosPromise<ProjectFunctionBaseResponse> {
            return localVarFp.updateProjectFunction(entityID, projectFunctionBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectFunctionsApi - object-oriented interface
 * @export
 * @class ProjectFunctionsApi
 * @extends {BaseAPI}
 */
export class ProjectFunctionsApi extends BaseAPI {
    /**
     * Creates new Project Function and returns newly created Project Function.
     * @summary Creates new Project Function.
     * @param {ProjectFunctionBase} [projectFunctionBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectFunctionsApi
     */
    public addProjectFunction(projectFunctionBase?: ProjectFunctionBase, options?: AxiosRequestConfig) {
        return ProjectFunctionsApiFp(this.configuration).addProjectFunction(projectFunctionBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Project Function.
     * @summary Delete Project Function.
     * @param {number} entityID Project Function identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectFunctionsApi
     */
    public deleteProjectFunction(entityID: number, options?: AxiosRequestConfig) {
        return ProjectFunctionsApiFp(this.configuration).deleteProjectFunction(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Project Function.
     * @summary Get Project Function details.
     * @param {number} entityID Project Function identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectFunctionsApi
     */
    public getProjectFunctionDetails(entityID: number, options?: AxiosRequestConfig) {
        return ProjectFunctionsApiFp(this.configuration).getProjectFunctionDetails(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Project Functions.
     * @summary Get filtered list of Project Functions.
     * @param {string} [search] Search query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectFunctionsApi
     */
    public getProjectFunctionList(search?: string, options?: AxiosRequestConfig) {
        return ProjectFunctionsApiFp(this.configuration).getProjectFunctionList(search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Project Function and returns the modified Project Function.
     * @summary Edit Project Function.
     * @param {number} entityID Project Function identifier
     * @param {ProjectFunctionBase} [projectFunctionBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectFunctionsApi
     */
    public updateProjectFunction(entityID: number, projectFunctionBase?: ProjectFunctionBase, options?: AxiosRequestConfig) {
        return ProjectFunctionsApiFp(this.configuration).updateProjectFunction(entityID, projectFunctionBase, options).then((request) => request(this.axios, this.basePath));
    }
}
