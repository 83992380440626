import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { useHeaderStyles } from '../../components/Header/Header';
import useHeaderContent from '../../components/HeaderProvider/useHeaderContent';
import ModuleCards from '../../components/ModuleCards/ModuleCards';
import config from '../../config/config';
import messages from '../messages';
import Page from '../Page/Page';
import { appPages } from '../PageRouter';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dashboardBox: {
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            flex: 1,
            height: '100%',
            overflow: 'auto',
            [theme.breakpoints.up('md')]: {
                flexFlow: 'row wrap',
                justifyContent: 'center',
                alignContent: 'center',
            },
        },
        dashboardItem: {
            height: '300px',
            width: '200px',
            margin: theme.spacing(1),
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'space-between',
            '&:last-child': {
                marginBottom: theme.spacing(4),
            },
            [theme.breakpoints.up('md')]: {
                height: '400px',
                width: '300px',
                margin: theme.spacing(2),
                '&:last-child': {
                    marginBottom: theme.spacing(2),
                },
            },
        },
    }),
);

const DashboardPage: React.FC = () => {
    const classes = useStyles();
    const headerClasses = useHeaderStyles();
    const { setHeaderContent } = useHeaderContent();

    // translations
    const intl = useIntl();
    const transHeaderTitle = intl.formatMessage({ ...messages.pageDashboard });

    useEffect(() => {
        setHeaderContent(
            <Typography variant="h1" component="h1" className={headerClasses.heading}>
                {transHeaderTitle}
            </Typography>,
        );
    }, [transHeaderTitle]);

    return (
        <Page>
            <Helmet>
                <title>{`${config?.template?.title} - ${transHeaderTitle}`}</title>
            </Helmet>
            <Box className={classes.dashboardBox}>
                <ModuleCards pages={appPages?.filter(page => page.inDashboard)} />
            </Box>
        </Page>
    );
};

export default DashboardPage;
