import axios, { AxiosResponse } from 'axios';

// const esriApi = new ApiClient('/sharing', 'https://www.arcgis.com');

const esriApi = axios.create({
    baseURL: 'https://www.arcgis.com/sharing',
    timeout: 1000,
});

export const getWebsceneJson = (
    websceneID: string,
    token: string,
    format = 'json',
): Promise<any> => {
    const url = `/content/items/${websceneID}/data?f=${format}&token=${token}`;

    return esriApi
        .get<any>(url, {
            headers: {
                // 'Access-Control-Allow-Origin': 'http://localhost:4000',
                'Content-Type': 'application/json',
            },
        })
        .then((response: AxiosResponse<any>) => {
            return Promise.resolve(response.data);
        });
};
