import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export interface IKeycloakState {
    idToken: string;
    refreshToken: string;
    token: string;
    event: unknown | null;
}

const initialState: Readonly<IKeycloakState> = {
    idToken: '',
    refreshToken: '',
    token: '',
    event: null,
};

export const keycloakSlice = createSlice({
    name: 'keycloak',
    initialState,

    reducers: {
        setEvent: (state, action: PayloadAction<unknown>) => {
            state.event = action.payload;
        },

        setToken: (
            state,
            action: PayloadAction<{ idToken?: string; refreshToken?: string; token?: string }>,
        ) => {
            state.idToken = action.payload.idToken as string;
            state.refreshToken = action.payload.refreshToken as string;
            state.token = action.payload.token as string;
        },
    },
});

export const { setEvent, setToken } = keycloakSlice.actions;

export const selectIdToken = (state: RootState) => state.keycloak.idToken;
export const selectRefreshToken = (state: RootState) => state.keycloak.refreshToken;
export const selectToken = (state: RootState) => state.keycloak.token;
export const selectEvent = (state: RootState) => state.keycloak.event;

export default keycloakSlice.reducer;
