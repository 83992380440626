import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
    ProblemDetails,
    ProjectDetailResponse,
    ReportValidationProblemDetails,
} from '../../../generate/api';
import { projReportsApi } from '../../../packages/Api/data/projectReports/client';
import {
    selectCompanyModal,
    selectIsLastReport,
    selectReport,
    setIsLastReport,
    setProjectID,
    setReport,
    setReports,
} from '../../../redux/projectInformation/projectInformationSlice';
import { store } from '../../../redux/store';
import CompanyForm from '../../CompanyForm/CompanyForm';
import ContractForm from '../../ContractForm/ContractForm';
import PersonForm from '../../PersonForm/PersonForm';
import {
    clearCompanyDetailData,
    clearInitialData,
    clearProjectReportData,
    fetchAndStoreProjectReports,
    loadCompanyDetailData,
    loadInitialData,
    loadProjectReportData,
} from '../apiActions';
import { ButtonsHeader } from '../components/ButtonsHeader/ButtonsHeader';
import Loader from '../components/Loader/Loader';
import ValidationErrorsSummary from '../components/ValidationErrorsSummary/ValidationErrorsSummary';
import AchievementOfObjectives from '../tabs/AchievementOfObjectives/AchievementOfObjectives';
import Assessments from '../tabs/Assessments/Assessments';
import ChecklistPhaseDocuments from '../tabs/ChecklistPhaseDocuments/ChecklistPhaseDocuments';
import DatesStatusReport from '../tabs/DatesStatusReport/DatesStatusReport';
import Finances from '../tabs/FInances/Finances';
import HumanResources from '../tabs/HumanResources/HumanResources';
import IntegralSafety from '../tabs/IntegralSafety/IntegralSafety';
import ProjectData from '../tabs/ProjectData/ProjectData';
import ProjectTeam from '../tabs/ProjectTeam/ProjectTeam';
import Risks from '../tabs/Risks/Risks';
import TimeProgress from '../tabs/TimeProgress/TimeProgress';
import messages from './messages';
import ReportSelection from './ReportSelection';

export interface IEntryPageProps {
    data?: ProjectDetailResponse;
    setPage: React.ComponentState;
}

const MainForm: React.FC<IEntryPageProps> = ({ data, setPage }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<{
        initialData: boolean;
        projectReportData: boolean;
        companyDetailData: boolean;
    }>({
        initialData: true,
        projectReportData: true,
        companyDetailData: true,
    });

    const [validationError, setValidationError] = useState<ReportValidationProblemDetails>();

    const intl = useIntl();
    const report = useSelector(selectReport);
    const companyModal = useSelector(selectCompanyModal);
    const isLastReport = useSelector(selectIsLastReport);

    /**
     * Loading initialData
     */
    useEffect(() => {
        loadInitialData(dispatch).then(() => {
            setLoading(prevState => ({ ...prevState, initialData: false }));
            console.log('### initial data loaded');
        });

        return () => {
            clearInitialData(dispatch);
        };
    }, []);

    /**
     * load all reports and select last
     */
    const loadReportsAndSelectLast = (projectID: number) => {
        fetchAndStoreProjectReports(projectID).then(reportsResponse => {
            if (reportsResponse.length > 0) {
                const lastReport = reportsResponse[reportsResponse.length - 1];
                if (lastReport?.projectReportID && lastReport?.projectID) {
                    dispatch(setReport(undefined));
                    projReportsApi
                        .getProjectReportDetails(lastReport.projectReportID, lastReport.projectID)
                        .then(reportResponse => {
                            dispatch(setReport(reportResponse.data));
                        });
                }
            }
        });
    };

    /**
     * Loading nested data when projectID is changed
     */
    useEffect(() => {
        const projectID = data?.projectID || undefined;

        // Set projectID into redux when data is loaded
        dispatch(setProjectID(projectID));

        // Load last report on project change
        if (projectID) {
            loadReportsAndSelectLast(projectID);
        }
        return () => {
            store.dispatch(setProjectID(undefined));
            store.dispatch(setReport(undefined));
            store.dispatch(setReports([]));
        };
    }, [data?.projectID]);

    /**
     * companyDetailData
     * load nested data into redux when ownerID is changed
     */
    useEffect(() => {
        setLoading(prevState => ({ ...prevState, companyDetailData: true }));
        if (report?.ownerID) {
            loadCompanyDetailData(report.ownerID).then(() => {
                console.log('### company detail data loaded');
                setLoading(prevState => ({ ...prevState, companyDetailData: false }));
            });
        }
        return () => {
            clearCompanyDetailData();
        };
    }, [report?.ownerID, data?.projectID, report?.projectReportID]);

    /**
     * Loading projectReportData
     */
    useEffect(() => {
        setLoading(prevState => ({ ...prevState, projectReportData: true }));
        if (data?.projectID && report?.projectReportID) {
            loadProjectReportData(data.projectID, report.projectReportID).then(() => {
                console.log('### project report data loaded');
                setLoading(prevState => ({ ...prevState, projectReportData: false }));
            });
        }
        return () => {
            clearProjectReportData();
        };
    }, [data?.projectID, report?.projectReportID]);

    /**
     * Submit new report
     */
    const handleSubmitNewReport = () => {
        if (!window.confirm(intl.formatMessage(messages.submitNewReportConfirmation))) return;
        submitNewReport();
    };

    /**
     * Forcibly submit new report
     */
    const forceSubmitNewReport = () => {
        setValidationError(undefined);
        submitNewReport(true);
    };

    /**
     * Submit new report with force support
     */
    const submitNewReport = (force = false) => {
        const projectID = data?.projectID;
        const previousReportID = report?.projectReportID;
        if (projectID && previousReportID) {
            setLoading(prevState => ({ ...prevState, projectReportData: true }));
            projReportsApi
                .postProjectReportsInitializeNewReport(projectID, previousReportID, force)
                .then(response => {
                    console.log('### handleSubmitNewReport response', response);

                    // load new report
                    if (response.data.projectReportID) {
                        loadReportsAndSelectLast(projectID);
                    }
                })
                .catch(error => {
                    console.error('### handleSubmitNewReport error', error);
                    setLoading(prevState => ({ ...prevState, projectReportData: false }));
                    setValidationError(error.response.data);
                });
        }
    };

    /**
     * Clear validation errors
     */
    const handleClearErrors = () => {
        setValidationError(undefined);
    };

    /**
     * Detect if current report is last and save to redux
     */
    useEffect(() => {
        if (report?.projectReportID) {
            const reports = store.getState().projectInformation.reports;
            const lastReport = reports[reports.length - 1];
            dispatch(setIsLastReport(lastReport?.projectReportID === report.projectReportID));
        }
    }, [report?.projectReportID]);

    return (
        <>
            <Loader
                parts={[
                    { name: 'Initial data', loading: loading.initialData },
                    { name: 'Project report data', loading: loading.projectReportData },
                    { name: 'Company detail data', loading: loading.companyDetailData },
                ]}
            />
            <div className="max-w-[1200px] mx-auto">
                <div className="xl:flex gap-5 mb-10">
                    <div className="flex-1 flex gap-2">
                        <div className="flex-1">
                            <ReportSelection projectID={data?.projectID} />
                        </div>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmitNewReport}
                            disabled={!isLastReport}
                        >
                            <FormattedMessage {...messages.submitNewReport} />
                        </Button>
                    </div>
                    <div className="mt-4 xl:mt-0">
                        <div className="flex">
                            <ButtonsHeader setPage={setPage} />
                        </div>
                    </div>
                </div>

                {/* Validation errors summary */}
                {validationError && (
                    <ValidationErrorsSummary
                        details={validationError}
                        onClose={handleClearErrors}
                        onForce={forceSubmitNewReport}
                    />
                )}

                {/* Project data */}
                {/* Projektkenndaten */}
                <ProjectData />

                {/* Dates Status reports */}
                {/* Termine Statusberichte */}
                <DatesStatusReport />

                {/* Project team */}
                {/* Projektteam */}
                <ProjectTeam />

                {/* Assessments */}
                {/* Gesamtbeurteilung, Teilbeurteilungen */}
                <Assessments />

                {/* 1 */}
                {/* Goal achievement */}
                {/* Zielerrechnung */}
                <AchievementOfObjectives />

                {/* 2 */}
                {/* Finances */}
                {/* Finanzen */}
                <Finances />

                {/* 3 */}
                {/* Human Resources */}
                {/* Personalressourcen */}
                <HumanResources />

                {/* 4 */}
                {/* Time progress */}
                {/* Zeitlicher Fortschritt */}
                <TimeProgress />

                {/* 5 */}
                {/* Risks */}
                {/* Risiken */}
                <Risks />

                {/* 6 */}
                {/* Integral safety */}
                {/* Integrale Sicherheit */}
                <IntegralSafety />

                {/* 7 */}
                {/* Checklist phase documents */}
                {/* Checkliste Phasendokumente */}
                <ChecklistPhaseDocuments />
            </div>

            {companyModal.isOpen && <CompanyForm />}

            <ContractForm />

            <PersonForm />
        </>
    );
};

export default MainForm;
