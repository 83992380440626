import { defineMessages } from 'react-intl';

export default defineMessages({
    targetAchievement: {
        id: 'components.projectInformation.goalAchievement.targetAchievement',
        defaultMessage: 'Target achievement', // DE: Zielerreichung
    },

    targetAchievementDescription: {
        id: 'components.projectInformation.goalAchievement.targetAchievementDescription',
        defaultMessage:
            'Project status (key words) in terms of results, quality and success thereof the goal achievements incl. assessment', // DE: Projektstand (Stichworte) in Bezug auf Ergebnisse, Qualität und darausfolgernde die Zielerreichnung inkl. Beurteilung
    },

    reviewOfWork: {
        id: 'components.projectInformation.goalAchievement.reviewOfWork',
        defaultMessage: 'Review of status of work/activities', // DE: Rückblick Stand der Arbeiten/Tätigkeiten
    },

    activitiesPerformed: {
        id: 'components.projectInformation.goalAchievement.activitiesPerformed',
        defaultMessage: 'Activities performed in the last reporting period', // DE: Ausgeführte Arbeiten/Tätigkeiten in der letzten Berichtsperiode
    },

    targetAchievementLastReportingPeriod: {
        id: 'components.projectInformation.goalAchievement.targetAchievementLastReportingPeriod',
        defaultMessage: 'Target achievement in the last reporting period', // DE: Zielerreichung in der letzten Berichtsperiode
    },

    recapitulationChapter13LastStatusReport: {
        id: 'components.projectInformation.goalAchievement.recapitulationChapter13LastStatusReport',
        defaultMessage: 'Recapitulation chapter 1.3 last status report', // DE: Abgleich Kap 1.3 letzter Statusbericht
    },

    currentActivities: {
        id: 'components.projectInformation.goalAchievement.currentActivities',
        defaultMessage: 'Current activities', // DE: Aktuelle Arbeiten/Tätigkeiten
    },

    assessmentOfTargetAchievementCurrentProjectStatus: {
        id: 'components.projectInformation.goalAchievement.assessmentOfTargetAchievementCurrentProjectStatus',
        defaultMessage: 'Assessment of target achievement current project status', // DE: Einschätzung Zielerreichung aktueller Projektstand
    },

    activitiesPerformedNextReportingPeriod: {
        id: 'components.projectInformation.goalAchievement.activitiesPerformedNextReportingPeriod',
        defaultMessage: 'Activities performed next reporting period', // DE: Anfallende Arbeiten/Tätigkeiten in nächster Berichtsperiode
    },

    measuresForDeviationFromTargetAchievementCurrentProjectStatus: {
        id: 'components.projectInformation.goalAchievement.measuresForDeviationFromTargetAchievementCurrentProjectStatus',
        defaultMessage: 'Measures for deviation from target achievement current project status', // DE: Massnahmen bei Abweichungen in Bezug auf Zielerreichung aktueller Arbeitsstand
    },

    decisionsToBeMadeOrClarifiedNextReportingPeriod: {
        id: 'components.projectInformation.goalAchievement.decisionsToBeMadeOrClarifiedNextReportingPeriod',
        defaultMessage: 'Decisions to be made or clarified next reporting period', // DE: Anstehende Entscheide/Abklärungen in der nächsten Berichtsperiode
    },

    remarks: {
        id: 'components.projectInformation.goalAchievement.remarks',
        defaultMessage: 'Remarks', // DE: Bemerkungen/Ergänzungen
    },

    newRow: {
        id: 'components.projectInformation.goalAchievement.newRow',
        defaultMessage: 'New row', // DE: Neue Zeile
    },
});
