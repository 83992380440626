import { defineMessages } from 'react-intl';

export default defineMessages({
    documentsDescription: {
        id: 'components.projectInformation.documents.documentsDescription',
        defaultMessage:
            'The following checklists for each phase serve as a control and as a "red thread". This increases the quality and efficiency of the project process. The lists should be critically examined and supplemented if necessary. Inputs are very welcome.',
        // DE: Die nachfolgenden Checklisten pro Phasen dienen der Kontrolle und als "roter Faden". Dadurch wird die Qualität und Effizienz im Projektablauf gesteigert. Die Listen sollen kritisch hinterfragt und bei Bedarf ergänzt werden. Inputs sind sehr willkommen.
    },

    titleChecklistPhaseDocumentsB1: {
        id: 'components.projectInformation.documents.title.checklistPhaseDocumentsB1',
        defaultMessage: 'Checklist Phase Documents B1', // DE: Checkliste Phasendokumente B1 Vorstudien
    },

    titleChecklistPhaseDocumentsB2: {
        id: 'components.projectInformation.documents.title.checklistPhaseDocumentsB2',
        defaultMessage: 'Checklist Phase Documents B2', // DE: Checkliste Phasendokumente B2 Projektierung
    },

    titleChecklistPhaseDocumentsB3: {
        id: 'components.projectInformation.documents.title.checklistPhaseDocumentsB3',
        defaultMessage: 'Checklist Phase Documents B3', // DE: Checkliste Phasendokumente B3 Realisierung
    },

    labelDocument: {
        id: 'components.projectInformation.documents.label.document',
        defaultMessage: 'Document', // DE: Dokument
    },
    placeholderDocument: {
        id: 'components.projectInformation.documents.placeholder.document',
        defaultMessage: 'Placeholder text', // DE:
    },

    labelComment: {
        id: 'components.projectInformation.documents.label.comment',
        defaultMessage: 'Comment', // DE: Anmerkung
    },
    placeholderComment: {
        id: 'components.projectInformation.documents.placeholder.comment',
        defaultMessage: 'Placeholder text', // DE:
    },

    labelResponsible: {
        id: 'components.projectInformation.documents.label.responsible',
        defaultMessage: 'Responsible', // DE: Zuständig
    },
    placeholderResponsible: {
        id: 'components.projectInformation.documents.placeholder.responsible',
        defaultMessage: 'Placeholder text', // DE:
    },

    labelDate: {
        id: 'components.projectInformation.documents.label.date',
        defaultMessage: 'Date', // DE: Termin
    },

    labelStatus: {
        id: 'components.projectInformation.documents.label.status',
        defaultMessage: 'Status', // DE: Status
    },

    buttonNewLine: {
        id: 'components.projectInformation.documents.button.newLine',
        defaultMessage: 'New line', // DE: Neue Zeile
    },
});
