import { defineMessages } from 'react-intl';

export default defineMessages({
    buttonNewRow: {
        id: 'components.projectInformation.pendencies.button.newRow',
        defaultMessage: 'New row', // DE: Neue Zeile
    },
    placeholder: {
        id: 'components.projectInformation.pendencies.placeholder',
        defaultMessage: 'Placeholder text', // DE:
    },
});
