import { companiesApi } from '../../packages/Api/data/companies/client';
import { companyTypesApi } from '../../packages/Api/data/companyTypes/client';
import { countriesApi } from '../../packages/Api/data/countries/client';
import { projFunctionsApi } from '../../packages/Api/data/functions/client';
import { projMilestonesApi } from '../../packages/Api/data/milestones/client';
import { pendencyTypesApi } from '../../packages/Api/data/pendencyTypes/client';
import { peopleApi } from '../../packages/Api/data/people/client';
import { projectPhasesApi } from '../../packages/Api/data/projectPhases/client';
import { projReportsApi } from '../../packages/Api/data/projectReports/client';
import { statusesApi } from '../../packages/Api/data/statuses/client';
import { textualItemTypesApi } from '../../packages/Api/data/textualItemTypes/client';
import {
    setCompanies,
    setCompanyTypes,
    setConcepts,
    setContractor,
    setCountries,
    setDocuments,
    setFunctions,
    setMilestones,
    setOrganizationUnits,
    setPendencies,
    setPendencyTypes,
    setPeople,
    setPhases,
    setProjectReportPeople,
    setReports,
    setRisks,
    setStatuses,
    setStatusSet,
    setTextualItems,
    setTextualItemTypes,
    setTrades,
} from '../../redux/projectInformation/projectInformationSlice';
import { store } from '../../redux/store';

/**
 * Single calls
 */
export const fetchAndStoreProjectReports = (projectID: number) => {
    return projReportsApi.getProjectReportList(projectID).then(res => {
        store.dispatch(setReports(res.data));
        return res.data;
    });
};

export const fetchAndStorePeopleList = () => {
    return peopleApi.getPeopleList().then(res => {
        store.dispatch(setPeople(res.data));
        return res.data;
    });
};

export const fetchAndStoreCompanyList = () => {
    return companiesApi.getCompanyList().then(res => {
        store.dispatch(setCompanies(res.data));
        return res.data;
    });
};

/**
 * Load all data needed for the project information form
 * @param dispatch
 */
export const loadInitialData = dispatch => {
    return Promise.all([
        // load companyTypes from api into redux
        companyTypesApi.getCompanyTypeList().then(res => {
            dispatch(setCompanyTypes(res.data));
        }),

        // load statusesSets from api into redux
        statusesApi.getStatusSetList().then(res => {
            dispatch(setStatusSet(res.data));
        }),

        // load statuses from api into redux
        statusesApi.getStatuseList().then(res => {
            dispatch(setStatuses(res.data));
        }),

        // load textualItemTypes from api into redux
        textualItemTypesApi.getTextualItemTypeList().then(res => {
            dispatch(setTextualItemTypes(res.data));
        }),

        // load functions from api into redux
        projFunctionsApi.getProjectFunctionList().then(res => {
            dispatch(setFunctions(res.data));
        }),

        // load people from api into redux
        peopleApi.getPeopleList().then(res => {
            dispatch(setPeople(res.data));
        }),

        // load companies from api into redux
        companiesApi.getCompanyList().then(res => {
            dispatch(setCompanies(res.data));
        }),

        // load project milestones from api into redux
        projMilestonesApi.getProjectMilestoneList().then(res => {
            dispatch(setMilestones(res.data));
        }),

        // load phases from api into redux
        projectPhasesApi.getProjectPhaseList().then(res => {
            dispatch(setPhases(res.data));
        }),

        // load countries from api into redux
        countriesApi.getCountryList().then(res => {
            dispatch(setCountries(res.data));
        }),

        // load pendency types from api into redux
        pendencyTypesApi.getPendencyTypeList().then(res => {
            dispatch(setPendencyTypes(res.data));
        }),

        // load people from api into redux
        peopleApi.getPeopleList().then(res => {
            dispatch(setPeople(res.data));
        }),
    ]);
};

/**
 * Clear all data needed for the project information form
 * @param dispatch
 */
export const clearInitialData = dispatch => {
    dispatch(setCompanyTypes([]));
    dispatch(setStatusSet([]));
    dispatch(setStatuses([]));
    dispatch(setTextualItemTypes([]));
    dispatch(setFunctions([]));
    dispatch(setPeople([]));
    dispatch(setCompanies([]));
    dispatch(setMilestones([]));
    dispatch(setPhases([]));
    dispatch(setCountries([]));
    dispatch(setPendencyTypes([]));
    dispatch(setPeople([]));
};

/**
 * Load all data needed for the project report form
 * @param projectID
 * @param projectReportID
 */
export const loadProjectReportData = (projectID: number, projectReportID: number) => {
    return Promise.all([
        // load pendencies from api into redux
        projReportsApi.getPendencyList(projectID, projectReportID).then(res => {
            store.dispatch(setPendencies(res.data));
        }),

        // load concepts from api into redux
        projReportsApi.getConceptList(projectID, projectReportID).then(res => {
            store.dispatch(setConcepts(res.data));
        }),

        // load documents from api into redux
        projReportsApi.getDocumentList(projectID, projectReportID).then(res => {
            store.dispatch(setDocuments(res.data));
        }),

        // load trades from api into redux
        projReportsApi.getTradeList(projectID, projectReportID).then(res => {
            store.dispatch(setTrades(res.data));
        }),

        // load project team from api into redux
        projReportsApi.getProjectReportPersonList(projectID, projectReportID).then(res => {
            store.dispatch(setProjectReportPeople(res.data));
        }),

        // load textual items from api into redux
        projReportsApi.getTextualItemList(projectID, projectReportID).then(res => {
            store.dispatch(setTextualItems(res.data));
        }),

        // load risks from api into redux
        projReportsApi.getRiskList(projectID, projectReportID).then(res => {
            store.dispatch(setRisks(res.data));
        }),
    ]);
};

/**
 * Clear all data needed for the project report form
 */
export const clearProjectReportData = () => {
    store.dispatch(setPendencies([]));
    store.dispatch(setConcepts([]));
    store.dispatch(setDocuments([]));
    store.dispatch(setTrades([]));
    store.dispatch(setProjectReportPeople([]));
    store.dispatch(setTextualItems([]));
    store.dispatch(setRisks([]));
};

/**
 * Load all data needed for the company detail form
 * @param companyID
 */
export const loadCompanyDetailData = (companyID: number) => {
    return Promise.all([
        // load contractor from api into redux
        companiesApi.getCompanyDetails(companyID).then(res => {
            store.dispatch(setContractor(res.data));
        }),

        // load organizationUnits from api into redux
        companiesApi.getOrganizationUnitList(companyID).then(res => {
            store.dispatch(setOrganizationUnits(res.data));
        }),
    ]);
};

/**
 * Clear all data needed for the company detail form
 */
export const clearCompanyDetailData = () => {
    store.dispatch(setContractor(undefined));
    store.dispatch(setOrganizationUnits([]));
};
