import { Button } from '@mui/material';
import React, { useState } from 'react';

const ProjectImport: React.FC = () => {
    const [file, setFile] = useState<File>();

    // function convert size to MB
    const convertSize = (size: number) => {
        const sizeInMB = size / 1024 / 1024;
        return sizeInMB.toFixed(2);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];
        setFile(selectedFile);
        console.log(selectedFile);
    };

    return (
        <div className="h-full w-full flex justify-center items-center">
            <div>
                <div className="text-3xl text-center font-medium mb-5">New project:</div>
                <div className="border border-gray-200 rounded-xl p-10">
                    <div className="text-center mb-3">Select file for import</div>
                    <div className="bg-gray-100 border border-gray-300 p-5 rounded">
                        <input type="file" onChange={handleFileChange} />
                    </div>

                    {file && (
                        <div className="p-5 bg-green-200 border-green-500 rounded mt-4">
                            <div>- {file.name}</div>
                            <div>- {convertSize(file?.size)} MB</div>
                        </div>
                    )}

                    <div className="text-center mt-5">
                        <Button variant="contained" disabled={!file}>
                            Upload and continue
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectImport;
