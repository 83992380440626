import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { CircularProgress, LinearProgress } from '@mui/material';
import React from 'react';

interface ILoaderProps {
    parts: { name: string; loading: boolean }[];
}

const Loader: React.FC<ILoaderProps> = ({ parts }) => {
    React.useEffect(() => {
        console.log('### Loader: parts', parts);
    }, [parts]);

    if (parts?.every(part => !part.loading)) return <></>;

    return (
        <div className="absolute inset-0 z-10 flex justify-center items-center bg-white">
            <div className="max-w-[350px] w-full">
                <div className="text-center mb-10">
                    <div className="text-2xl mb-4">LOADING:</div>
                    <div className="">
                        <LinearProgress color="primary" />
                    </div>
                </div>
                <div className="table mx-auto">
                    {parts?.map((part, index) => (
                        <div key={index} className="flex items-center mb-3">
                            <div className="w-[20px] h-[20px] mr-3 block">
                                {part.loading ? (
                                    <CircularProgress size="20px" className="" />
                                ) : (
                                    <CheckRoundedIcon
                                        color="success"
                                        className="relative top-[-3px]"
                                    />
                                )}
                            </div>
                            <div>{part.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Loader;
