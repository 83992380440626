import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    saveReport,
    selectIsLastReport,
    selectReport,
} from '../../../../redux/projectInformation/projectInformationSlice';
import { store } from '../../../../redux/store';
import messages from './messages';

interface IRowProps {
    title?: string;
    reduxTarget: string;
}

const ReportTextareaComment: React.FC<IRowProps> = ({ title, reduxTarget }) => {
    const intl = useIntl();
    const reduxReport = useSelector(selectReport);
    const isLastReport = useSelector(selectIsLastReport);

    const [value, setValue] = useState<string>('');

    useEffect(() => {
        if (reduxReport) {
            setValue(reduxReport[reduxTarget]);
        }
    }, [reduxReport, reduxTarget]);

    const handleFieldChange = e => {
        setValue(e.target.value);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!reduxReport) return;
            if (value === reduxReport[reduxTarget]) return;
            store.dispatch(saveReport({ ...reduxReport, [reduxTarget]: value }));
        }, 2000);
        return () => clearTimeout(timeout);
    }, [value, reduxReport]);

    return (
        <div>
            {title && (
                <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                    <div className="font-medium">{title}</div>
                </div>
            )}
            <TextField
                value={value}
                multiline
                fullWidth
                size="small"
                margin="none"
                onChange={handleFieldChange}
                placeholder={intl.formatMessage({ ...messages.placeholder })}
                minRows={4}
                disabled={!isLastReport}
            />
        </div>
    );
};

export default ReportTextareaComment;
