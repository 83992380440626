import { Button, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { DocumentBaseResponse } from '../../../../generate/api';
import {
    createDocument,
    deleteDocument,
    selectDocuments,
    selectIsLastReport,
    selectPhasesByType,
    selectReportPhase,
    updateDocument,
} from '../../../../redux/projectInformation/projectInformationSlice';
import StateSelector from '../../../Form/StateSelector/StateSelector';
import ButtonDelete from '../../components/ButtonDelete/ButtonDelete';
import { FormDatepicker } from '../../components/FormDatepicker/FormDatepicker';
import styleTable from '../../styles/table.module.css';
import messages from './messages';

interface IRowProps {
    formRowData: DocumentBaseResponse;
    previousPhases: number[];
}

const Row: React.FC<IRowProps> = ({ formRowData, previousPhases }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const isLastReport = useSelector(selectIsLastReport);
    const reportPhase = useSelector(selectReportPhase);

    const isClosed = useMemo<boolean>(() => {
        return formRowData.closingProjectPhaseID
            ? previousPhases.includes(formRowData.closingProjectPhaseID)
            : false;
    }, [reportPhase]);

    const isDisabled = !isLastReport || isClosed;

    const [row, setRow] = useState<DocumentBaseResponse>(formRowData);

    const reduxRow = useSelector(selectDocuments).find(
        document => document.documentID === row.documentID,
    );

    useEffect(() => {
        setRow(formRowData);
    }, [formRowData]);

    const handleFieldChange = (fieldName, value) => {
        setRow({ ...row, [fieldName]: value });
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (row?.documentID) {
                // Compare if the row is the same as the row in redux, if it is, don't update
                if (
                    reduxRow?.name === row.name &&
                    reduxRow?.comment === row.comment &&
                    reduxRow?.responsible === row.responsible &&
                    reduxRow?.deadline === row.deadline &&
                    reduxRow?.statusID === row.statusID
                )
                    return;

                dispatch(updateDocument(row));
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [row]);

    const handleDeleteRow = () => {
        if (!formRowData.documentID) return;
        dispatch(deleteDocument(formRowData.documentID));
    };

    return (
        <>
            <tr>
                <td>
                    <TextField
                        value={row.name}
                        onChange={e => handleFieldChange('name', e.target.value)}
                        fullWidth
                        size="small"
                        margin="none"
                        multiline
                        placeholder={intl.formatMessage({ ...messages.placeholderDocument })}
                        disabled={isDisabled}
                    />
                </td>
                <td>
                    <TextField
                        value={row.comment}
                        onChange={e => handleFieldChange('comment', e.target.value)}
                        fullWidth
                        size="small"
                        margin="none"
                        multiline
                        placeholder={intl.formatMessage({ ...messages.placeholderComment })}
                        disabled={isDisabled}
                    />
                </td>
                <td>
                    <TextField
                        value={row.responsible}
                        onChange={e => handleFieldChange('responsible', e.target.value)}
                        fullWidth
                        size="small"
                        margin="none"
                        multiline
                        placeholder={intl.formatMessage({ ...messages.placeholderResponsible })}
                        disabled={isDisabled}
                    />
                </td>
                <td>
                    <FormDatepicker
                        value={row.deadline || undefined}
                        onChange={value => handleFieldChange('deadline', value)}
                        disabled={isDisabled}
                    />
                </td>
                <td>
                    <StateSelector
                        category="completion"
                        canBeEmpty
                        value={row?.statusID || undefined}
                        onChange={e => handleFieldChange('statusID', e.target.value)}
                        disabled={isDisabled}
                    />
                </td>
                <td>
                    <ButtonDelete onClick={handleDeleteRow} disabled={isDisabled} />
                </td>
            </tr>
        </>
    );
};

const Documents: React.FC = () => {
    const dispatch = useDispatch();
    const reduxDocumentData = useSelector(selectDocuments);
    const isLastReport = useSelector(selectIsLastReport);
    const reportPhase = useSelector(selectReportPhase);

    const allPhases = useSelector(state => selectPhasesByType(state, 'Project'));

    // Memoized previous phases to the current report phase
    const previousPhases = useMemo<number[]>(() => {
        if (!reportPhase || !allPhases) return [];
        const index = allPhases.findIndex(
            phase => phase.projectPhaseID === reportPhase.projectPhaseID,
        );
        return allPhases.slice(0, index).map(phase => phase.projectPhaseID);
    }, [reportPhase, allPhases]);

    // Add a new row to the Redux
    const handleAddRow = () => {
        dispatch(
            createDocument({
                name: '',
            }),
        );
    };

    return (
        <>
            <div className={styleTable.tableOuterBorder}>
                <table className={styleTable.table}>
                    <thead>
                        <tr>
                            <th>
                                <FormattedMessage {...messages.labelDocument} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelComment} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelResponsible} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelDate} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelStatus} />
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {reduxDocumentData.map((row, index) => (
                            <Row formRowData={row} key={index} previousPhases={previousPhases} />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-4">
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={handleAddRow}
                    disabled={!isLastReport}
                >
                    <FormattedMessage {...messages.buttonNewLine} />
                </Button>
            </div>
        </>
    );
};

export default Documents;
