import { defineMessages } from 'react-intl';

export default defineMessages({
    titleCreate: {
        id: 'components.personForm.title.create',
        defaultMessage: 'Create person', // DE: Person erstellen
    },

    titleEdit: {
        id: 'components.personForm.title.edit',
        defaultMessage: 'Edit person', // DE: Person bearbeiten
    },

    userName: {
        id: 'components.personForm.userName',
        defaultMessage: 'User name', // DE: Benutzername
    },

    email: {
        id: 'components.personForm.email',
        defaultMessage: 'E-mail', // DE: E-Mail
    },

    firstName: {
        id: 'components.personForm.firstName',
        defaultMessage: 'First name', // DE: Vorname
    },

    lastName: {
        id: 'components.personForm.lastName',
        defaultMessage: 'Last name', // DE: Nachname
    },

    buttonCancel: {
        id: 'components.personForm.button.cancel',
        defaultMessage: 'Cancel', // DE: Abbrechen
    },

    buttonCreate: {
        id: 'components.personForm.button.create',
        defaultMessage: 'Create', // DE: Erstellen
    },

    buttonUpdate: {
        id: 'components.personForm.button.update',
        defaultMessage: 'Update', // DE: Aktualisieren
    },

    buttonClose: {
        id: 'components.personForm.button.close',
        defaultMessage: 'Close', // DE: Schließen
    },

    errorEmailRequired: {
        id: 'components.personForm.error.emailRequired',
        defaultMessage: 'E-mail is required', // DE: E-Mail ist erforderlich
    },

    errorEmailInvalid: {
        id: 'components.personForm.error.emailInvalid',
        defaultMessage: 'E-mail is invalid', // DE: E-Mail ist ungültig
    },

    errorFirstNameRequired: {
        id: 'components.personForm.error.firstNameRequired',
        defaultMessage: 'First name is required', // DE: Vorname ist erforderlich
    },

    errorLastNameRequired: {
        id: 'components.personForm.error.lastNameRequired',
        defaultMessage: 'Last name is required', // DE: Nachname ist erforderlich
    },
});
