/* tslint:disable */
/* eslint-disable */
/**
 * Armasuisse Dashboard Web Application API
 * API definitions for Armasuisse Dashboard Web Application
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GeneratedToken } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * ArcGISApi - axios parameter creator
 * @export
 */
export const ArcGISApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Calls ArcGIS api GenerateToken and returns it.
         * @summary Generate token.
         * @param {string} [referer] Referer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArcGisGenerateToken: async (referer?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ArcGIS/GenerateToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (referer !== undefined) {
                localVarQueryParameter['referer'] = referer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArcGISApi - functional programming interface
 * @export
 */
export const ArcGISApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArcGISApiAxiosParamCreator(configuration)
    return {
        /**
         * Calls ArcGIS api GenerateToken and returns it.
         * @summary Generate token.
         * @param {string} [referer] Referer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArcGisGenerateToken(referer?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneratedToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArcGisGenerateToken(referer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArcGISApi - factory interface
 * @export
 */
export const ArcGISApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArcGISApiFp(configuration)
    return {
        /**
         * Calls ArcGIS api GenerateToken and returns it.
         * @summary Generate token.
         * @param {string} [referer] Referer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArcGisGenerateToken(referer?: string, options?: any): AxiosPromise<GeneratedToken> {
            return localVarFp.getArcGisGenerateToken(referer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArcGISApi - object-oriented interface
 * @export
 * @class ArcGISApi
 * @extends {BaseAPI}
 */
export class ArcGISApi extends BaseAPI {
    /**
     * Calls ArcGIS api GenerateToken and returns it.
     * @summary Generate token.
     * @param {string} [referer] Referer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArcGISApi
     */
    public getArcGisGenerateToken(referer?: string, options?: AxiosRequestConfig) {
        return ArcGISApiFp(this.configuration).getArcGisGenerateToken(referer, options).then((request) => request(this.axios, this.basePath));
    }
}
