import config from '../../../../config/config';
import { Configuration, ProjectReportsApi } from '../../../../generate/api';
import { store } from '../../../../redux/store';

export const projReportsApi = new ProjectReportsApi(
    new Configuration({
        basePath: config.api.baseUrl,
        accessToken: () => {
            return store.getState().keycloak.token as string;
        },
    }),
);
