import { CheckCircle, Dangerous, Error, Info, Report, Warning } from '@mui/icons-material';
import {
    Alert,
    AlertTitle,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReportValidationProblemDetails, Severity } from '../../../../generate/api';
import messages from './messages';

interface IValidationErrorsSummary {
    /**
     * Validation details
     */
    details: ReportValidationProblemDetails;
    /**
     * On close callback
     */
    onClose: () => void;
    /**
     * On force callback
     */
    onForce?: () => void;
}

/**
 * Get severity icon
 * @param severity
 */
const getIcon = (severity?: Severity) => {
    switch (severity) {
        case Severity.Error:
            return <Dangerous color={'error'} />;
        case Severity.Warning:
            return <Warning color={'warning'} />;
        // case Severity.Success:
        //     return <CheckCircle />;
        case Severity.Info:
        default:
            return <Info color={'success'} />;
    }
};

/**
 * Compare two severities, generally used for sorting, generated by AI
 * @param a
 * @param b
 */
const compareBySeverity = (a?: Severity, b?: Severity) => {
    if (a === Severity.Error) return -1;
    if (b === Severity.Error) return 1;
    if (a === Severity.Warning) return -1;
    if (b === Severity.Warning) return 1;
    if (a === Severity.Info) return -1;
    if (b === Severity.Info) return 1;
    return 0;
};

const ValidationErrorsSummary: React.FC<IValidationErrorsSummary> = ({
    details,
    onClose,
    onForce,
}) => {
    const handleClose = () => {
        onClose?.();
    };

    const handleForce = () => {
        onForce?.();
    };

    const isForcable = useMemo(
        () => !details?.errors?.some(p => p.severity === Severity.Error),
        [details],
    );

    return (
        <>
            <Alert severity="error" onClose={handleClose}>
                <AlertTitle className="mt-[1px] mb-4">{details.title}</AlertTitle>
                <div className="flex flex-col gap-1 mt-2">
                    {details?.errors?.map((error, index) => (
                        <Tooltip
                            title={`Field: ${error.fieldKey}`}
                            key={`error-${index}`}
                            // followCursor
                            placement={'left'}
                            arrow
                        >
                            <div className="flex items-center">
                                <div className="mr-3">{getIcon(error.severity)}</div>
                                <div>{error.message}</div>
                            </div>
                        </Tooltip>
                    ))}
                </div>
                {isForcable && onForce && (
                    <Tooltip title={<FormattedMessage {...messages.forceTooltip} />}>
                        <Button
                            onClick={handleForce}
                            color="primary"
                            variant="contained"
                            className="mt-5"
                        >
                            <FormattedMessage {...messages.forceButton} />
                        </Button>
                    </Tooltip>
                )}
            </Alert>
        </>
    );
};
export default ValidationErrorsSummary;
