import React from 'react';
import { useIntl } from 'react-intl';

import ReportTextareaComment from '../../components/ReportTextareaComment/ReportTextareaComment';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import sectionTitleMessages from '../../MainForm/sectionTitleMessages';
import Concepts from '../Concepts/Concepts';
import messages from './messages';

const IntegralSafety = () => {
    const intl = useIntl();
    return (
        <section className="my-[100px]">
            <section id="section-06" className="scroll-my-5">
                <SectionHeader
                    number={6}
                    mainSection
                    title={intl.formatMessage({ ...sectionTitleMessages.integralSafety })}
                    aspect="integralSafetyOverallAspect"
                />

                <div className="my-8">
                    <ReportTextareaComment
                        title={intl.formatMessage({
                            ...messages.integralSafetyComment,
                        })}
                        reduxTarget="integralSafetyComment"
                    />
                </div>
            </section>

            <section className="my-[50px]">
                <SectionHeader
                    number={6.2}
                    subSection
                    title={intl.formatMessage({ ...sectionTitleMessages.concepts })}
                    aspect="integralSafetyConceptsAspect"
                />

                <div className="my-8">
                    <Concepts />
                </div>
            </section>
        </section>
    );
};

export default IntegralSafety;
