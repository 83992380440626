import 'three-mesh-bvh/src/index';

import { CircularProgress, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useKeycloak } from '@react-keycloak/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Color } from 'three';
import { IFCModel } from 'three/examples/jsm/loaders/IFCLoader';
import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree } from 'three-mesh-bvh';
import { IFCOPENINGELEMENT, IFCSPACE } from 'web-ifc';
import { IfcViewerAPI } from 'web-ifc-viewer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            width: '100%',
            height: '100%',
        },
        viewer: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
        },
        loadingOverlay: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            backgroundColor: 'rgba(255,255,255,0.8)',
            textAlign: 'center',
            paddingTop: '25%',
        },
        propertiesWindow: {
            position: 'absolute',
            left: '1rem',
            top: '1rem',
            backgroundColor: 'rgba(255,255,255,0.8)',
            width: '30%',
            maxHeight: '80%',
            overflowY: 'scroll',
            fontSize: '10px',
            lineHeight: '1.2',
        },
    }),
);

export interface IIfcViewerProps {
    ifcData?: Blob;
    ifcDataLoading?: boolean;
    onModelLaded?: (model: IFCModel, viewer: IfcViewerAPI) => void;
}

const IfcViewer: React.FC<IIfcViewerProps> = ({ ifcData, ifcDataLoading, onModelLaded }) => {
    const classes = useStyles();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const [loading, setLoading] = useState<boolean>(false);

    const viewerContainer = useRef<HTMLDivElement | null>(null);

    const [properties, setProperties] = useState<any>();

    const [viewer, setViewerInternal] = useState<IfcViewerAPI>();
    const viewerRef = useRef<IfcViewerAPI>();
    const [model, setModel] = useState<IFCModel>();

    const setViewer = (viewerToSet: IfcViewerAPI) => {
        viewerRef.current = viewerToSet;
        setViewerInternal(viewerToSet);
    };

    const handleViewerClick = async () => {
        // if (viewerRef.current) {
        //     viewerRef.current.IFC.selector
        //         .pickIfcItem(true)
        //         .then(ids => {
        //             if (ids && viewerRef.current) {
        //                 viewerRef.current.IFC.getProperties(ids.modelID, ids.id, true, false).then(
        //                     props => {
        //                         setProperties(props);
        //                     },
        //                 );
        //             }
        //         })
        //         .catch(e => {
        //             console.error('xxx click error', e);
        //         });
        // }
    };

    useEffect(() => {
        let myViewer;
        if (viewerContainer && viewerContainer.current) {
            console.log('XXX initializing viewer');
            // viewerContainer.current.onclick = handleViewerClick;
            myViewer = new IfcViewerAPI({
                container: viewerContainer.current as HTMLDivElement,
                backgroundColor: new Color(255, 255, 255),
            });
            // myViewer.IFC.loader.ifcManager.useWebWorkers(true, 'IFCWorker.js').then(() => {
            myViewer.IFC.setWasmPath('../../');
            myViewer.IFC.loader.ifcManager.setupThreeMeshBVH(
                computeBoundsTree,
                disposeBoundsTree,
                acceleratedRaycast,
            );
            myViewer.IFC.loader.ifcManager.applyWebIfcConfig({
                USE_FAST_BOOLS: true,
                COORDINATE_TO_ORIGIN: true,
            });
            myViewer.IFC.loader.ifcManager.parser.setupOptionalCategories({
                [IFCSPACE]: false,
                [IFCOPENINGELEMENT]: false,
            });

            setViewer(myViewer);
            // });
        }
    }, [viewerContainer]);

    useEffect(() => {
        return () => {
            console.log('XXX Disposing viewer');
            viewer?.dispose();
        };
    }, []);

    useEffect(() => {
        if (viewer && ifcData) {
            setLoading(true);
            console.log('XXX Loading IFC data of size', ifcData.size);
            const ifcURL = URL.createObjectURL(ifcData);
            viewer.IFC.loadIfcUrl(ifcURL, true).then(ifcModel => {
                if (ifcModel) {
                    // hack to renumber the automatically incremented model id
                    ifcModel.modelID = 0;
                }

                setLoading(false);
                setModel(ifcModel);

                // viewer.current?.IFC.properties.serializeAllProperties(model).then(properties => {
                //     console.log(properties.toString());
                // });
            });
        }
    }, [viewer, ifcData]);

    useEffect(() => {
        if (model) {
            if (onModelLaded && viewer) {
                onModelLaded(model, viewer);
            }
        }
    }, [model]);

    return (
        <div className={classes.root}>
            <div className={classes.viewer} ref={viewerContainer} onClick={handleViewerClick}></div>
            {properties && (
                <pre className={classes.propertiesWindow}>
                    {JSON.stringify(properties, null, 2)}
                </pre>
            )}
            {(ifcDataLoading || loading) && (
                <div className={classes.loadingOverlay}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};

export default IfcViewer;
