import Color from '@arcgis/core/Color';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Pagination, PaginationItem } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import AsideNavigation from '../../components/AsideNavigation/AsideNavigation';
import EsriViewer, { ClickedPoint } from '../../components/EsriViewer/EsriViewer';
import Icon from '../../components/Icon/Icon';
import ProjectInformation from '../../components/ProjectInformation/ProjectInformation';
import { ProjectBaseResponse } from '../../generate/api';
import { projApi } from '../../packages/Api/data/projects/client';
import exampleThumbnail from './example/example-thumbnail.png';
import { FilterPopup } from './FilterPopup';
import acc from './img/acc.png';
import buildagil from './img/buildagil.svg';
import dalux from './img/dalux.svg';
import libal from './img/libal.svg';

export interface IListItem {
    id: number;
    showData: {
        code: string;
        title?: string;
        status?: 'green' | 'orange' | 'red';
        estimatedInvoicing?: string;
        invoiced?: string;
        issues?: string[];
        projectManagerBuilder?: string;
    };
    exampleItem?: boolean;
    handleSelectedItemCallback: (code: string) => void;
    handleShowInformationCallback: (id: number) => void;
    highlight?: boolean;
    externalLink?: { type: string; link: string };
}

const testHideMap = false;

export const ListItem: React.FC<IListItem> = ({
    id,
    showData,
    handleSelectedItemCallback,
    handleShowInformationCallback,
    highlight,
    externalLink,
    exampleItem,
}) => {
    // Test data
    if (!showData.issues) {
        showData.issues = ['Gestoppt', 'Issue2'];
    }

    const handleInformationOpen = e => {
        e.stopPropagation();
        handleShowInformationCallback(id);
    };

    const handleHighlight = () => {
        handleSelectedItemCallback(showData.code);
    };

    const externalLinkImg = (): string | undefined => {
        switch (externalLink?.type) {
            case 'acc':
                return acc;
            case 'buildAgil':
                return buildagil;
            case 'libal':
                return libal;
            case 'dalux':
                return dalux;
            default:
                return undefined;
        }
    };

    return (
        <div
            className={clsx(
                'sm:flex p-2 sm:p-0 w-full border-b border-gray-200 hover:bg-gray-50 text-sm',
                highlight ? 'bg-gray-100' : 'bg-white',
            )}
            onClick={handleHighlight}
        >
            <div className="sm:p-2">
                {exampleItem ? (
                    <img
                        src={exampleThumbnail}
                        alt={showData.title}
                        className="bg-gray-200 aspect-square w-[80px] rounded border border-gray-200"
                    />
                ) : (
                    <div className="bg-gray-200 aspect-square w-[80px] rounded" />
                )}
            </div>
            <div className="flex-1 py-2 md:pr-2">
                <h2 className="mt-0 text-[16px] mb-1 table">
                    {showData.title ? showData.title : 'Pilotprojekt'}
                </h2>
                <div className="md:flex w-full justify-between text-small">
                    {exampleItem ? <div>P-123-ABC</div> : <div>P-123-CDE</div>}
                    <div>
                        {showData.projectManagerBuilder
                            ? showData.projectManagerBuilder
                            : 'Max Mustermann'}
                    </div>
                    <div>{showData.code}</div>
                    {exampleItem ? <div>Hermance 1248</div> : <div>Hermance 1248</div>}
                </div>
                <div className="flex justify-between border-t border-gray-100 pt-1 mt-1">
                    <div className="flex gap-3">
                        <div>
                            <div className="text-[11px]">Estimated invoicing</div>
                            <div className="text-[16px]">
                                {showData.estimatedInvoicing
                                    ? showData.estimatedInvoicing
                                    : '50.000.000'}
                                ,-
                            </div>
                        </div>
                        <div>
                            <div className="text-[11px]">Invoiced</div>
                            <div className="text-[14px] text-red-400">
                                {showData.invoiced ? showData.invoiced : '45.000.000'},-
                            </div>
                        </div>
                    </div>
                    <div className="flex items-end">
                        <div className="flex text-sm">
                            <div className="mr-2">
                                {externalLinkImg() && (
                                    <a href={externalLink?.link} target="_blank">
                                        <img src={externalLinkImg()} className="h-[16px]" />
                                    </a>
                                )}
                            </div>
                            <div
                                className="bg-gray-100 rounded-sm px-2 py-[1px] ml-2 cursor-pointer hover:bg-gray-200"
                                onClick={handleInformationOpen}
                            >
                                Info
                            </div>
                            <div className="bg-gray-100 rounded-sm px-2 py-[1px] ml-2">BCF</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex md:block md:border-l border-gray-200 p-2">
                {status ? (
                    <>
                        <div
                            className={clsx(
                                'w-[12px] h-[12px] mr-2 md:mr-0 mb-2 rounded-full',
                                status === 'green' ? 'bg-green-400' : 'bg-gray-200',
                            )}
                        ></div>
                        <div
                            className={clsx(
                                'w-[12px] h-[12px] mr-2 md:mr-0 mb-2 rounded-full',
                                status === 'orange' ? 'bg-orange-400' : 'bg-gray-200',
                            )}
                        ></div>
                        <div
                            className={clsx(
                                'w-[12px] h-[12px] mr-2 md:mr-0 mb-2 rounded-full',
                                status === 'red' ? 'bg-red-400' : 'bg-gray-200',
                            )}
                        ></div>
                    </>
                ) : (
                    <>
                        <div className="w-[12px] h-[12px] mr-2 md:mr-0 mb-2 rounded-full bg-gray-200"></div>
                        <div className="w-[12px] h-[12px] mr-2 md:mr-0 mb-2 rounded-full bg-gray-200"></div>
                        <div className="w-[12px] h-[12px] mr-2 md:mr-0 mb-2 rounded-full bg-gray-200"></div>
                    </>
                )}
            </div>
            <div className="md:border-l border-gray-200 py-2 md:p-2 text-[12px] leading-4 w-[110px]">
                {showData.issues?.map((issue, index) => {
                    return (
                        <div
                            key={index}
                            className="text-ellipsis overflow-hidden whitespace-nowrap"
                            title={issue}
                        >
                            {issue}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const FilterTag: React.FC = () => {
    return (
        <div className="bg-gray-200 rounded-sm text-[12px] whitespace-nowrap flex overflow-hidden">
            <span className="hover:bg-gray-400 h-full px-[5px] flex items-center border-r border-gray-300 cursor-pointer">
                <Icon name="times" size={8} />
            </span>
            <span className="py-[1px] pl-[5px] pr-[10px]">Tag example</span>
        </div>
    );
};

const projects = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(i => {
    return {
        id: i + 2,
        code: `CBA-A/${i}`,
        status:
            i === 1
                ? 'green'
                : i === 2
                ? 'orange'
                : (undefined as 'green' | 'orange' | 'red' | undefined),
        issues:
            i === 2
                ? ['Gestoppt', 'Issue2', 'Max Mustermann']
                : i === 5
                ? [
                      'Gestoppt',
                      'Issue2',
                      'Max Mustermann',
                      'Gestoppt',
                      'Issue2',
                      'Max Mustermann',
                      'Gestoppt',
                      'Issue2',
                      'Max Mustermann',
                  ]
                : undefined,
        position:
            // i == 1
            //     ? {
            //           latitude: 47.3891029915352,
            //           longitude: 9.288021423418714,
            //       }
            //     : {
            //           latitude: 46.47650502555427 + i * (i % 2 == 0 ? 0.12 : 0.08),
            //           longitude: 6.25864324850697 + i * (i % 2 == 1 ? 0.33 : 0.27),
            //       },
            i === 1
                ? {
                      latitude: 47.383326013,
                      longitude: 9.283322683,
                  }
                : i === 2
                ? {
                      latitude: 47.421503804,
                      longitude: 8.267951733,
                  }
                : i === 3
                ? {
                      latitude: 47.037698992,
                      longitude: 7.636241374,
                  }
                : i === 4
                ? {
                      latitude: 47.040615215,
                      longitude: 7.63517311,
                  }
                : i === 5
                ? {
                      latitude: 47.009497851,
                      longitude: 7.502660905,
                  }
                : i === 6
                ? {
                      latitude: 47.05825561,
                      longitude: 9.405522202,
                  }
                : i === 7
                ? {
                      latitude: 47.126271972,
                      longitude: 9.304481619,
                  }
                : i === 8
                ? {
                      latitude: 46.672539247,
                      longitude: 6.893971538,
                  }
                : i === 9
                ? {
                      latitude: 46.824538455,
                      longitude: 6.937041681,
                  }
                : i === 10
                ? {
                      latitude: 46.850321183,
                      longitude: 6.923269431,
                  }
                : i === 11
                ? {
                      latitude: 47.573289082,
                      longitude: 8.888048064,
                  }
                : i === 12
                ? {
                      latitude: 46.82908622,
                      longitude: 7.4515008,
                  }
                : i === 13
                ? {
                      latitude: 46.928135532,
                      longitude: 6.930052705,
                  }
                : {
                      latitude: 0,
                      longitude: 0,
                  },

        externalLink:
            i === 1
                ? {
                      type: 'acc',
                      link: 'https://acc.autodesk.com/docs/files/projects/0ee1875d-117e-4127-9c5b-7be3a1acffaa?folderUrn=urn%3aadsk.wipprod%3afs.folder%3aco.XkRpTehdRGy22boikI2uaw&viewModel=detail&moduleId=folders',
                  }
                : i === 2
                ? {
                      type: 'buildAgil',
                      link: 'https://buildagil.sharepoint.com/teams/181579/Freigegebene%20Dokumente/Forms/Items.aspx?Mode=AllItems&ApperanceId=DocumentList&GroupField=Folders&SortField=FileLeafRef+asc&RootFolder=%2Fteams%2F181579%2FFreigegebene%2520Dokumente%2F99%2520IoLabs',
                  }
                : i === 3
                ? {
                      type: 'libal',
                      link: 'https://arimmo.libal-tech.ch/#/file-operations/1273757501/models',
                  }
                : {
                      type: 'dalux',
                      link: 'https://build.dalux.com/client/507528/box/files/7408522',
                  },
    };
});

const ProjectList: React.FC = () => {
    const [apiProjects, setApiProjects] = useState<ProjectBaseResponse[]>();

    useEffect(() => {
        projApi.getProjectList().then(response => {
            const data = response.data;
            if (data) {
                // console.log(data);
                setApiProjects(response.data);
            }
        });
    }, []);

    // Map
    // -------------------------------------------------------------------------------
    const [activeProjectCode, setActiveProjectCode] = useState<string>();
    const handleClickViewerPoint = (point: ClickedPoint) => {
        if (point.object) {
            const pointCode = point.object.graphic?.attributes?.code;
            if (pointCode) {
                setActiveProjectCode(pointCode);
            }
        }
    };

    // Project information + New project
    // -------------------------------------------------------------------------------
    const [projectInformationOpen, setProjectInformationOpen] = useState<boolean>(false);
    const [newProjectOpen, setNewProjectOpen] = useState<boolean>(false);

    const [activeProjectId, setActiveProjectId] = useState<number>();

    const handleNewProjectClick = () => {
        setProjectInformationOpen(true);
        setNewProjectOpen(true);
    };

    const isProjectInformationOpenCallback = isOpen => {
        if (!isOpen) {
            setProjectInformationOpen(false);
            setNewProjectOpen(false);
        }
    };

    // Open project information
    const handleShowInformationCallback = (id: number) => {
        setProjectInformationOpen(true);
        setActiveProjectId(id);
    };

    // highlight project
    const handleSelectedItemCallback = (code: string) => {
        setActiveProjectCode(code);
    };

    return (
        <>
            <div className="h-full">
                <div className="md:flex w-full h-full">
                    <AsideNavigation />
                    <div className="h-full w-full max-w-[800px] flex flex-col">
                        <div className="w-full flex sticky top-0 bg-white border-b border-gray-200 z-[1]">
                            <div className="w-[70px]">
                                <FilterPopup />
                            </div>
                            <div className="flex flex-wrap gap-[5px] p-2 border-l border-gray-200">
                                <FilterTag />
                                <FilterTag />
                                <FilterTag />
                                <FilterTag />
                                <FilterTag />
                            </div>
                        </div>
                        <div className="w-full flex-1 overflow-y-auto">
                            <ListItem
                                handleShowInformationCallback={handleShowInformationCallback}
                                handleSelectedItemCallback={handleSelectedItemCallback}
                                id={3}
                                showData={{
                                    title: 'P-123 Hermance 4321',
                                    code: 'CBA-A/4321',
                                    projectManagerBuilder: 'Max Mustermann',
                                }}
                                externalLink={{
                                    type: 'acc',
                                    link: 'https://acc.autodesk.com/docs/files/projects/0ee1875d-117e-4127-9c5b-7be3a1acffaa?folderUrn=urn%3aadsk.wipprod%3afs.folder%3aco.XkRpTehdRGy22boikI2uaw&viewModel=detail&moduleId=folders',
                                }}
                                exampleItem
                            />

                            <div className="border-t border-b border-black">
                                {apiProjects?.map((project, index) => {
                                    return (
                                        <ListItem
                                            key={index}
                                            id={project.projectID}
                                            showData={{
                                                code: project.code,
                                                title: project.name,
                                            }}
                                            handleShowInformationCallback={
                                                handleShowInformationCallback
                                            }
                                            handleSelectedItemCallback={handleSelectedItemCallback}
                                        />
                                    );
                                })}
                            </div>

                            {projects.map((pr, index) => (
                                <ListItem
                                    key={index}
                                    id={pr.id}
                                    showData={{
                                        code: pr.code,
                                        status: pr.status,
                                        issues: pr.issues,
                                    }}
                                    handleShowInformationCallback={handleShowInformationCallback}
                                    handleSelectedItemCallback={handleSelectedItemCallback}
                                    highlight={pr.code === activeProjectCode}
                                    externalLink={pr.externalLink}
                                />
                            ))}
                        </div>
                        <div className="flex justify-between items-center bg-white border-b border-t border-gray-200">
                            <div className="p-2 lex items-center">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleNewProjectClick}
                                >
                                    New project
                                </Button>
                            </div>
                            <div className="">
                                <Pagination
                                    count={10}
                                    renderItem={item => (
                                        <PaginationItem
                                            slots={{
                                                previous: ArrowBackIcon,
                                                next: ArrowForwardIcon,
                                            }}
                                            {...item}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-100 flex-1 flex justify-center items-center">
                        {testHideMap ? (
                            <div className="text-gray-300 text-4xl">MAP</div>
                        ) : (
                            <EsriViewer
                                center={[8.443467804484614, 46.78315878974793]}
                                zoom={8.3}
                                onClick={handleClickViewerPoint}
                                disableAutoPopup={true}
                                activeSlide={activeProjectCode}
                                points={projects.map(pr => {
                                    return {
                                        code: pr.code,
                                        latitude: pr.position.latitude,
                                        longitude: pr.position.longitude,
                                        symbol: {
                                            color: new Color(
                                                activeProjectCode == pr.code
                                                    ? [220, 100, 24]
                                                    : [220, 0, 24],
                                            ),
                                        },
                                    };
                                })}
                            />
                        )}
                    </div>
                </div>
            </div>

            {projectInformationOpen && (
                <ProjectInformation
                    open={true}
                    id={activeProjectId}
                    isOpenCallback={isProjectInformationOpenCallback}
                    newProject={newProjectOpen}
                />
            )}
        </>
    );
};

export default ProjectList;
