import { defineMessages } from 'react-intl';

export default defineMessages({
    titleConcepts: {
        id: 'components.projectInformation.concepts.title.Concepts',
        defaultMessage: 'Concepts', // DE: Konzepte
    },

    labelConcept: {
        id: 'components.projectInformation.concepts.label.concept',
        defaultMessage: 'Concept', // DE: Konzept
    },
    placeholderConcept: {
        id: 'components.projectInformation.concepts.placeholder.concept',
        defaultMessage: 'Placeholder text', // DE:
    },

    labelComment: {
        id: 'components.projectInformation.concepts.label.comment',
        defaultMessage: 'Comment', // DE: Anmerkung
    },
    placeholderComment: {
        id: 'components.projectInformation.concepts.placeholder.comment',
        defaultMessage: 'Placeholder text', // DE:
    },

    labelResponsible: {
        id: 'components.projectInformation.concepts.label.responsible',
        defaultMessage: 'Responsible', // DE: Zuständig
    },
    placeholderResponsible: {
        id: 'components.projectInformation.concepts.placeholder.responsible',
        defaultMessage: 'Placeholder text', // DE:
    },

    labelDeadline: {
        id: 'components.projectInformation.concepts.label.deadline',
        defaultMessage: 'Deadline', // DE: Termin
    },

    labelStatus: {
        id: 'components.projectInformation.concepts.label.status',
        defaultMessage: 'Status', // DE: Status
    },

    buttonNewRow: {
        id: 'components.projectInformation.concepts.button.newRow',
        defaultMessage: 'New row', // DE: Neue Zeile
    },
});
