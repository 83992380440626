export const generateNegativeID = (arr: any[], key: string): number => {
    let lowestNegativeNumber = 0;
    for (const obj of arr) {
        if (obj.hasOwnProperty(key) && typeof obj[key] === 'number' && obj[key] < 0) {
            if (obj[key] < lowestNegativeNumber) {
                lowestNegativeNumber = obj[key];
            }
        }
    }
    return lowestNegativeNumber - 1;
};
