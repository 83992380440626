import { Button } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import {
    selectCommonSettings,
    selectIsLastReport,
} from '../../../../redux/projectInformation/projectInformationSlice';
import Icon from '../../../Icon/Icon';

export interface IStateEditRowProps {
    editFieldComponent?: React.ReactNode;
    editFieldValue?: string; // todo
    editFieldCallbackValue?: (value: string) => void;
    // todo oldState
    // todo stateSelector
    // todo stateSelectorCallback
    oldStateComponent?: React.ReactNode;
    stateSelectorComponent?: React.ReactNode;
    handleDelete: () => void;
}

const StateEditRow: React.FC<IStateEditRowProps> = ({
    editFieldComponent,
    stateSelectorComponent,
    oldStateComponent,
    handleDelete,
}) => {
    const settings = useSelector(selectCommonSettings);
    const isLastReport = useSelector(selectIsLastReport);
    const handleDeleteButton = () => {
        handleDelete();
    };
    return (
        // <div className="flex gap-5 my-2 justify-between items-center border border-gray-200 rounded pl-4 pr-2 shadow">
        <div className="flex gap-5 items-center py-1">
            <div className="flex-1">{editFieldComponent}</div>
            <div className="flex gap-2 items-center">
                {settings.showPreviousVersion && <>{oldStateComponent}</>}
                {stateSelectorComponent}
                <Button
                    variant="text"
                    size="small"
                    onClick={handleDeleteButton}
                    style={{
                        maxWidth: '30px',
                        maxHeight: '30px',
                        minWidth: '30px',
                        minHeight: '30px',
                    }}
                    disabled={!isLastReport}
                >
                    <Icon name="trash" size={14} />
                </Button>
            </div>
        </div>
    );
};

export default StateEditRow;
