import { createListenerMiddleware } from '@reduxjs/toolkit';
import { diff } from 'deep-object-diff';
import { isEmpty } from 'lodash';

import {
    ConceptBaseResponse,
    DocumentBaseResponse,
    MilestoneDeadlineBase,
    PendencyBaseResponse,
    ProjectReportPersonBaseResponse,
    RiskBaseResponse,
    TextualItemBaseResponse,
    TradeBaseResponse,
} from '../../generate/api';
import { projReportsApi } from '../../packages/Api/data/projectReports/client';
import { propertiesToArray } from '../../utils/Object';
import { store } from '../store';
import {
    deleteConcept,
    deleteDocument,
    deletePendency,
    deleteProjectReportPerson,
    deleteRisk,
    deleteTextualItem,
    deleteTrade,
    IProjectInformationState,
    setFormAffectedFields,
    setFormSavingStatus,
    switchConcept,
    switchDocument,
    switchPendency,
    switchProjectReportPerson,
    switchRisk,
    switchTextualItem,
    switchTrade,
    updateConcept,
    updateDocument,
    updatePendency,
    updateProjectReportPerson,
    updateReportMilestoneDeadline,
    updateRisk,
    updateTextualItem,
    updateTrade,
} from './projectInformationSlice';

export const listenerMiddleware = createListenerMiddleware({
    onError: (error, errorInfo) => {
        console.error('Error in listenerMiddleware', error, errorInfo);
        store.dispatch(
            setFormSavingStatus({
                status: 'failed',
                error: error,
                affectedFieldNames: [],
            }),
        );
    },
});

// TODO make following listeners more generic and reuse code as much as possible
// TODO remove tempID from request objects before sending them to the server

// Pendencies
// ------------------------------------------------------------------
listenerMiddleware.startListening({
    actionCreator: updatePendency,
    effect: async (action, listenerApi) => {
        const pendencyID = action.payload.pendencyID as number;

        console.log(`Pendency ID: ${pendencyID} detecting changes`);

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        const originalPendency: PendencyBaseResponse = currentReport.pendencies.find(
            p => p.pendencyID === pendencyID,
        ) as PendencyBaseResponse;

        if (!originalPendency) {
            return;
        }

        const newPendency: PendencyBaseResponse = action.payload;

        const diffChanges = diff(originalPendency, newPendency);
        if (isEmpty(diffChanges)) {
            return;
        }

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: propertiesToArray(diffChanges).map(
                    p => `pendencies[${pendencyID}].${p}`,
                ),
            }),
        );
        console.log(`Pendency ID: ${pendencyID} changed, calling api, changes:`, diffChanges);

        if (pendencyID < 0) {
            // create new pendency
            const response = await projReportsApi.addPendency(
                currentReport.report?.projectID as number,
                currentReport.report?.projectReportID as number,
                newPendency,
            );
            console.log(`Pendency ID: ${pendencyID} created, response:`, response.data);
            listenerApi.dispatch(switchPendency({ tempID: pendencyID, newEntity: response.data }));
        } else {
            // update existing pendency
            const response = await projReportsApi.updatePendency(
                pendencyID,
                currentReport.report?.projectID as number,
                currentReport.report?.projectReportID as number,
                newPendency,
            );
            console.log(`Pendency ID: ${pendencyID} updated, response:`, response.data);
        }
        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );

        // listenerApi.dispatch(updatePendency(response.data));
    },
});

listenerMiddleware.startListening({
    actionCreator: deletePendency,
    effect: async (action, listenerApi) => {
        const pendencyID = action.payload as number;

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: [`pendencies[${pendencyID}]`],
            }),
        );
        console.log(`Pendency ID: ${pendencyID} to be deleted, calling api`);

        const response = await projReportsApi.deletePendency(
            pendencyID,
            currentReport.report?.projectID as number,
            currentReport.report?.projectReportID as number,
        );

        console.log(`Pendency ID: ${pendencyID} deleted, response:`, response.data);

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );
    },
});

// Textual Items
// ------------------------------------------------------------------
listenerMiddleware.startListening({
    actionCreator: updateTextualItem,
    effect: async (action, listenerApi) => {
        const textualItemID = action.payload.textualItemID as number;

        console.log(`TextualItem ID: ${textualItemID} detecting changes`);

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        const originalTextualItem: TextualItemBaseResponse = currentReport.textualItems.find(
            p => p.textualItemID === textualItemID,
        ) as TextualItemBaseResponse;

        if (!originalTextualItem) {
            return;
        }

        const newTextualItem: TextualItemBaseResponse = action.payload;

        const diffChanges = diff(originalTextualItem, newTextualItem);
        if (isEmpty(diffChanges)) {
            return;
        }

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: propertiesToArray(diffChanges).map(
                    p => `textualItems[${textualItemID}].${p}`,
                ),
            }),
        );

        console.log(`TextualItem ID: ${textualItemID} changed, calling api, changes:`, diffChanges);

        if (textualItemID < 0) {
            // create new textualItem
            const response = await projReportsApi.addTextualItem(
                currentReport.report?.projectID as number,
                currentReport.report?.projectReportID as number,
                newTextualItem,
            );
            console.log(`TextualItem ID: ${textualItemID} created, response:`, response.data);
            listenerApi.dispatch(
                switchTextualItem({ tempID: textualItemID, newEntity: response.data }),
            );
        } else {
            // update existing textualItem
            const response = await projReportsApi.updateTextualItem(
                textualItemID,
                currentReport.report?.projectID as number,
                currentReport.report?.projectReportID as number,
                newTextualItem,
            );
            console.log(`TextualItem ID: ${textualItemID} updated, response:`, response.data);
        }

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );
        // listenerApi.dispatch(updateTextualItem(response.data));
    },
});

listenerMiddleware.startListening({
    actionCreator: deleteTextualItem,
    effect: async (action, listenerApi) => {
        const textualItemID = action.payload as number;

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: [`textualItems[${textualItemID}]`],
            }),
        );

        console.log(`TextualItem ID: ${textualItemID} to be deleted, calling api`);

        const response = await projReportsApi.deleteTextualItem(
            textualItemID,
            currentReport.report?.projectID as number,
            currentReport.report?.projectReportID as number,
        );

        console.log(`TextualItem ID: ${textualItemID} deleted, response:`, response.data);

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );
    },
});

// Risks
// ------------------------------------------------------------------
listenerMiddleware.startListening({
    actionCreator: updateRisk,
    effect: async (action, listenerApi) => {
        const riskID = action.payload.riskID as number;

        console.log(`Risk ID: ${riskID} detecting changes`);

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        const originalRisk: RiskBaseResponse = currentReport.risks.find(
            p => p.riskID === riskID,
        ) as RiskBaseResponse;

        if (!originalRisk) {
            return;
        }

        const newRisk: RiskBaseResponse = action.payload;

        const diffChanges = diff(originalRisk, newRisk);
        if (isEmpty(diffChanges)) {
            return;
        }

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: propertiesToArray(diffChanges).map(
                    p => `risks[${riskID}].${p}`,
                ),
            }),
        );

        console.log(`Risk ID: ${riskID} changed, calling api, changes:`, diffChanges);

        if (riskID < 0) {
            // create new risk
            const response = await projReportsApi.addRisk(
                currentReport.report?.projectID as number,
                currentReport.report?.projectReportID as number,
                newRisk,
            );
            console.log(`Risk ID: ${riskID} created, response:`, response.data);
            listenerApi.dispatch(switchRisk({ tempID: riskID, newEntity: response.data }));
        } else {
            // update existing risk
            const response = await projReportsApi.updateRisk(
                riskID,
                currentReport.report?.projectID as number,
                currentReport.report?.projectReportID as number,
                newRisk,
            );
            console.log(`Risk ID: ${riskID} updated, response:`, response.data);
        }

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );

        // listenerApi.dispatch(updateRisk(response.data));
    },
});

listenerMiddleware.startListening({
    actionCreator: deleteRisk,
    effect: async (action, listenerApi) => {
        const riskID = action.payload as number;

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: [`risks[${riskID}]`],
            }),
        );

        console.log(`Risk ID: ${riskID} to be deleted, calling api`);

        const response = await projReportsApi.deleteRisk(
            riskID,
            currentReport.report?.projectID as number,
            currentReport.report?.projectReportID as number,
        );

        console.log(`Risk ID: ${riskID} deleted, response:`, response.data);

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );
    },
});

// Concepts
// ------------------------------------------------------------------
listenerMiddleware.startListening({
    actionCreator: updateConcept,
    effect: async (action, listenerApi) => {
        const conceptID = action.payload.conceptID as number;

        console.log(`Concept ID: ${conceptID} detecting changes`);

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        const originalConcept: ConceptBaseResponse = currentReport.concepts.find(
            p => p.conceptID === conceptID,
        ) as ConceptBaseResponse;

        if (!originalConcept) {
            return;
        }

        const newConcept: ConceptBaseResponse = action.payload;

        const diffChanges = diff(originalConcept, newConcept);
        if (isEmpty(diffChanges)) {
            return;
        }

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: propertiesToArray(diffChanges).map(
                    p => `concepts[${conceptID}].${p}`,
                ),
            }),
        );

        console.log(`Concept ID: ${conceptID} changed, calling api, changes:`, diffChanges);

        if (conceptID < 0) {
            // create new concept
            const response = await projReportsApi.addConcept(
                currentReport.report?.projectID as number,
                currentReport.report?.projectReportID as number,
                newConcept,
            );
            console.log(`Concept ID: ${conceptID} created, response:`, response.data);
            listenerApi.dispatch(switchConcept({ tempID: conceptID, newEntity: response.data }));
        } else {
            // update existing concept
            const response = await projReportsApi.updateConcept(
                conceptID,
                currentReport.report?.projectID as number,
                currentReport.report?.projectReportID as number,
                newConcept,
            );
            console.log(`Concept ID: ${conceptID} updated, response:`, response.data);
        }
        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );

        // listenerApi.dispatch(updateConcept(response.data));
    },
});

listenerMiddleware.startListening({
    actionCreator: deleteConcept,
    effect: async (action, listenerApi) => {
        const conceptID = action.payload as number;

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: [`concepts[${conceptID}]`],
            }),
        );

        console.log(`Concept ID: ${conceptID} to be deleted, calling api`);

        const response = await projReportsApi.deleteConcept(
            conceptID,
            currentReport.report?.projectID as number,
            currentReport.report?.projectReportID as number,
        );

        console.log(`Concept ID: ${conceptID} deleted, response:`, response.data);

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );
    },
});

// Documents
// ------------------------------------------------------------------
listenerMiddleware.startListening({
    actionCreator: updateDocument,
    effect: async (action, listenerApi) => {
        const documentID = action.payload.documentID as number;

        console.log(`Document ID: ${documentID} detecting changes`);

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        const originalDocument: DocumentBaseResponse = currentReport.documents.find(
            p => p.documentID === documentID,
        ) as DocumentBaseResponse;

        if (!originalDocument) {
            return;
        }

        const newDocument: DocumentBaseResponse = action.payload;

        const diffChanges = diff(originalDocument, newDocument);
        if (isEmpty(diffChanges)) {
            return;
        }

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: propertiesToArray(diffChanges).map(
                    p => `documents[${documentID}].${p}`,
                ),
            }),
        );

        console.log(`Document ID: ${documentID} changed, calling api, changes:`, diffChanges);

        if (documentID < 0) {
            // create new document
            const response = await projReportsApi.addDocument(
                currentReport.report?.projectID as number,
                currentReport.report?.projectReportID as number,
                newDocument,
            );
            console.log(`Document ID: ${documentID} created, response:`, response.data);
            listenerApi.dispatch(switchDocument({ tempID: documentID, newEntity: response.data }));
        } else {
            // update existing document
            const response = await projReportsApi.updateDocument(
                documentID,
                currentReport.report?.projectID as number,
                currentReport.report?.projectReportID as number,
                newDocument,
            );
            console.log(`Document ID: ${documentID} updated, response:`, response.data);
        }
        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );

        // listenerApi.dispatch(updateDocument(response.data));
    },
});

listenerMiddleware.startListening({
    actionCreator: deleteDocument,
    effect: async (action, listenerApi) => {
        const documentID = action.payload as number;

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: [`documents[${documentID}]`],
            }),
        );

        console.log(`Document ID: ${documentID} to be deleted, calling api`);

        const response = await projReportsApi.deleteDocument(
            documentID,
            currentReport.report?.projectID as number,
            currentReport.report?.projectReportID as number,
        );

        console.log(`Document ID: ${documentID} deleted, response:`, response.data);

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );
    },
});

// Trades
// ------------------------------------------------------------------
listenerMiddleware.startListening({
    actionCreator: updateTrade,
    effect: async (action, listenerApi) => {
        const tradeID = action.payload.tradeID as number;

        console.log(`Trade ID: ${tradeID} detecting changes`);

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        const originalTrade: TradeBaseResponse = currentReport.trades.find(
            p => p.tradeID === tradeID,
        ) as TradeBaseResponse;

        if (!originalTrade) {
            return;
        }

        const newTrade: TradeBaseResponse = action.payload;

        const diffChanges = diff(originalTrade, newTrade);
        if (isEmpty(diffChanges)) {
            return;
        }

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: propertiesToArray(diffChanges).map(
                    p => `trades[${tradeID}].${p}`,
                ),
            }),
        );

        console.log(`Trade ID: ${tradeID} changed, calling api, changes:`, diffChanges);

        if (tradeID < 0) {
            // create new trade
            const response = await projReportsApi.addTrade(
                currentReport.report?.projectID as number,
                currentReport.report?.projectReportID as number,
                newTrade,
            );
            console.log(`Trade ID: ${tradeID} created, response:`, response.data);
            listenerApi.dispatch(switchTrade({ tempID: tradeID, newEntity: response.data }));
        } else {
            // update existing trade
            const response = await projReportsApi.updateTrade(
                tradeID,
                currentReport.report?.projectID as number,
                currentReport.report?.projectReportID as number,
                newTrade,
            );
            console.log(`Trade ID: ${tradeID} updated, response:`, response.data);
        }
        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );

        // listenerApi.dispatch(updateTrade(response.data));
    },
});

listenerMiddleware.startListening({
    actionCreator: deleteTrade,
    effect: async (action, listenerApi) => {
        const tradeID = action.payload as number;

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: [`trades[${tradeID}]`],
            }),
        );

        console.log(`Trade ID: ${tradeID} to be deleted, calling api`);

        const response = await projReportsApi.deleteTrade(
            tradeID,
            currentReport.report?.projectID as number,
            currentReport.report?.projectReportID as number,
        );

        console.log(`Trade ID: ${tradeID} deleted, response:`, response.data);

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );
    },
});

// People
// ------------------------------------------------------------------
listenerMiddleware.startListening({
    actionCreator: updateProjectReportPerson,
    effect: async (action, listenerApi) => {
        const projectReportPersonID = action.payload.projectReportPersonID as number;

        console.log(`ProjectReportPerson ID: ${projectReportPersonID} detecting changes`);

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        const originalProjectPerson: ProjectReportPersonBaseResponse =
            currentReport.projectReportPeople.find(
                p => p.projectReportPersonID === projectReportPersonID,
            ) as ProjectReportPersonBaseResponse;

        if (!originalProjectPerson) {
            return;
        }

        const newProjectPerson: ProjectReportPersonBaseResponse = action.payload;

        const diffChanges = diff(originalProjectPerson, newProjectPerson);
        if (isEmpty(diffChanges)) {
            return;
        }

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: propertiesToArray(diffChanges).map(
                    p => `projectReportPeople[${projectReportPersonID}].${p}`,
                ),
            }),
        );

        console.log(
            `ProjectReportPerson ID: ${projectReportPersonID} changed, calling api, changes:`,
            diffChanges,
        );

        if (projectReportPersonID < 0) {
            // create new trade
            const response = await projReportsApi.addProjectReportPerson(
                currentReport.report?.projectID as number,
                currentReport.report?.projectReportID as number,
                newProjectPerson,
            );
            console.log(
                `ProjectReportPerson ID: ${projectReportPersonID} created, response:`,
                response.data,
            );
            listenerApi.dispatch(
                switchProjectReportPerson({
                    tempID: projectReportPersonID,
                    newEntity: response.data,
                }),
            );
        } else {
            // update existing trade
            const response = await projReportsApi.updateProjectReportPerson(
                projectReportPersonID,
                currentReport.report?.projectID as number,
                currentReport.report?.projectReportID as number,
                newProjectPerson,
            );
            console.log(
                `ProjectReportPerson ID: ${projectReportPersonID} updated, response:`,
                response.data,
            );
        }
        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );

        // listenerApi.dispatch(updateTrade(response.data));
    },
});

listenerMiddleware.startListening({
    actionCreator: deleteProjectReportPerson,
    effect: async (action, listenerApi) => {
        const projectReportPersonID = action.payload as number;

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: [`projectReportPeople[${projectReportPersonID}]`],
            }),
        );

        console.log(`ProjectReportPerson ID: ${projectReportPersonID} to be deleted, calling api`);

        const response = await projReportsApi.deleteProjectReportPerson(
            projectReportPersonID,
            currentReport.report?.projectID as number,
            currentReport.report?.projectReportID as number,
        );

        console.log(
            `ProjectReportPerson ID: ${projectReportPersonID} deleted, response:`,
            response.data,
        );

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );
    },
});

// ------------------------------------------------------------------
// Different scheme for function below

// Milestone deadlines
// TODO: refactor to use partial update
// ------------------------------------------------------------------
listenerMiddleware.startListening({
    actionCreator: updateReportMilestoneDeadline,
    effect: async (action, listenerApi) => {
        const projectMilestoneID = action.payload.projectMilestoneID as number;

        console.log(
            `Milestone deadline for milestone with ID: ${projectMilestoneID} detecting changes`,
        );

        const currentReport = (
            (listenerApi.getOriginalState() as any).projectInformation as IProjectInformationState
        ).currentReport;

        const originalDeadline: MilestoneDeadlineBase | undefined =
            currentReport.report?.milestoneDeadlines?.find(
                p => p.projectMilestoneID === projectMilestoneID,
            );

        const newDeadline: MilestoneDeadlineBase = action.payload;

        let diffChanges;
        if (originalDeadline) {
            diffChanges = diff(originalDeadline, newDeadline);
            if (isEmpty(diffChanges)) {
                return;
            }
        } else {
            diffChanges = newDeadline;
        }

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'loading',
                affectedFieldNames: propertiesToArray(diffChanges).map(
                    p => `milestoneDeadlines[${projectMilestoneID}].${p}`,
                ),
            }),
        );

        console.log(
            `Milestone deadline for milestone with ID: ${projectMilestoneID} changed, calling api, changes:`,
            diffChanges,
        );

        const toChange = [...(currentReport.report?.milestoneDeadlines ?? [])];
        const index = toChange.findIndex(p => p.projectMilestoneID === projectMilestoneID);
        if (index >= 0) {
            toChange[index] = newDeadline;
        } else {
            toChange.push(newDeadline);
        }

        // create new trade
        const response = await projReportsApi.postProjectReportsUpdatePartial(
            currentReport.report?.projectReportID as number,
            currentReport.report?.projectID as number,
            {
                milestoneDeadlines: toChange,
            },
        );
        console.log(
            `Milestone deadline for milestone with ID: ${projectMilestoneID} created, response:`,
            response.data,
        );

        listenerApi.dispatch(
            setFormSavingStatus({
                status: 'succeeded',
                affectedFieldNames: [],
            }),
        );

        // listenerApi.dispatch(updateTrade(response.data));
    },
});
