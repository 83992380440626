import { defineMessages } from 'react-intl';

export default defineMessages({
    veryUnlikely: {
        id: 'components.projectInformation.humanResources.VeryUnlikely',
        defaultMessage: 'Very unlikely', // DE: sehr unwahrscheinlich
    },

    unlikely: {
        id: 'components.projectInformation.humanResources.Unlikely',
        defaultMessage: 'Unlikely', // DE: unwahrscheinlich
    },

    quality: {
        id: 'components.projectInformation.humanResources.Quality',
        defaultMessage: 'Quality / expected results', // DE: Qualität / erwartete Ergebnisse
    },

    measures: {
        id: 'components.projectInformation.humanResources.Measures',
        defaultMessage: 'Measures', // DE: Massnahmen
    },

    assessment: {
        id: 'components.projectInformation.humanResources.Assessment',
        defaultMessage: 'Assessment', // DE: Beurteilung
    },
});
