import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

export interface IFormDatepickerProps {
    value?: string;
    onChange: (date: string) => void;
    disabled?: boolean;
}

export const FormDatepicker: React.FC<IFormDatepickerProps> = ({ value, onChange, disabled }) => {
    const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

    const [newValue, setNewValue] = useState<string>();
    useEffect(() => {
        setNewValue(value);
    }, [value]);

    const handleDateChange = date => {
        const formattedDate = dayjs(date).format(dateFormat);
        setNewValue(formattedDate);
        onChange(formattedDate);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={newValue || null}
                onChange={handleDateChange}
                inputFormat="DD.MM.YYYY"
                disabled={disabled}
                renderInput={params => (
                    <TextField
                        {...params}
                        size="small"
                        margin="none"
                        fullWidth
                        autoComplete="off"
                        value={newValue ? dayjs(newValue).format(dateFormat) : ''}
                    />
                )}
            />
        </LocalizationProvider>
    );
};
