import { MenuItem, TextField } from '@mui/material';
import Select from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { RiskBaseResponse } from '../../../../generate/api';
import {
    selectCommonSettings,
    selectIsLastReport,
    selectPeople,
    selectRisks,
    updateRisk,
} from '../../../../redux/projectInformation/projectInformationSlice';
import StateSelector from '../../../Form/StateSelector/StateSelector';
import styleTable from '../../styles/table.module.css';
import messages from './messages';
import { IRowProps } from './Risks';

const Row: React.FC<IRowProps> = ({ formRowData, number }) => {
    const intl = useIntl();
    const settings = useSelector(selectCommonSettings);
    const isLastReport = useSelector(selectIsLastReport);
    const people = useSelector(selectPeople);
    const dispatch = useDispatch();

    const [row, setRow] = useState<RiskBaseResponse>(formRowData);

    const reduxRow = useSelector(selectRisks).find(risk => risk.riskID === row.riskID);

    useEffect(() => {
        setRow(formRowData);
    }, [formRowData]);

    const handleFieldChange = (fieldName, value) => {
        setRow({ ...row, [fieldName]: value });
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            // Compare if the reduxRow is different from the row
            if (
                reduxRow?.opportunityDevelopmentDescription ===
                    row.opportunityDevelopmentDescription &&
                reduxRow?.opportunityDevelopmentRiskTypeText ===
                    row.opportunityDevelopmentRiskTypeText &&
                reduxRow?.opportunityDevelopmentMeasure === row.opportunityDevelopmentMeasure &&
                reduxRow?.opportunityDevelopmentWhoID === row.opportunityDevelopmentWhoID &&
                reduxRow?.opportunityDevelopmentStatusID === row.opportunityDevelopmentStatusID
            )
                return;

            if (row?.riskID) {
                dispatch(updateRisk(row));
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [row]);

    return (
        <tr>
            <th>{number}</th>
            <td>{row.name}</td>
            <td>
                <TextField
                    value={row.opportunityDevelopmentDescription}
                    onChange={e =>
                        handleFieldChange('opportunityDevelopmentDescription', e.target.value)
                    }
                    fullWidth
                    size="small"
                    margin="none"
                    multiline
                    placeholder={intl.formatMessage({ ...messages.placeholderDescription })}
                    disabled={!isLastReport}
                />
            </td>
            <td>
                <TextField
                    value={row.opportunityDevelopmentRiskTypeText}
                    onChange={e =>
                        handleFieldChange('opportunityDevelopmentRiskTypeText', e.target.value)
                    }
                    fullWidth
                    size="small"
                    margin="none"
                    placeholder={intl.formatMessage({ ...messages.placeholderType })}
                    disabled={!isLastReport}
                />
            </td>
            <td>
                <TextField
                    value={row.opportunityDevelopmentMeasure}
                    onChange={e =>
                        handleFieldChange('opportunityDevelopmentMeasure', e.target.value)
                    }
                    fullWidth
                    size="small"
                    margin="none"
                    placeholder={intl.formatMessage({ ...messages.placeholderMeasure })}
                    disabled={!isLastReport}
                />
            </td>
            <td>
                <Select
                    value={row.opportunityDevelopmentWhoID || ''}
                    onChange={e => handleFieldChange('opportunityDevelopmentWhoID', e.target.value)}
                    fullWidth
                    size="small"
                    margin="none"
                    displayEmpty
                    disabled={!isLastReport}
                >
                    <MenuItem value={''}>-</MenuItem>
                    {people.map(person => (
                        <MenuItem key={person.personID} value={person.personID}>
                            {person.lastName} {person.firstName}
                        </MenuItem>
                    ))}
                </Select>
            </td>
            <td>
                <div className="flex gap-2">
                    {settings.showPreviousVersion && (
                        <StateSelector
                            category="risk" // tode - change to dynamic
                            value={row?.previousOpportunityDevelopmentStatusID || undefined}
                            oldState
                        />
                    )}
                    <StateSelector
                        category="risk" // tode - change to dynamic
                        canBeEmpty
                        value={row?.opportunityDevelopmentStatusID || undefined}
                        onChange={e =>
                            handleFieldChange('opportunityDevelopmentStatusID', e.target.value)
                        }
                        disabled={!isLastReport}
                    />
                </div>
            </td>
        </tr>
    );
};

const RiskOpportunityTable: React.FC = () => {
    const reduxRisksData = useSelector(selectRisks); // Fetch data from Redux

    return (
        <>
            <div className={styleTable.tableOuterBorder}>
                <table className={styleTable.table}>
                    <thead>
                        <tr>
                            <th className="w-0">
                                <FormattedMessage {...messages.labelNo} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelRisk} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelDescription} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelType} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelMeasure} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelWho} />
                            </th>
                            <th className="w-0">
                                <FormattedMessage {...messages.labelStatus} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {reduxRisksData?.map((row, index) => (
                            <Row formRowData={row} key={index} number={index + 1} />
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default RiskOpportunityTable;
