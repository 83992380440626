import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        esriViewer: {
            width: '100%',
            height: '100%',
            padding: 0,
            margin: 0,
            position: 'relative',
        },
        reactToolsOverlay: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
        },
        reactToolsButtons: {
            position: 'absolute',
            display: 'flex',
            flexFlow: 'column',
            zIndex: 9999,
            fontSize: '0.5rem',
            right: theme.spacing(2),
            top: theme.spacing(2),
            gap: theme.spacing(1),
            width: '4em',
            '&, & *': {
                pointerEvents: 'all',
            },
            '& .material-icons': {
                fontSize: theme.typography.pxToRem(15),
            },
            '& .MuiButton-root': {
                // width: '1em',
                padding: theme.spacing(0.5),
                // maxWidth: '1em',
                minWidth: '1em',
                color: theme.palette.grey[900],
                borderColor: theme.palette.grey[400],
                borderStyle: 'solid',
                borderWidth: '1px',
                backgroundColor: theme.palette.common.white,
            },
        },
        reactToolsActive: {
            backgroundColor: `${theme.palette.primary.main}!important`,
            color: `${theme.palette.common.white}!important`,
            borderColor: theme.palette.primary.main,
        },
        reactToolsDialog: {
            pointerEvents: 'all',
            marginBottom: theme.spacing(10),
            display: 'none',
            minWidth: '15em',
        },
        reactToolsActiveDialog: {
            display: 'block',
        },
        reactToolsCards: {
            position: 'absolute',
            right: theme.spacing(7),
            top: theme.spacing(2),
        },
        reactToolsDialogContent: {
            marginLeft: '-16px',
            marginRight: '-16px',
            marginBottom: '-16px',
        },
        closeIcon: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
        filterCard: {
            display: 'flex',
            '& .MuiTypography-root': {
                fontSize: '0.8rem',
            },
        },
    }),
);

export default useStyles;
