import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Pendencies from '../../components/Pendencies/Pendencies';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import sectionTitleMessages from '../../MainForm/sectionTitleMessages';
import messages from './messages';

const HumanResources = () => {
    const intl = useIntl();
    return (
        <section className="my-[100px]">
            <section id="section-03" className="scroll-my-5">
                <SectionHeader
                    mainSection
                    number={3}
                    title={intl.formatMessage({ ...sectionTitleMessages.humanResources })}
                    aspect="humanResourcesOverallAspect"
                />
            </section>

            <section className="my-[50px]">
                <SectionHeader
                    number={3.1}
                    subSection
                    title={intl.formatMessage({ ...sectionTitleMessages.internalProjectResources })}
                    aspect="internalProjectResourcesAspect"
                />
                <div className="my-8">
                    <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                        <div className="font-medium">
                            <FormattedMessage {...messages.assessment} />
                        </div>
                    </div>
                    <Pendencies code={'3.1.1'} />
                </div>
                <div className="my-8">
                    <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                        <div className="font-medium">
                            <FormattedMessage {...messages.quality} />
                        </div>
                    </div>
                    <Pendencies code={'3.1.2'} />
                </div>
                <div className="my-8">
                    <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                        <div className="font-medium">
                            <FormattedMessage {...messages.measures} />
                        </div>
                    </div>
                    <Pendencies code={'3.1.3'} />
                </div>
            </section>

            <section className="my-[50px]">
                <SectionHeader
                    number={3.2}
                    subSection
                    title={intl.formatMessage({ ...sectionTitleMessages.externalProjectResources })}
                    aspect="externalProjectResourcesAspect"
                />
                <div className="my-8">
                    <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                        <div className="font-medium">
                            <FormattedMessage {...messages.assessment} />
                        </div>
                    </div>
                    <Pendencies code={'3.2.1'} />
                </div>
                <div className="my-8">
                    <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                        <div className="font-medium">
                            <FormattedMessage {...messages.quality} />
                        </div>
                    </div>
                    <Pendencies code={'3.2.2'} />
                </div>
                <div className="my-8">
                    <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                        <div className="font-medium">
                            <FormattedMessage {...messages.measures} />
                        </div>
                    </div>
                    <Pendencies code={'3.2.3'} />
                </div>
            </section>
        </section>
    );
};

export default HumanResources;
