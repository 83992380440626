import { Button, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { PersonBase } from '../../generate/api';
import { peopleApi } from '../../packages/Api/data/people/client';
import {
    selectPersonModal,
    setPersonModal,
} from '../../redux/projectInformation/projectInformationSlice';
import { fetchAndStorePeopleList } from '../ProjectInformation/apiActions';
import messages from './messages';
import style from './style.module.css';

const PersonForm: React.FC = () => {
    /*
     * TODO
     *  - update button
     *  - cancel button
     *  - loading state
     *  - error handling
     *    - error message
     *  - success message --- must fix notifier
     *  - edit variant
     *  - create variant --- almost done
     *  - functions for fetch data from api after edit
     *  - autocomplete / checking if email is already in use
     *  - allow submit email only if it is not in database / if it is in database, show error
     *  - error handling
     */

    /**
     * Testing
     */
    useEffect(() => {
        // store.dispatch(setPersonModal({ isOpen: true, personID: undefined }));
        // store.dispatch(setPersonModal({ isOpen: true, personID: 1 }));
    }, []);

    const initialFormData: PersonBase = {
        userName: '',
        email: '',
        firstName: '',
        lastName: '',
    };

    const intl = useIntl();

    const dispatch = useDispatch();

    const [formData, setFormData] = useState<PersonBase>(initialFormData);

    const modal = useSelector(selectPersonModal);

    const mode: 'create' | 'edit' = modal?.personID ? 'edit' : 'create';

    /**
     * Errors for the form
     */
    const [errors, setErrors] = useState<PersonBase>({
        ...initialFormData,
    });

    /**
     * Validates the form and returns true if there are no errors
     */
    const validateForm = () => {
        const { userName, email, firstName, lastName } = formData;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const newErrors: PersonBase = { ...initialFormData };

        if (!email?.trim()) {
            newErrors.email = intl.formatMessage(messages.errorEmailRequired);
        } else if (!emailRegex.test(email)) {
            newErrors.email = intl.formatMessage(messages.errorEmailInvalid);
        }

        if (!firstName?.trim()) {
            newErrors.firstName = intl.formatMessage(messages.errorFirstNameRequired);
        }

        if (!lastName?.trim()) {
            newErrors.lastName = intl.formatMessage(messages.errorLastNameRequired);
        }

        setErrors(newErrors);

        // Return true if there are no errors, indicating the form is valid
        return Object.values(newErrors).every(error => !error);
    };

    /**
     * Handle changes to the form fields
     */
    const handleFieldChange = (fieldName: keyof PersonBase, value: string) => {
        setFormData(prevData => ({ ...prevData, [fieldName]: value }));
        setErrors(prevErrors => ({ ...prevErrors, [fieldName]: '' }));
    };

    /**
     * Closes the modal and clears the form
     */
    const closeAndClear = () => {
        setFormData(initialFormData);
        setErrors(initialFormData);
        dispatch(setPersonModal({ isOpen: false, personID: undefined }));
    };

    /**
     * Handle closing the modal
     */
    const handleClose = () => {
        closeAndClear();
    };

    /**
     * Handle create a new person
     */
    const handleCreate = () => {
        if (!validateForm()) return;

        /**
         * TODO
         *  - show success message by notifier
         *  - handle error - show error (email exists etc.)
         */

        peopleApi
            .addPeople(formData)
            .then(response => {
                console.log(response.data);

                // Fetch all people again
                // than close modal and clear form
                fetchAndStorePeopleList().then(r => {
                    console.log(r);
                    closeAndClear();
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    /**
     * Handle update an existing person
     */
    const handleUpdate = () => {
        if (!modal?.personID) return;
        if (!validateForm()) return;
        console.log('handleUpdate');

        /*
         * TODO
         *  - close modal
         *  - show success message
         *  - fetch all people again
         *  - clear form
         *  - clear errors
         */

        // peopleApi.updatePeople(modal.personID).then(response => {
        //     console.log(response);
        // });
    };

    /**
     * Required indicator
     */
    const Required: React.FC = () => <span className="text-red-600">*</span>;

    return (
        <Modal open={modal.isOpen}>
            <div className={style.modalOuter}>
                <div className={style.modal}>
                    <div className="w-full">
                        <form autoComplete="on">
                            {mode === 'create' ? (
                                <h2 className="text-2xl font-bold mt-0 mb-6">
                                    <FormattedMessage {...messages.titleCreate} />
                                </h2>
                            ) : (
                                <h2 className="text-2xl font-bold mt-0 mb-6">
                                    <FormattedMessage {...messages.titleEdit} />
                                </h2>
                            )}

                            <label className="block mb-4">
                                <span className="block font-bold">
                                    <FormattedMessage {...messages.userName} />
                                </span>
                                <TextField
                                    value={formData.userName}
                                    fullWidth
                                    onChange={e => handleFieldChange('userName', e.target.value)}
                                    error={!!errors.userName}
                                    helperText={errors.userName}
                                    autoComplete="username"
                                />
                            </label>

                            <label className="block mb-4">
                                <span className="block font-bold">
                                    <FormattedMessage {...messages.email} /> <Required />
                                </span>
                                <TextField
                                    value={formData.email}
                                    fullWidth
                                    type="email"
                                    onChange={e => handleFieldChange('email', e.target.value)}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    autoComplete="email"
                                />
                            </label>

                            <label className="block mb-4">
                                <span className="block font-bold">
                                    <FormattedMessage {...messages.firstName} /> <Required />
                                </span>
                                <TextField
                                    value={formData.firstName}
                                    fullWidth
                                    onChange={e => handleFieldChange('firstName', e.target.value)}
                                    error={!!errors.firstName}
                                    helperText={errors.firstName}
                                    autoComplete="given-name"
                                />
                            </label>

                            <label className="">
                                <span className="block font-bold">
                                    <FormattedMessage {...messages.lastName} /> <Required />
                                </span>
                                <TextField
                                    value={formData.lastName}
                                    fullWidth
                                    onChange={e => handleFieldChange('lastName', e.target.value)}
                                    error={!!errors.lastName}
                                    helperText={errors.lastName}
                                    autoComplete="family-name"
                                />
                            </label>

                            <div className="mt-6 flex gap-2 justify-end">
                                <Button variant="outlined" color="primary" onClick={handleClose}>
                                    <FormattedMessage {...messages.buttonCancel} />
                                </Button>
                                {mode === 'create' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCreate}
                                    >
                                        <FormattedMessage {...messages.buttonCreate} />
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleUpdate}
                                    >
                                        <FormattedMessage {...messages.buttonUpdate} />
                                    </Button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PersonForm;
