import { defineMessages } from 'react-intl';

export default defineMessages({
    overallAssessmentDescription: {
        id: 'components.projectInformation.assessments.overallAssessmentDescription',
        defaultMessage:
            '<p><strong>Goal and purpose</strong><br />- Complete overview per quarter to ensure that deadlines, finances and quality are met<br />- Definition of measures for the following quarter<br />- Basics/reporting in favor of project team builder (PT-B) and project supervision (PA) and VBS-internal reporting</p><p><strong>Guidelines</strong><br />- The overall / project manager documents the development within a quarter in the form of a status report at the end of the quarter. <br />- Submission of the status reports by <strong>no later than the 2nd Tuesday</strong> of the months <strong>April, July, October, January</strong> to the PL-B for approval. <br />- Distribution list as an attachment to the respective protocols PT-B or as AVOR for PA resp. as needed to other interest groups</p>',
        // DE: <p><strong>Ziel und Zweck</strong><br />- Gesamtübersicht pro Quartal zur Sicherung der Anforderungen an Termine, Finanzen und Qualität<br />- Definition von Massnahmen für das Folgequartal<br />- Grundlagen/Reporting zu Gunsten Projetkteam Bauherr (PT-B) und Projektaufsicht (PA) und VBS-internes Reporting</p><p><strong>Handlungsrichtlinien</strong> <br />- Der Gesamt- / Projektleiter dokumentiert die Entwicklung innerhalb einer Quartals in Form eines Statusbericht per Ende Quartal. <br />- Abgabe der Statusberichte bis <strong>spätestens am 2ten Dienstag</strong> der Monate <strong>April, Juli, Oktober, Januar</strong> an den PL-B zur Freigabe. <br />- Verteiler als Anhang an die jeweiligen Protokolle PT-B oder als AVOR für PA resp. nach Bedarf an weitere Interessensgruppen</p>
    },

    overallAssessmentAspectPlaceholder: {
        id: 'components.projectInformation.assessments.overallAssessmentAspectPlaceholder',
        defaultMessage: 'Placeholder text', // DE:
    },

    partialAssessmentDescription: {
        id: 'components.projectInformation.assessments.partialAssessmentDescription',
        defaultMessage:
            'The following partial assessments result from the detailed aspects of the respective chapters.', // DE: Die nachfolgenden Teilbeurteilungen resultieren aus den Detailaspekten der jeweiligen Kapitel.
    },

    aspect: {
        id: 'components.projectInformation.assessments.aspect',
        defaultMessage: 'Aspect', // DE: Aspekt
    },

    previousPeriod: {
        id: 'components.projectInformation.assessments.previousPeriod',
        defaultMessage: 'Previous period', // DE: Vorperiode
    },

    current: {
        id: 'components.projectInformation.assessments.current',
        defaultMessage: 'Current', // DE: Aktuell
    },

    forecast: {
        id: 'components.projectInformation.assessments.forecast',
        defaultMessage: 'Forecast', // DE: Prognose
    },

    comment: {
        id: 'components.projectInformation.assessments.comment',
        defaultMessage: 'Comment / Aspect assessment', // DE: Kommentar / Aspektbeurteilung
    },

    overallAssessment: {
        id: 'components.projectInformation.assessments.overallAssessment',
        defaultMessage: 'Overall assessment', // DE: Gesamtbeurteilung
    },

    overallAssessmentSubtitle: {
        id: 'components.projectInformation.assessments.overallAssessment.subtitle',
        defaultMessage: 'Summary', // DE: Zusammenfassung
    },

    goalAchievement: {
        id: 'components.projectInformation.assessments.goalAchievement',
        defaultMessage: 'Goal achievement', // DE: Zielerreichung
    },

    goalAchievementSubtitle: {
        id: 'components.projectInformation.assessments.goalAchievement.subtitle',
        defaultMessage: 'Results, quality according to Chapter 1', // DE: Ergebnisse, Qualität gem. Kapitel 1
    },

    targetAchievementOverallAspectPlaceholder: {
        id: 'components.projectInformation.assessments.targetAchievementOverallAspectPlaceholder',
        defaultMessage: 'Placeholder text', // DE:
    },

    finances: {
        id: 'components.projectInformation.assessments.finances',
        defaultMessage: 'Finances', // DE: Finanzen
    },

    financesSubtitle: {
        id: 'components.projectInformation.assessments.finances.subtitle',
        defaultMessage: 'Investments, costs according to Chapter 2', // DE: Investitionen, Kosten gem. Kapitel 2
    },

    financesOverallAspectPlaceholderPlaceholder: {
        id: 'components.projectInformation.assessments.financesOverallAspectPlaceholderPlaceholder',
        defaultMessage: 'Placeholder text', // DE:
    },

    humanResources: {
        id: 'components.projectInformation.assessments.humanResources',
        defaultMessage: 'Human resources', // DE: Personalressourcen
    },

    humanResourcesSubtitle: {
        id: 'components.projectInformation.assessments.humanResources.subtitle',
        defaultMessage: 'Availability, skills according to Chapter 3', // DE: Verfügbarkeit, Skills gem. Kapitel 3
    },

    humanResourcesOverallAspectPlaceholder: {
        id: 'components.projectInformation.assessments.humanResourcesOverallAspectPlaceholder',
        defaultMessage: 'Placeholder text', // DE:
    },

    timeProgress: {
        id: 'components.projectInformation.assessments.timeProgress',
        defaultMessage: 'Time progress', // DE: Zeitl. Fortschritt
    },

    timeProgressSubtitle: {
        id: 'components.projectInformation.assessments.timeProgress.subtitle',
        defaultMessage: 'Schedule, Milestones according to Chapter 4', // DE: Terminplan, Meilensteine gem. Kapitel 4
    },

    timeProgressOverallAspectPlaceholder: {
        id: 'components.projectInformation.assessments.timeProgressOverallAspectPlaceholder',
        defaultMessage: 'Placeholder text', // DE:
    },

    risks: {
        id: 'components.projectInformation.assessments.risks',
        defaultMessage: 'Risks', // DE: Risiken
    },

    risksSubtitle: {
        id: 'components.projectInformation.assessments.risks.subtitle',
        defaultMessage: 'Overall risk of the project according to Chapter 5', // DE: Gesamtrisiko des Projektes gem. Kapitel 5
    },

    risksOverallAspectPlaceholder: {
        id: 'components.projectInformation.assessments.risksOverallAspectPlaceholder',
        defaultMessage: 'Placeholder text', // DE:
    },

    integralSafety: {
        id: 'components.projectInformation.assessments.integralSafety',
        defaultMessage: 'Integral safety', // DE: Integrale Sicherheit
    },

    integralSafetySubtitle: {
        id: 'components.projectInformation.assessments.integralSafetySubtitle.subtitle',
        defaultMessage: 'According to Chapter 6', // DE: gem. Kapitel 6
    },

    integralSafetyOverallAspectPlaceholder: {
        id: 'components.projectInformation.assessments.integralSafetyOverallAspectPlaceholder',
        defaultMessage: 'Placeholder text', // DE:
    },

    checklistPhaseDocuments: {
        id: 'components.projectInformation.assessments.checklistPhaseDocuments',
        defaultMessage: 'Checklist phase documents', // DE: Checkliste Phasendokumente
    },

    checklistPhaseDocumentsSubtitle: {
        id: 'components.projectInformation.assessments.checklistPhaseDocuments.subtitle',
        defaultMessage: 'According to Chapter 7', // DE: gem. Kapitel 7
    },

    checklistOverallAspectPlaceholder: {
        id: 'components.projectInformation.assessments.checklistOverallAspectPlaceholder',
        defaultMessage: 'Placeholder text', // DE:
    },

    criterionMessageOk: {
        id: 'components.projectInformation.assessments.criterionMessage.ok',
        defaultMessage: 'Criterion is OK and under control. There is room for manoeuvre.', // DE: Kriterium ist OK und unter Kontrolle. Handlungsspielraum ist vorhanden.
    },

    criterionMessageWarning: {
        id: 'components.projectInformation.assessments.criterionMessage.warning',
        defaultMessage: 'Criterion is in danger, but under control. Risks are known and addressed.', // DE: Kriterium in Gefahr, jedoch unter Kontrolle. Risiken sind bekannt und adressiert.
    },

    criterionMessageCritical: {
        id: 'components.projectInformation.assessments.criterionMessage.critical',
        defaultMessage:
            'Criterion is out of scope or support measures are not working. Damage control is necessary.', // DE: Kriterium ist nicht mehr im Rahmen oder Unterstützungsmassnahmen greifen nicht. SoMa zur Schadensbegrenzung sind nötig.
    },
});
