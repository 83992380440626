import config from '../../../../config/config';
import { Configuration, ProjectFunctionsApi } from '../../../../generate/api';
import { store } from '../../../../redux/store';

export const projFunctionsApi = new ProjectFunctionsApi(
    new Configuration({
        basePath: config.api.baseUrl,
        accessToken: () => {
            return store.getState().keycloak.token as string;
        },
    }),
);
