import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    selectPhases,
    selectReportPhase,
} from '../../../../redux/projectInformation/projectInformationSlice';
import StateSelector from '../../../Form/StateSelector/StateSelector';
import { AspectType } from '../../acpects.type';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import SectionStateSelector from '../../components/SectionStateSelector/SectionStateSelector';
import sectionTitleMessages from '../../MainForm/sectionTitleMessages';
import Documents from './Documents';
import messages from './messages';

/**
 * Section props
 */
interface SectionProps {
    /**
     * Section number
     */
    number: number;
    /**
     * Section title
     */
    title: string;
    /**
     * Section aspect
     */
    aspect: AspectType;
}

const ChecklistPhaseDocuments = () => {
    const intl = useIntl();
    const reportPhase = useSelector(selectReportPhase);

    /**
     * Prepare section props, memoized
     */
    const sectionProps = useMemo<SectionProps>(() => {
        switch (reportPhase?.code) {
            case 'B3':
                return {
                    number: 7.3,
                    title: intl.formatMessage({
                        ...messages.titleChecklistPhaseDocumentsB3,
                    }),
                    aspect: 'checklistPhaseB3Aspect',
                };
            case 'B2':
                return {
                    number: 7.2,
                    title: intl.formatMessage({
                        ...messages.titleChecklistPhaseDocumentsB2,
                    }),
                    aspect: 'checklistPhaseB2Aspect',
                };
            case 'B1':
            default:
                return {
                    number: 7.1,
                    title: intl.formatMessage({
                        ...messages.titleChecklistPhaseDocumentsB1,
                    }),
                    aspect: 'checklistPhaseB1Aspect',
                };
        }
    }, [reportPhase]);

    return (
        <section className="my-[100px]">
            <section id="section-07" className="scroll-my-5">
                <SectionHeader
                    number={7}
                    mainSection
                    title={intl.formatMessage({ ...sectionTitleMessages.checklistPhaseDocuments })}
                    aspect="checklistOverallAspect"
                />
                <div className="my-8">
                    <FormattedMessage {...messages.documentsDescription} />
                </div>
            </section>

            <section className="my-[50px]">
                <SectionHeader
                    number={sectionProps.number}
                    subSection
                    title={sectionProps.title}
                    aspect={sectionProps.aspect}
                />
                <div className="my-8">
                    <Documents />
                </div>
            </section>
        </section>
    );
};

export default ChecklistPhaseDocuments;
