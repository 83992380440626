import config from '../../../../config/config';
import { Configuration, TextualItemTypesApi } from '../../../../generate/api';
import { store } from '../../../../redux/store';

export const textualItemTypesApi = new TextualItemTypesApi(
    new Configuration({
        basePath: config.api.baseUrl,
        accessToken: () => {
            return store.getState().keycloak.token as string;
        },
    }),
);
