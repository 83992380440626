import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { RootState } from '../../redux/store';

export function PrivateRoute({ component: Component, ...rest }) {
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const keycloakEvent = useSelector((state: RootState) => state.keycloak.event);

    useEffect(() => {
        if (
            keycloakInitialized &&
            (keycloak?.authenticated == false || keycloakEvent == 'onAuthRefreshError')
        ) {
            if (keycloakEvent == 'onAuthRefreshError') {
                // todo handle login on error without redirect/navigation, only in popup
                console.warn('Refresh token error, redirecting to login page');
            }
            keycloak.login();
        }
    }, [keycloakInitialized, keycloakEvent]);

    return (
        <Route
            {...rest}
            render={props =>
                keycloakInitialized && keycloak?.authenticated == true ? (
                    <Component {...props} />
                ) : (
                    ''
                )
            }
        />
    );
}
