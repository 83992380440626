import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectReport } from '../../../../redux/projectInformation/projectInformationSlice';
import StateSelector from '../../../Form/StateSelector/StateSelector';
import ReportTextareaComment from '../../components/ReportTextareaComment/ReportTextareaComment';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import SectionStateSelector from '../../components/SectionStateSelector/SectionStateSelector';
import TextualItems from '../../components/TextualItems/TextualItems';
import sectionTitleMessages from '../../MainForm/sectionTitleMessages';
import styleTable from '../../styles/table.module.css';
import FinanceDetailOverviewTable from './FinanceDetailOverviewTable';
import messages from './messages';
import TradesTable from './TradesTable';

const Finances = () => {
    const intl = useIntl();

    const report = useSelector(selectReport);
    const projectNumber = report?.projectNumber;
    const projectNumberLead = report?.projectNumberLead;

    return (
        <section className="my-[100px]">
            <section id="section-02" className="scroll-my-5">
                <SectionHeader
                    number={2}
                    mainSection
                    title={intl.formatMessage({ ...sectionTitleMessages.finances })}
                    aspect="financesOverallAspect"
                />

                <div className="flex gap-5">
                    <div className="flex-1">
                        <div className={styleTable.tableOuterBorder}>
                            <table className={styleTable.table}>
                                <thead>
                                    <tr>
                                        <th className="text-left">
                                            <FormattedMessage {...messages.projectCredit} />
                                        </th>
                                        <th className="text-left">{projectNumber}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <FormattedMessage {...messages.approvedCredit} />
                                        </td>
                                        <td>0.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage {...messages.totalDisposes} />
                                        </td>
                                        <td>0.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage {...messages.totalObligo} />
                                        </td>
                                        <td>0.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage {...messages.totalPayments} />
                                        </td>
                                        <td>0.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage {...messages.remainingCredit} />
                                        </td>
                                        <td>0.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className={styleTable.tableOuterBorder}>
                            <table className={styleTable.table}>
                                <thead>
                                    <tr>
                                        <th className="text-left">
                                            <FormattedMessage {...messages.projectCreditProject} />
                                        </th>
                                        <th className="text-left">{projectNumberLead}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <FormattedMessage {...messages.approvedCredit} />
                                        </td>
                                        <td>0.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage {...messages.totalDisposes} />
                                        </td>
                                        <td>0.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage {...messages.totalObligo} />
                                        </td>
                                        <td>0.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage {...messages.totalPayments} />
                                        </td>
                                        <td>0.00</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage {...messages.remainingCredit} />
                                        </td>
                                        <td>0.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="text-red-500 mt-4 text-right">
                    <FormattedMessage {...messages.financeTableDescription} />
                </div>
                <div className="my-8">
                    <ReportTextareaComment
                        title={intl.formatMessage({
                            ...messages.financesComment,
                        })}
                        reduxTarget="financesComment"
                    />
                </div>
            </section>

            <section className="my-[50px]">
                <SectionHeader
                    number={2.1}
                    subSection
                    title={intl.formatMessage({
                        ...sectionTitleMessages.detailOverviewProjectCredit,
                    })}
                    middlePart={<div className="text-lg">{projectNumber}</div>}
                    aspect="projectPlanCreditAspect"
                />
                <FinanceDetailOverviewTable />
                <div className="my-8">
                    <ReportTextareaComment
                        title={intl.formatMessage({
                            ...messages.projectPlanCreditComment,
                        })}
                        reduxTarget="projectPlanCreditComment"
                    />
                </div>
            </section>

            <section className="my-[50px]">
                <SectionHeader
                    number={2.2}
                    subSection
                    title={intl.formatMessage({
                        ...sectionTitleMessages.costOverviewRealizationCredit,
                    })}
                    middlePart={<div className="text-lg">{projectNumberLead}</div>}
                    aspect="realizationCostStatusCreditAspect" // todo not working
                />

                <div className="">
                    <TextualItems code={'2.2'} />
                </div>
            </section>

            <section className="my-[50px]">
                <SectionHeader
                    number={2.3}
                    subSection
                    title={intl.formatMessage({ ...sectionTitleMessages.useBKP8 })}
                    middlePart={<div className="text-lg">{projectNumberLead}</div>}
                    aspect="projectReserveAspect"
                />
                <div className="my-8">
                    <TradesTable />
                </div>
            </section>

            <section className="my-[50px]">
                <SectionHeader
                    number={2.4}
                    subSection
                    title={intl.formatMessage({
                        ...sectionTitleMessages.detailOverviewRealizationCredit,
                    })}
                    middlePart={<div className="text-lg">{projectNumberLead}</div>}
                    aspect="realizationCreditAspect"
                />
                <div className="my-8">
                    <ReportTextareaComment
                        title={intl.formatMessage({
                            ...messages.realizationCreditComment,
                        })}
                        reduxTarget="realizationCreditComment"
                    />
                </div>
            </section>

            <section className="my-[50px]">
                <SectionHeader
                    number={2.5}
                    subSection
                    title={intl.formatMessage({ ...sectionTitleMessages.awards })}
                    aspect="contractsAspect"
                />
                <div className="my-8">
                    <ReportTextareaComment
                        title={intl.formatMessage({
                            ...messages.contractsComment,
                        })}
                        reduxTarget="contractsComment"
                    />
                </div>
            </section>
        </section>
    );
};

export default Finances;
