import { defineMessages } from 'react-intl';

export default defineMessages({
    projectTeamOwner: {
        id: 'components.projectInformation.projectTeam.owner',
        defaultMessage: 'Project team owner', // DE: Projektteam Bauherr (Auftragssteuerung)
    },

    projectTeamDescription: {
        id: 'components.projectInformation.projectTeam.owner.description',
        defaultMessage: 'Other partners according to the project organization plan', // DE: weitere Partner gemäss Projektorganigramm
    },

    function: {
        id: 'components.projectInformation.projectTeam.function',
        defaultMessage: 'Function', // DE: Funktion
    },

    company: {
        id: 'components.projectInformation.projectTeam.company',
        defaultMessage: 'Company', // DE: Firma
    },

    firstNameAndLastName: {
        id: 'components.projectInformation.projectTeam.firstNameAndLastName',
        defaultMessage: 'First name and last name', // DE: Vor- und Nachname
    },

    organizationUnit: {
        id: 'components.projectInformation.projectTeam.organizationUnit',
        defaultMessage: 'Organization unit', // DE: Organisationseinheit
    },

    projectTeamContractor: {
        id: 'components.projectInformation.projectTeam.contractor',
        defaultMessage: 'Project team contractor', // DE: Projektteam Bauherr (Auftragnehmer)
    },

    projectTeamContractorDescription: {
        id: 'components.projectInformation.projectTeam.contractor.contractorDescription',
        defaultMessage: 'Other partners according to the project organization plan', // DE: weitere Partner gemäss Projektorganigramm
    },

    newRow: {
        id: 'components.projectInformation.projectTeam.newRow',
        defaultMessage: 'New row', // DE: Neue Zeile
    },

    organizationUnitNeedToBeChanged: {
        id: 'components.projectInformation.projectTeam.organizationUnitNeedToBeChanged',
        defaultMessage: 'Organization unit need to be changed', // DE: Organisationseinheit muss geändert werden
    },
});
