/* tslint:disable */
/* eslint-disable */
/**
 * Armasuisse Dashboard Web Application API
 * API definitions for Armasuisse Dashboard Web Application
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const DashboardType = {
    IsShould: 'IsShould',
    Simple: 'Simple'
} as const;

export type DashboardType = typeof DashboardType[keyof typeof DashboardType];



