import React from 'react';

interface ITodoProps {
    title?: string;
}

const Todo: React.FC<ITodoProps> = ({ title }) => {
    return (
        <span className="bg-amber-500 rounded px-1 mr-1">
            <span className="text-white">{title ? title : 'TODO'}</span>
        </span>
    );
};

export default Todo;
