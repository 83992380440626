import React from 'react';
import { generatePath, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import Icon from '../../components/Icon/Icon';
import style from '../../components/ProjectInformation/styles.module.css';
import { Path } from '../PageRouter';
import IfcFileViewer from './IfcFileViewer';
import ProjectList from './ProjectList';

const ProjectListWrapper: React.FC = () => {
    const history = useHistory();
    const { fileID } = useParams<{ fileID: string }>();

    const handleClose = () => {
        const path = generatePath(Path.PORTFOLIO_DASHBOARD, {});
        history.push(path);
    };

    return (
        <>
            <ProjectList />
            {fileID && (
                <div className={style.wrapperOuter}>
                    <div className={style.wrapper}>
                        <div className="flex h-full">
                            <div className="flex-1 p-5 overflow-y-auto h-full">
                                <IfcFileViewer fileID={fileID} />
                            </div>
                        </div>
                    </div>
                    <div className={style.close} onClick={handleClose}>
                        <Icon name="times" size={20} />
                    </div>
                </div>
            )}
        </>
    );
};

export default ProjectListWrapper;
