/* tslint:disable */
/* eslint-disable */
/**
 * Armasuisse Dashboard Web Application API
 * API definitions for Armasuisse Dashboard Web Application
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PendencyTypeBase } from '../models';
// @ts-ignore
import { PendencyTypeBaseResponse } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * PendencyTypesApi - axios parameter creator
 * @export
 */
export const PendencyTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new Pendency Type and returns newly created Pendency Type.
         * @summary Creates new Pendency Type.
         * @param {PendencyTypeBase} [pendencyTypeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPendencyType: async (pendencyTypeBase?: PendencyTypeBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/PendencyTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pendencyTypeBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Pendency Type.
         * @summary Delete Pendency Type.
         * @param {number} entityID Pendency Type identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePendencyType: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deletePendencyType', 'entityID', entityID)
            const localVarPath = `/PendencyTypes/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Pendency Type.
         * @summary Get Pendency Type details.
         * @param {number} entityID Pendency Type identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendencyTypeDetails: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getPendencyTypeDetails', 'entityID', entityID)
            const localVarPath = `/PendencyTypes/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Pendency Types.
         * @summary Get list of Pendency Types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendencyTypeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/PendencyTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Pendency Type and returns the modified Pendency Type.
         * @summary Edit Pendency Type.
         * @param {number} entityID Pendency Type identifier
         * @param {PendencyTypeBase} [pendencyTypeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePendencyType: async (entityID: number, pendencyTypeBase?: PendencyTypeBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updatePendencyType', 'entityID', entityID)
            const localVarPath = `/PendencyTypes/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pendencyTypeBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PendencyTypesApi - functional programming interface
 * @export
 */
export const PendencyTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PendencyTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new Pendency Type and returns newly created Pendency Type.
         * @summary Creates new Pendency Type.
         * @param {PendencyTypeBase} [pendencyTypeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPendencyType(pendencyTypeBase?: PendencyTypeBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PendencyTypeBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPendencyType(pendencyTypeBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Pendency Type.
         * @summary Delete Pendency Type.
         * @param {number} entityID Pendency Type identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePendencyType(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePendencyType(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Pendency Type.
         * @summary Get Pendency Type details.
         * @param {number} entityID Pendency Type identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendencyTypeDetails(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PendencyTypeBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendencyTypeDetails(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Pendency Types.
         * @summary Get list of Pendency Types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendencyTypeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PendencyTypeBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendencyTypeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Pendency Type and returns the modified Pendency Type.
         * @summary Edit Pendency Type.
         * @param {number} entityID Pendency Type identifier
         * @param {PendencyTypeBase} [pendencyTypeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePendencyType(entityID: number, pendencyTypeBase?: PendencyTypeBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PendencyTypeBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePendencyType(entityID, pendencyTypeBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PendencyTypesApi - factory interface
 * @export
 */
export const PendencyTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PendencyTypesApiFp(configuration)
    return {
        /**
         * Creates new Pendency Type and returns newly created Pendency Type.
         * @summary Creates new Pendency Type.
         * @param {PendencyTypeBase} [pendencyTypeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPendencyType(pendencyTypeBase?: PendencyTypeBase, options?: any): AxiosPromise<PendencyTypeBaseResponse> {
            return localVarFp.addPendencyType(pendencyTypeBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Pendency Type.
         * @summary Delete Pendency Type.
         * @param {number} entityID Pendency Type identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePendencyType(entityID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePendencyType(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Pendency Type.
         * @summary Get Pendency Type details.
         * @param {number} entityID Pendency Type identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendencyTypeDetails(entityID: number, options?: any): AxiosPromise<PendencyTypeBaseResponse> {
            return localVarFp.getPendencyTypeDetails(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Pendency Types.
         * @summary Get list of Pendency Types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendencyTypeList(options?: any): AxiosPromise<Array<PendencyTypeBaseResponse>> {
            return localVarFp.getPendencyTypeList(options).then((request) => request(axios, basePath));
        },
        /**
         * Update Pendency Type and returns the modified Pendency Type.
         * @summary Edit Pendency Type.
         * @param {number} entityID Pendency Type identifier
         * @param {PendencyTypeBase} [pendencyTypeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePendencyType(entityID: number, pendencyTypeBase?: PendencyTypeBase, options?: any): AxiosPromise<PendencyTypeBaseResponse> {
            return localVarFp.updatePendencyType(entityID, pendencyTypeBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PendencyTypesApi - object-oriented interface
 * @export
 * @class PendencyTypesApi
 * @extends {BaseAPI}
 */
export class PendencyTypesApi extends BaseAPI {
    /**
     * Creates new Pendency Type and returns newly created Pendency Type.
     * @summary Creates new Pendency Type.
     * @param {PendencyTypeBase} [pendencyTypeBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendencyTypesApi
     */
    public addPendencyType(pendencyTypeBase?: PendencyTypeBase, options?: AxiosRequestConfig) {
        return PendencyTypesApiFp(this.configuration).addPendencyType(pendencyTypeBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Pendency Type.
     * @summary Delete Pendency Type.
     * @param {number} entityID Pendency Type identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendencyTypesApi
     */
    public deletePendencyType(entityID: number, options?: AxiosRequestConfig) {
        return PendencyTypesApiFp(this.configuration).deletePendencyType(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Pendency Type.
     * @summary Get Pendency Type details.
     * @param {number} entityID Pendency Type identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendencyTypesApi
     */
    public getPendencyTypeDetails(entityID: number, options?: AxiosRequestConfig) {
        return PendencyTypesApiFp(this.configuration).getPendencyTypeDetails(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Pendency Types.
     * @summary Get list of Pendency Types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendencyTypesApi
     */
    public getPendencyTypeList(options?: AxiosRequestConfig) {
        return PendencyTypesApiFp(this.configuration).getPendencyTypeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Pendency Type and returns the modified Pendency Type.
     * @summary Edit Pendency Type.
     * @param {number} entityID Pendency Type identifier
     * @param {PendencyTypeBase} [pendencyTypeBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PendencyTypesApi
     */
    public updatePendencyType(entityID: number, pendencyTypeBase?: PendencyTypeBase, options?: AxiosRequestConfig) {
        return PendencyTypesApiFp(this.configuration).updatePendencyType(entityID, pendencyTypeBase, options).then((request) => request(this.axios, this.basePath));
    }
}
