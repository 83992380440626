import { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AspectBaseResponse, ProjectReportBaseDetailResponse } from '../../../../generate/api';
import {
    saveReport,
    selectIsLastReport,
    selectReport,
} from '../../../../redux/projectInformation/projectInformationSlice';
import { store } from '../../../../redux/store';
import StateSelector from '../../../Form/StateSelector/StateSelector';
import { AspectType } from '../../acpects.type';

interface ISectionStateSelectorProps {
    stateSelectorName: AspectType;
}

const SectionStateSelector: React.FC<ISectionStateSelectorProps> = ({ stateSelectorName }) => {
    const reduxReportData = useSelector(selectReport);
    const isLastReport = useSelector(selectIsLastReport);
    const [formData, setFormData] = useState<ProjectReportBaseDetailResponse>();

    useEffect(() => {
        setFormData(reduxReportData);
    }, [reduxReportData]);

    // Handle form data change
    // set form data to state
    const handleInputChange = (e: SelectChangeEvent<any>) => {
        const { name, value } = e.target;
        const [fieldName, nestedFieldName] = name.split('.');
        setFormData(prevData => ({
            ...prevData,
            [fieldName]: {
                ...(prevData?.[fieldName] || {}),
                [nestedFieldName]: value,
            },
        }));
    };

    // Save form data to redux
    useEffect(() => {
        store.dispatch(saveReport(formData));
    }, [formData]);

    const currentAspect = formData?.[stateSelectorName] as AspectBaseResponse | undefined;
    const currentStatusID = currentAspect?.currentStatusID as number | undefined;

    return (
        <StateSelector
            category="aspect"
            name={stateSelectorName + '.currentStatusID'}
            value={currentStatusID}
            onChange={handleInputChange}
            disabled={!isLastReport}
        />
    );
};

export default SectionStateSelector;
