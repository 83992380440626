import { defineMessages } from 'react-intl';

export default defineMessages({
    submitNewReport: {
        id: 'components.projectInformation.mainForm.submitNewReport.button.submitNewReport',
        defaultMessage: 'Submit new report', // DE: Neuen Bericht einreichen
    },
    submitNewReportConfirmation: {
        id: 'components.projectInformation.mainForm.submitNewReport.submitConfirmation',
        defaultMessage: 'Are you sure you want to submit a new report?', // DE: Möchten Sie wirklich einen neuen Bericht einreichen?
    },
});
