import { defineMessages } from 'react-intl';

export default defineMessages({
    timeProgressOverallComment: {
        id: 'components.projectInformation.timeProgress.overallComment',
        defaultMessage: 'Overall comment', // DE: Zusammenfassende Beurteilung
    },

    should: {
        id: 'components.projectInformation.timeProgress.should',
        defaultMessage: 'Should', // DE: soll
    },

    forecast: {
        id: 'components.projectInformation.timeProgress.forecast',
        defaultMessage: 'Forecast', // DE: IST Prognose
    },

    milestones: {
        id: 'components.projectInformation.timeProgress.milestones',
        defaultMessage: 'Milestones <br /> End dates', // DE: Meilensteine <br /> Endtermine
    },

    projectOrder: {
        id: 'components.projectInformation.timeProgress.projectOrder',
        defaultMessage: 'Project order', // DE: Projektauftrag
    },

    ipVaYear: {
        id: 'components.projectInformation.timeProgress.ipVaYear',
        defaultMessage: 'IP/VA year <br /> Real estate program', // DE: IP/VA-Jahr <br /> Immobilenprogramm
    },

    'B1-1': {
        id: 'components.projectInformation.timeProgress.b11',
        defaultMessage: 'B1-1 <br /> MBS feasibility study', // DE: B1-1 <br /> Machbarkeitsstudie MBS
    },

    'B1-2': {
        id: 'components.projectInformation.timeProgress.b12',
        defaultMessage: 'B1-2 <br /> Project specification PPH', // DE: B1-2 <br /> Projektpflichtenheft PPH
    },

    'B2-1': {
        id: 'components.projectInformation.timeProgress.b21',
        defaultMessage: 'B2-1 <br /> Preliminary project <br /> VP', // DE: B2-1 <br /> Vorprojekt <br /> VP
    },

    'B2-2': {
        id: 'components.projectInformation.timeProgress.b22',
        defaultMessage: 'B2-2 <br /> Construction project <br /> BP', // DE: B2-2 <br /> Bauprojekt <br /> BP
    },

    'B3-1': {
        id: 'components.projectInformation.timeProgress.b31',
        defaultMessage: 'B3-1 <br /> Start of building', // DE: B3-1 <br /> Baubeginn
    },

    'B3-2': {
        id: 'components.projectInformation.timeProgress.b32',
        defaultMessage: 'B3-2 <br /> Delivery <br /> Start of use', // DE: B3-2 <br /> Übergegabe <br /> Nutzungsbeginn
    },

    'B3-3': {
        id: 'components.projectInformation.timeProgress.b33',
        defaultMessage: 'B3-3 <br /> Invoice <br /> Project completion', // DE: B3-3 <br /> Abrechnung <br /> Projektabschluss
    },
});
