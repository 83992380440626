import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectRisks } from '../../../../../redux/projectInformation/projectInformationSlice';
import messages from '../messages';
import style from './riskManagement.module.css';

interface ISingleData {
    number: number;
    x: number;
    y: number;
}

type IAllData = ISingleData[];

type IColor = 'r' | 'y' | 'g';

interface IRectProps {
    allData: IAllData;
    coords: {
        x: number;
        y: number;
    };
    color: IColor;
}

const Rect: React.FC<IRectProps> = ({ coords, allData, color }) => {
    const match = allData.filter(item => item.x === coords.x && item.y === coords.y);

    // return from allData all numbers as string
    const tooltipText = match.map(item => item.number).join(', ');

    return (
        <div className={style[color]}>
            {match?.length === 1 &&
                match.map((item, index) => {
                    return (
                        <div key={index} className={style.number}>
                            <div>{item.number}</div>
                        </div>
                    );
                })}
            {match.length >= 2 && match.length <= 4 && (
                <Tooltip title={tooltipText} arrow>
                    <div className="grid grid-cols-2">
                        {match.map((item, index) => {
                            return (
                                <div key={index} className={clsx(style.number, style.number2)}>
                                    <div key={index}>{item.number}</div>
                                </div>
                            );
                        })}
                    </div>
                </Tooltip>
            )}
            {match.length >= 5 && match.length <= 9 && (
                <Tooltip title={tooltipText} arrow>
                    <div className="grid grid-cols-3">
                        {match.map((item, index) => {
                            return (
                                <div key={index} className={clsx(style.number, style.number3)}>
                                    <div key={index}>{item.number}</div>
                                </div>
                            );
                        })}
                    </div>
                </Tooltip>
            )}
            {match.length > 9 && (
                <Tooltip title={tooltipText} arrow>
                    <div className="grid grid-cols-3">
                        {match.map((item, index) => {
                            if (index === 8) {
                                return (
                                    <div key={index} className={clsx(style.number, style.number4)}>
                                        <div>...</div>
                                    </div>
                                );
                            }

                            if (index >= 9) {
                                return <React.Fragment key={index} />;
                            }

                            return (
                                <div key={index} className={clsx(style.number, style.number3)}>
                                    <div>{item.number}</div>
                                </div>
                            );
                        })}
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

const NumberSticker: React.FC<{ index: number; allData: IAllData }> = ({ index, allData }) => {
    const match = allData[index]?.x !== 0 && allData[index]?.y !== 0;
    return (
        <div>
            <div className={clsx(style.number, match && 'opacity-25')}>{index + 1}</div>
        </div>
    );
};

const RiskManagement: React.FC = () => {
    // translations
    const intl = useIntl();

    // get risks from redux
    const risks = useSelector(selectRisks);

    const dataStructureRows = [
        {
            title: intl.formatMessage({ ...messages.stateVeryHigh }),
            y: 6,
            colors: ['y', 'y', 'r', 'r', 'r', 'r'],
        },
        {
            title: intl.formatMessage({ ...messages.stateHigh }),
            y: 5,
            colors: ['y', 'y', 'y', 'r', 'r', 'r'],
        },
        {
            title: intl.formatMessage({ ...messages.stateOccasional }),
            y: 4,
            colors: ['g', 'y', 'y', 'y', 'r', 'r'],
        },
        {
            title: intl.formatMessage({ ...messages.stateModerate }),
            y: 3,
            colors: ['g', 'g', 'y', 'y', 'y', 'r'],
        },
        {
            title: intl.formatMessage({ ...messages.stateLow }),
            y: 2,
            colors: ['g', 'g', 'g', 'y', 'y', 'y'],
        },
        {
            title: intl.formatMessage({ ...messages.stateVeryLow }),
            y: 1,
            colors: ['g', 'g', 'g', 'g', 'y', 'y'],
        },
    ];

    const dataStructureBottomCols = [
        {
            number: 1,
            title: intl.formatMessage({ ...messages.stateVeryUnlikely }),
            subTitle: '&#60; 1%',
        },
        {
            number: 2,
            title: intl.formatMessage({ ...messages.stateUnlikely }),
            subTitle: '&#62; 1% bis &#60; 2%',
        },
        {
            number: 3,
            title: intl.formatMessage({ ...messages.stateRare }),
            subTitle: '&#62; 2% bis &#60; 10%',
        },
        {
            number: 4,
            title: intl.formatMessage({ ...messages.statePossible }),
            subTitle: '&#62; 10% bis &#60; 33%',
        },
        {
            number: 5,
            title: intl.formatMessage({ ...messages.stateProbable }),
            subTitle: '&#62; 33% bis &#60; 50%',
        },
        {
            number: 6,
            title: intl.formatMessage({ ...messages.stateVeryLikely }),
            subTitle: '&#8806; 100%',
        },
    ];

    const [data, setData] = useState<IAllData>([]);

    const generate = () => {
        const x = ['VeryUnlikely', 'Unlikely', 'Rare', 'Possible', 'Probable', 'VeryLikely'];
        const y = ['VeryLow', 'Low', 'Moderate', 'Occasional', 'High', 'VeryHigh'];
        const dataStructure = risks.map((risk, index) => ({
            number: index + 1,
            x: risk.managementLikehood ? x.indexOf(risk.managementLikehood) + 1 : 0,
            y: risk.managementImpact ? y.indexOf(risk.managementImpact) + 1 : 0,
        }));
        setData(dataStructure);
    };

    useEffect(() => {
        generate();
    }, [risks]);

    return (
        <div className="flex">
            <div className="flex">
                <div className={clsx(style.rotate, 'pr-2 mr-4 border-r border-gray-200 h-[360px]')}>
                    <span className="uppercase text-gray-400">
                        <FormattedMessage {...messages.labelImpact} />
                    </span>
                </div>
                <div>
                    {dataStructureRows.map((row, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="flex items-center">
                                    <div className={style.leftColumn}>
                                        <div className={style.leftColumnTitle}>{row.title}</div>
                                        <div className={style.titleNumber}>{row.y}</div>
                                    </div>
                                    {row.colors.map((color, index2) => (
                                        <div key={index2}>
                                            <div className="flex">
                                                <Rect
                                                    allData={data}
                                                    coords={{ x: index2 + 1, y: row.y }}
                                                    color={color as IColor}
                                                />
                                                {index2 === 3 && (
                                                    <div className="border-r-[3px] border-black h-[60px]" />
                                                )}
                                            </div>
                                            {index === 2 && (
                                                <div className="border-t-[3px] border-black"></div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </React.Fragment>
                        );
                    })}
                    <div className="flex items-start">
                        <div className={style.leftColumn}>
                            <div className={style.leftColumnTitle} />
                            <div className={style.titleNumber} />
                        </div>
                        <div>
                            <div className="flex">
                                {dataStructureBottomCols.map((col, index3) => (
                                    <div className={style.bottomColumn} key={index3}>
                                        <div className={style.titleNumber}>{col.number}</div>
                                        <div className={style.rotate}>
                                            <span>
                                                <div className="font-medium">{col.title}</div>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: col.subTitle,
                                                    }}
                                                ></div>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="pt-2 mt-4 border-t border-gray-200 text-right uppercase text-gray-400">
                                <FormattedMessage {...messages.labelProbabilityOfOccurrence} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="table">
                    <div className="ml-10 border border-gray-200 rounded-md p-2 grid grid-cols-2 gap-3">
                        {risks.map((item, index) => (
                            <NumberSticker index={index} key={index} allData={data} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RiskManagement;
