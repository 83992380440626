import './style.css';

import Basemap from '@arcgis/core/Basemap';
import esriConfig from '@arcgis/core/config';
import Handles from '@arcgis/core/core/Handles';
import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import IdentityManager from '@arcgis/core/identity/IdentityManager';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import SceneLayer from '@arcgis/core/layers/SceneLayer';
import Map from '@arcgis/core/Map';
import EsriSymbol from '@arcgis/core/symbols/Symbol';
import SceneView from '@arcgis/core/views/SceneView';
import WebScene from '@arcgis/core/WebScene';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import Daylight from '@arcgis/core/widgets/Daylight';
import LayerList from '@arcgis/core/widgets/LayerList';
import Legend from '@arcgis/core/widgets/Legend';
import Widget from '@arcgis/core/widgets/Widget';
import { Menu, MenuItem } from '@mui/material';
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';

import config from '../../config/config';
import { ExternalFileInfo, ExternalSystemDetails } from '../../generate/api';
import { getWebsceneJson } from '../../packages/Api/arcgis/client';
import { arcgisProxyApi } from '../../packages/Api/data/arcGis/client';
import { pocApi } from '../../packages/Api/data/externalSystems/client';
import { Path } from '../../pages/PageRouter';
import { isFileAllowed } from '../../pages/PocPage/ExternalSystemInfo';
import useStyles from './styles';
import ViewerTools, { ViewerToolsRenderer } from './ViewerTools';

interface IEsriViewerProps {
    center: number[];
    zoom: number;
    viewLoaded?: (view: SceneView) => void;
    onClick?: (point: ClickedPoint) => void;
    highlights?: Highlights;
    disableAutoPopup?: boolean;
    toolsGetter?: (view: SceneView) => IToolDef[];
    points?: IPoint[];
    activeSlide?: any; // todo
}

export interface IPoint {
    code: string;
    longitude: number;
    latitude: number;
    state?: string;
    symbol?: Partial<EsriSymbol>;
}

export interface IToolDef {
    name: string;
    code: string;
    icon?: string;
    tool: Node | string | Widget | ReactElement;
}

export type Highlights = { [key: number]: string[] };

export interface ScreenPoint {
    x: number;
    y: number;
}

export interface ClickedPoint {
    screen: ScreenPoint;
    world: Point;
    mouseButton: number;
    object?: any;
}

export const toolsEquals = (tool1?: IToolDef, tool2?: IToolDef): boolean => {
    return tool1?.code === tool2?.code;
};

const EsriViewer: React.FC<IEsriViewerProps> = ({
    center,
    zoom,
    viewLoaded,
    onClick,
    disableAutoPopup,
    highlights,
    points,
    activeSlide,
}) => {
    const classes = useStyles();
    const mapDiv = useRef<HTMLDivElement | null>(null);
    const history = useHistory();

    const [view, setView] = React.useState<SceneView>();
    const [webScene, setWebScene] = React.useState<WebScene>();
    const [graphicsLayer, setGraphicsLayer] = React.useState<GraphicsLayer>();
    const [currentHighlights, setCurrentHighlights] = React.useState<Handles[]>();

    const [clickedPoint, setClickedPoint] = React.useState<ClickedPoint | null>(null);
    const [detailsData, setDetailsData] = useState<ExternalSystemDetails>();

    useEffect(() => {
        pocApi.getPocGetExternalSystemDetailsByCode('libal').then(response => {
            setDetailsData(response.data);
            // const firstAllowed = response?.data?.files?.find(f => isFileAllowed(f));
        });
    }, []);

    useEffect(() => {
        arcgisProxyApi.getArcGisGenerateToken(window.location.origin).then(response => {
            IdentityManager.registerToken({
                server: 'https://www.arcgis.com/sharing/rest',
                token: response.data.token,
                expires: response.data.expires,
                ssl: response.data.ssl,
            });

            getWebsceneJson(config.esri.webSceneId, response.data.token).then(json => {
                if (mapDiv.current) {
                    /**
                     * Initialize application
                     */
                    const webmap = WebScene.fromJSON(json);

                    const myView = new SceneView({
                        container: mapDiv.current,
                        center: center,
                        zoom: zoom,
                        map: webmap,
                    });

                    // const myGraphicsLayer = new GraphicsLayer();
                    // webmap.add(myGraphicsLayer);

                    setWebScene(webmap);
                    setView(myView);
                    // setGraphicsLayer(myGraphicsLayer);
                }
            });
        });
        // esriConfig.apiKey = config.esri.apiKey;
        // esriConfig.request.trustedServers?.push('http://localhost:4000');
    }, []);

    useEffect(() => {
        if (activeSlide && webScene && view) {
            const firstSlide = webScene.presentation.slides.getItemAt(0);
            firstSlide
                ?.applyTo(view, {
                    duration: 1000,
                })
                .then(function () {
                    console.log('SLide has been activated');
                });
        }
    }, [activeSlide, webScene, view]);

    useEffect(() => {
        if (view) {
            view.graphics.removeAll();
            points?.forEach(p => {
                const point = {
                    //Create a point
                    type: 'point',
                    longitude: p.longitude,
                    latitude: p.latitude,
                };
                const simpleMarkerSymbol = {
                    type: 'simple-marker',
                    color: [220, 0, 24],
                    size: 20,
                    outline: {
                        color: [255, 255, 255], // White
                        width: 2,
                    },
                    ...p.symbol,
                };

                const pointGraphic = new Graphic({
                    attributes: {
                        code: p.code,
                    },
                    geometry: point,
                    symbol: simpleMarkerSymbol,
                } as any);
                view.graphics.add(pointGraphic);
            });
        }
    }, [points, view]);

    useEffect(() => {
        if (view) {
            view.popup.autoOpenEnabled = !disableAutoPopup;
            view.on('click', event => {
                const point: ClickedPoint = {
                    screen: {
                        x: event.x,
                        y: event.y,
                    },
                    world: event.mapPoint,
                    mouseButton: event.button,
                };
                view.hitTest(point.screen).then(response => {
                    if (response.results.length) {
                        const object = response.results[0];
                        point.object = object;
                    }
                    if (onClick) {
                        onClick(point);
                    }
                    if (response.results[0]?.graphic.attributes.OBJECTID === 3) {
                        setClickedPoint(point);
                    }
                });
            });
        }
    }, [view]);

    useEffect(() => {
        const handles: Handles[] = [];
        if (currentHighlights) {
            currentHighlights.forEach(handle => {
                handle.remove();
            });
        }
        if (highlights) {
            view?.allLayerViews.forEach(lw => {
                lw.when(layerView => {
                    if (layerView.layer.id) {
                        highlights[layerView.layer.id]?.forEach(objectId => {
                            handles.push(layerView.highlight(objectId));
                        });
                    }
                });
            });
        }
        setCurrentHighlights(handles);
    }, [highlights]);

    const handleSetView = loadedView => {
        if (viewLoaded) {
            viewLoaded(loadedView);
        }
        setView(loadedView);
    };

    const handleClose = () => {
        setClickedPoint(null);
    };

    const viewerBounds = mapDiv.current ? mapDiv.current?.getBoundingClientRect() : null;

    const handleShowFile = (f: ExternalFileInfo) => () => {
        const path = generatePath(Path.PORTFOLIO_DASHBOARD, { fileID: f.fileID });
        setClickedPoint(null);
        history.push(path);
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <div className={classes.esriViewer} ref={mapDiv} />
            {mapDiv.current && (
                <Menu
                    open={clickedPoint !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        clickedPoint !== null
                            ? {
                                  top: clickedPoint.screen.y,
                                  left: (viewerBounds?.left || 0) + clickedPoint.screen.x,
                              }
                            : undefined
                    }
                    container={mapDiv.current}
                    disablePortal={true}
                >
                    {detailsData?.files
                        ?.filter(f => isFileAllowed(f))
                        .map(f => (
                            <MenuItem key={`file-${f.fileID}`} onClick={handleShowFile(f)}>
                                {f.name}
                            </MenuItem>
                        ))}
                </Menu>
            )}

            {view && (
                <ViewerTools
                    view={view}
                    renderer={ViewerToolsRenderer.ESRI}
                    tools={[
                        {
                            name: 'Base map',
                            code: 'basemap',
                            icon: 'map',
                            tool: new BasemapGallery({
                                view: view,
                                // activeBasemap: Basemap.fromId('arcgis-terrain'),
                                source: [
                                    Basemap.fromId('arcgis-terrain'),
                                    Basemap.fromId('satellite'),
                                    Basemap.fromId('streets-vector'),
                                ],
                            }),
                        },
                        {
                            name: 'Daylight',
                            code: 'daylight',
                            icon: 'light_mode',
                            tool: new Daylight({
                                view: view,
                            }),
                        },
                        {
                            name: 'Layers',
                            code: 'layers',
                            icon: 'layers',
                            tool: new LayerList({
                                view: view,
                            }),
                        },
                        {
                            name: 'Legend',
                            code: 'legend',
                            icon: 'legend',
                            tool: new Legend({
                                view: view,
                            }),
                        },
                        // {
                        //     name: 'Variants',
                        //     code: 'filter',
                        //     icon: 'filter_alt',
                        //     tool: <FeatureFilter view={view} filters={filters} />,
                        // },
                        // {
                        //     name: 'Shadow casting',
                        //     code: 'shadowcast',
                        //     icon: 'brightness_medium',
                        //     tool: new ShadowCast({
                        //         view: view,
                        //     }),
                        // },
                    ]}
                />
            )}
        </div>
    );
};

export default EsriViewer;
