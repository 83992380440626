import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { diff } from 'deep-object-diff';
import { isEmpty, isUndefined, some } from 'lodash';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
    CompanyBaseResponse,
    CompanyTypeBaseResponse,
    ConceptBaseResponse,
    CountryBaseResponse,
    DocumentBaseResponse,
    MilestoneDeadlineBase,
    OrganizationUnitBaseResponse,
    PendencyBaseResponse,
    PendencyTypeBase,
    PendencyTypeBaseResponse,
    PersonBaseResponse,
    PhaseType,
    ProjectFunctionBaseResponse,
    ProjectMilestoneBaseResponse,
    ProjectPhaseBase,
    ProjectPhaseBaseResponse,
    ProjectReportBaseDetailResponse,
    ProjectReportBaseResponse,
    ProjectReportPersonBaseResponse,
    RiskBaseResponse,
    StatusBaseResponse,
    StatusSetBaseResponse,
    TextualItemBaseResponse,
    TextualItemTypeBaseResponse,
    TradeBaseResponse,
} from '../../generate/api';
import { projReportsApi } from '../../packages/Api/data/projectReports/client';
import { generateNegativeID } from '../../utils/negativeID';
import { propertiesToArray } from '../../utils/Object';
import { RootState } from '../store';

export interface FormSavingStatus {
    // Multiple possible status enum values
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: any;
    affectedFieldNames?: string[];
}

export interface SwitchCreatedEntity<TEntity> {
    tempID: number;
    newEntity: TEntity;
}

export interface IProjectInformationState {
    projectID?: number;
    commonStaticData: {
        statusSet: StatusSetBaseResponse[];
        statuses: StatusBaseResponse[];
        textualItemTypes: TextualItemTypeBaseResponse[];
        pendencyTypes: PendencyTypeBase[];
        companyTypes: CompanyTypeBaseResponse[];
        milestones: ProjectMilestoneBaseResponse[];
        phases: ProjectPhaseBaseResponse[];
        countries: CountryBaseResponse[];
    };
    sharedData: {
        companies: CompanyBaseResponse[];
        people: PersonBaseResponse[];
    }; // todo - move here people and companies
    reports: ProjectReportBaseResponse[];
    commonSettings: {
        showPreviousVersion: boolean;
    };
    currentReport: {
        settings: {
            companyModal: {
                isOpen: boolean;
                companyID?: number;
            };
            personModal: {
                isOpen: boolean;
                personID?: number;
            };
        };
        isLastReport: boolean;
        report?: ProjectReportBaseDetailResponse;
        functions: ProjectFunctionBaseResponse[];
        projectReportPeople: ProjectReportPersonBaseResponse[];
        risks: RiskBaseResponse[];
        contractor?: CompanyBaseResponse;
        organizationUnits: OrganizationUnitBaseResponse[];
        textualItems: TextualItemBaseResponse[];
        pendencies: PendencyBaseResponse[];
        documents: DocumentBaseResponse[];
        trades: TradeBaseResponse[];
        concepts: ConceptBaseResponse[];
    };
    formSavingStatus: FormSavingStatus;
}

const initialState: IProjectInformationState = {
    projectID: undefined,
    commonStaticData: {
        statusSet: [],
        statuses: [],
        textualItemTypes: [],
        pendencyTypes: [],
        companyTypes: [],
        milestones: [],
        phases: [],
        countries: [],
    },
    sharedData: {
        companies: [],
        people: [],
    },
    reports: [],
    commonSettings: {
        showPreviousVersion: true,
    },
    currentReport: {
        settings: {
            companyModal: {
                isOpen: false,
                companyID: undefined,
            },
            personModal: {
                isOpen: false,
                personID: undefined,
            },
        },
        isLastReport: false, // todo
        report: undefined,
        functions: [],
        projectReportPeople: [],
        risks: [],
        contractor: undefined,
        organizationUnits: [],
        textualItems: [],
        pendencies: [],
        documents: [],
        trades: [],
        concepts: [],
    },
    // sharedData: {},
    formSavingStatus: {
        status: 'idle',
        error: null,
    },
};

export const projectInformationSlice = createSlice({
    name: 'projectInformation',
    initialState,

    reducers: {
        // Project ID
        // ------------------------------------------------------------------
        setProjectID: (state, action: PayloadAction<number | undefined>) => {
            state.projectID = action.payload;
        },

        // Common static data
        // ------------------------------------------------------------------
        setStatusSet: (state, action: PayloadAction<StatusSetBaseResponse[]>) => {
            state.commonStaticData.statusSet = action.payload;
        },
        setStatuses: (state, action: PayloadAction<StatusBaseResponse[]>) => {
            state.commonStaticData.statuses = action.payload;
        },
        setTextualItemTypes: (state, action: PayloadAction<TextualItemTypeBaseResponse[]>) => {
            state.commonStaticData.textualItemTypes = action.payload;
        },
        setPendencyTypes: (state, action: PayloadAction<PendencyTypeBase[]>) => {
            state.commonStaticData.pendencyTypes = action.payload;
        },
        setCompanyTypes: (state, action: PayloadAction<CompanyTypeBaseResponse[]>) => {
            state.commonStaticData.companyTypes = action.payload;
        },
        setMilestones: (state, action: PayloadAction<ProjectMilestoneBaseResponse[]>) => {
            state.commonStaticData.milestones = action.payload;
        },
        setPhases: (state, action: PayloadAction<ProjectPhaseBaseResponse[]>) => {
            state.commonStaticData.phases = action.payload;
        },
        setCountries: (state, action: PayloadAction<CountryBaseResponse[]>) => {
            state.commonStaticData.countries = action.payload;
        },

        // Reports
        // ------------------------------------------------------------------
        setReports: (state, action: PayloadAction<ProjectReportBaseResponse[]>) => {
            state.reports = action.payload;
        },

        // Common settings
        // ------------------------------------------------------------------
        setCommonSettings: (state, action: PayloadAction<{ parameterName; value }>) => {
            const { parameterName, value } = action.payload;
            state.commonSettings[parameterName] = value;
        },

        // Current report
        // ------------------------------------------------------------------
        setCompanyModal: (
            state,
            action: PayloadAction<
                IProjectInformationState['currentReport']['settings']['companyModal']
            >,
        ) => {
            state.currentReport.settings.companyModal = action.payload;
        },

        setPersonModal: (
            state,
            action: PayloadAction<
                IProjectInformationState['currentReport']['settings']['personModal']
            >,
        ) => {
            state.currentReport.settings.personModal = action.payload;
        },

        setReport: (state, action: PayloadAction<ProjectReportBaseResponse | undefined>) => {
            state.currentReport.report = action.payload;
        },

        setIsLastReport: (state, action: PayloadAction<boolean>) => {
            state.currentReport.isLastReport = action.payload;
        },

        /// Report people
        setProjectReportPeople: (
            state,
            action: PayloadAction<ProjectReportPersonBaseResponse[]>,
        ) => {
            if (state.currentReport) {
                state.currentReport.projectReportPeople = action.payload;
            }
        },
        createProjectReportPerson: (
            state,
            action: PayloadAction<ProjectReportPersonBaseResponse>,
        ) => {
            if (state.currentReport) {
                if (!state.currentReport.projectReportPeople) {
                    state.currentReport.projectReportPeople = [];
                }
                state.currentReport.projectReportPeople.push(action.payload);
            }
        },
        updateProjectReportPerson: (
            state,
            action: PayloadAction<ProjectReportPersonBaseResponse>,
        ) => {
            if (state.currentReport.projectReportPeople) {
                const index = state.currentReport.projectReportPeople.findIndex(
                    person => person.projectReportPersonID === action.payload.projectReportPersonID,
                );
                if (index !== -1) {
                    state.currentReport.projectReportPeople[index] = action.payload;
                }
            }
        },
        deleteProjectReportPerson: (state, action: PayloadAction<number>) => {
            if (state.currentReport.projectReportPeople) {
                const index = state.currentReport.projectReportPeople.findIndex(
                    person => person.projectReportPersonID === action.payload,
                );
                if (index !== -1) {
                    state.currentReport.projectReportPeople.splice(index, 1);
                }
            }
        },
        switchProjectReportPerson: (
            state,
            action: PayloadAction<SwitchCreatedEntity<ProjectReportPersonBaseResponse>>,
        ) => {
            const ProjectReportPersonSwitch = action.payload;
            const index = state.currentReport.projectReportPeople.findIndex(
                p => p.projectReportPersonID === ProjectReportPersonSwitch.tempID,
            );
            if (index !== -1) {
                state.currentReport.projectReportPeople[index] =
                    ProjectReportPersonSwitch.newEntity;
            }
        },

        setFunctions: (state, action: PayloadAction<ProjectFunctionBaseResponse[]>) => {
            state.currentReport.functions = action.payload;
        },
        setPeople: (state, action: PayloadAction<PersonBaseResponse[]>) => {
            state.sharedData.people = action.payload;
        },
        setCompanies: (state, action: PayloadAction<CompanyBaseResponse[]>) => {
            state.sharedData.companies = action.payload;
        },
        updateReportMilestoneDeadline: (state, action: PayloadAction<MilestoneDeadlineBase>) => {
            if (state.currentReport.report?.milestoneDeadlines) {
                const index = state.currentReport.report.milestoneDeadlines.findIndex(
                    deadline => deadline.projectMilestoneID === action.payload.projectMilestoneID,
                );
                if (index !== -1) {
                    state.currentReport.report.milestoneDeadlines[index] = action.payload;
                } else {
                    state.currentReport.report.milestoneDeadlines.push(action.payload);
                }
            }
        },

        /// Risks
        setRisks: (state, action: PayloadAction<RiskBaseResponse[]>) => {
            state.currentReport.risks = action.payload;
        },
        createRisk: (state, action: PayloadAction<RiskBaseResponse>) => {
            const newRisk: RiskBaseResponse = {
                ...action.payload,
                riskID: generateNegativeID(state.currentReport.risks, 'riskID'),
            };
            state.currentReport.risks.push(newRisk);
        },
        updateRisk: (state, action: PayloadAction<RiskBaseResponse>) => {
            const updatedRisk = action.payload;
            const index = state.currentReport.risks.findIndex(
                risk => risk.riskID === updatedRisk.riskID,
            );
            if (index !== -1) {
                state.currentReport.risks[index] = updatedRisk;
            }
        },
        deleteRisk: (state, action: PayloadAction<number>) => {
            const riskID = action.payload;
            const index = state.currentReport.risks.findIndex(risk => risk.riskID === riskID);
            if (index !== -1) {
                state.currentReport.risks.splice(index, 1);
            }
        },
        switchRisk: (state, action: PayloadAction<SwitchCreatedEntity<RiskBaseResponse>>) => {
            const riskSwitch = action.payload;
            const index = state.currentReport.risks.findIndex(p => p.riskID === riskSwitch.tempID);
            if (index !== -1) {
                state.currentReport.risks[index] = riskSwitch.newEntity;
            }
        },

        setContractor: (state, action: PayloadAction<CompanyBaseResponse | undefined>) => {
            state.currentReport.contractor = action.payload;
        },
        setOrganizationUnits: (state, action: PayloadAction<OrganizationUnitBaseResponse[]>) => {
            state.currentReport.organizationUnits = action.payload;
        },

        /// Textual items
        setTextualItems: (state, action: PayloadAction<TextualItemBaseResponse[]>) => {
            state.currentReport.textualItems = action.payload;
        },
        createTextualItem: (state, action: PayloadAction<TextualItemBaseResponse>) => {
            const newTextualItem: TextualItemBaseResponse = {
                ...action.payload,
                textualItemID: generateNegativeID(
                    state.currentReport.textualItems,
                    'textualItemID',
                ),
            };
            state.currentReport.textualItems.push(newTextualItem);
        },
        updateTextualItem: (state, action: PayloadAction<TextualItemBaseResponse>) => {
            const textualItem = action.payload;
            const index = state.currentReport.textualItems.findIndex(
                t => t.textualItemID === textualItem.textualItemID,
            );
            if (index !== -1) {
                state.currentReport.textualItems[index] = textualItem;
            }
        },
        deleteTextualItem: (state, action: PayloadAction<number>) => {
            const textualItemID = action.payload;
            const index = state.currentReport.textualItems.findIndex(
                t => t.textualItemID === textualItemID,
            );
            if (index !== -1) {
                state.currentReport.textualItems.splice(index, 1);
            }
        },
        switchTextualItem: (
            state,
            action: PayloadAction<SwitchCreatedEntity<TextualItemBaseResponse>>,
        ) => {
            const textualItemSwitch = action.payload;
            const index = state.currentReport.textualItems.findIndex(
                p => p.textualItemID === textualItemSwitch.tempID,
            );
            if (index !== -1) {
                state.currentReport.textualItems[index] = textualItemSwitch.newEntity;
            }
        },

        /// Pendencies
        setPendencies: (state, action: PayloadAction<PendencyBaseResponse[]>) => {
            state.currentReport.pendencies = action.payload;
        },
        createPendency: (state, action: PayloadAction<PendencyBaseResponse>) => {
            const newPendency: PendencyBaseResponse = {
                ...action.payload,
                pendencyID: generateNegativeID(state.currentReport.pendencies, 'pendencyID'),
            };
            state.currentReport.pendencies.push(newPendency);
        },
        updatePendency: (state, action: PayloadAction<PendencyBaseResponse>) => {
            const pendency = action.payload;
            const index = state.currentReport.pendencies.findIndex(
                p => p.pendencyID === pendency.pendencyID,
            );
            if (index !== -1) {
                state.currentReport.pendencies[index] = pendency;
            }
        },
        switchPendency: (
            state,
            action: PayloadAction<SwitchCreatedEntity<PendencyBaseResponse>>,
        ) => {
            const pendencySwitch = action.payload;
            const index = state.currentReport.pendencies.findIndex(
                p => p.pendencyID === pendencySwitch.tempID,
            );
            if (index !== -1) {
                state.currentReport.pendencies[index] = pendencySwitch.newEntity;
            }
        },
        deletePendency: (state, action: PayloadAction<number>) => {
            const pendencyID = action.payload;
            state.currentReport.pendencies = state.currentReport.pendencies.filter(
                p => p.pendencyID !== pendencyID,
            );
        },

        /// Documents
        setDocuments: (state, action: PayloadAction<DocumentBaseResponse[]>) => {
            state.currentReport.documents = action.payload;
        },
        createDocument: (state, action: PayloadAction<DocumentBaseResponse>) => {
            const newDocument: DocumentBaseResponse = {
                ...action.payload,
                documentID: generateNegativeID(state.currentReport.documents, 'documentID'),
            };
            state.currentReport.documents.push(newDocument);
        },
        updateDocument: (state, action: PayloadAction<DocumentBaseResponse>) => {
            const document = action.payload;
            const index = state.currentReport.documents.findIndex(
                d => d.documentID === document.documentID,
            );
            if (index !== -1) {
                state.currentReport.documents[index] = document;
            }
        },
        deleteDocument: (state, action: PayloadAction<number>) => {
            const documentID = action.payload;
            state.currentReport.documents = state.currentReport.documents.filter(
                d => d.documentID !== documentID,
            );
        },
        switchDocument: (
            state,
            action: PayloadAction<SwitchCreatedEntity<DocumentBaseResponse>>,
        ) => {
            const documentSwitch = action.payload;
            const index = state.currentReport.documents.findIndex(
                p => p.documentID === documentSwitch.tempID,
            );
            if (index !== -1) {
                state.currentReport.documents[index] = documentSwitch.newEntity;
            }
        },

        /// Trades
        setTrades: (state, action: PayloadAction<TradeBaseResponse[]>) => {
            state.currentReport.trades = action.payload;
        },
        createTrade: (state, action: PayloadAction<TradeBaseResponse>) => {
            const newTrade: TradeBaseResponse = {
                ...action.payload,
                tradeID: generateNegativeID(state.currentReport.trades, 'tradeID'),
            };
            state.currentReport.trades.push(newTrade);
        },
        updateTrade: (state, action: PayloadAction<TradeBaseResponse>) => {
            const trade = action.payload;
            const index = state.currentReport.trades.findIndex(t => t.tradeID === trade.tradeID);
            if (index !== -1) {
                state.currentReport.trades[index] = trade;
            }
        },
        deleteTrade: (state, action: PayloadAction<number>) => {
            const tradeID = action.payload;
            state.currentReport.trades = state.currentReport.trades.filter(
                t => t.tradeID !== tradeID,
            );
        },
        switchTrade: (state, action: PayloadAction<SwitchCreatedEntity<TradeBaseResponse>>) => {
            const tradeSwitch = action.payload;
            const index = state.currentReport.trades.findIndex(
                p => p.tradeID === tradeSwitch.tempID,
            );
            if (index !== -1) {
                state.currentReport.trades[index] = tradeSwitch.newEntity;
            }
        },

        /// Concepts
        setConcepts: (state, action: PayloadAction<ConceptBaseResponse[]>) => {
            state.currentReport.concepts = action.payload;
        },
        createConcept: (state, action: PayloadAction<ConceptBaseResponse>) => {
            const newConcept: ConceptBaseResponse = {
                ...action.payload,
                conceptID: generateNegativeID(state.currentReport.concepts, 'conceptID'),
            };
            state.currentReport.concepts.push(newConcept);
        },
        updateConcept: (state, action: PayloadAction<ConceptBaseResponse>) => {
            const concept = action.payload;
            const index = state.currentReport.concepts.findIndex(
                c => c.conceptID === concept.conceptID,
            );
            if (index !== -1) {
                state.currentReport.concepts[index] = concept;
            }
        },
        deleteConcept: (state, action: PayloadAction<number>) => {
            const conceptID = action.payload;
            state.currentReport.concepts = state.currentReport.concepts.filter(
                c => c.conceptID !== conceptID,
            );
        },
        switchConcept: (state, action: PayloadAction<SwitchCreatedEntity<ConceptBaseResponse>>) => {
            const conceptSwitch = action.payload;
            const index = state.currentReport.concepts.findIndex(
                p => p.conceptID === conceptSwitch.tempID,
            );
            if (index !== -1) {
                state.currentReport.concepts[index] = conceptSwitch.newEntity;
            }
        },

        // Form saving status
        // ------------------------------------------------------------------
        setFormAffectedFields: (state, action: PayloadAction<string[] | undefined>) => {
            state.formSavingStatus.affectedFieldNames = action.payload;
        },
        setFormSavingStatus: (state, action: PayloadAction<Partial<FormSavingStatus>>) => {
            state.formSavingStatus = {
                status: action.payload.status ?? state.formSavingStatus.status,
                error: action.payload.error ?? state.formSavingStatus.error,
                affectedFieldNames:
                    action.payload.affectedFieldNames ?? state.formSavingStatus.affectedFieldNames,
            };
        },
    },
    extraReducers(builder) {
        builder
            .addCase(saveReport.pending, (state, action) => {
                state.formSavingStatus.status = 'loading';
            })
            .addCase(saveReport.fulfilled, (state, action) => {
                state.formSavingStatus.status = 'succeeded';
                // // Add any fetched posts to the array
                // state.posts = state.posts.concat(action.payload)
            })
            .addCase(saveReport.rejected, (state, action) => {
                state.formSavingStatus.status = 'failed';
                state.formSavingStatus.error = action.error;
            });
    },
});

export const {
    // Project ID
    // ------------------------------------------------------------------
    setProjectID,

    // Common static data
    // ------------------------------------------------------------------
    setStatusSet,
    setStatuses,
    setTextualItemTypes,
    setPendencyTypes,
    setCompanyTypes,
    setPhases,
    setCountries,

    // Reports
    // ------------------------------------------------------------------
    setReports,

    // Common settings
    // ------------------------------------------------------------------
    setCommonSettings,

    // Current report
    // ------------------------------------------------------------------
    setCompanyModal,
    setPersonModal,
    setReport,
    setIsLastReport,

    /// Report people
    setProjectReportPeople,
    createProjectReportPerson,
    updateProjectReportPerson,
    deleteProjectReportPerson,
    switchProjectReportPerson,

    setFunctions,
    setPeople,
    setCompanies,
    setMilestones,
    updateReportMilestoneDeadline,

    /// Risks
    setRisks,
    createRisk,
    updateRisk,
    deleteRisk,
    switchRisk,

    setContractor,
    setOrganizationUnits,

    /// Textual items
    setTextualItems,
    createTextualItem,
    updateTextualItem,
    deleteTextualItem,
    switchTextualItem,

    /// Pendencies,
    setPendencies,
    createPendency,
    updatePendency,
    deletePendency,
    switchPendency,

    /// Documents
    setDocuments,
    createDocument,
    updateDocument,
    deleteDocument,
    switchDocument,

    /// Trades
    setTrades,
    createTrade,
    updateTrade,
    deleteTrade,
    switchTrade,

    /// Concepts
    setConcepts,
    createConcept,
    updateConcept,
    deleteConcept,
    switchConcept,

    // Form saving status
    // ------------------------------------------------------------------
    setFormAffectedFields,
    setFormSavingStatus,
} = projectInformationSlice.actions;

// Project ID
// ------------------------------------------------------------------
export const selectProjectID = (state: RootState): IProjectInformationState['projectID'] => {
    return state.projectInformation.projectID;
};

// Common static data
// ------------------------------------------------------------------
export const selectStatusSet = (state: RootState): StatusSetBaseResponse[] => {
    return state.projectInformation.commonStaticData.statusSet;
};

export const selectStatuses = (state: RootState): StatusBaseResponse[] => {
    return state.projectInformation.commonStaticData.statuses;
};

export const selectPendencyTypes = (state: RootState): PendencyTypeBaseResponse[] => {
    return state.projectInformation.commonStaticData.pendencyTypes;
};

export const selectCompanyTypes = (state: RootState): CompanyTypeBaseResponse[] => {
    return state.projectInformation.commonStaticData.companyTypes;
};

export const selectMilestones = (state: RootState): ProjectMilestoneBaseResponse[] => {
    return state.projectInformation.commonStaticData.milestones ?? [];
};

export const selectTextualItemTypes = (state: RootState): TextualItemTypeBaseResponse[] => {
    return state.projectInformation.commonStaticData.textualItemTypes;
};

export const selectPhases = (state: RootState): ProjectPhaseBaseResponse[] => {
    return state.projectInformation.commonStaticData.phases;
};

export const selectCountries = (state: RootState): CountryBaseResponse[] => {
    return state.projectInformation.commonStaticData.countries;
};

// Reports
// ------------------------------------------------------------------
export const selectReports = (state: RootState): ProjectReportBaseResponse[] => {
    return state.projectInformation.reports;
};

// Common settings
// ------------------------------------------------------------------
export const selectCommonSettings = (
    state: RootState,
): IProjectInformationState['commonSettings'] => {
    return state.projectInformation.commonSettings;
};

// Current report
// ------------------------------------------------------------------
export const selectCompanyModal = (
    state: RootState,
): IProjectInformationState['currentReport']['settings']['companyModal'] => {
    return state.projectInformation.currentReport.settings.companyModal;
};

export const selectPersonModal = (
    state: RootState,
): IProjectInformationState['currentReport']['settings']['personModal'] => {
    return state.projectInformation.currentReport.settings.personModal;
};

export const selectReport = (state: RootState): ProjectReportBaseDetailResponse | undefined => {
    return state.projectInformation.currentReport.report;
};

export const selectIsLastReport = (state: RootState): boolean => {
    return state.projectInformation.currentReport.isLastReport;
};

export const selectProjectReportPeople = (state: RootState): ProjectReportPersonBaseResponse[] => {
    return state.projectInformation.currentReport.projectReportPeople ?? [];
};

export const selectFunctions = (state: RootState): ProjectFunctionBaseResponse[] => {
    return state.projectInformation.currentReport.functions ?? [];
};

export const selectPeople = (state: RootState): PersonBaseResponse[] => {
    return state.projectInformation.sharedData.people ?? [];
};

export const selectCompanies = (state: RootState): CompanyBaseResponse[] => {
    return state.projectInformation.sharedData.companies ?? [];
};

export const selectRisks = (state: RootState): RiskBaseResponse[] => {
    return state.projectInformation.currentReport.risks ?? [];
};

export const selectTextualItems = (state: RootState): TextualItemBaseResponse[] => {
    return state.projectInformation.currentReport.textualItems ?? [];
};

export const selectPendencies = (state: RootState): PendencyBaseResponse[] => {
    return state.projectInformation.currentReport.pendencies ?? [];
};

export const selectDocuments = (state: RootState): DocumentBaseResponse[] => {
    return state.projectInformation.currentReport.documents ?? [];
};

export const selectTrades = (state: RootState): TradeBaseResponse[] => {
    return state.projectInformation.currentReport.trades ?? [];
};

export const selectConcepts = (state: RootState): ConceptBaseResponse[] => {
    return state.projectInformation.currentReport.concepts ?? [];
};

export const selectOrganizationUnits = (state: RootState): OrganizationUnitBaseResponse[] => {
    return state.projectInformation.currentReport.organizationUnits ?? [];
};

// Phases selector helpers
// ------------------------------------------------------------------
const selectPhaseType = (state, phaseType: PhaseType) => phaseType;

export const selectPhasesByType = createSelector(
    [selectPhases, selectPhaseType],
    (phases: ProjectPhaseBaseResponse[], phaseType) => {
        return phases
            .filter(phase => phase.type === phaseType)
            .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    },
);

export const selectReportPhase = createSelector(
    [selectReport, selectPhases],
    (report: ProjectReportBaseDetailResponse | undefined, phases: ProjectPhaseBaseResponse[]) => {
        return phases?.find(p => p.projectPhaseID === report?.projectPhaseID) ?? undefined;
    },
);

// Form saving status
// ------------------------------------------------------------------
export const selectFormSavingStatus = (state: RootState): FormSavingStatus => {
    return state.projectInformation.formSavingStatus;
};

export const saveReport = createAsyncThunk(
    'projectInformation/saveReport',
    async (data: ProjectReportBaseResponse | undefined, { getState, dispatch, extra }) => {
        const state: any = getState();
        const projectInformationState = state.projectInformation as IProjectInformationState;
        const diffChanges = diff(projectInformationState.currentReport.report!, data!);

        // todo: calculate diff only once (see condition below)
        const fields = propertiesToArray(diffChanges);
        dispatch(setFormAffectedFields(fields));

        // check in unless one parameter has some value
        const hasValue = some(diffChanges, value => !isUndefined(value));
        if (!hasValue) return;

        const response = await projReportsApi.postProjectReportsUpdatePartial(
            projectInformationState?.currentReport.report?.projectReportID as number,
            projectInformationState?.currentReport.report?.projectID as number, // todo from somewhere else
            diffChanges,
        );

        dispatch(setFormAffectedFields(undefined));
        dispatch(setReport(response.data));
    },
    {
        condition(data: ProjectReportBaseResponse | undefined, api) {
            const state: any = api.getState();
            const projectInformationState = state.projectInformation as IProjectInformationState;
            if (projectInformationState.currentReport.report && data) {
                const diffChanges = diff(projectInformationState.currentReport.report, data);
                if (!isEmpty(diffChanges)) {
                    return true;
                }
            }
            return false;
        },
        // serializeError: error => {
        //     if (error instanceof AxiosError) {
        //         console.log('error', error);
        //         const errorString = `${error.message}${
        //             error.response?.data?.ErrorMessage
        //                 ? ` (${error.response?.data?.ErrorMessage})`
        //                 : ''
        //         }`;
        //         return {
        //             message: errorString,
        //             // status: error.response?.status,
        //             // data: error.response?.data,
        //         };
        //     } else {
        //         return error as any;
        //     }
        // },
    },
);

export default projectInformationSlice.reducer;
