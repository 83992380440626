import React from 'react';

import Icon from '../Icon/Icon';
import styles from './styles.module.css';

const handleClick = (type: string) => {
    console.log('## Navigation switcher:', type);
};

const AsideNavigation: React.FC = () => {
    return (
        <div className="flex md:block border-r border-gray-200">
            <div className={styles.item} onClick={() => handleClick('gantt')}>
                <Icon name="gantt" size={20} />
            </div>
            <div className={styles.item} onClick={() => handleClick('map')}>
                <Icon name="map" size={20} />
            </div>
            <div className={styles.item} onClick={() => handleClick('project')}>
                <Icon name="folder-open" size={20} />
            </div>
            <div className={styles.item} onClick={() => handleClick('project-info')}>
                <Icon name="info" size={20} />
            </div>
            <div className={styles.item} onClick={() => handleClick('administration')}>
                <Icon name="settings" size={20} />
            </div>
        </div>
    );
};

export default AsideNavigation;
