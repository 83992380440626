import { ClickAwayListener } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import { appPages, hasRouteAccess } from '../../pages/PageRouter';
import switcherIcon from './switcher.svg';

const PluginSwitcher: React.FC<{ isOpen?: boolean }> = ({ isOpen = false }) => {
    const [open, setOpen] = useState<boolean>(isOpen);
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const getPath = (path: string) => {
        return generatePath(path, {});
    };

    return (
        <div className="relative h-full max-w-[60px] w-full mr-2">
            <div
                className={clsx(
                    'flex items-center bg-red-150 justify-center hover:bg-gray-100 cursor-pointer w-full h-full relative',
                    open && 'bg-primary text-white hover:bg-primary hover:text-white',
                )}
                onClick={() => setOpen(!open)}
            >
                <img src={switcherIcon} alt="" />
            </div>
            {open && (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <div className="absolute w-[705px] border-t-4 border-primary bg-white p-5 shadow-lg flex flex-wrap">
                        {keycloakInitialized &&
                            keycloak &&
                            appPages
                                .filter(appPage => hasRouteAccess(keycloak, appPage))
                                .map((appPage, index) => {
                                    const path = getPath(appPage?.path as string);

                                    return (
                                        <React.Fragment key={index}>
                                            {path ? (
                                                <div className="">
                                                    <Link
                                                        to={getPath(appPage?.path as string)}
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        <div className="w-[150px] h-[150px] border rounded p-5 m-2 justify-center flex items-center hover:bg-red-100">
                                                            {appPage.title}
                                                        </div>
                                                    </Link>
                                                </div>
                                            ) : (
                                                <div key={index} className="">
                                                    <div className="w-[150px] h-[150px] border border-gray-300 text-gray-300 rounded p-5 m-2 justify-center flex items-center text-center">
                                                        {appPage.title}
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                    </div>
                </ClickAwayListener>
            )}
        </div>
    );
};

export default PluginSwitcher;
