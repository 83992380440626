import { Button } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';

import styleTable from '../../styles/table.module.css';

const CompanyRow: React.FC = () => {
    const [showContracts, setShowContracts] = useState<boolean>(false);

    const ArrowIcon: React.FC = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.193"
                height="7.996"
                viewBox="0 0 13.193 7.996"
            >
                <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M13.338,12.248,17.92,7.669a.866.866,0,0,0-1.226-1.222L11.5,11.635a.864.864,0,0,0-.025,1.193l5.213,5.224a.866.866,0,0,0,1.226-1.222Z"
                    transform="translate(18.805 -10.678) rotate(90)"
                    fill="#0d0d0d"
                    stroke="rgba(0,0,0,0)"
                />
            </svg>
        );
    };

    return (
        <React.Fragment>
            <tr className="text-right">
                <td
                    className="font-bold hover:bg-gray-100 cursor-pointer text-left"
                    onClick={() => setShowContracts(!showContracts)}
                >
                    <span
                        className={clsx(
                            showContracts ? 'rotate-0' : 'rotate-180',
                            'inline-block mx-2',
                        )}
                    >
                        <ArrowIcon />
                    </span>{' '}
                    Mustermann AG
                </td>
                <td>0.00</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>0.00</td>
                <td>edit</td>
            </tr>
            {showContracts && (
                <React.Fragment>
                    <tr className="text-sm text-gray-400 text-right">
                        <td className="text-left">
                            <span className="pl-5 text-gray-600">Contract name</span>
                        </td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>edit</td>
                    </tr>
                    <tr className="text-sm text-gray-400 text-right">
                        <td className="text-left">
                            <span className="pl-5 text-gray-600">Contract name</span>
                        </td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>edit</td>
                    </tr>
                    <tr className="text-sm text-gray-400 text-right">
                        <td className="text-left">
                            <span className="pl-5 text-gray-600">Contract name</span>
                        </td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>0.00</td>
                        <td>edit</td>
                    </tr>
                    <tr>
                        <td colSpan={11}>
                            <span className="pl-5">
                                <Button variant="outlined" color="secondary" size="small">
                                    New contract
                                </Button>
                            </span>
                        </td>
                    </tr>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const FinanceDetailOverviewTable: React.FC = () => {
    return (
        <>
            <div className="border border-gray-200 rounded">
                <table
                    className={clsx(
                        styleTable.table,
                        styleTable.tableBordered,
                        styleTable.tableHoverRow,
                    )}
                >
                    <thead>
                        <tr>
                            <th rowSpan={2}>Aufragnehmer</th>
                            <th rowSpan={2}>Verträge inkl. NT</th>
                            <th rowSpan={2}>Obligo</th>
                            <th colSpan={4}>Zahlungen IST</th>
                            <th colSpan={3}>Zahlungsprognose*</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>Total</th>
                            <th>in %</th>
                            <th>Vorjahre</th>
                            <th>2023</th>
                            <th>2023</th>
                            <th>2024</th>
                            <th>2025</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <CompanyRow />
                        <CompanyRow />
                        <CompanyRow />
                        <tr className="text-right bg-gray-50">
                            <td className="">
                                <span className="font-bold">Total</span>
                            </td>
                            <td className="font-bold">0.00</td>
                            <td className="font-bold">0.00</td>
                            <td className="font-bold">0.00</td>
                            <td className="font-bold">0.00</td>
                            <td className="font-bold">0.00</td>
                            <td className="font-bold">0.00</td>
                            <td className="font-bold">0.00</td>
                            <td className="font-bold">0.00</td>
                            <td className="font-bold">0.00</td>
                            <td className="font-bold">edit</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="mt-4">
                <Button variant="outlined" color="secondary" size="small">
                    New company
                </Button>
            </div>
        </>
    );
};

export default FinanceDetailOverviewTable;
