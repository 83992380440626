import { defineMessages } from 'react-intl';

export default defineMessages({
    companyDetails: {
        id: 'components.companyForm.companyDetails',
        defaultMessage: 'Company details', // DE: Firmendetails
    },

    description: {
        id: 'components.companyForm.description',
        defaultMessage: 'Description', // DE: Beschreibung
    },

    email: {
        id: 'components.companyForm.email',
        defaultMessage: 'E-mail', // DE: E-Mail
    },

    website: {
        id: 'components.companyForm.website',
        defaultMessage: 'Website', // DE: Webseite
    },

    address: {
        id: 'components.companyForm.address',
        defaultMessage: 'Address', // DE: Adresse
    },

    line1: {
        id: 'components.companyForm.line1',
        defaultMessage: 'Line 1', // DE: Zeile 1
    },

    line2: {
        id: 'components.companyForm.line2',
        defaultMessage: 'Line 2', // DE: Zeile 2
    },

    city: {
        id: 'components.companyForm.city',
        defaultMessage: 'City', // DE: Stadt
    },

    zipCode: {
        id: 'components.companyForm.zipCode',
        defaultMessage: 'ZIP code', // DE: Postleitzahl
    },

    country: {
        id: 'components.companyForm.country',
        defaultMessage: 'Country', // DE: Land
    },

    organizationUnits: {
        id: 'components.companyForm.organizationUnits',
        defaultMessage: 'Organization units', // DE: Organisationseinheiten
    },

    name: {
        id: 'components.companyForm.name',
        defaultMessage: 'Name', // DE: Name
    },

    people: {
        id: 'components.companyForm.people',
        defaultMessage: 'People', // DE: Personen
    },

    firstName: {
        id: 'components.companyForm.firstName',
        defaultMessage: 'First name', // DE: Vorname
    },

    lastName: {
        id: 'components.companyForm.lastName',
        defaultMessage: 'Last name', // DE: Nachname
    },

    contractor: {
        id: 'components.companyForm.contractor',
        defaultMessage: 'Contractor', // DE: Auftragnehmer
    },

    owner: {
        id: 'components.companyForm.owner',
        defaultMessage: 'Owner', // DE: Auftraggeber
    },

    buttonAdd: {
        id: 'components.companyForm.button.add',
        defaultMessage: 'Add', // DE: Hinzufügen
    },

    buttonCancel: {
        id: 'components.companyForm.button.cancel',
        defaultMessage: 'Cancel', // DE: Abbrechen
    },

    buttonUpdate: {
        id: 'components.companyForm.button.update',
        defaultMessage: 'Update', // DE: Aktualisieren
    },

    buttonCreateNewCompany: {
        id: 'components.companyForm.button.createNewCompany',
        defaultMessage: 'Create new company', // DE: Neue Firma erstellen
    },

    buttonClose: {
        id: 'components.companyForm.button.close',
        defaultMessage: 'Close', // DE: Schließen
    },
    buttonNew: {
        id: 'components.companyForm.button.new',
        defaultMessage: 'New', // DE: Neu
    },

    errorEmailRequired: {
        id: 'components.personForm.error.emailRequired',
        defaultMessage: 'E-mail is required', // DE: E-Mail ist erforderlich
    },

    errorEmailInvalid: {
        id: 'components.personForm.error.emailInvalid',
        defaultMessage: 'E-mail is invalid', // DE: E-Mail ist ungültig
    },

    errorNameRequired: {
        id: 'components.companyForm.error.nameRequired',
        defaultMessage: 'Name is required', // DE: Name ist erforderlich
    },

    errorCountryRequired: {
        id: 'components.companyForm.error.countryRequired',
        defaultMessage: 'Country is required', // DE: Land ist erforderlich
    },
});
