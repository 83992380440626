import { Box, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import Select from '@mui/material/Select';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    selectStatuses,
    selectStatusSet,
} from '../../../redux/projectInformation/projectInformationSlice';
import iconArrowDown from './iconArrowDown.svg';
import iconArrowRight from './iconArrowRight.svg';
import iconArrowRightDown from './iconArrowRightDown.svg';
import iconArrowUp from './iconArrowUp.svg';
import iconArrowUpRight from './iconArrowUpRight.svg';
import iconCheck from './iconCheck.svg';
import iconCross from './iconCross.svg';
import iconExclamationMark from './iconExclamationMark.svg';
import iconStateEmpty from './iconStateEmpty.svg';
import iconStateFull from './iconStateFull.svg';
import iconStateHalf from './iconStateHalf.svg';
import style from './stateSelector.module.css';
import useImagePreloader from './useImagePreloader';

type ISet = 'completion' | 'fulfillment' | 'forecast' | 'risk' | 'aspect';

type IStatus =
    | 'open'
    | 'inProgress'
    | 'closed'
    | 'notFulfilled'
    | 'partlyFulfilled'
    | 'fulfilled'
    | 'notOnTrack'
    | 'watch'
    | 'onTrack'
    | 'actImmediately'
    | 'increasing'
    | 'constant'
    | 'decreasing'
    | 'completed'
    | 'outOfScope'
    | 'controlledRisk'
    | 'ok';

export interface IStateSelectorProps {
    category?: ISet;
    value?: number;
    disabled?: boolean;
    size?: 'small' | 'medium';
    canBeEmpty?: boolean;
    callbackValue?: (value: number) => void;
    oldState?: boolean; // if true - show only old state - not selector
    name?: string;
    onChange?: any;
    statusSetID?: number;
}

const StateSelector: React.FC<IStateSelectorProps> = ({
    disabled,
    size,
    category,
    canBeEmpty,
    value,
    callbackValue,
    oldState,
    name,
    onChange,
    statusSetID,
}) => {
    const [currentValue, setCurrentValue] = useState('');
    const [selectStyle, setSelectStyle] = useState({});

    const statusSet = useSelector(selectStatusSet);
    const statuses = useSelector(selectStatuses);

    const handleChange = (event: SelectChangeEvent) => {
        setCurrentValue(event.target.value as string);

        if (onChange) {
            onChange(event);
        } else {
            console.debug('onChange is not defined');
        }
    };

    useEffect(() => {
        setCurrentValue(value?.toString() || '');
    }, [value]);

    useEffect(() => {
        callbackValue?.(parseInt(currentValue));
    }, [currentValue]);

    useEffect(() => {
        if (!size) {
            size = 'small';
        }
    }, []);

    useEffect(() => {
        if (size === 'small') {
            setSelectStyle({ height: '40px', width: '80px', minWidth: '80px', maxWidth: '80px' });
        } else {
            setSelectStyle({});
        }
    }, [size]);

    // Preload images
    const preloadSrcList: string[] = [
        iconArrowDown,
        iconArrowRight,
        iconArrowRightDown,
        iconArrowUp,
        iconArrowUpRight,
        iconCheck,
        iconCross,
        iconExclamationMark,
    ];
    const { imagesPreloaded } = useImagePreloader(preloadSrcList);

    if (!imagesPreloaded) {
        return <></>;
    }

    const getStatusIcon = (status: IStatus): string | null => {
        switch (status) {
            case 'open':
                return iconStateEmpty;
            case 'inProgress':
                return iconStateHalf;
            case 'closed':
                return iconStateFull;
            case 'notFulfilled':
                return iconArrowDown;
            case 'partlyFulfilled':
                return iconArrowRight;
            case 'fulfilled':
                return iconArrowUp;
            case 'notOnTrack':
                return iconArrowUp;
            case 'watch':
                return iconArrowRight;
            case 'onTrack':
                return iconArrowDown;
            case 'actImmediately':
                return iconArrowUp;
            case 'increasing':
                return iconArrowUpRight;
            case 'constant':
                return iconArrowRight;
            case 'decreasing':
                return iconArrowRightDown;
            case 'completed':
                return iconArrowDown;
            case 'outOfScope':
                return iconCross;
            case 'controlledRisk':
                return iconExclamationMark;
            case 'ok':
                return iconCheck;

            default:
                return null;
        }
    };

    const StatusIcon: React.FC<{ status: IStatus }> = ({ status }) => {
        const icon = getStatusIcon(status);

        if (!icon) {
            return null;
        }

        return <img src={icon} alt={status} />;
    };

    let selectedStatusSet = category;

    if (statusSetID) {
        selectedStatusSet = statusSet?.find(set => set.statusSetID === statusSetID)?.code as ISet;
    }

    return (
        <Box>
            <FormControl className={style.selector} margin="none">
                <Select
                    value={currentValue}
                    onChange={handleChange}
                    disabled={disabled || oldState}
                    size={size}
                    style={selectStyle}
                    className={clsx(disabled && style.disabled, oldState && style.oldState)}
                    name={name}
                    displayEmpty={canBeEmpty}
                >
                    {canBeEmpty && <MenuItem value="">-</MenuItem>}

                    {statusSet?.map(set => {
                        if (set.code === selectedStatusSet) {
                            return statuses
                                ?.filter(status => status.statusSetID === set.statusSetID)
                                .map((status, index2) => (
                                    <MenuItem value={status.statusID} key={index2}>
                                        <StatusIcon status={status.code as IStatus} />
                                    </MenuItem>
                                ));
                        }
                        return null;
                    })}
                </Select>
            </FormControl>
        </Box>
    );
};

export default StateSelector;
