/* tslint:disable */
/* eslint-disable */
/**
 * Armasuisse Dashboard Web Application API
 * API definitions for Armasuisse Dashboard Web Application
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ExternalSystemDetails } from '../models';
// @ts-ignore
import { ExternalSystemSummary } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * POCApi - axios parameter creator
 * @export
 */
export const POCApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a detail of external system.
         * @summary Get external system details.
         * @param {string} externalSystemCode External system code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPocGetExternalSystemDetailsByCode: async (externalSystemCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalSystemCode' is not null or undefined
            assertParamExists('getPocGetExternalSystemDetailsByCode', 'externalSystemCode', externalSystemCode)
            const localVarPath = `/Poc/ExternalSystems/{externalSystemCode}`
                .replace(`{${"externalSystemCode"}}`, encodeURIComponent(String(externalSystemCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns IFC data.
         * @summary Get IFC data.
         * @param {string} externalSystemCode External system code
         * @param {string} fileIdentifier File ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPocGetExternalSystemFileIfcData: async (externalSystemCode: string, fileIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalSystemCode' is not null or undefined
            assertParamExists('getPocGetExternalSystemFileIfcData', 'externalSystemCode', externalSystemCode)
            // verify required parameter 'fileIdentifier' is not null or undefined
            assertParamExists('getPocGetExternalSystemFileIfcData', 'fileIdentifier', fileIdentifier)
            const localVarPath = `/Poc/ExternalSystems/{externalSystemCode}/File/{fileIdentifier}/Ifc`
                .replace(`{${"externalSystemCode"}}`, encodeURIComponent(String(externalSystemCode)))
                .replace(`{${"fileIdentifier"}}`, encodeURIComponent(String(fileIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of external systems.
         * @summary Get external systems list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPocGetExternalSystemsSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Poc/ExternalSystems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * POCApi - functional programming interface
 * @export
 */
export const POCApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = POCApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a detail of external system.
         * @summary Get external system details.
         * @param {string} externalSystemCode External system code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPocGetExternalSystemDetailsByCode(externalSystemCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalSystemDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPocGetExternalSystemDetailsByCode(externalSystemCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns IFC data.
         * @summary Get IFC data.
         * @param {string} externalSystemCode External system code
         * @param {string} fileIdentifier File ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPocGetExternalSystemFileIfcData(externalSystemCode: string, fileIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPocGetExternalSystemFileIfcData(externalSystemCode, fileIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of external systems.
         * @summary Get external systems list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPocGetExternalSystemsSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExternalSystemSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPocGetExternalSystemsSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * POCApi - factory interface
 * @export
 */
export const POCApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = POCApiFp(configuration)
    return {
        /**
         * Returns a detail of external system.
         * @summary Get external system details.
         * @param {string} externalSystemCode External system code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPocGetExternalSystemDetailsByCode(externalSystemCode: string, options?: any): AxiosPromise<ExternalSystemDetails> {
            return localVarFp.getPocGetExternalSystemDetailsByCode(externalSystemCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns IFC data.
         * @summary Get IFC data.
         * @param {string} externalSystemCode External system code
         * @param {string} fileIdentifier File ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPocGetExternalSystemFileIfcData(externalSystemCode: string, fileIdentifier: string, options?: any): AxiosPromise<File> {
            return localVarFp.getPocGetExternalSystemFileIfcData(externalSystemCode, fileIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of external systems.
         * @summary Get external systems list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPocGetExternalSystemsSummary(options?: any): AxiosPromise<Array<ExternalSystemSummary>> {
            return localVarFp.getPocGetExternalSystemsSummary(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * POCApi - object-oriented interface
 * @export
 * @class POCApi
 * @extends {BaseAPI}
 */
export class POCApi extends BaseAPI {
    /**
     * Returns a detail of external system.
     * @summary Get external system details.
     * @param {string} externalSystemCode External system code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POCApi
     */
    public getPocGetExternalSystemDetailsByCode(externalSystemCode: string, options?: AxiosRequestConfig) {
        return POCApiFp(this.configuration).getPocGetExternalSystemDetailsByCode(externalSystemCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns IFC data.
     * @summary Get IFC data.
     * @param {string} externalSystemCode External system code
     * @param {string} fileIdentifier File ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POCApi
     */
    public getPocGetExternalSystemFileIfcData(externalSystemCode: string, fileIdentifier: string, options?: AxiosRequestConfig) {
        return POCApiFp(this.configuration).getPocGetExternalSystemFileIfcData(externalSystemCode, fileIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of external systems.
     * @summary Get external systems list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POCApi
     */
    public getPocGetExternalSystemsSummary(options?: AxiosRequestConfig) {
        return POCApiFp(this.configuration).getPocGetExternalSystemsSummary(options).then((request) => request(this.axios, this.basePath));
    }
}
