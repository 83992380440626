/* tslint:disable */
/* eslint-disable */
/**
 * Armasuisse Dashboard Web Application API
 * API definitions for Armasuisse Dashboard Web Application
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompanyTypeBase } from '../models';
// @ts-ignore
import { CompanyTypeBaseResponse } from '../models';
// @ts-ignore
import { CompanyTypeDetailResponse } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * CompanyTypesApi - axios parameter creator
 * @export
 */
export const CompanyTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new Company Type and returns newly created Company Type.
         * @summary Creates new Company Type.
         * @param {CompanyTypeBase} [companyTypeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanyType: async (companyTypeBase?: CompanyTypeBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/CompanyTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyTypeBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Company Type.
         * @summary Delete Company Type.
         * @param {number} entityID Company Type identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyType: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteCompanyType', 'entityID', entityID)
            const localVarPath = `/CompanyTypes/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Company Type.
         * @summary Get Company Type details.
         * @param {number} entityID Company Type identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTypeDetails: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getCompanyTypeDetails', 'entityID', entityID)
            const localVarPath = `/CompanyTypes/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Company Types.
         * @summary Get list of Company Types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTypeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/CompanyTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Company Type and returns the modified Company Type.
         * @summary Edit Company Type.
         * @param {number} entityID Company Type identifier
         * @param {CompanyTypeBase} [companyTypeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyType: async (entityID: number, companyTypeBase?: CompanyTypeBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateCompanyType', 'entityID', entityID)
            const localVarPath = `/CompanyTypes/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyTypeBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyTypesApi - functional programming interface
 * @export
 */
export const CompanyTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new Company Type and returns newly created Company Type.
         * @summary Creates new Company Type.
         * @param {CompanyTypeBase} [companyTypeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCompanyType(companyTypeBase?: CompanyTypeBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyTypeDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCompanyType(companyTypeBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Company Type.
         * @summary Delete Company Type.
         * @param {number} entityID Company Type identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanyType(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanyType(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Company Type.
         * @summary Get Company Type details.
         * @param {number} entityID Company Type identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyTypeDetails(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyTypeDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyTypeDetails(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Company Types.
         * @summary Get list of Company Types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyTypeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyTypeBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyTypeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Company Type and returns the modified Company Type.
         * @summary Edit Company Type.
         * @param {number} entityID Company Type identifier
         * @param {CompanyTypeBase} [companyTypeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyType(entityID: number, companyTypeBase?: CompanyTypeBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyTypeDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyType(entityID, companyTypeBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyTypesApi - factory interface
 * @export
 */
export const CompanyTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyTypesApiFp(configuration)
    return {
        /**
         * Creates new Company Type and returns newly created Company Type.
         * @summary Creates new Company Type.
         * @param {CompanyTypeBase} [companyTypeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanyType(companyTypeBase?: CompanyTypeBase, options?: any): AxiosPromise<CompanyTypeDetailResponse> {
            return localVarFp.addCompanyType(companyTypeBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Company Type.
         * @summary Delete Company Type.
         * @param {number} entityID Company Type identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanyType(entityID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCompanyType(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Company Type.
         * @summary Get Company Type details.
         * @param {number} entityID Company Type identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTypeDetails(entityID: number, options?: any): AxiosPromise<CompanyTypeDetailResponse> {
            return localVarFp.getCompanyTypeDetails(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Company Types.
         * @summary Get list of Company Types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyTypeList(options?: any): AxiosPromise<Array<CompanyTypeBaseResponse>> {
            return localVarFp.getCompanyTypeList(options).then((request) => request(axios, basePath));
        },
        /**
         * Update Company Type and returns the modified Company Type.
         * @summary Edit Company Type.
         * @param {number} entityID Company Type identifier
         * @param {CompanyTypeBase} [companyTypeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyType(entityID: number, companyTypeBase?: CompanyTypeBase, options?: any): AxiosPromise<CompanyTypeDetailResponse> {
            return localVarFp.updateCompanyType(entityID, companyTypeBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyTypesApi - object-oriented interface
 * @export
 * @class CompanyTypesApi
 * @extends {BaseAPI}
 */
export class CompanyTypesApi extends BaseAPI {
    /**
     * Creates new Company Type and returns newly created Company Type.
     * @summary Creates new Company Type.
     * @param {CompanyTypeBase} [companyTypeBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyTypesApi
     */
    public addCompanyType(companyTypeBase?: CompanyTypeBase, options?: AxiosRequestConfig) {
        return CompanyTypesApiFp(this.configuration).addCompanyType(companyTypeBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Company Type.
     * @summary Delete Company Type.
     * @param {number} entityID Company Type identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyTypesApi
     */
    public deleteCompanyType(entityID: number, options?: AxiosRequestConfig) {
        return CompanyTypesApiFp(this.configuration).deleteCompanyType(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Company Type.
     * @summary Get Company Type details.
     * @param {number} entityID Company Type identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyTypesApi
     */
    public getCompanyTypeDetails(entityID: number, options?: AxiosRequestConfig) {
        return CompanyTypesApiFp(this.configuration).getCompanyTypeDetails(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Company Types.
     * @summary Get list of Company Types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyTypesApi
     */
    public getCompanyTypeList(options?: AxiosRequestConfig) {
        return CompanyTypesApiFp(this.configuration).getCompanyTypeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Company Type and returns the modified Company Type.
     * @summary Edit Company Type.
     * @param {number} entityID Company Type identifier
     * @param {CompanyTypeBase} [companyTypeBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyTypesApi
     */
    public updateCompanyType(entityID: number, companyTypeBase?: CompanyTypeBase, options?: AxiosRequestConfig) {
        return CompanyTypesApiFp(this.configuration).updateCompanyType(entityID, companyTypeBase, options).then((request) => request(this.axios, this.basePath));
    }
}
