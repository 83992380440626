import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RiskBaseResponse } from '../../../../generate/api';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import sectionTitleMessages from '../../MainForm/sectionTitleMessages';
import messages from './messages';
import RiskManagement from './RiskManagement/RiskManagement';
import RiskManagementTable from './RiskManagement/RiskManagementTable';
import RiskMitigationTable from './RiskMitigationTable';
import RiskOpportunityTable from './RiskOpportunityTable';

export interface IRowProps {
    formRowData: RiskBaseResponse;
    number: number;
}

const Risks = () => {
    const intl = useIntl();

    return (
        <section className="my-[100px]">
            <section id="section-05" className="scroll-my-5">
                <SectionHeader
                    number={5}
                    mainSection
                    title={intl.formatMessage({ ...sectionTitleMessages.risks })}
                    aspect="risksOverallAspect"
                />
                <div className="my-8">
                    <FormattedMessage {...messages.titleRiskDescription} />
                </div>
            </section>

            <section className="my-[50px]">
                <SectionHeader
                    number={5.1}
                    subSection
                    title={intl.formatMessage({ ...messages.titleRiskManagement })}
                    aspect="risksManagementAspect"
                />

                <div className="my-10">
                    <div className="table mx-auto">
                        <RiskManagement />
                    </div>

                    <div className="mt-10">
                        <RiskManagementTable />
                    </div>
                </div>
            </section>

            <section className="my-[50px]">
                <SectionHeader
                    number={5.2}
                    subSection
                    title={intl.formatMessage({ ...messages.titleRiskMitigation })}
                    aspect="risksMitigationAspect"
                />
                <div className="my-10">
                    <RiskMitigationTable />
                </div>
            </section>

            <section className="my-[50px]">
                <SectionHeader
                    number={5.3}
                    subSection
                    title={intl.formatMessage({ ...messages.titleRiskOpportunity })}
                    aspect="opportunityDevelopmentAspect"
                />
                <div className="my-10">
                    <RiskOpportunityTable />
                </div>
            </section>
        </section>
    );
};

export default Risks;
