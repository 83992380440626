import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ConceptBaseResponse } from '../../../../generate/api';
import {
    createConcept,
    deleteConcept,
    selectConcepts,
    selectIsLastReport,
    updateConcept,
} from '../../../../redux/projectInformation/projectInformationSlice';
import StateSelector from '../../../Form/StateSelector/StateSelector';
import ButtonDelete from '../../components/ButtonDelete/ButtonDelete';
import { FormDatepicker } from '../../components/FormDatepicker/FormDatepicker';
import styleTable from '../../styles/table.module.css';
import messages from './messages';

interface IRowProps {
    formRowData: ConceptBaseResponse;
}

const Row: React.FC<IRowProps> = ({ formRowData }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const isLastReport = useSelector(selectIsLastReport);
    const [row, setRow] = useState<ConceptBaseResponse>(formRowData);
    const reduxRow = useSelector(selectConcepts).find(
        concept => concept.conceptID === row.conceptID,
    );

    useEffect(() => {
        setRow(formRowData);
    }, [formRowData]);

    const handleFieldChange = (fieldName, value) => {
        setRow({ ...row, [fieldName]: value });
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (row?.conceptID) {
                // Compare if the row is the same as the row in redux, if it is, don't update
                if (
                    reduxRow?.name === row.name &&
                    reduxRow?.comment === row.comment &&
                    reduxRow?.responsible === row.responsible &&
                    reduxRow?.deadline === row.deadline &&
                    reduxRow?.statusID === row.statusID
                )
                    return;

                dispatch(updateConcept(row));
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [row]);

    const handleDeleteRow = () => {
        if (!formRowData.conceptID) return;
        dispatch(deleteConcept(formRowData.conceptID));
    };

    return (
        <tr>
            <td>
                <TextField
                    value={row.name}
                    onChange={e => handleFieldChange('name', e.target.value)}
                    fullWidth
                    size="small"
                    margin="none"
                    placeholder={intl.formatMessage({ ...messages.placeholderConcept })}
                    disabled={!isLastReport}
                />
            </td>
            <td>
                <TextField
                    value={row.comment}
                    onChange={e => handleFieldChange('comment', e.target.value)}
                    fullWidth
                    size="small"
                    margin="none"
                    placeholder={intl.formatMessage({ ...messages.placeholderComment })}
                    disabled={!isLastReport}
                />
            </td>
            <td>
                <TextField
                    value={row.responsible}
                    onChange={e => handleFieldChange('responsible', e.target.value)}
                    fullWidth
                    size="small"
                    margin="none"
                    placeholder={intl.formatMessage({ ...messages.placeholderResponsible })}
                    disabled={!isLastReport}
                />
            </td>
            <td>
                <FormDatepicker
                    value={row.deadline || undefined}
                    onChange={value => handleFieldChange('deadline', value)}
                    disabled={!isLastReport}
                />
            </td>
            <td>
                <StateSelector
                    category="completion"
                    canBeEmpty
                    value={row?.statusID || undefined}
                    onChange={e => handleFieldChange('statusID', e.target.value)}
                    disabled={!isLastReport}
                />
            </td>
            <td>
                <ButtonDelete onClick={handleDeleteRow} disabled={!isLastReport} />
            </td>
        </tr>
    );
};

const Concepts: React.FC = () => {
    const dispatch = useDispatch();
    const reduxConceptsData = useSelector(selectConcepts);
    const isLastReport = useSelector(selectIsLastReport);

    // Add a new row to the Redux
    const handleAddRow = () => {
        dispatch(
            createConcept({
                name: '',
            }),
        );
    };

    return (
        <>
            <div className={styleTable.tableOuterBorder}>
                <table className={styleTable.table}>
                    <thead>
                        <tr>
                            <th>
                                <FormattedMessage {...messages.labelConcept} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelComment} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelResponsible} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelDeadline} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelStatus} />
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {reduxConceptsData?.map((row, index) => (
                            <Row formRowData={row} key={index} />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-4">
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={handleAddRow}
                    disabled={!isLastReport}
                >
                    <FormattedMessage {...messages.buttonNewRow} />
                </Button>
            </div>
        </>
    );
};

export default Concepts;
