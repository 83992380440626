import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import SectionHeader from '../../components/SectionHeader/SectionHeader';
import sectionTitleMessages from '../../MainForm/sectionTitleMessages';
import styleTable from '../../styles/table.module.css';
import messages from './messages';

const DatesStatusReport: React.FC = () => {
    // translations
    const intl = useIntl();

    return (
        <section id="section-002" className="scroll-my-5 my-[50px]">
            <SectionHeader
                title={intl.formatMessage({
                    ...sectionTitleMessages.datesStatusReports,
                })}
            />

            <div className="flex gap-5">
                <div className="flex-1 border border-gray-200 rounded">
                    <table className={styleTable.table}>
                        <thead>
                            <tr>
                                <th className="text-left">
                                    <FormattedMessage {...messages.labelStatusReport} />
                                </th>
                                <th className="text-left">
                                    <FormattedMessage {...messages.labelProjectStatusEnd} />
                                </th>
                                <th className="text-left">
                                    <FormattedMessage {...messages.labelDueOnSecondTuesday} /> *
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>01/2021</td>
                                <td>März</td>
                                <td>April</td>
                            </tr>
                            <tr>
                                <td>01/2021</td>
                                <td>Juni</td>
                                <td>Juli</td>
                            </tr>
                            <tr>
                                <td>01/2021</td>
                                <td>September</td>
                                <td>Oktober</td>
                            </tr>
                            <tr>
                                <td>01/2021</td>
                                <td>Dezember</td>
                                <td>Januar</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex-1 border border-gray-200 rounded-lg">
                    <div>
                        <div className="p-3 border-b border-gray-200 font-bold">
                            * <FormattedMessage {...messages.riskManagementApprovalTitle} />
                        </div>
                        <div className="p-3">
                            <FormattedMessage {...messages.riskManagementApprovalDescription} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DatesStatusReport;
