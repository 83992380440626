import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { TradeBaseResponse } from '../../../../generate/api';
import {
    createTrade,
    deleteTrade,
    selectIsLastReport,
    selectTrades,
    updateTrade,
} from '../../../../redux/projectInformation/projectInformationSlice';
import ButtonDelete from '../../components/ButtonDelete/ButtonDelete';
import styleTable from '../../styles/table.module.css';
import messages from './messages';

interface IRowProps {
    formRowData: TradeBaseResponse;
}

const Row: React.FC<IRowProps> = ({ formRowData }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [row, setRow] = useState<TradeBaseResponse>(formRowData);
    const reduxRow = useSelector(selectTrades).find(trade => trade.tradeID === row.tradeID);
    const isLastReport = useSelector(selectIsLastReport);

    useEffect(() => {
        setRow(formRowData);
    }, [formRowData]);

    const handleFieldChange = (fieldName, value) => {
        setRow({ ...row, [fieldName]: value });
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (row?.tradeID) {
                // Compare if the row is the same as the row in redux, if it is, don't update
                if (
                    reduxRow?.name === row.name &&
                    reduxRow?.amount === row.amount &&
                    reduxRow?.justification === row.justification
                )
                    return;

                dispatch(updateTrade(row));
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [row]);

    const handleDeleteRow = () => {
        if (!formRowData.tradeID) return;
        dispatch(deleteTrade(formRowData.tradeID));
    };

    return (
        <tr>
            <td>
                <TextField
                    value={row.name}
                    onChange={e => handleFieldChange('name', e.target.value)}
                    fullWidth
                    size="small"
                    margin="none"
                    placeholder={intl.formatMessage({ ...messages.placeholderTrade })}
                    disabled={!isLastReport}
                />
            </td>
            <td>
                <TextField
                    value={row.amount}
                    onChange={e => handleFieldChange('amount', e.target.value)}
                    fullWidth
                    size="small"
                    margin="none"
                    type="number"
                    placeholder={intl.formatMessage({ ...messages.placeholderAmount })}
                    disabled={!isLastReport}
                />
            </td>
            <td>
                <TextField
                    value={row.justification}
                    onChange={e => handleFieldChange('justification', e.target.value)}
                    fullWidth
                    size="small"
                    margin="none"
                    placeholder={intl.formatMessage({ ...messages.placeholderReason })}
                    disabled={!isLastReport}
                />
            </td>
            <td>
                <ButtonDelete onClick={handleDeleteRow} />
            </td>
        </tr>
    );
};

const TradesTable: React.FC = () => {
    const dispatch = useDispatch();
    const reduxTradesData = useSelector(selectTrades);
    const isLastReport = useSelector(selectIsLastReport);

    // Add a new row to the Redux
    const handleAddRow = () => {
        dispatch(
            createTrade({
                name: '',
            }),
        );
    };

    // calculate the total of all amount in reduxTradesData - default value is 0 - round to 2 decimals
    const total = reduxTradesData?.reduce((acc, curr) => acc + Number(curr.amount), 0).toFixed(2);

    return (
        <>
            <div className={styleTable.tableOuterBorder}>
                <table className={styleTable.table}>
                    <thead>
                        <tr>
                            <th>
                                <FormattedMessage {...messages.labelTrade} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelAmount} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelReason} />
                            </th>
                            <th className="w-0"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {reduxTradesData?.map((row, index) => (
                            <Row formRowData={row} key={index} />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-between mt-4">
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={handleAddRow}
                    disabled={!isLastReport}
                >
                    <FormattedMessage {...messages.buttonNewRow} />
                </Button>
                <div className="flex justify-between w-full max-w-[200px] bg-neutral-200 px-2 py-1 rounded">
                    <div className="font-bold">
                        <FormattedMessage {...messages.total} />:
                    </div>
                    <div>{total}</div>
                </div>
            </div>
        </>
    );
};

export default TradesTable;
