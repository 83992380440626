import { defineMessages } from 'react-intl';

export default defineMessages({
    buttonPdfExport: {
        id: 'components.projectInformation.buttonsHeader.button.pdfExport',
        defaultMessage: 'PDF Export', // DE: PDF-Export
    },

    buttonXlsxExport: {
        id: 'components.projectInformation.buttonsHeader.button.xlsxExport',
        defaultMessage: 'XLSX Export', // DE: XLSX-Export
    },

    buttonXlsxImport: {
        id: 'components.projectInformation.buttonsHeader.button.xlsxImport',
        defaultMessage: 'XLSX Import', // DE: XLSX-Import
    },
});
