import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { defaultDrawerWidth, defaultHeaderHeight, StylesProps } from './Layout';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: { display: 'flex', paddingLeft: 0, paddingRight: 0, height: '100%' },
        appBar: (props: StylesProps | undefined) => ({
            zIndex: theme.zIndex.drawer - 1,
            color: props?.headerBarColor ? props?.headerBarColor : theme.palette.common.white,
            backgroundColor: props?.headerBarBackground
                ? props?.headerBarBackground
                : theme.palette.primary.dark,
            flexGrow: 1,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        }),
        appBarShift: (props: StylesProps | undefined) => ({
            width: `calc(100% - ${props?.drawerWidth ? props?.drawerWidth : defaultDrawerWidth}px)`,
            marginLeft: props?.drawerWidth ? props?.drawerWidth : defaultDrawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        drawer: (props: StylesProps | undefined) => ({
            background: props?.drawerWidth ? props?.drawerWidth : theme.palette.primary.main,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        }),
        drawerOpen: (props: StylesProps | undefined) => ({
            width: props?.drawerWidth ? props?.drawerWidth : defaultDrawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(6),
        },
        drawerHeader: (props: StylesProps | undefined) => ({
            display: 'flex',
            alignItems: 'center',
            padding: `0 ${theme.spacing(1)}`,
            justifyContent: 'space-between',
            color: props?.drawerHeaderColor ? props?.drawerHeaderColor : theme.palette.common.white,
            background: props?.drawerHeaderBackground
                ? props?.drawerHeaderBackground
                : theme.palette.primary.dark,
            minHeight: props?.headerHeight ? props?.headerHeight : defaultHeaderHeight,
            height: props?.headerHeight ? props?.headerHeight : defaultHeaderHeight,
        }),
        drawerPaper: (props: StylesProps | undefined) => ({
            color: props?.drawerPaperColor ? props?.drawerPaperColor : theme.palette.common.white,
            background: props?.drawerPaperBackground
                ? props?.drawerPaperBackground
                : theme.palette.primary.main,
            overflowX: 'hidden',
        }),
        drawerAnchorLeft: {
            borderRight: 0,
        },
        drawerMenu: {
            fontSize: '1rem',
            marginLeft: theme.spacing(2),
            fontWeight: 'bold',
            cursor: 'pointer',
        },
        main: {
            flexGrow: 1,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: `calc(100vw - 58px)`,
            height: '100%',
            overflowX: 'hidden',
        },
        mainShift: (props: StylesProps | undefined) => ({
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
            width: `calc(100vw - ${
                props?.drawerWidth ? props?.drawerWidth : defaultDrawerWidth
            }px)`,
        }),
        footerBar: (props: StylesProps | undefined) => ({
            background: props?.footerBarBackground
                ? props?.footerBarBackground
                : theme.palette.grey['500'],
            zIndex: theme.zIndex.drawer - 1,
            paddingLeft: props?.drawerDisabled ? 0 : theme.spacing(6),
            top: 'auto',
            bottom: 0,
            flexGrow: 1,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        }),
        footerBarShift: (props: StylesProps | undefined) => ({
            paddingLeft: props?.drawerWidth ? props?.drawerWidth : defaultDrawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        content: {
            maxHeight: '-webkit-fill-available',
            position: 'relative',
            height: `calc(100% - ${defaultHeaderHeight}px - 25px)`,
            marginTop: `${defaultHeaderHeight}px`,
            paddingBottom: '58px',
            flexGrow: 1,
            [theme.breakpoints.up('md')]: {
                height: `calc(100% - ${defaultHeaderHeight}px - 21px)`,
            },
        },
        hybridContent: {
            height: 'calc(100vh - 75px - env(safe-area-inset-top) - env(safe-area-inset-bottom))',
            marginTop: '21px',
            [theme.breakpoints.up('sm')]: {
                height: `calc(100vh - ${defaultHeaderHeight}px - env(safe-area-inset-top) - env(safe-area-inset-bottom))`,
            },
            [theme.breakpoints.up('md')]: {
                height: `calc(100vh - ${defaultHeaderHeight}px - env(safe-area-inset-top) - env(safe-area-inset-bottom))`,
            },
        },
        logo: {
            minWidth: '120px',
            '& img': {
                marginTop: '7px',
                height: '20px',
                minWidth: theme.spacing(4),
                cursor: 'pointer',
            },
        },
        logoShift: {
            width: '100%',
            textAlign: 'center',
        },
    }),
);

export default useStyles;
