import { Button, FormControl, MenuItem, TextField } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TextFieldProps } from 'mui-rff';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { ProjectReportBaseDetailResponse } from '../../../../generate/api';
import {
    saveReport,
    selectCompanies,
    selectCompanyTypes,
    selectIsLastReport,
    selectMilestones,
    selectPhases,
    selectReport,
    setCompanyModal,
} from '../../../../redux/projectInformation/projectInformationSlice';
import { store } from '../../../../redux/store';
import Todo from '../../../Todo/Todo';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import sectionTitleMessages from '../../MainForm/sectionTitleMessages';
import messages from './messages';

const ProjectData: React.FC = () => {
    const intl = useIntl();

    const quartals = [
        {
            id: 1,
            name: <FormattedMessage id="q1" defaultMessage={intl.formatMessage(messages.q1)} />,
        },
        {
            id: 2,
            name: <FormattedMessage id="q2" defaultMessage={intl.formatMessage(messages.q2)} />,
        },
        {
            id: 3,
            name: <FormattedMessage id="q3" defaultMessage={intl.formatMessage(messages.q3)} />,
        },
        {
            id: 4,
            name: <FormattedMessage id="q4" defaultMessage={intl.formatMessage(messages.q4)} />,
        },
    ];

    // Companies
    const companies = useSelector(selectCompanies);

    // Company types
    const companyTypes = useSelector(selectCompanyTypes);

    const ownerTypeId = companyTypes.find(
        companyType => companyType.code === 'owner',
    )?.companyTypeID;

    // Project Phases
    const projectPhases = useSelector(selectPhases);

    // Project Milestones
    const projectMilestones = useSelector(selectMilestones);

    const isLastReport = useSelector(selectIsLastReport);

    /**
     * Form
     * --------------------------------------------------------
     */
    const reduxProjectData = useSelector(selectReport);
    const [formData, setFormData] = useState<ProjectReportBaseDetailResponse>();

    useEffect(() => {
        setFormData(reduxProjectData);
    }, [reduxProjectData]);

    // Handle form input change
    type FieldEvent = ChangeEvent<any> | SelectChangeEvent<any>;
    const handleInputChange = (e: FieldEvent) => {
        const { name, value } = e.target;
        const [fieldName, nestedFieldName] = name.split('.');
        if (nestedFieldName) {
            setFormData(prevData => ({
                ...prevData,
                [fieldName]: {
                    ...(prevData?.[fieldName] || {}),
                    [nestedFieldName]: value,
                },
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                [fieldName]: value,
            }));
        }
    };

    // Save form data to redux
    useEffect(() => {
        const timeout = setTimeout(() => {
            console.log('formData', formData);
            // store.dispatch(setReport(formData));
            store.dispatch(saveReport(formData));
        }, 500);
        return () => clearTimeout(timeout);
    }, [formData]);

    /**
     * Company Form Modal
     * --------------------------------------------------------
     */
    const handleEditClient = () => {
        store.dispatch(
            setCompanyModal({ isOpen: true, companyID: reduxProjectData?.ownerID || undefined }),
        );
    };
    const handleCreateClient = () => {
        store.dispatch(setCompanyModal({ isOpen: true, companyID: undefined }));
    };

    interface CommonComponentProps {
        margin?: TextFieldProps['margin'];
        size?: TextFieldProps['size'];
    }
    const commonProps: CommonComponentProps = {
        margin: 'none',
        size: 'small',
    };

    return (
        <section id="section-001" className="scroll-my-5 my-[50px]">
            <SectionHeader title={intl.formatMessage({ ...sectionTitleMessages.projectData })} />

            <div className="xl:flex gap-6">
                <div className="flex-1">
                    <div className="grid gap-[3px_20px] grid-cols-[auto_1fr]">
                        {/* Project name */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.projectName} />
                        </div>
                        <TextField
                            {...commonProps}
                            value={formData?.name || ''}
                            name={'name'}
                            onChange={handleInputChange}
                            placeholder={intl.formatMessage({ ...messages.projectNamePlaceholder })}
                            disabled={!isLastReport}
                        />

                        {/* Project number lead */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.projectNumberLead} />
                        </div>
                        <TextField
                            {...commonProps}
                            value={formData?.projectNumberLead || ''}
                            name={'projectNumberLead'}
                            onChange={handleInputChange}
                            placeholder={intl.formatMessage({
                                ...messages.projectNumberLeadPlaceholder,
                            })}
                            disabled={!isLastReport}
                        />

                        {/* Approved credit realization */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.approvedCreditRealization} />
                        </div>
                        <TextField
                            {...commonProps}
                            value={formData?.realizationCredit?.toString() || ''}
                            name={'realizationCredit'}
                            onChange={handleInputChange}
                            type="number"
                            placeholder={intl.formatMessage({
                                ...messages.realizationCreditPlaceholder,
                            })}
                            disabled={!isLastReport}
                        />

                        {/* Network plan lead */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.networkPlanLead} />
                        </div>
                        <TextField
                            {...commonProps}
                            value={formData?.networkPlanLead || ''}
                            name={'networkPlanLead'}
                            onChange={handleInputChange}
                            placeholder={intl.formatMessage({
                                ...messages.networkPlanLeadPlaceholder,
                            })}
                            disabled={!isLastReport}
                        />

                        {/* Project number */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.projectNumber} />
                        </div>
                        <TextField
                            {...commonProps}
                            value={formData?.projectNumber || ''}
                            name={'projectNumber'}
                            onChange={handleInputChange}
                            placeholder={intl.formatMessage({
                                ...messages.projectNumberPlaceholder,
                            })}
                            disabled={!isLastReport}
                        />

                        {/* Approved credit project */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.approvedCreditProject} />
                        </div>
                        <TextField
                            {...commonProps}
                            value={formData?.projectPlanCredit?.toString() || ''}
                            name={'projectPlanCredit'}
                            onChange={handleInputChange}
                            type="number"
                            placeholder={intl.formatMessage({
                                ...messages.approvedCreditProjectPlaceholder,
                            })}
                            disabled={!isLastReport}
                        />

                        {/* Network plan */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.networkPlan} />
                        </div>
                        <TextField
                            {...commonProps}
                            value={formData?.networkPlan || ''}
                            name={'networkPlan'}
                            onChange={handleInputChange}
                            placeholder={intl.formatMessage({
                                ...messages.networkPlanPlaceholder,
                            })}
                            disabled={!isLastReport}
                        />

                        {/* Owner */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.owner} />
                        </div>
                        <div className="flex gap-2">
                            <FormControl fullWidth margin="none" {...commonProps}>
                                <Select
                                    name="ownerID"
                                    value={
                                        companies.some(
                                            company => company.companyID === formData?.ownerID,
                                        )
                                            ? formData?.ownerID
                                            : ''
                                    }
                                    onChange={handleInputChange}
                                    disabled={!isLastReport}
                                >
                                    <MenuItem value=""> - </MenuItem>
                                    {companies.map((company, index) => {
                                        if (company.companyTypeID !== ownerTypeId) return null;
                                        return (
                                            <MenuItem key={index} value={company.companyID}>
                                                {company.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            {reduxProjectData?.ownerID && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleEditClient}
                                    disabled={!isLastReport}
                                >
                                    <FormattedMessage {...messages.buttonEdit} />
                                </Button>
                            )}
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleCreateClient}
                                disabled={!isLastReport}
                            >
                                <FormattedMessage {...messages.buttonNew} />
                            </Button>
                        </div>

                        {/* Project manager owner */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.projectManagerOwner} />
                        </div>
                        <div className="py-2">
                            <Todo /> Max Mustermann
                        </div>

                        {/* Creator status report */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.creatorStatusReport} />
                        </div>
                        <div className="py-2">
                            <Todo /> 0
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    <div className="grid gap-[3px_20px] grid-cols-[auto_1fr]">
                        {/* Reporting year */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.reportingYear} />
                        </div>
                        <TextField
                            {...commonProps}
                            value={formData?.reportingYear?.toString() || ''}
                            name={'reportingYear'}
                            onChange={handleInputChange}
                            type="number"
                            placeholder={intl.formatMessage({
                                ...messages.reportingYearPlaceholder,
                            })}
                            disabled={!isLastReport}
                        />

                        {/* Quartal */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.quartal} />
                        </div>
                        <div className="">
                            <FormControl fullWidth {...commonProps}>
                                <Select
                                    name="reportQuarter"
                                    value={
                                        quartals.some(
                                            quartal => quartal.id === formData?.reportQuarter,
                                        )
                                            ? formData?.reportQuarter
                                            : ''
                                    }
                                    onChange={handleInputChange}
                                    disabled={!isLastReport}
                                >
                                    <MenuItem value=""> - </MenuItem>
                                    {quartals.map((quartal, index) => (
                                        <MenuItem key={index} value={quartal.id}>
                                            {quartal.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        {/* Reporting period */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.reportingPeriod} />
                        </div>
                        <div className="py-2">
                            <Todo /> 01.01.{reduxProjectData?.reportingYear}{' '}
                            <strong>
                                <FormattedMessage {...messages.until} />
                            </strong>{' '}
                            31.03.{reduxProjectData?.reportingYear}
                        </div>

                        {/* Current process phase */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.currentProcessPhase} />
                        </div>
                        <div className="">
                            <FormControl fullWidth {...commonProps}>
                                <Select
                                    name="projectPhaseID"
                                    value={
                                        projectPhases.some(
                                            projectPhase =>
                                                projectPhase.projectPhaseID ===
                                                formData?.projectPhaseID,
                                        )
                                            ? formData?.projectPhaseID
                                            : ''
                                    }
                                    onChange={handleInputChange}
                                    disabled={!isLastReport}
                                >
                                    <MenuItem value=""> - </MenuItem>
                                    {projectPhases.map((projectPhase, index) => {
                                        if (projectPhase.type !== 'Project') {
                                            return null;
                                        }
                                        return (
                                            <MenuItem
                                                key={index}
                                                value={projectPhase.projectPhaseID}
                                            >
                                                {projectPhase.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        {/* Next milestone process */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.nextMilestoneProcess} />
                        </div>
                        <div className="">
                            <FormControl fullWidth {...commonProps}>
                                <Select
                                    name="nextMilestoneProcessID"
                                    value={
                                        projectMilestones.some(
                                            projectMilestone =>
                                                projectMilestone.projectMilestoneID ===
                                                formData?.nextMilestoneProcessID,
                                        )
                                            ? formData?.nextMilestoneProcessID
                                            : ''
                                    }
                                    onChange={handleInputChange}
                                    disabled={!isLastReport}
                                >
                                    <MenuItem value=""> - </MenuItem>
                                    {projectMilestones.map((projectMilestone, index) => {
                                        if (projectMilestone.type !== 'Process') {
                                            return null;
                                        }
                                        return (
                                            <MenuItem
                                                key={index}
                                                value={projectMilestone.projectMilestoneID}
                                            >
                                                {projectMilestone.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        {/* Current SIA phase */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.currentSiaPhase} />
                        </div>
                        <div className="">
                            <FormControl fullWidth {...commonProps}>
                                <Select
                                    name="siaPhaseID"
                                    value={
                                        projectPhases.some(
                                            projectPhase =>
                                                projectPhase.projectPhaseID ===
                                                formData?.siaPhaseID,
                                        )
                                            ? formData?.siaPhaseID
                                            : ''
                                    }
                                    onChange={handleInputChange}
                                    disabled={!isLastReport}
                                >
                                    <MenuItem value=""> - </MenuItem>
                                    {projectPhases.map((projectPhase, index) => {
                                        if (projectPhase.type !== 'Sia') {
                                            return null;
                                        }
                                        return (
                                            <MenuItem
                                                key={index}
                                                value={projectPhase.projectPhaseID}
                                            >
                                                {projectPhase.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        {/* Next milestone PmV */}
                        {/* -------------------------------------------------------- */}
                        <div className="font-medium flex items-center">
                            <FormattedMessage {...messages.nextMilestonePmV} />
                        </div>
                        <div className="">
                            <FormControl fullWidth {...commonProps}>
                                <Select
                                    name="nextMilestoneID"
                                    value={
                                        projectMilestones.some(
                                            projectMilestone =>
                                                projectMilestone.projectMilestoneID ===
                                                formData?.nextMilestoneID,
                                        )
                                            ? formData?.nextMilestoneID
                                            : ''
                                    }
                                    onChange={handleInputChange}
                                    disabled={!isLastReport}
                                >
                                    <MenuItem value=""> - </MenuItem>
                                    {projectMilestones
                                        .filter(projectMilestone => projectMilestone.type === 'PmV')
                                        .map(projectMilestone => (
                                            <MenuItem
                                                key={projectMilestone.projectMilestoneID}
                                                value={projectMilestone.projectMilestoneID}
                                            >
                                                {projectMilestone.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectData;
