import { TextField } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    ProjectReportBaseDetailResponse,
    ProjectReportBaseResponse,
} from '../../../../generate/api';
import {
    saveReport,
    selectIsLastReport,
    selectReport,
    setReport,
} from '../../../../redux/projectInformation/projectInformationSlice';
import { store } from '../../../../redux/store';
import iconCheck from '../../../Form/StateSelector/iconCheck.svg';
import iconCross from '../../../Form/StateSelector/iconCross.svg';
import iconExclamationMark from '../../../Form/StateSelector/iconExclamationMark.svg';
import StateSelector from '../../../Form/StateSelector/StateSelector';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import sectionTitleMessages from '../../MainForm/sectionTitleMessages';
import styleTable from '../../styles/table.module.css';
import messages from './messages';

const Assessments: React.FC = () => {
    const intl = useIntl();

    // Get report detail from redux
    const reduxReportData = useSelector(selectReport);

    const isLastReport = useSelector(selectIsLastReport);

    const [formData, setFormData] = useState<ProjectReportBaseDetailResponse>();

    // Set form data from redux
    useEffect(() => {
        setFormData(reduxReportData);
    }, [reduxReportData]);

    // Handle form data change
    // set form data to state
    type FieldEvent = ChangeEvent<any> | SelectChangeEvent<any>;
    const handleInputChange = (e: FieldEvent) => {
        const { name, value } = e.target;
        const [fieldName, nestedFieldName] = name.split('.');
        if (nestedFieldName) {
            setFormData(prevData => ({
                ...prevData,
                [fieldName]: {
                    ...(prevData?.[fieldName] || {}),
                    [nestedFieldName]: value,
                },
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                [fieldName]: value,
            }));
        }
    };

    // Save form data to redux
    useEffect(() => {
        const timeout = setTimeout(() => {
            store.dispatch(saveReport(formData));
        }, 500);
        return () => clearTimeout(timeout);
    }, [formData]);

    return (
        <>
            {/* Gesamtbeurteilung */}
            <section id="section-004" className="scroll-my-5 my-[50px]">
                <SectionHeader
                    title={intl.formatMessage({ ...sectionTitleMessages.overallAssessment })}
                />
                <div>
                    <table className={styleTable.table}>
                        <thead>
                            <tr>
                                <th className="text-left">
                                    <FormattedMessage {...messages.aspect} />
                                </th>
                                <th>
                                    <FormattedMessage {...messages.previousPeriod} />
                                </th>
                                <th>
                                    <FormattedMessage {...messages.current} />
                                </th>
                                <th>
                                    <FormattedMessage {...messages.forecast} />
                                </th>
                                <th className="text-left">
                                    <FormattedMessage {...messages.comment} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="block font-bold">
                                        <FormattedMessage {...messages.overallAssessment} />
                                    </span>
                                    <span className="text-xs">
                                        <FormattedMessage {...messages.overallAssessmentSubtitle} />
                                    </span>
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.overallAssessmentAspect?.previousStatusID ||
                                            undefined
                                        }
                                        oldState
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.overallAssessmentAspect?.currentStatusID ||
                                            undefined
                                        }
                                        name={'overallAssessmentAspect.currentStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.overallAssessmentAspect?.forecastStatusID ||
                                            undefined
                                        }
                                        name={'overallAssessmentAspect.forecastStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        margin={'none'}
                                        value={
                                            formData?.overallAssessmentAspect?.comment || undefined
                                        }
                                        name={'overallAssessmentAspect.comment'}
                                        onChange={handleInputChange}
                                        multiline
                                        placeholder={intl.formatMessage({
                                            ...messages.overallAssessmentAspectPlaceholder,
                                        })}
                                        disabled={!isLastReport}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="mt-[20px] text-sm">
                        <div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({
                                        ...messages.overallAssessmentDescription,
                                    }),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>

            {/* Teilbeurteilungen */}
            <section id="section-005" className="scroll-my-5 my-[50px]">
                <SectionHeader
                    title={intl.formatMessage({ ...sectionTitleMessages.partialAssessments })}
                />
                <div>
                    <p>
                        <FormattedMessage {...messages.partialAssessmentDescription} />
                    </p>
                </div>

                <div>
                    <table className={styleTable.table}>
                        <thead>
                            <tr>
                                <th className="text-left">
                                    <FormattedMessage {...messages.aspect} />
                                </th>
                                <th>
                                    <FormattedMessage {...messages.previousPeriod} />
                                </th>
                                <th>
                                    <FormattedMessage {...messages.current} />
                                </th>
                                <th>
                                    <FormattedMessage {...messages.forecast} />
                                </th>
                                <th className="text-left">
                                    <FormattedMessage {...messages.comment} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span className="block font-bold">
                                        <FormattedMessage {...messages.goalAchievement} />
                                    </span>
                                    <span className="text-xs">
                                        <FormattedMessage {...messages.goalAchievementSubtitle} />
                                    </span>
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.targetAchievementOverallAspect
                                                ?.previousStatusID || undefined
                                        }
                                        oldState
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.targetAchievementOverallAspect
                                                ?.currentStatusID || undefined
                                        }
                                        name={'targetAchievementOverallAspect.currentStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.targetAchievementOverallAspect
                                                ?.forecastStatusID || undefined
                                        }
                                        name={'targetAchievementOverallAspect.forecastStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        margin={'none'}
                                        value={
                                            formData?.targetAchievementOverallAspect?.comment || ''
                                        }
                                        name={'targetAchievementOverallAspect.comment'}
                                        onChange={handleInputChange}
                                        multiline
                                        placeholder={intl.formatMessage({
                                            ...messages.targetAchievementOverallAspectPlaceholder,
                                        })}
                                        disabled={!isLastReport}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="block font-bold">
                                        <FormattedMessage {...messages.finances} />
                                    </span>
                                    <span className="text-xs leading-3">
                                        <FormattedMessage {...messages.financesSubtitle} />
                                    </span>
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.financesOverallAspect?.previousStatusID ||
                                            undefined
                                        }
                                        oldState
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.financesOverallAspect?.currentStatusID ||
                                            undefined
                                        }
                                        name={'financesOverallAspect.currentStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.financesOverallAspect?.forecastStatusID ||
                                            undefined
                                        }
                                        name={'financesOverallAspect.forecastStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        margin={'none'}
                                        value={formData?.financesOverallAspect?.comment || ''}
                                        name={'financesOverallAspect.comment'}
                                        onChange={handleInputChange}
                                        multiline
                                        placeholder={intl.formatMessage({
                                            ...messages.financesOverallAspectPlaceholderPlaceholder,
                                        })}
                                        disabled={!isLastReport}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="block font-bold">
                                        <FormattedMessage {...messages.humanResources} />
                                    </span>
                                    <span className="text-xs leading-3">
                                        <FormattedMessage {...messages.humanResourcesSubtitle} />
                                    </span>
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.humanResourcesOverallAspect
                                                ?.previousStatusID || undefined
                                        }
                                        oldState
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.humanResourcesOverallAspect
                                                ?.currentStatusID || undefined
                                        }
                                        name={'humanResourcesOverallAspect.currentStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.humanResourcesOverallAspect
                                                ?.forecastStatusID || undefined
                                        }
                                        name={'humanResourcesOverallAspect.forecastStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        margin={'none'}
                                        value={formData?.humanResourcesOverallAspect?.comment || ''}
                                        name={'humanResourcesOverallAspect.comment'}
                                        onChange={handleInputChange}
                                        multiline
                                        placeholder={intl.formatMessage({
                                            ...messages.humanResourcesOverallAspectPlaceholder,
                                        })}
                                        disabled={!isLastReport}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="block font-bold">
                                        <FormattedMessage {...messages.timeProgress} />
                                    </span>
                                    <span className="text-xs leading-3">
                                        <FormattedMessage {...messages.timeProgressSubtitle} />
                                    </span>
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.timeProgressOverallAspect?.previousStatusID ||
                                            undefined
                                        }
                                        oldState
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.timeProgressOverallAspect?.currentStatusID ||
                                            undefined
                                        }
                                        name={'timeProgressOverallAspect.currentStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.timeProgressOverallAspect?.forecastStatusID ||
                                            undefined
                                        }
                                        name={'timeProgressOverallAspect.forecastStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        margin={'none'}
                                        value={formData?.timeProgressOverallAspect?.comment || ''}
                                        name={'timeProgressOverallAspect.comment'}
                                        onChange={handleInputChange}
                                        multiline
                                        placeholder={intl.formatMessage({
                                            ...messages.timeProgressOverallAspectPlaceholder,
                                        })}
                                        disabled={!isLastReport}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="block font-bold">
                                        <FormattedMessage {...messages.risks} />
                                    </span>
                                    <span className="text-xs leading-3">
                                        <FormattedMessage {...messages.risksSubtitle} />
                                    </span>
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.risksOverallAspect?.previousStatusID ||
                                            undefined
                                        }
                                        oldState
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.risksOverallAspect?.currentStatusID ||
                                            undefined
                                        }
                                        name={'risksOverallAspect.currentStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.risksOverallAspect?.forecastStatusID ||
                                            undefined
                                        }
                                        name={'risksOverallAspect.forecastStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        margin={'none'}
                                        value={formData?.risksOverallAspect?.comment || ''}
                                        name={'risksOverallAspect.comment'}
                                        onChange={handleInputChange}
                                        multiline
                                        placeholder={intl.formatMessage({
                                            ...messages.risksOverallAspectPlaceholder,
                                        })}
                                        disabled={!isLastReport}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="block font-bold">
                                        <FormattedMessage {...messages.integralSafety} />
                                    </span>
                                    <span className="text-xs leading-3">
                                        <FormattedMessage {...messages.integralSafetySubtitle} />
                                    </span>
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.integralSafetyOverallAspect
                                                ?.previousStatusID || undefined
                                        }
                                        oldState
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.integralSafetyOverallAspect
                                                ?.currentStatusID || undefined
                                        }
                                        name={'integralSafetyOverallAspect.currentStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.integralSafetyOverallAspect
                                                ?.forecastStatusID || undefined
                                        }
                                        name={'integralSafetyOverallAspect.forecastStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        margin={'none'}
                                        value={formData?.integralSafetyOverallAspect?.comment || ''}
                                        name={'integralSafetyOverallAspect.comment'}
                                        onChange={handleInputChange}
                                        multiline
                                        placeholder={intl.formatMessage({
                                            ...messages.integralSafetyOverallAspectPlaceholder,
                                        })}
                                        disabled={!isLastReport}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="block font-bold">
                                        <FormattedMessage {...messages.checklistPhaseDocuments} />
                                    </span>
                                    <span className="text-xs leading-3">
                                        <FormattedMessage
                                            {...messages.checklistPhaseDocumentsSubtitle}
                                        />
                                    </span>
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.checklistOverallAspect?.previousStatusID ||
                                            undefined
                                        }
                                        oldState
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.checklistOverallAspect?.currentStatusID ||
                                            undefined
                                        }
                                        name={'checklistOverallAspect.currentStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <StateSelector
                                        category="aspect"
                                        size="medium"
                                        value={
                                            formData?.checklistOverallAspect?.forecastStatusID ||
                                            undefined
                                        }
                                        name={'checklistOverallAspect.forecastStatusID'}
                                        onChange={handleInputChange}
                                        disabled={!isLastReport}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        fullWidth
                                        size={'small'}
                                        margin={'none'}
                                        value={formData?.checklistOverallAspect?.comment || ''}
                                        name={'checklistOverallAspect.comment'}
                                        onChange={handleInputChange}
                                        multiline
                                        placeholder={intl.formatMessage({
                                            ...messages.checklistOverallAspectPlaceholder,
                                        })}
                                        disabled={!isLastReport}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="mt-[20px] text-sm">
                        <div>
                            <div className="flex items-center">
                                <div className="mr-2">
                                    <img src={iconCheck} className="w-[20px]" alt="icon" />
                                </div>
                                <div>
                                    <FormattedMessage {...messages.criterionMessageOk} />
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="mr-2">
                                    <img
                                        src={iconExclamationMark}
                                        className="w-[20px]"
                                        alt="icon"
                                    />
                                </div>
                                <div>
                                    <FormattedMessage {...messages.criterionMessageWarning} />
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="mr-2">
                                    <img src={iconCross} className="w-[20px]" alt="icon" />
                                </div>
                                <div>
                                    <FormattedMessage {...messages.criterionMessageCritical} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Assessments;
