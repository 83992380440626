import { defineMessages } from 'react-intl';

export default defineMessages({
    overallAssessment: {
        id: 'components.projectInformation.overallAssessment.overallAssessment',
        defaultMessage: 'Overall assessment', // DE: Gesamtbeurteilung
    },

    assessment: {
        id: 'components.projectInformation.overallAssessment.assessment',
        defaultMessage: 'Assessment', // DE: Beurteilung
    },

    projectData: {
        id: 'components.projectInformation.section.title.projectData',
        defaultMessage: 'Project data', // DE: Projektkenndaten
    },

    datesStatusReports: {
        id: 'components.projectInformation.section.title.datesStatusReports',
        defaultMessage: 'Dates Status reports', // DE: Termine Statusberichte
    },

    projectTeam: {
        id: 'components.projectInformation.section.title.projectTeam',
        defaultMessage: 'Project team', // DE: Projektteam
    },

    partialAssessments: {
        id: 'components.projectInformation.section.title.partialAssessments',
        defaultMessage: 'Partial assessments', // DE: Teilbeurteilungen
    },

    goalAchievement: {
        // 1
        id: 'components.projectInformation.section.title.goalAchievement',
        defaultMessage: 'Goal achievement', // DE: Zielerreichnung
    },

    reviewOfWork: {
        // 1.1
        id: 'components.projectInformation.section.title.reviewOfWork',
        defaultMessage: 'Review of status of work/activities', // DE: Rückblick Stand der Arbeiten/Tätigkeiten
    },

    currentStatusOfWorkActivities: {
        // 1.2
        id: 'components.projectInformation.section.title.currentStatusOfWorkActivities',
        defaultMessage: 'Current status of work/activities', // DE: aktueller Stand der Arbeiten/Tätigkeiten
    },

    outlookOfWorkActivities: {
        // 1.3
        id: 'components.projectInformation.section.title.outlookOfWorkActivities',
        defaultMessage: 'Outlook of work/activities', // DE: Ausblick der Arbeiten/Tätigkeiten
    },

    finances: {
        // 2
        id: 'components.projectInformation.section.title.finances',
        defaultMessage: 'Finances', // DE: Finanzen
    },

    detailOverviewProjectCredit: {
        // 2.1
        id: 'components.projectInformation.section.title.detailOverview',
        defaultMessage: 'Detail overview (project credit)', // DE: Detailübersicht (Projektierungskredit)
    },

    costOverviewRealizationCredit: {
        // 2.2
        id: 'components.projectInformation.section.title.costOverview',
        defaultMessage: 'Cost overview (realization credit)', // DE: Übersicht Kostenstand (Realisierungskredit)
    },

    useBKP8: {
        // 2.3
        id: 'components.projectInformation.section.title.useBKP8',
        defaultMessage: 'Use BKP 8 (project reserve)', // DE: Verwendung BKP 8 (Projektreserve)
    },

    detailOverviewRealizationCredit: {
        // 2.4
        id: 'components.projectInformation.section.title.detailOverviewRealizationCredit',
        defaultMessage: 'Detail overview (realization credit)', // DE: Detailübersicht Realisierungskredit
    },

    awards: {
        // 2.5
        id: 'components.projectInformation.section.title.awards',
        defaultMessage: 'Awards', // DE: Vergaben
    },

    humanResources: {
        // 3
        id: 'components.projectInformation.section.title.humanResources',
        defaultMessage: 'Human resources', // DE: Personalressourcen
    },

    internalProjectResources: {
        // 3.1
        id: 'components.projectInformation.section.title.internalProjectResources',
        defaultMessage: 'Internal project resources', // DE: Interne Projektressourcen
    },

    externalProjectResources: {
        // 3.2
        id: 'components.projectInformation.section.title.externalProjectResources',
        defaultMessage: 'External project resources', // DE: Externe Projektressourcen
    },

    timeProgress: {
        // 4
        id: 'components.projectInformation.section.title.timeProgress',
        defaultMessage: 'Time progress', // DE: Zeitlicher Fortschritt
    },

    projectMilestonesEndDates: {
        // 4.1
        id: 'components.projectInformation.section.title.projectMilestonesEndDates',
        defaultMessage: 'Project milestones / end dates', // DE: Projektmeilensteine / Endtermine
    },

    comparisonActualTargetDatesCurrentProjectStatus: {
        // 4.2
        id: 'components.projectInformation.section.title.comparisonActualTargetDatesCurrentProjectStatus',
        defaultMessage: 'Comparison actual/target dates (current project status)', // DE: IST-/SOLL Vergleich Termine aktueller Projektstand
    },

    risks: {
        // 5
        id: 'components.projectInformation.section.title.risks',
        defaultMessage: 'Risks', // DE: Risiken
    },

    integralSafety: {
        // 6
        id: 'components.projectInformation.section.title.integralSafety',
        defaultMessage: 'Integral safety', // DE: Integrale Sicherheit
    },

    concepts: {
        id: 'components.projectInformation.section.title.concepts',
        defaultMessage: 'Concepts', // DE: Konzepte
    },

    checklistPhaseDocuments: {
        // 7
        id: 'components.projectInformation.section.title.checklistPhaseDocuments',
        defaultMessage: 'Checklist phase documents', // DE: Checkliste Phasendokumente
    },
});
