import { Button, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { PendencyBaseResponse } from '../../../../generate/api';
import {
    createPendency,
    deletePendency,
    selectIsLastReport,
    selectPendencies,
    selectPendencyTypes,
    updatePendency,
} from '../../../../redux/projectInformation/projectInformationSlice';
import { store } from '../../../../redux/store';
import StateSelector from '../../../Form/StateSelector/StateSelector';
import StateEditRow from '../StateEditRow/StateEditRow';
import messages from './messages';

interface IPendenciesProps {
    code: string;
}

interface IRowProps {
    formRowData: PendencyBaseResponse;
    statusSetID?: number;
}

const Row: React.FC<IRowProps> = ({ formRowData, statusSetID }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const isLastReport = useSelector(selectIsLastReport);

    const [row, setRow] = React.useState<PendencyBaseResponse>(formRowData);

    const reduxPendency = useSelector(selectPendencies).find(p => p.pendencyID === row.pendencyID);

    useEffect(() => {
        setRow(formRowData);
    }, [formRowData]);

    const handleFieldChange = (fieldName, value) => {
        setRow({ ...row, [fieldName]: value });
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (row?.pendencyID) {
                // Compare if the row is the same as the row in redux, if it is, don't update
                if (
                    reduxPendency?.description === row.description &&
                    reduxPendency?.previousStatusID === row.previousStatusID &&
                    reduxPendency?.statusID === row.statusID
                )
                    return;
                dispatch(updatePendency(row));
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [row]);

    const handleDeleteRow = () => {
        if (!row?.pendencyID) return;
        dispatch(deletePendency(row.pendencyID));
    };

    return (
        <>
            <StateEditRow
                editFieldComponent={
                    <TextField
                        value={row.description}
                        onChange={e => handleFieldChange('description', e.target.value)}
                        fullWidth
                        size="small"
                        margin="none"
                        multiline
                        placeholder={intl.formatMessage({ ...messages.placeholder })}
                        disabled={!isLastReport}
                    />
                }
                oldStateComponent={
                    <StateSelector
                        value={row?.previousStatusID || undefined}
                        oldState
                        statusSetID={statusSetID}
                    />
                }
                stateSelectorComponent={
                    <StateSelector
                        value={row?.statusID || undefined}
                        onChange={e => handleFieldChange('statusID', e.target.value)}
                        statusSetID={statusSetID}
                        disabled={!isLastReport}
                    />
                }
                handleDelete={handleDeleteRow}
            />
        </>
    );
};

const Pendencies: React.FC<IPendenciesProps> = ({ code }) => {
    const dispatch = useDispatch();

    // load all pendencyTypes and pendencies from redux
    const pendencyTypes = useSelector(selectPendencyTypes);
    const pendencies = useSelector(selectPendencies);
    const isLastReport = useSelector(selectIsLastReport);

    // find current pendencyType
    const currentPendencyType = pendencyTypes.find(p => p.code === code);

    // Add a new row to the Redux
    const handleAddRow = () => {
        dispatch(
            createPendency({
                description: '',
                pendencyTypeID: currentPendencyType?.pendencyTypeID,
            }),
        );
    };

    // TODO
    // // "Placeholder" first empty row on initial load
    // // check if number of pendencies is 0 and pendencyTypeID is the same as in currentPendencyType
    // useEffect(() => {
    //     const pendenciesFiltered = pendencies.filter(
    //         p => p.pendencyTypeID === currentPendencyType?.pendencyTypeID,
    //     );
    //
    //     if (pendenciesFiltered.length === 0) {
    //         dispatch(
    //             createPendency({
    //                 description: '',
    //                 pendencyTypeID: currentPendencyType?.pendencyTypeID,
    //             }),
    //         );
    //     }
    // }, [pendencyTypes, pendencies]);

    return (
        <div>
            {pendencies.map((row, index) => {
                if (row.pendencyTypeID !== currentPendencyType?.pendencyTypeID) {
                    return null;
                }

                return (
                    <Row
                        formRowData={row}
                        key={index}
                        statusSetID={currentPendencyType?.statusSetID || undefined}
                    />
                );
            })}
            <div className="mt-4">
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={handleAddRow}
                    disabled={!isLastReport}
                >
                    <FormattedMessage {...messages.buttonNewRow} />
                </Button>
            </div>
        </div>
    );
};

export default Pendencies;
