import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import Icon from '../../components/Icon/Icon';
import style from '../../components/ProjectInformation/styles.module.css';
import { ExternalFileInfo } from '../../generate/api';
import { pocApi } from '../../packages/Api/data/externalSystems/client';
import { Path } from '../PageRouter';
import IfcViewer from '../PocPage/IfcViewer';
import ProjectList from './ProjectList';
export interface IIfcFileViewerProps {
    fileID: string;
}
const IfcFileViewer: React.FC<IIfcFileViewerProps> = ({ fileID }) => {
    const [ifcData, setIfcData] = useState<Blob>();
    const [ifcDataLoading, setIfcDataLoading] = useState<boolean>(false);

    useEffect(() => {
        if (fileID) {
            setIfcDataLoading(true);
            // load data
            pocApi
                .getPocGetExternalSystemFileIfcData('libal', fileID)
                .then(response => {
                    if (response.data) {
                        setIfcData(new Blob([response.data]));
                    }
                })
                .finally(() => {
                    setIfcDataLoading(false);
                });
        }
    }, [fileID]);

    return (
        <>
            <IfcViewer
                key={`viewer-${fileID}`}
                ifcData={ifcData}
                ifcDataLoading={ifcDataLoading}
                // onModelLaded={handleModelLoaded}
            />
        </>
    );
};

export default IfcFileViewer;
