import { Button, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { TextualItemBaseResponse } from '../../../../generate/api';
import {
    createTextualItem,
    deleteTextualItem,
    selectIsLastReport,
    selectTextualItems,
    selectTextualItemTypes,
    setTextualItems,
    updateTextualItem,
} from '../../../../redux/projectInformation/projectInformationSlice';
import Icon from '../../../Icon/Icon';
import ButtonDelete from '../ButtonDelete/ButtonDelete';
import messages from './messages';

interface ITextualItemsProps {
    code: string;
}

interface IRowProps {
    formRowData: TextualItemBaseResponse;
}

const Row: React.FC<IRowProps> = ({ formRowData }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const isLastReport = useSelector(selectIsLastReport);

    const [row, setRow] = React.useState<TextualItemBaseResponse>(formRowData);

    const reduxRow = useSelector(selectTextualItems).find(
        p => p.textualItemID === row.textualItemID,
    );

    useEffect(() => {
        setRow(formRowData);
    }, [formRowData]);

    const handleFieldChange = (fieldName, value) => {
        setRow({ ...row, [fieldName]: value });
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (row?.textualItemID) {
                // Compare if the row is the same as the row in redux, if it is, don't update
                if (reduxRow?.text === row.text) return;

                dispatch(updateTextualItem(row));
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [row]);

    const handleDeleteRow = () => {
        if (!row?.textualItemID) return;
        dispatch(deleteTextualItem(row.textualItemID));
    };

    return (
        <div className="my-2 flex gap-2 items-center">
            <div className="flex-1">
                <TextField
                    value={row.text}
                    onChange={e => handleFieldChange('text', e.target.value)}
                    fullWidth
                    size="small"
                    margin="none"
                    multiline
                    placeholder={intl.formatMessage({ ...messages.placeholder })}
                    disabled={!isLastReport}
                />
            </div>
            <ButtonDelete onClick={handleDeleteRow} />
        </div>
    );
};

const TextualItems: React.FC<ITextualItemsProps> = ({ code }) => {
    const dispatch = useDispatch();

    // load all textualItemTypes and textualItems from redux
    const textualItemTypes = useSelector(selectTextualItemTypes);
    const textualItems = useSelector(selectTextualItems);
    const isLastReport = useSelector(selectIsLastReport);

    // find current textualItemType
    const currentTextualItemType = textualItemTypes.find(item => item.code === code);

    // Add a new row to the Redux
    const handleAddRow = () => {
        dispatch(
            createTextualItem({
                textualItemTypeID: currentTextualItemType?.textualItemTypeID,
                text: '',
            }),
        );
    };

    // TODO
    // // "Placeholder" first empty row on initial load
    // // check if number of textualItems is 0 and textualItemTypeID is same as in currentTextualItemType
    // useEffect(() => {
    //     const initialTextualItems = textualItems.filter(
    //         item => item.textualItemTypeID === currentTextualItemType?.textualItemTypeID,
    //     );
    //     if (initialTextualItems.length === 0) {
    //         dispatch(
    //             createTextualItem({
    //                 textualItemTypeID: currentTextualItemType?.textualItemTypeID,
    //                 text: '',
    //             }),
    //         );
    //     }
    // }, [textualItemTypes, textualItems]);

    return (
        <div>
            {textualItems.map((row, index) => {
                if (row.textualItemTypeID !== currentTextualItemType?.textualItemTypeID)
                    return null;

                return <Row formRowData={row} key={index} />;
            })}
            <div className="mt-4">
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={handleAddRow}
                    disabled={!isLastReport}
                >
                    <FormattedMessage {...messages.buttonNewRow} />
                </Button>
            </div>
        </div>
    );
};

export default TextualItems;
