// import { notifierReducer } from '@iolabs/notifier/dist/reduxToolkit';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import keycloakReducer from './keyclock/keycloakSlice';
import { listenerMiddleware } from './projectInformation/listenerMiddleware';
import projectNotificationReducer from './projectInformation/projectInformationSlice';

export const store = configureStore({
    reducer: {
        keycloak: keycloakReducer,
        // notifier: notifierReducer,
        projectInformation: projectNotificationReducer,
    },
    middleware: getDefaultMiddleWare => {
        return getDefaultMiddleWare(/*{ thunk: false }*/).prepend(listenerMiddleware.middleware);
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
