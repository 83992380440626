import CheckIcon from '@mui/icons-material/Check';
import { Alert, CircularProgress, Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { selectFormSavingStatus } from '../../../redux/projectInformation/projectInformationSlice';
import ShowPreviousVersionSwitcher from '../components/ShowPreviousVersionSwitcher/ShowPreviousVersionSwitcher';
import { IEntryPageSidebarProps } from '../ProjectInformation';
import sectionTitleMessages from './sectionTitleMessages';
import style from './sidebar.module.css';

const EntryPageSidebar: React.FC<IEntryPageSidebarProps> = ({ onSectionClick }) => {
    const handleSectionClick = (section: string) => {
        onSectionClick(section);
    };

    const formSavingStatus = useSelector(selectFormSavingStatus);

    return (
        <div className="bg-[#F6F6F6] flex flex-col justify-between">
            <div>
                <div className="text-[18px] py-[25px] pl-[20px] font-medium">
                    Project Information
                </div>

                <div>
                    <div
                        className={clsx(style.sidebarItem)}
                        onClick={() => handleSectionClick('section-001')}
                    >
                        <FormattedMessage {...sectionTitleMessages.projectData} />
                    </div>
                    <div
                        className={clsx(style.sidebarItem)}
                        onClick={() => handleSectionClick('section-002')}
                    >
                        <FormattedMessage {...sectionTitleMessages.datesStatusReports} />
                    </div>
                    <div
                        className={clsx(style.sidebarItem)}
                        onClick={() => handleSectionClick('section-003')}
                    >
                        <FormattedMessage {...sectionTitleMessages.projectTeam} />
                    </div>
                    <div
                        className={clsx(style.sidebarItem)}
                        onClick={() => handleSectionClick('section-004')}
                    >
                        <FormattedMessage {...sectionTitleMessages.overallAssessment} />
                    </div>
                    <div
                        className={clsx(style.sidebarItem)}
                        onClick={() => handleSectionClick('section-005')}
                    >
                        <FormattedMessage {...sectionTitleMessages.partialAssessments} />
                    </div>
                    <div
                        className={clsx(style.sidebarItem)}
                        onClick={() => handleSectionClick('section-01')}
                    >
                        1 - <FormattedMessage {...sectionTitleMessages.goalAchievement} />
                    </div>
                    <div
                        className={clsx(style.sidebarItem)}
                        onClick={() => handleSectionClick('section-02')}
                    >
                        2 - <FormattedMessage {...sectionTitleMessages.finances} />
                    </div>
                    <div
                        className={style.sidebarItem}
                        onClick={() => handleSectionClick('section-03')}
                    >
                        3 - <FormattedMessage {...sectionTitleMessages.humanResources} />
                    </div>
                    <div
                        className={style.sidebarItem}
                        onClick={() => handleSectionClick('section-04')}
                    >
                        4 - <FormattedMessage {...sectionTitleMessages.timeProgress} />
                    </div>
                    <div
                        className={style.sidebarItem}
                        onClick={() => handleSectionClick('section-05')}
                    >
                        5 - <FormattedMessage {...sectionTitleMessages.risks} />
                    </div>
                    <div
                        className={style.sidebarItem}
                        onClick={() => handleSectionClick('section-06')}
                    >
                        6 - <FormattedMessage {...sectionTitleMessages.integralSafety} />
                    </div>
                    <div
                        className={style.sidebarItem}
                        onClick={() => handleSectionClick('section-07')}
                    >
                        7 - <FormattedMessage {...sectionTitleMessages.checklistPhaseDocuments} />
                    </div>
                </div>

                <div className="ml-4 mt-8">
                    <ShowPreviousVersionSwitcher />
                </div>
            </div>

            <div>
                {/* TODO: Saving status */}
                {formSavingStatus.status === 'loading' && (
                    <div className="bg-blue-200 rounded px-4 m-2 flex h-[50px]">
                        <div className="flex items-center gap-4">
                            <CircularProgress size={20} color="secondary" />
                            <div>Saving ...</div>
                        </div>
                        <div className="text-xs">
                            {/*{formSavingStatus.affectedFieldNames?.join('<br />')}*/}
                        </div>
                    </div>
                )}
                {formSavingStatus.status === 'succeeded' && (
                    <div className={style.fadeOut}>
                        <div className="bg-green-300 rounded px-4 m-2 flex h-[50px]">
                            <div className="flex items-center gap-4">
                                <CheckIcon />
                                <div>Saved</div>
                            </div>
                        </div>
                    </div>
                )}
                {formSavingStatus.status === 'failed' && (
                    <Alert severity={'warning'}>
                        API ERROR: {formSavingStatus.error?.message}
                        {/* <pre>{JSON.stringify(formSavingStatus.error, null, 2)}</pre> */}
                    </Alert>
                )}
            </div>
        </div>
    );
};

export default EntryPageSidebar;
