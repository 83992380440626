import { defineMessages } from 'react-intl';

export default defineMessages({
    projectManagerOwner: {
        id: 'components.projectInformation.projectData.projectManagerOwner',
        defaultMessage: 'Project manager (Owner)', // DE: Projektleiter Bauherr
    },

    creatorStatusReport: {
        id: 'components.projectInformation.projectData.creatorStatusReport',
        defaultMessage: 'Creator status report', // DE: Ersteller Statusbericht
    },

    projectName: {
        id: 'components.projectInformation.projectData.projectName',
        defaultMessage: 'Project name', // DE: Projektbezeichnung
    },
    projectNamePlaceholder: {
        id: 'components.projectInformation.projectData.projectNamePlaceholder',
        defaultMessage: 'Enter project name', // DE: Projektbezeichnung eingeben
    },

    gpsPlaceholder: {
        id: 'components.projectInformation.projectData.gpsPlaceholder',
        defaultMessage: 'Enter GPS', // DE: GPS eingeben
    },

    projectNumberLead: {
        id: 'components.projectInformation.projectData.projectNumberLead',
        defaultMessage: 'Project number (Lead)', // DE: Projektnummer (Lead)
    },

    projectNumberLeadPlaceholder: {
        id: 'components.projectInformation.projectData.projectNumberLeadPlaceholder',
        defaultMessage: 'Enter project number (Lead)', // DE: Projektnummer (Lead) eingeben
    },

    approvedCreditRealization: {
        id: 'components.projectInformation.projectData.approvedCreditRealization',
        defaultMessage: 'Approved credit realization', // DE: Realisierungs bewilligter Kredit
    },

    realizationCreditPlaceholder: {
        id: 'components.projectInformation.projectData.realizationCreditPlaceholder',
        defaultMessage: 'Enter realization credit', // DE: Realisierungs bewilligter Kredit eingeben
    },

    networkPlanLead: {
        id: 'components.projectInformation.projectData.networkPlanLead',
        defaultMessage: 'Network plan (Lead)', // DE: Netzplan (Lead)
    },

    networkPlanLeadPlaceholder: {
        id: 'components.projectInformation.projectData.networkPlanLeadPlaceholder',
        defaultMessage: 'Enter network plan (Lead)', // DE: Netzplan (Lead) eingeben
    },

    projectNumberPlaceholder: {
        id: 'components.projectInformation.projectData.projectNumberPlaceholder',
        defaultMessage: 'Enter project number', // DE: Projektnummer eingeben
    },

    projectNumber: {
        id: 'components.projectInformation.projectData.projectNumber',
        defaultMessage: 'Project number', // DE: Projektnummer
    },

    approvedCreditProject: {
        id: 'components.projectInformation.projectData.approvedCreditProject',
        defaultMessage: 'Approved credit project', // DE: Projektierungs bewilligter Kredit
    },

    approvedCreditProjectPlaceholder: {
        id: 'components.projectInformation.projectData.approvedCreditProjectPlaceholder',
        defaultMessage: 'Enter approved credit project', // DE: Projektierungs bewilligter Kredit eingeben
    },

    networkPlan: {
        id: 'components.projectInformation.projectData.networkPlan',
        defaultMessage: 'Network plan', // DE: Netzplan
    },

    networkPlanPlaceholder: {
        id: 'components.projectInformation.projectData.networkPlanPlaceholder',
        defaultMessage: 'Enter network plan', // DE: Netzplan eingeben
    },

    owner: {
        id: 'components.projectInformation.projectData.contractor',
        defaultMessage: 'Owner', // DE: Auftraggeber
    },

    projectManagerClient: {
        id: 'components.projectInformation.projectData.projectManagerClient',
        defaultMessage: 'Project manager client', // DE: Projektleiter Bauherr
    },

    reportCreator: {
        id: 'components.projectInformation.projectData.reportCreator',
        defaultMessage: 'Report creator', // DE: Ersteller Statusbericht
    },

    reportingYear: {
        id: 'components.projectInformation.projectData.reportingYear',
        defaultMessage: 'Reporting year', // DE: Berichtsjahr
    },

    reportingYearPlaceholder: {
        id: 'components.projectInformation.projectData.reportingYearPlaceholder',
        defaultMessage: 'Enter reporting year', // DE: Berichtsjahr eingeben
    },

    quartal: {
        id: 'components.projectInformation.projectData.quartal',
        defaultMessage: 'Quartal', // DE: Quartal
    },

    currentProcessPhase: {
        id: 'components.projectInformation.projectData.currentProcessPhase',
        defaultMessage: 'Current process phase', // DE: Aktuelle Prozessphase
    },

    reportingPeriod: {
        id: 'components.projectInformation.projectData.reportingPeriod',
        defaultMessage: 'Reporting period', // DE: Berichtsperiode
    },

    nextMilestoneProcess: {
        id: 'components.projectInformation.projectData.nextMilestoneProcess',
        defaultMessage: 'Next milestone process', // DE: Nächster Meilenstein Prozess
    },

    currentSiaPhase: {
        id: 'components.projectInformation.projectData.currentSiaPhase',
        defaultMessage: 'Current SIA phase', // DE: Aktuelle SIA-Phase
    },

    nextMilestonePmV: {
        id: 'components.projectInformation.projectData.nextMilestonePmV',
        defaultMessage: 'Next milestone PM V', // DE: Nächster Meilenstein PM V
    },

    until: {
        id: 'components.projectInformation.projectData.until',
        defaultMessage: 'until', // DE: bis
    },

    q1: {
        id: 'components.projectInformation.projectData.q1',
        defaultMessage: 'Q1 (January - March)', // DE: Q1 (Januar - März)
    },

    q2: {
        id: 'components.projectInformation.projectData.q2',
        defaultMessage: 'Q2 (April - June)', // DE: Q2 (April - Juni)
    },

    q3: {
        id: 'components.projectInformation.projectData.q3',
        defaultMessage: 'Q3 (July - September)', // DE: Q3 (Juli - September)
    },

    q4: {
        id: 'components.projectInformation.projectData.q4',
        defaultMessage: 'Q4 (October - December)', // DE: Q4 (Oktober - Dezember)
    },

    buttonEdit: {
        id: 'components.projectInformation.projectData.buttonEdit',
        defaultMessage: 'Edit', // DE: Bearbeiten
    },

    buttonNew: {
        id: 'components.projectInformation.projectData.buttonNew',
        defaultMessage: 'New', // DE: Neu
    },
});
