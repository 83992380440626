/* tslint:disable */
/* eslint-disable */
/**
 * Armasuisse Dashboard Web Application API
 * API definitions for Armasuisse Dashboard Web Application
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { ProjectBase } from '../models';
// @ts-ignore
import { ProjectBaseResponse } from '../models';
// @ts-ignore
import { ProjectDetailResponse } from '../models';
/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new Project and returns newly created Project.
         * @summary Creates new Project.
         * @param {ProjectBase} [projectBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProject: async (projectBase?: ProjectBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Project.
         * @summary Delete Project.
         * @param {number} entityID Project identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteProject', 'entityID', entityID)
            const localVarPath = `/Projects/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Project.
         * @summary Get Project details.
         * @param {number} entityID Project identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDetails: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getProjectDetails', 'entityID', entityID)
            const localVarPath = `/Projects/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Projects.
         * @summary Get list of Projects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Project and returns the modified Project.
         * @summary Edit Project.
         * @param {number} entityID Project identifier
         * @param {ProjectBase} [projectBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (entityID: number, projectBase?: ProjectBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateProject', 'entityID', entityID)
            const localVarPath = `/Projects/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new Project and returns newly created Project.
         * @summary Creates new Project.
         * @param {ProjectBase} [projectBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProject(projectBase?: ProjectBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProject(projectBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Project.
         * @summary Delete Project.
         * @param {number} entityID Project identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Project.
         * @summary Get Project details.
         * @param {number} entityID Project identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectDetails(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectDetails(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Projects.
         * @summary Get list of Projects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Project and returns the modified Project.
         * @summary Edit Project.
         * @param {number} entityID Project identifier
         * @param {ProjectBase} [projectBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(entityID: number, projectBase?: ProjectBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(entityID, projectBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * Creates new Project and returns newly created Project.
         * @summary Creates new Project.
         * @param {ProjectBase} [projectBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProject(projectBase?: ProjectBase, options?: any): AxiosPromise<ProjectDetailResponse> {
            return localVarFp.addProject(projectBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Project.
         * @summary Delete Project.
         * @param {number} entityID Project identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(entityID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProject(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Project.
         * @summary Get Project details.
         * @param {number} entityID Project identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDetails(entityID: number, options?: any): AxiosPromise<ProjectDetailResponse> {
            return localVarFp.getProjectDetails(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Projects.
         * @summary Get list of Projects.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectList(options?: any): AxiosPromise<Array<ProjectBaseResponse>> {
            return localVarFp.getProjectList(options).then((request) => request(axios, basePath));
        },
        /**
         * Update Project and returns the modified Project.
         * @summary Edit Project.
         * @param {number} entityID Project identifier
         * @param {ProjectBase} [projectBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(entityID: number, projectBase?: ProjectBase, options?: any): AxiosPromise<ProjectDetailResponse> {
            return localVarFp.updateProject(entityID, projectBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * Creates new Project and returns newly created Project.
     * @summary Creates new Project.
     * @param {ProjectBase} [projectBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public addProject(projectBase?: ProjectBase, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).addProject(projectBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Project.
     * @summary Delete Project.
     * @param {number} entityID Project identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProject(entityID: number, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteProject(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Project.
     * @summary Get Project details.
     * @param {number} entityID Project identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectDetails(entityID: number, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectDetails(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Projects.
     * @summary Get list of Projects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectList(options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Project and returns the modified Project.
     * @summary Edit Project.
     * @param {number} entityID Project identifier
     * @param {ProjectBase} [projectBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProject(entityID: number, projectBase?: ProjectBase, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProject(entityID, projectBase, options).then((request) => request(this.axios, this.basePath));
    }
}
