import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import StateSelector from '../../../Form/StateSelector/StateSelector';
import Pendencies from '../../components/Pendencies/Pendencies';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import SectionStateSelector from '../../components/SectionStateSelector/SectionStateSelector';
import sectionTitleMessages from '../../MainForm/sectionTitleMessages';
import messages from './messages';

const AchievementOfObjectives = () => {
    const intl = useIntl();

    return (
        <section className="my-[100px]">
            <section id="section-01" className="scroll-my-5">
                <SectionHeader
                    number={1}
                    mainSection
                    title={intl.formatMessage({ ...sectionTitleMessages.goalAchievement })}
                    aspect="targetAchievementOverallAspect"
                />
                <p>
                    <FormattedMessage {...messages.targetAchievementDescription} />
                </p>
            </section>
            <section className="my-[50px]">
                <SectionHeader
                    number={1.1}
                    subSection
                    title={intl.formatMessage({ ...sectionTitleMessages.reviewOfWork })}
                    aspect="activitiesReviewAspect"
                />

                <div className="my-8">
                    <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                        <div className="font-medium">
                            <FormattedMessage {...messages.activitiesPerformed} />
                        </div>
                        <div className="italic">
                            <FormattedMessage
                                {...messages.recapitulationChapter13LastStatusReport}
                            />
                        </div>
                    </div>
                    <Pendencies code={'1.1.1'} />
                </div>

                <div className="my-8">
                    <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                        <div className="font-medium">
                            <FormattedMessage {...messages.targetAchievementLastReportingPeriod} />
                        </div>
                        <div className="italic">
                            <FormattedMessage
                                {...messages.recapitulationChapter13LastStatusReport}
                            />
                        </div>
                    </div>
                    <Pendencies code={'1.1.2'} />
                </div>
            </section>
            <section className="my-[50px]">
                <SectionHeader
                    number={1.2}
                    subSection
                    title={intl.formatMessage({
                        ...sectionTitleMessages.currentStatusOfWorkActivities,
                    })}
                    aspect="activitiesCurrentAspect"
                />

                <div className="my-8">
                    <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                        <div className="font-medium">
                            <FormattedMessage {...messages.currentActivities} />
                        </div>
                    </div>
                    <Pendencies code={'1.2.1'} />
                </div>

                <div className="my-8">
                    <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                        <div className="font-medium">
                            <FormattedMessage
                                {...messages.assessmentOfTargetAchievementCurrentProjectStatus}
                            />
                        </div>
                    </div>
                    <Pendencies code={'1.2.2'} />
                </div>
            </section>
            <section className="my-[50px]">
                <SectionHeader
                    number={1.3}
                    subSection
                    title={intl.formatMessage({ ...sectionTitleMessages.outlookOfWorkActivities })}
                    aspect="activitiesForecastAspect"
                />

                <div className="my-8">
                    <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                        <div className="font-medium">
                            <FormattedMessage
                                {...messages.activitiesPerformedNextReportingPeriod}
                            />
                        </div>
                    </div>
                    <Pendencies code={'1.3.1'} />
                </div>

                <div className="my-8">
                    <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                        <div className="font-medium">
                            <FormattedMessage
                                {...messages.measuresForDeviationFromTargetAchievementCurrentProjectStatus}
                            />
                        </div>
                    </div>
                    <Pendencies code={'1.3.2'} />
                </div>

                <div className="my-8">
                    <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                        <div className="font-medium">
                            <FormattedMessage
                                {...messages.decisionsToBeMadeOrClarifiedNextReportingPeriod}
                            />
                        </div>
                    </div>
                    <Pendencies code={'1.3.3'} />
                </div>

                <div className="my-8">
                    <div className="flex justify-between mb-4 border-b border-black pb-[4px]">
                        <div className="font-medium">
                            <FormattedMessage {...messages.remarks} />
                        </div>
                    </div>
                    <Pendencies code={'1.3.4'} />
                </div>
            </section>
        </section>
    );
};

export default AchievementOfObjectives;
