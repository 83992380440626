import { Button } from '@mui/material';
import React from 'react';

import Icon from '../../../Icon/Icon';

interface IButtonDeleteProps {
    onClick: () => void;
    disabled?: boolean;
}

const ButtonDelete: React.FC<IButtonDeleteProps> = ({ onClick, disabled }) => {
    const handleClick = () => {
        onClick();
    };

    return (
        <Button
            onClick={handleClick}
            variant="text"
            size="small"
            style={{
                maxWidth: '30px',
                maxHeight: '30px',
                minWidth: '30px',
                minHeight: '30px',
            }}
            disabled={disabled}
        >
            <Icon name="trash" size={14} />
        </Button>
    );
};

export default ButtonDelete;
