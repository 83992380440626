/* tslint:disable */
/* eslint-disable */
/**
 * Armasuisse Dashboard Web Application API
 * API definitions for Armasuisse Dashboard Web Application
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ConceptBase } from '../models';
// @ts-ignore
import { ConceptBaseResponse } from '../models';
// @ts-ignore
import { DocumentBase } from '../models';
// @ts-ignore
import { DocumentBaseResponse } from '../models';
// @ts-ignore
import { PendencyBase } from '../models';
// @ts-ignore
import { PendencyBaseResponse } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { ProjectReportBaseDetailResponse } from '../models';
// @ts-ignore
import { ProjectReportBaseResponse } from '../models';
// @ts-ignore
import { ProjectReportPartialUpdateRequest } from '../models';
// @ts-ignore
import { ProjectReportPersonBase } from '../models';
// @ts-ignore
import { ProjectReportPersonBaseResponse } from '../models';
// @ts-ignore
import { ProjectReportRequest } from '../models';
// @ts-ignore
import { ReportValidationProblemDetails } from '../models';
// @ts-ignore
import { RiskBase } from '../models';
// @ts-ignore
import { RiskBaseResponse } from '../models';
// @ts-ignore
import { TextualItemBase } from '../models';
// @ts-ignore
import { TextualItemBaseResponse } from '../models';
// @ts-ignore
import { TradeBase } from '../models';
// @ts-ignore
import { TradeBaseResponse } from '../models';
/**
 * ProjectReportsApi - axios parameter creator
 * @export
 */
export const ProjectReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new Concept and returns newly created Concept.
         * @summary Creates new Concept.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {ConceptBase} [conceptBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConcept: async (projectID: number, projectReportID: number, conceptBase?: ConceptBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('addConcept', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('addConcept', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Concepts`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conceptBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new Document and returns newly created Document.
         * @summary Creates new Document.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {DocumentBase} [documentBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocument: async (projectID: number, projectReportID: number, documentBase?: DocumentBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('addDocument', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('addDocument', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Documents`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new Pendency and returns newly created Pendency.
         * @summary Creates new Pendency.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {PendencyBase} [pendencyBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPendency: async (projectID: number, projectReportID: number, pendencyBase?: PendencyBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('addPendency', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('addPendency', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Pendencies`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pendencyBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new project report and returns newly created project report.
         * @summary Creates new project report.
         * @param {number} projectID 
         * @param {ProjectReportRequest} [projectReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectReport: async (projectID: number, projectReportRequest?: ProjectReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('addProjectReport', 'projectID', projectID)
            const localVarPath = `/Projects/{projectID}/ProjectReports`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new Project Report Person and returns newly created Project Report Person.
         * @summary Creates new Project Report Person.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {ProjectReportPersonBase} [projectReportPersonBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectReportPerson: async (projectID: number, projectReportID: number, projectReportPersonBase?: ProjectReportPersonBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('addProjectReportPerson', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('addProjectReportPerson', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/People`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectReportPersonBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new Risk and returns newly created Risk.
         * @summary Creates new Risk.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {RiskBase} [riskBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRisk: async (projectID: number, projectReportID: number, riskBase?: RiskBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('addRisk', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('addRisk', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Risks`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(riskBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new Textual Item and returns newly created Textual Item.
         * @summary Creates new Textual Item.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {TextualItemBase} [textualItemBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTextualItem: async (projectID: number, projectReportID: number, textualItemBase?: TextualItemBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('addTextualItem', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('addTextualItem', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/TextualItems`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(textualItemBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new Trade and returns newly created Trade.
         * @summary Creates new Trade.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {TradeBase} [tradeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTrade: async (projectID: number, projectReportID: number, tradeBase?: TradeBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('addTrade', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('addTrade', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Trades`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradeBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Concept.
         * @summary Delete Concept.
         * @param {number} entityID Concept identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConcept: async (entityID: number, projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteConcept', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('deleteConcept', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('deleteConcept', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Concepts/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Document.
         * @summary Delete Document.
         * @param {number} entityID Document identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (entityID: number, projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteDocument', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('deleteDocument', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('deleteDocument', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Documents/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Pendency.
         * @summary Delete Pendency.
         * @param {number} entityID Pendency identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePendency: async (entityID: number, projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deletePendency', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('deletePendency', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('deletePendency', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Pendencies/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete project report.
         * @summary Delete project report.
         * @param {number} entityID project report identifier
         * @param {number} projectID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectReport: async (entityID: number, projectID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteProjectReport', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('deleteProjectReport', 'projectID', projectID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Project Report Person.
         * @summary Delete Project Report Person.
         * @param {number} entityID Project Report Person identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectReportPerson: async (entityID: number, projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteProjectReportPerson', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('deleteProjectReportPerson', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('deleteProjectReportPerson', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/People/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Risk.
         * @summary Delete Risk.
         * @param {number} entityID Risk identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRisk: async (entityID: number, projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteRisk', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('deleteRisk', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('deleteRisk', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Risks/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Textual Item.
         * @summary Delete Textual Item.
         * @param {number} entityID Textual Item identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTextualItem: async (entityID: number, projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteTextualItem', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('deleteTextualItem', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('deleteTextualItem', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/TextualItems/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Trade.
         * @summary Delete Trade.
         * @param {number} entityID Trade identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrade: async (entityID: number, projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteTrade', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('deleteTrade', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('deleteTrade', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Trades/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Concept.
         * @summary Get Concept details.
         * @param {number} entityID Concept identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConceptDetails: async (entityID: number, projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getConceptDetails', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getConceptDetails', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('getConceptDetails', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Concepts/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Concepts.
         * @summary Get list of Concepts.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConceptList: async (projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getConceptList', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('getConceptList', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Concepts`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Document.
         * @summary Get Document details.
         * @param {number} entityID Document identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentDetails: async (entityID: number, projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getDocumentDetails', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getDocumentDetails', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('getDocumentDetails', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Documents/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Documents.
         * @summary Get list of Documents.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentList: async (projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getDocumentList', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('getDocumentList', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Documents`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Pendency.
         * @summary Get Pendency details.
         * @param {number} entityID Pendency identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendencyDetails: async (entityID: number, projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getPendencyDetails', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getPendencyDetails', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('getPendencyDetails', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Pendencies/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Pendencies.
         * @summary Get list of Pendencies.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendencyList: async (projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getPendencyList', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('getPendencyList', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Pendencies`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the project report.
         * @summary Get project report details.
         * @param {number} entityID project report identifier
         * @param {number} projectID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectReportDetails: async (entityID: number, projectID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getProjectReportDetails', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getProjectReportDetails', 'projectID', projectID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of project reports.
         * @summary Get list of project reports.
         * @param {number} projectID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectReportList: async (projectID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getProjectReportList', 'projectID', projectID)
            const localVarPath = `/Projects/{projectID}/ProjectReports`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Project Report Person.
         * @summary Get Project Report Person details.
         * @param {number} entityID Project Report Person identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectReportPersonDetails: async (entityID: number, projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getProjectReportPersonDetails', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getProjectReportPersonDetails', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('getProjectReportPersonDetails', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/People/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Project Report Person.
         * @summary Get list of Project Report Person.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectReportPersonList: async (projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getProjectReportPersonList', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('getProjectReportPersonList', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/People`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Risk.
         * @summary Get Risk details.
         * @param {number} entityID Risk identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskDetails: async (entityID: number, projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getRiskDetails', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getRiskDetails', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('getRiskDetails', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Risks/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Risks.
         * @summary Get list of Risks.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskList: async (projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getRiskList', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('getRiskList', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Risks`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Textual Item.
         * @summary Get Textual Item details.
         * @param {number} entityID Textual Item identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextualItemDetails: async (entityID: number, projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getTextualItemDetails', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getTextualItemDetails', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('getTextualItemDetails', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/TextualItems/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Textual Items.
         * @summary Get list of Textual Items.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextualItemList: async (projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getTextualItemList', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('getTextualItemList', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/TextualItems`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Trade.
         * @summary Get Trade details.
         * @param {number} entityID Trade identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeDetails: async (entityID: number, projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getTradeDetails', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getTradeDetails', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('getTradeDetails', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Trades/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Trades.
         * @summary Get list of Trades.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeList: async (projectID: number, projectReportID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('getTradeList', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('getTradeList', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Trades`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initialize new report. If the previous report identifier will be send, it will copy the data from it.
         * @summary Initialize new project report.
         * @param {number} projectID 
         * @param {number} [previousReportID] Optional previous project report identifier
         * @param {boolean} [force] Force initialization and skip non error validation problems
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectReportsInitializeNewReport: async (projectID: number, previousReportID?: number, force?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('postProjectReportsInitializeNewReport', 'projectID', projectID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/Initialize`
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (previousReportID !== undefined) {
                localVarQueryParameter['previousReportID'] = previousReportID;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update project report only respecting sent properties.
         * @summary Partially edit project report.
         * @param {number} entityID Project report identifier
         * @param {number} projectID 
         * @param {ProjectReportPartialUpdateRequest} [projectReportPartialUpdateRequest] Project report partial edit request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectReportsUpdatePartial: async (entityID: number, projectID: number, projectReportPartialUpdateRequest?: ProjectReportPartialUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('postProjectReportsUpdatePartial', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('postProjectReportsUpdatePartial', 'projectID', projectID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{entityID}/Partial`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectReportPartialUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Concept and returns the modified Concept.
         * @summary Edit Concept.
         * @param {number} entityID Concept identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {ConceptBase} [conceptBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConcept: async (entityID: number, projectID: number, projectReportID: number, conceptBase?: ConceptBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateConcept', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('updateConcept', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('updateConcept', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Concepts/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conceptBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Document and returns the modified Document.
         * @summary Edit Document.
         * @param {number} entityID Document identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {DocumentBase} [documentBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument: async (entityID: number, projectID: number, projectReportID: number, documentBase?: DocumentBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateDocument', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('updateDocument', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('updateDocument', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Documents/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Pendency and returns the modified Pendency.
         * @summary Edit Pendency.
         * @param {number} entityID Pendency identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {PendencyBase} [pendencyBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePendency: async (entityID: number, projectID: number, projectReportID: number, pendencyBase?: PendencyBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updatePendency', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('updatePendency', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('updatePendency', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Pendencies/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pendencyBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update project report and returns the modified project report.
         * @summary Edit project report.
         * @param {number} entityID project report identifier
         * @param {number} projectID 
         * @param {ProjectReportRequest} [projectReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectReport: async (entityID: number, projectID: number, projectReportRequest?: ProjectReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateProjectReport', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('updateProjectReport', 'projectID', projectID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Project Report Person and returns the modified Project Report Person.
         * @summary Edit Project Report Person.
         * @param {number} entityID Project Report Person identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {ProjectReportPersonBase} [projectReportPersonBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectReportPerson: async (entityID: number, projectID: number, projectReportID: number, projectReportPersonBase?: ProjectReportPersonBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateProjectReportPerson', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('updateProjectReportPerson', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('updateProjectReportPerson', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/People/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectReportPersonBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Risk and returns the modified Risk.
         * @summary Edit Risk.
         * @param {number} entityID Risk identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {RiskBase} [riskBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRisk: async (entityID: number, projectID: number, projectReportID: number, riskBase?: RiskBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateRisk', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('updateRisk', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('updateRisk', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Risks/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(riskBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Textual Item and returns the modified Textual Item.
         * @summary Edit Textual Item.
         * @param {number} entityID Textual Item identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {TextualItemBase} [textualItemBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTextualItem: async (entityID: number, projectID: number, projectReportID: number, textualItemBase?: TextualItemBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateTextualItem', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('updateTextualItem', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('updateTextualItem', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/TextualItems/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(textualItemBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Trade and returns the modified Trade.
         * @summary Edit Trade.
         * @param {number} entityID Trade identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {TradeBase} [tradeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrade: async (entityID: number, projectID: number, projectReportID: number, tradeBase?: TradeBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateTrade', 'entityID', entityID)
            // verify required parameter 'projectID' is not null or undefined
            assertParamExists('updateTrade', 'projectID', projectID)
            // verify required parameter 'projectReportID' is not null or undefined
            assertParamExists('updateTrade', 'projectReportID', projectReportID)
            const localVarPath = `/Projects/{projectID}/ProjectReports/{projectReportID}/Trades/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"projectID"}}`, encodeURIComponent(String(projectID)))
                .replace(`{${"projectReportID"}}`, encodeURIComponent(String(projectReportID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradeBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectReportsApi - functional programming interface
 * @export
 */
export const ProjectReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new Concept and returns newly created Concept.
         * @summary Creates new Concept.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {ConceptBase} [conceptBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addConcept(projectID: number, projectReportID: number, conceptBase?: ConceptBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConceptBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addConcept(projectID, projectReportID, conceptBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates new Document and returns newly created Document.
         * @summary Creates new Document.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {DocumentBase} [documentBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDocument(projectID: number, projectReportID: number, documentBase?: DocumentBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDocument(projectID, projectReportID, documentBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates new Pendency and returns newly created Pendency.
         * @summary Creates new Pendency.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {PendencyBase} [pendencyBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPendency(projectID: number, projectReportID: number, pendencyBase?: PendencyBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PendencyBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPendency(projectID, projectReportID, pendencyBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates new project report and returns newly created project report.
         * @summary Creates new project report.
         * @param {number} projectID 
         * @param {ProjectReportRequest} [projectReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProjectReport(projectID: number, projectReportRequest?: ProjectReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReportBaseDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProjectReport(projectID, projectReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates new Project Report Person and returns newly created Project Report Person.
         * @summary Creates new Project Report Person.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {ProjectReportPersonBase} [projectReportPersonBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProjectReportPerson(projectID: number, projectReportID: number, projectReportPersonBase?: ProjectReportPersonBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReportPersonBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProjectReportPerson(projectID, projectReportID, projectReportPersonBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates new Risk and returns newly created Risk.
         * @summary Creates new Risk.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {RiskBase} [riskBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRisk(projectID: number, projectReportID: number, riskBase?: RiskBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRisk(projectID, projectReportID, riskBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates new Textual Item and returns newly created Textual Item.
         * @summary Creates new Textual Item.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {TextualItemBase} [textualItemBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTextualItem(projectID: number, projectReportID: number, textualItemBase?: TextualItemBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextualItemBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTextualItem(projectID, projectReportID, textualItemBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates new Trade and returns newly created Trade.
         * @summary Creates new Trade.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {TradeBase} [tradeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTrade(projectID: number, projectReportID: number, tradeBase?: TradeBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTrade(projectID, projectReportID, tradeBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Concept.
         * @summary Delete Concept.
         * @param {number} entityID Concept identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConcept(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConcept(entityID, projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Document.
         * @summary Delete Document.
         * @param {number} entityID Document identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(entityID, projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Pendency.
         * @summary Delete Pendency.
         * @param {number} entityID Pendency identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePendency(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePendency(entityID, projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete project report.
         * @summary Delete project report.
         * @param {number} entityID project report identifier
         * @param {number} projectID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectReport(entityID: number, projectID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectReport(entityID, projectID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Project Report Person.
         * @summary Delete Project Report Person.
         * @param {number} entityID Project Report Person identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectReportPerson(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectReportPerson(entityID, projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Risk.
         * @summary Delete Risk.
         * @param {number} entityID Risk identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRisk(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRisk(entityID, projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Textual Item.
         * @summary Delete Textual Item.
         * @param {number} entityID Textual Item identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTextualItem(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTextualItem(entityID, projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Trade.
         * @summary Delete Trade.
         * @param {number} entityID Trade identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTrade(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTrade(entityID, projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Concept.
         * @summary Get Concept details.
         * @param {number} entityID Concept identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConceptDetails(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConceptBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConceptDetails(entityID, projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Concepts.
         * @summary Get list of Concepts.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConceptList(projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConceptBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConceptList(projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Document.
         * @summary Get Document details.
         * @param {number} entityID Document identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentDetails(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentDetails(entityID, projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Documents.
         * @summary Get list of Documents.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentList(projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentList(projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Pendency.
         * @summary Get Pendency details.
         * @param {number} entityID Pendency identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendencyDetails(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PendencyBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendencyDetails(entityID, projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Pendencies.
         * @summary Get list of Pendencies.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendencyList(projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PendencyBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendencyList(projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the project report.
         * @summary Get project report details.
         * @param {number} entityID project report identifier
         * @param {number} projectID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectReportDetails(entityID: number, projectID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReportBaseDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectReportDetails(entityID, projectID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of project reports.
         * @summary Get list of project reports.
         * @param {number} projectID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectReportList(projectID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectReportBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectReportList(projectID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Project Report Person.
         * @summary Get Project Report Person details.
         * @param {number} entityID Project Report Person identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectReportPersonDetails(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReportPersonBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectReportPersonDetails(entityID, projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Project Report Person.
         * @summary Get list of Project Report Person.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectReportPersonList(projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectReportPersonBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectReportPersonList(projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Risk.
         * @summary Get Risk details.
         * @param {number} entityID Risk identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRiskDetails(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRiskDetails(entityID, projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Risks.
         * @summary Get list of Risks.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRiskList(projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RiskBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRiskList(projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Textual Item.
         * @summary Get Textual Item details.
         * @param {number} entityID Textual Item identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextualItemDetails(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextualItemBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTextualItemDetails(entityID, projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Textual Items.
         * @summary Get list of Textual Items.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextualItemList(projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TextualItemBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTextualItemList(projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Trade.
         * @summary Get Trade details.
         * @param {number} entityID Trade identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradeDetails(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradeDetails(entityID, projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Trades.
         * @summary Get list of Trades.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradeList(projectID: number, projectReportID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradeBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradeList(projectID, projectReportID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Initialize new report. If the previous report identifier will be send, it will copy the data from it.
         * @summary Initialize new project report.
         * @param {number} projectID 
         * @param {number} [previousReportID] Optional previous project report identifier
         * @param {boolean} [force] Force initialization and skip non error validation problems
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProjectReportsInitializeNewReport(projectID: number, previousReportID?: number, force?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReportBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProjectReportsInitializeNewReport(projectID, previousReportID, force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update project report only respecting sent properties.
         * @summary Partially edit project report.
         * @param {number} entityID Project report identifier
         * @param {number} projectID 
         * @param {ProjectReportPartialUpdateRequest} [projectReportPartialUpdateRequest] Project report partial edit request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProjectReportsUpdatePartial(entityID: number, projectID: number, projectReportPartialUpdateRequest?: ProjectReportPartialUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReportBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProjectReportsUpdatePartial(entityID, projectID, projectReportPartialUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Concept and returns the modified Concept.
         * @summary Edit Concept.
         * @param {number} entityID Concept identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {ConceptBase} [conceptBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConcept(entityID: number, projectID: number, projectReportID: number, conceptBase?: ConceptBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConceptBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConcept(entityID, projectID, projectReportID, conceptBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Document and returns the modified Document.
         * @summary Edit Document.
         * @param {number} entityID Document identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {DocumentBase} [documentBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocument(entityID: number, projectID: number, projectReportID: number, documentBase?: DocumentBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocument(entityID, projectID, projectReportID, documentBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Pendency and returns the modified Pendency.
         * @summary Edit Pendency.
         * @param {number} entityID Pendency identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {PendencyBase} [pendencyBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePendency(entityID: number, projectID: number, projectReportID: number, pendencyBase?: PendencyBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PendencyBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePendency(entityID, projectID, projectReportID, pendencyBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update project report and returns the modified project report.
         * @summary Edit project report.
         * @param {number} entityID project report identifier
         * @param {number} projectID 
         * @param {ProjectReportRequest} [projectReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectReport(entityID: number, projectID: number, projectReportRequest?: ProjectReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReportBaseDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectReport(entityID, projectID, projectReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Project Report Person and returns the modified Project Report Person.
         * @summary Edit Project Report Person.
         * @param {number} entityID Project Report Person identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {ProjectReportPersonBase} [projectReportPersonBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectReportPerson(entityID: number, projectID: number, projectReportID: number, projectReportPersonBase?: ProjectReportPersonBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectReportPersonBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectReportPerson(entityID, projectID, projectReportID, projectReportPersonBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Risk and returns the modified Risk.
         * @summary Edit Risk.
         * @param {number} entityID Risk identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {RiskBase} [riskBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRisk(entityID: number, projectID: number, projectReportID: number, riskBase?: RiskBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRisk(entityID, projectID, projectReportID, riskBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Textual Item and returns the modified Textual Item.
         * @summary Edit Textual Item.
         * @param {number} entityID Textual Item identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {TextualItemBase} [textualItemBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTextualItem(entityID: number, projectID: number, projectReportID: number, textualItemBase?: TextualItemBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextualItemBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTextualItem(entityID, projectID, projectReportID, textualItemBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Trade and returns the modified Trade.
         * @summary Edit Trade.
         * @param {number} entityID Trade identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {TradeBase} [tradeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTrade(entityID: number, projectID: number, projectReportID: number, tradeBase?: TradeBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrade(entityID, projectID, projectReportID, tradeBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectReportsApi - factory interface
 * @export
 */
export const ProjectReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectReportsApiFp(configuration)
    return {
        /**
         * Creates new Concept and returns newly created Concept.
         * @summary Creates new Concept.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {ConceptBase} [conceptBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConcept(projectID: number, projectReportID: number, conceptBase?: ConceptBase, options?: any): AxiosPromise<ConceptBaseResponse> {
            return localVarFp.addConcept(projectID, projectReportID, conceptBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates new Document and returns newly created Document.
         * @summary Creates new Document.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {DocumentBase} [documentBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDocument(projectID: number, projectReportID: number, documentBase?: DocumentBase, options?: any): AxiosPromise<DocumentBaseResponse> {
            return localVarFp.addDocument(projectID, projectReportID, documentBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates new Pendency and returns newly created Pendency.
         * @summary Creates new Pendency.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {PendencyBase} [pendencyBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPendency(projectID: number, projectReportID: number, pendencyBase?: PendencyBase, options?: any): AxiosPromise<PendencyBaseResponse> {
            return localVarFp.addPendency(projectID, projectReportID, pendencyBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates new project report and returns newly created project report.
         * @summary Creates new project report.
         * @param {number} projectID 
         * @param {ProjectReportRequest} [projectReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectReport(projectID: number, projectReportRequest?: ProjectReportRequest, options?: any): AxiosPromise<ProjectReportBaseDetailResponse> {
            return localVarFp.addProjectReport(projectID, projectReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates new Project Report Person and returns newly created Project Report Person.
         * @summary Creates new Project Report Person.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {ProjectReportPersonBase} [projectReportPersonBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProjectReportPerson(projectID: number, projectReportID: number, projectReportPersonBase?: ProjectReportPersonBase, options?: any): AxiosPromise<ProjectReportPersonBaseResponse> {
            return localVarFp.addProjectReportPerson(projectID, projectReportID, projectReportPersonBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates new Risk and returns newly created Risk.
         * @summary Creates new Risk.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {RiskBase} [riskBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRisk(projectID: number, projectReportID: number, riskBase?: RiskBase, options?: any): AxiosPromise<RiskBaseResponse> {
            return localVarFp.addRisk(projectID, projectReportID, riskBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates new Textual Item and returns newly created Textual Item.
         * @summary Creates new Textual Item.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {TextualItemBase} [textualItemBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTextualItem(projectID: number, projectReportID: number, textualItemBase?: TextualItemBase, options?: any): AxiosPromise<TextualItemBaseResponse> {
            return localVarFp.addTextualItem(projectID, projectReportID, textualItemBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates new Trade and returns newly created Trade.
         * @summary Creates new Trade.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {TradeBase} [tradeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTrade(projectID: number, projectReportID: number, tradeBase?: TradeBase, options?: any): AxiosPromise<TradeBaseResponse> {
            return localVarFp.addTrade(projectID, projectReportID, tradeBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Concept.
         * @summary Delete Concept.
         * @param {number} entityID Concept identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConcept(entityID: number, projectID: number, projectReportID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteConcept(entityID, projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Document.
         * @summary Delete Document.
         * @param {number} entityID Document identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(entityID: number, projectID: number, projectReportID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocument(entityID, projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Pendency.
         * @summary Delete Pendency.
         * @param {number} entityID Pendency identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePendency(entityID: number, projectID: number, projectReportID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePendency(entityID, projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete project report.
         * @summary Delete project report.
         * @param {number} entityID project report identifier
         * @param {number} projectID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectReport(entityID: number, projectID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectReport(entityID, projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Project Report Person.
         * @summary Delete Project Report Person.
         * @param {number} entityID Project Report Person identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectReportPerson(entityID: number, projectID: number, projectReportID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectReportPerson(entityID, projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Risk.
         * @summary Delete Risk.
         * @param {number} entityID Risk identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRisk(entityID: number, projectID: number, projectReportID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRisk(entityID, projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Textual Item.
         * @summary Delete Textual Item.
         * @param {number} entityID Textual Item identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTextualItem(entityID: number, projectID: number, projectReportID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTextualItem(entityID, projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Trade.
         * @summary Delete Trade.
         * @param {number} entityID Trade identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrade(entityID: number, projectID: number, projectReportID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTrade(entityID, projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Concept.
         * @summary Get Concept details.
         * @param {number} entityID Concept identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConceptDetails(entityID: number, projectID: number, projectReportID: number, options?: any): AxiosPromise<ConceptBaseResponse> {
            return localVarFp.getConceptDetails(entityID, projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Concepts.
         * @summary Get list of Concepts.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConceptList(projectID: number, projectReportID: number, options?: any): AxiosPromise<Array<ConceptBaseResponse>> {
            return localVarFp.getConceptList(projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Document.
         * @summary Get Document details.
         * @param {number} entityID Document identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentDetails(entityID: number, projectID: number, projectReportID: number, options?: any): AxiosPromise<DocumentBaseResponse> {
            return localVarFp.getDocumentDetails(entityID, projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Documents.
         * @summary Get list of Documents.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentList(projectID: number, projectReportID: number, options?: any): AxiosPromise<Array<DocumentBaseResponse>> {
            return localVarFp.getDocumentList(projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Pendency.
         * @summary Get Pendency details.
         * @param {number} entityID Pendency identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendencyDetails(entityID: number, projectID: number, projectReportID: number, options?: any): AxiosPromise<PendencyBaseResponse> {
            return localVarFp.getPendencyDetails(entityID, projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Pendencies.
         * @summary Get list of Pendencies.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendencyList(projectID: number, projectReportID: number, options?: any): AxiosPromise<Array<PendencyBaseResponse>> {
            return localVarFp.getPendencyList(projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the project report.
         * @summary Get project report details.
         * @param {number} entityID project report identifier
         * @param {number} projectID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectReportDetails(entityID: number, projectID: number, options?: any): AxiosPromise<ProjectReportBaseDetailResponse> {
            return localVarFp.getProjectReportDetails(entityID, projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of project reports.
         * @summary Get list of project reports.
         * @param {number} projectID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectReportList(projectID: number, options?: any): AxiosPromise<Array<ProjectReportBaseResponse>> {
            return localVarFp.getProjectReportList(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Project Report Person.
         * @summary Get Project Report Person details.
         * @param {number} entityID Project Report Person identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectReportPersonDetails(entityID: number, projectID: number, projectReportID: number, options?: any): AxiosPromise<ProjectReportPersonBaseResponse> {
            return localVarFp.getProjectReportPersonDetails(entityID, projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Project Report Person.
         * @summary Get list of Project Report Person.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectReportPersonList(projectID: number, projectReportID: number, options?: any): AxiosPromise<Array<ProjectReportPersonBaseResponse>> {
            return localVarFp.getProjectReportPersonList(projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Risk.
         * @summary Get Risk details.
         * @param {number} entityID Risk identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskDetails(entityID: number, projectID: number, projectReportID: number, options?: any): AxiosPromise<RiskBaseResponse> {
            return localVarFp.getRiskDetails(entityID, projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Risks.
         * @summary Get list of Risks.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskList(projectID: number, projectReportID: number, options?: any): AxiosPromise<Array<RiskBaseResponse>> {
            return localVarFp.getRiskList(projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Textual Item.
         * @summary Get Textual Item details.
         * @param {number} entityID Textual Item identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextualItemDetails(entityID: number, projectID: number, projectReportID: number, options?: any): AxiosPromise<TextualItemBaseResponse> {
            return localVarFp.getTextualItemDetails(entityID, projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Textual Items.
         * @summary Get list of Textual Items.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextualItemList(projectID: number, projectReportID: number, options?: any): AxiosPromise<Array<TextualItemBaseResponse>> {
            return localVarFp.getTextualItemList(projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Trade.
         * @summary Get Trade details.
         * @param {number} entityID Trade identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeDetails(entityID: number, projectID: number, projectReportID: number, options?: any): AxiosPromise<TradeBaseResponse> {
            return localVarFp.getTradeDetails(entityID, projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Trades.
         * @summary Get list of Trades.
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeList(projectID: number, projectReportID: number, options?: any): AxiosPromise<Array<TradeBaseResponse>> {
            return localVarFp.getTradeList(projectID, projectReportID, options).then((request) => request(axios, basePath));
        },
        /**
         * Initialize new report. If the previous report identifier will be send, it will copy the data from it.
         * @summary Initialize new project report.
         * @param {number} projectID 
         * @param {number} [previousReportID] Optional previous project report identifier
         * @param {boolean} [force] Force initialization and skip non error validation problems
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectReportsInitializeNewReport(projectID: number, previousReportID?: number, force?: boolean, options?: any): AxiosPromise<ProjectReportBaseResponse> {
            return localVarFp.postProjectReportsInitializeNewReport(projectID, previousReportID, force, options).then((request) => request(axios, basePath));
        },
        /**
         * Update project report only respecting sent properties.
         * @summary Partially edit project report.
         * @param {number} entityID Project report identifier
         * @param {number} projectID 
         * @param {ProjectReportPartialUpdateRequest} [projectReportPartialUpdateRequest] Project report partial edit request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectReportsUpdatePartial(entityID: number, projectID: number, projectReportPartialUpdateRequest?: ProjectReportPartialUpdateRequest, options?: any): AxiosPromise<ProjectReportBaseResponse> {
            return localVarFp.postProjectReportsUpdatePartial(entityID, projectID, projectReportPartialUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Concept and returns the modified Concept.
         * @summary Edit Concept.
         * @param {number} entityID Concept identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {ConceptBase} [conceptBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConcept(entityID: number, projectID: number, projectReportID: number, conceptBase?: ConceptBase, options?: any): AxiosPromise<ConceptBaseResponse> {
            return localVarFp.updateConcept(entityID, projectID, projectReportID, conceptBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Document and returns the modified Document.
         * @summary Edit Document.
         * @param {number} entityID Document identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {DocumentBase} [documentBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocument(entityID: number, projectID: number, projectReportID: number, documentBase?: DocumentBase, options?: any): AxiosPromise<DocumentBaseResponse> {
            return localVarFp.updateDocument(entityID, projectID, projectReportID, documentBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Pendency and returns the modified Pendency.
         * @summary Edit Pendency.
         * @param {number} entityID Pendency identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {PendencyBase} [pendencyBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePendency(entityID: number, projectID: number, projectReportID: number, pendencyBase?: PendencyBase, options?: any): AxiosPromise<PendencyBaseResponse> {
            return localVarFp.updatePendency(entityID, projectID, projectReportID, pendencyBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Update project report and returns the modified project report.
         * @summary Edit project report.
         * @param {number} entityID project report identifier
         * @param {number} projectID 
         * @param {ProjectReportRequest} [projectReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectReport(entityID: number, projectID: number, projectReportRequest?: ProjectReportRequest, options?: any): AxiosPromise<ProjectReportBaseDetailResponse> {
            return localVarFp.updateProjectReport(entityID, projectID, projectReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Project Report Person and returns the modified Project Report Person.
         * @summary Edit Project Report Person.
         * @param {number} entityID Project Report Person identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {ProjectReportPersonBase} [projectReportPersonBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectReportPerson(entityID: number, projectID: number, projectReportID: number, projectReportPersonBase?: ProjectReportPersonBase, options?: any): AxiosPromise<ProjectReportPersonBaseResponse> {
            return localVarFp.updateProjectReportPerson(entityID, projectID, projectReportID, projectReportPersonBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Risk and returns the modified Risk.
         * @summary Edit Risk.
         * @param {number} entityID Risk identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {RiskBase} [riskBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRisk(entityID: number, projectID: number, projectReportID: number, riskBase?: RiskBase, options?: any): AxiosPromise<RiskBaseResponse> {
            return localVarFp.updateRisk(entityID, projectID, projectReportID, riskBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Textual Item and returns the modified Textual Item.
         * @summary Edit Textual Item.
         * @param {number} entityID Textual Item identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {TextualItemBase} [textualItemBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTextualItem(entityID: number, projectID: number, projectReportID: number, textualItemBase?: TextualItemBase, options?: any): AxiosPromise<TextualItemBaseResponse> {
            return localVarFp.updateTextualItem(entityID, projectID, projectReportID, textualItemBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Trade and returns the modified Trade.
         * @summary Edit Trade.
         * @param {number} entityID Trade identifier
         * @param {number} projectID 
         * @param {number} projectReportID 
         * @param {TradeBase} [tradeBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrade(entityID: number, projectID: number, projectReportID: number, tradeBase?: TradeBase, options?: any): AxiosPromise<TradeBaseResponse> {
            return localVarFp.updateTrade(entityID, projectID, projectReportID, tradeBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectReportsApi - object-oriented interface
 * @export
 * @class ProjectReportsApi
 * @extends {BaseAPI}
 */
export class ProjectReportsApi extends BaseAPI {
    /**
     * Creates new Concept and returns newly created Concept.
     * @summary Creates new Concept.
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {ConceptBase} [conceptBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public addConcept(projectID: number, projectReportID: number, conceptBase?: ConceptBase, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).addConcept(projectID, projectReportID, conceptBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates new Document and returns newly created Document.
     * @summary Creates new Document.
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {DocumentBase} [documentBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public addDocument(projectID: number, projectReportID: number, documentBase?: DocumentBase, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).addDocument(projectID, projectReportID, documentBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates new Pendency and returns newly created Pendency.
     * @summary Creates new Pendency.
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {PendencyBase} [pendencyBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public addPendency(projectID: number, projectReportID: number, pendencyBase?: PendencyBase, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).addPendency(projectID, projectReportID, pendencyBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates new project report and returns newly created project report.
     * @summary Creates new project report.
     * @param {number} projectID 
     * @param {ProjectReportRequest} [projectReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public addProjectReport(projectID: number, projectReportRequest?: ProjectReportRequest, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).addProjectReport(projectID, projectReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates new Project Report Person and returns newly created Project Report Person.
     * @summary Creates new Project Report Person.
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {ProjectReportPersonBase} [projectReportPersonBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public addProjectReportPerson(projectID: number, projectReportID: number, projectReportPersonBase?: ProjectReportPersonBase, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).addProjectReportPerson(projectID, projectReportID, projectReportPersonBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates new Risk and returns newly created Risk.
     * @summary Creates new Risk.
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {RiskBase} [riskBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public addRisk(projectID: number, projectReportID: number, riskBase?: RiskBase, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).addRisk(projectID, projectReportID, riskBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates new Textual Item and returns newly created Textual Item.
     * @summary Creates new Textual Item.
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {TextualItemBase} [textualItemBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public addTextualItem(projectID: number, projectReportID: number, textualItemBase?: TextualItemBase, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).addTextualItem(projectID, projectReportID, textualItemBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates new Trade and returns newly created Trade.
     * @summary Creates new Trade.
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {TradeBase} [tradeBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public addTrade(projectID: number, projectReportID: number, tradeBase?: TradeBase, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).addTrade(projectID, projectReportID, tradeBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Concept.
     * @summary Delete Concept.
     * @param {number} entityID Concept identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public deleteConcept(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).deleteConcept(entityID, projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Document.
     * @summary Delete Document.
     * @param {number} entityID Document identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public deleteDocument(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).deleteDocument(entityID, projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Pendency.
     * @summary Delete Pendency.
     * @param {number} entityID Pendency identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public deletePendency(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).deletePendency(entityID, projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete project report.
     * @summary Delete project report.
     * @param {number} entityID project report identifier
     * @param {number} projectID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public deleteProjectReport(entityID: number, projectID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).deleteProjectReport(entityID, projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Project Report Person.
     * @summary Delete Project Report Person.
     * @param {number} entityID Project Report Person identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public deleteProjectReportPerson(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).deleteProjectReportPerson(entityID, projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Risk.
     * @summary Delete Risk.
     * @param {number} entityID Risk identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public deleteRisk(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).deleteRisk(entityID, projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Textual Item.
     * @summary Delete Textual Item.
     * @param {number} entityID Textual Item identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public deleteTextualItem(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).deleteTextualItem(entityID, projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Trade.
     * @summary Delete Trade.
     * @param {number} entityID Trade identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public deleteTrade(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).deleteTrade(entityID, projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Concept.
     * @summary Get Concept details.
     * @param {number} entityID Concept identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getConceptDetails(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getConceptDetails(entityID, projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Concepts.
     * @summary Get list of Concepts.
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getConceptList(projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getConceptList(projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Document.
     * @summary Get Document details.
     * @param {number} entityID Document identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getDocumentDetails(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getDocumentDetails(entityID, projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Documents.
     * @summary Get list of Documents.
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getDocumentList(projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getDocumentList(projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Pendency.
     * @summary Get Pendency details.
     * @param {number} entityID Pendency identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getPendencyDetails(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getPendencyDetails(entityID, projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Pendencies.
     * @summary Get list of Pendencies.
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getPendencyList(projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getPendencyList(projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the project report.
     * @summary Get project report details.
     * @param {number} entityID project report identifier
     * @param {number} projectID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getProjectReportDetails(entityID: number, projectID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getProjectReportDetails(entityID, projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of project reports.
     * @summary Get list of project reports.
     * @param {number} projectID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getProjectReportList(projectID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getProjectReportList(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Project Report Person.
     * @summary Get Project Report Person details.
     * @param {number} entityID Project Report Person identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getProjectReportPersonDetails(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getProjectReportPersonDetails(entityID, projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Project Report Person.
     * @summary Get list of Project Report Person.
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getProjectReportPersonList(projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getProjectReportPersonList(projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Risk.
     * @summary Get Risk details.
     * @param {number} entityID Risk identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getRiskDetails(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getRiskDetails(entityID, projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Risks.
     * @summary Get list of Risks.
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getRiskList(projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getRiskList(projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Textual Item.
     * @summary Get Textual Item details.
     * @param {number} entityID Textual Item identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getTextualItemDetails(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getTextualItemDetails(entityID, projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Textual Items.
     * @summary Get list of Textual Items.
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getTextualItemList(projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getTextualItemList(projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Trade.
     * @summary Get Trade details.
     * @param {number} entityID Trade identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getTradeDetails(entityID: number, projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getTradeDetails(entityID, projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Trades.
     * @summary Get list of Trades.
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public getTradeList(projectID: number, projectReportID: number, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).getTradeList(projectID, projectReportID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Initialize new report. If the previous report identifier will be send, it will copy the data from it.
     * @summary Initialize new project report.
     * @param {number} projectID 
     * @param {number} [previousReportID] Optional previous project report identifier
     * @param {boolean} [force] Force initialization and skip non error validation problems
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public postProjectReportsInitializeNewReport(projectID: number, previousReportID?: number, force?: boolean, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).postProjectReportsInitializeNewReport(projectID, previousReportID, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update project report only respecting sent properties.
     * @summary Partially edit project report.
     * @param {number} entityID Project report identifier
     * @param {number} projectID 
     * @param {ProjectReportPartialUpdateRequest} [projectReportPartialUpdateRequest] Project report partial edit request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public postProjectReportsUpdatePartial(entityID: number, projectID: number, projectReportPartialUpdateRequest?: ProjectReportPartialUpdateRequest, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).postProjectReportsUpdatePartial(entityID, projectID, projectReportPartialUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Concept and returns the modified Concept.
     * @summary Edit Concept.
     * @param {number} entityID Concept identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {ConceptBase} [conceptBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public updateConcept(entityID: number, projectID: number, projectReportID: number, conceptBase?: ConceptBase, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).updateConcept(entityID, projectID, projectReportID, conceptBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Document and returns the modified Document.
     * @summary Edit Document.
     * @param {number} entityID Document identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {DocumentBase} [documentBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public updateDocument(entityID: number, projectID: number, projectReportID: number, documentBase?: DocumentBase, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).updateDocument(entityID, projectID, projectReportID, documentBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Pendency and returns the modified Pendency.
     * @summary Edit Pendency.
     * @param {number} entityID Pendency identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {PendencyBase} [pendencyBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public updatePendency(entityID: number, projectID: number, projectReportID: number, pendencyBase?: PendencyBase, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).updatePendency(entityID, projectID, projectReportID, pendencyBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update project report and returns the modified project report.
     * @summary Edit project report.
     * @param {number} entityID project report identifier
     * @param {number} projectID 
     * @param {ProjectReportRequest} [projectReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public updateProjectReport(entityID: number, projectID: number, projectReportRequest?: ProjectReportRequest, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).updateProjectReport(entityID, projectID, projectReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Project Report Person and returns the modified Project Report Person.
     * @summary Edit Project Report Person.
     * @param {number} entityID Project Report Person identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {ProjectReportPersonBase} [projectReportPersonBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public updateProjectReportPerson(entityID: number, projectID: number, projectReportID: number, projectReportPersonBase?: ProjectReportPersonBase, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).updateProjectReportPerson(entityID, projectID, projectReportID, projectReportPersonBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Risk and returns the modified Risk.
     * @summary Edit Risk.
     * @param {number} entityID Risk identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {RiskBase} [riskBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public updateRisk(entityID: number, projectID: number, projectReportID: number, riskBase?: RiskBase, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).updateRisk(entityID, projectID, projectReportID, riskBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Textual Item and returns the modified Textual Item.
     * @summary Edit Textual Item.
     * @param {number} entityID Textual Item identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {TextualItemBase} [textualItemBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public updateTextualItem(entityID: number, projectID: number, projectReportID: number, textualItemBase?: TextualItemBase, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).updateTextualItem(entityID, projectID, projectReportID, textualItemBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Trade and returns the modified Trade.
     * @summary Edit Trade.
     * @param {number} entityID Trade identifier
     * @param {number} projectID 
     * @param {number} projectReportID 
     * @param {TradeBase} [tradeBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectReportsApi
     */
    public updateTrade(entityID: number, projectID: number, projectReportID: number, tradeBase?: TradeBase, options?: AxiosRequestConfig) {
        return ProjectReportsApiFp(this.configuration).updateTrade(entityID, projectID, projectReportID, tradeBase, options).then((request) => request(this.axios, this.basePath));
    }
}
