import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
    Avatar,
    Box,
    Button,
    ClickAwayListener,
    Divider,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Theme,
    Toolbar,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { createStyles, makeStyles } from '@mui/styles';
import { useKeycloak } from '@react-keycloak/web';
import clsx from 'clsx';
import { KeycloakProfile } from 'keycloak-js';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Path } from '../../pages/PageRouter';
import { template } from '../../theme/Theme';
import useHeaderContent from '../HeaderProvider/useHeaderContent';
import { defaultHeaderHeight } from '../Layout/Layout';
import LanguageSwitcher from './LanguageSwitcher';
import messages from './messages';
import PluginSwitcher from './PluginSwitcher';

type StylesProps = {
    headerHeight?: string;
    drawerDisabled?: boolean;
};

export const useHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: StylesProps | undefined) => ({
            minHeight: props?.headerHeight ? props?.headerHeight : defaultHeaderHeight,
            [theme.breakpoints.up('sm')]: {
                flexWrap: 'nowrap',
                minHeight: props?.headerHeight ? props?.headerHeight : defaultHeaderHeight,
                height: props?.headerHeight ? props?.headerHeight : defaultHeaderHeight,
            },
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            [theme.breakpoints.up('md')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
            zIndex: 200,
            boxShadow: '0px 0px 6px 0px rgb(0 0 0 / 16%) !important',
        }),
        close: (props: StylesProps | undefined) => ({
            paddingLeft: props?.drawerDisabled ? '!inherit' : `${theme.spacing(7)} !important`,
        }),
        burgerButton: {
            color: theme.palette.common.white,
            marginLeft: '-12px',
        },
        burgerIcon: {
            width: '20px',
            height: '20px',
        },
        backButton: {
            color: theme.palette.common.white,
            padding: '8px',
            marginRight: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                height: '40px',
            },
        },
        iconButton: {
            color: theme.palette.grey['100'],
            padding: '8px',
            marginRight: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                height: '40px',
            },
        },
        menu: {
            flex: '1 1 0%',
            textAlign: 'right',
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'flex-end',
            alignItems: 'center',
            order: 1,
            [theme.breakpoints.up('sm')]: {
                order: 2,
                flexBasis: '30%',
            },
            [theme.breakpoints.up('lg')]: {
                flexBasis: '50%',
            },
        },
        menuButton: {
            minWidth: 'auto',
            paddingLeft: 0,
            paddingRight: 0,
            '&:hover': {
                backgroundColor: 'inherit',
            },
        },
        logoBox: {
            display: 'flex',
            alignItems: 'center',
            minHeight: defaultHeaderHeight,
            marginRight: '20px',
            '& img': {
                height: '100%',
                width: '100%',
                maxWidth: '100px',
                maxHeight: defaultHeaderHeight,
                cursor: 'pointer',
            },
            [theme.breakpoints.up('sm')]: {
                marginRight: '0',
                '& img': {
                    maxWidth: '300px',
                },
            },
        },
        headingBox: {
            display: 'flex',
            flexGrow: 1,
            flexBasis: '80%',
            alignSelf: 'flex-start',
            alignItems: 'center',
            textAlign: 'left',
            color: theme.palette.grey['200'],
            order: 1,
            minHeight: defaultHeaderHeight,
            [theme.breakpoints.up('sm')]: {
                flexBasis: '70%',
                minHeight: defaultHeaderHeight,
                marginLeft: theme.spacing(4),
                order: 1,
            },
            [theme.breakpoints.up('lg')]: {
                flexBasis: '30%',
            },
        },
        heading: {
            color: template.headerBarColor,
            fontSize: theme.typography.pxToRem(12),
            fontWeight: 400,
            // maxWidth: 200,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            [theme.breakpoints.up('sm')]: {
                fontSize: theme.typography.pxToRem(17),
            },
        },
        popper: {
            zIndex: 10,
        },
        avatar: {
            color: template.headerCircleTextColor,
            backgroundColor: template.headerCircleBgColor,
            textTransform: 'uppercase',
            width: '38px',
            height: '38px',
            fontSize: theme.typography.pxToRem(14),
            fontWeight: 300,
            [theme.breakpoints.up('sm')]: {
                width: '40px',
                height: '40px',
                fontSize: theme.typography.pxToRem(18),
            },
        },
        menuList: {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.common.white,
            minWidth: theme.spacing(18),
            [theme.breakpoints.up('sm')]: {
                '& li': {
                    justifyContent: 'flex-start',
                },
            },
        },
        userBox: {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'block',
                marginRight: theme.spacing(2),
                '& h4': {
                    color: template.headerBarColor,
                    fontSize: theme.typography.pxToRem(18),
                },
            },
        },
        menuBox: {
            display: 'block',
            '& > li': {
                color: theme.palette.common.black,
            },
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        },
        menuItemIcon: {
            color: theme.palette.text.secondary,
            minWidth: '35px',
        },
        divider: {
            backgroundColor: theme.palette.text.secondary,
            opacity: 0.2,
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        },
        hide: {
            display: 'none',
        },
    }),
);

interface IHeaderProps {
    drawerOpen: boolean;
    handleDrawerOpen: () => void;
    logo?: string;
    rightMenu?: React.ReactNode;
    drawerDisabled?: boolean;
    stylesProps?: StylesProps;
}

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
    const { drawerOpen, logo, drawerDisabled, stylesProps } = props;

    if (stylesProps) {
        stylesProps.drawerDisabled = !!drawerDisabled;
    }

    const classes = useHeaderStyles(stylesProps);
    const { keycloak } = useKeycloak();
    const history = useHistory();
    const { headerContent } = useHeaderContent();

    const [menuOpen, setMenuOpen] = useState(false);
    const [userData, setUserData] = useState<KeycloakProfile | null>(null);
    const anchorRef = useRef<HTMLButtonElement | null>(null);

    const handleMenuToggle = () => {
        setMenuOpen(prevMenuOpen => !prevMenuOpen);
    };

    const handleMenuClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setMenuOpen(false);
    };

    const handleSignOut = () => {
        keycloak?.logout();
    };

    const handleRootClick = () => {
        history.push(Path.ROOT);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setMenuOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevMenuOpen = React.useRef(menuOpen);

    useEffect(() => {
        Promise.resolve(keycloak?.loadUserProfile()).then(data => {
            setUserData(data as KeycloakProfile);
        });

        if (prevMenuOpen.current && !menuOpen) {
            anchorRef.current?.focus();
        }

        prevMenuOpen.current = menuOpen;
    }, [keycloak, menuOpen]);

    // translations
    const intl = useIntl();
    const transSignOut = intl.formatMessage({ ...messages.signOut });

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.close]: !drawerOpen,
            })}
        >
            <PluginSwitcher />
            {logo && (
                <Box className={classes.logoBox} onClick={handleRootClick}>
                    <img alt="Armasuisse" src={logo} />
                </Box>
            )}
            <Box className={classes.headingBox}>{headerContent}</Box>
            <Box className={classes.menu}>
                <LanguageSwitcher />

                {userData && (
                    <>
                        <Box className={classes.userBox}>
                            <Typography variant="h4">{`${userData?.firstName} ${userData?.lastName}`}</Typography>
                        </Box>
                        <Button
                            ref={anchorRef}
                            aria-controls={menuOpen ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            variant="text"
                            onClick={handleMenuToggle}
                            className={classes.menuButton}
                        >
                            <Avatar classes={{ root: classes.avatar }}>
                                {`${userData?.firstName?.charAt(0)}${userData?.lastName?.charAt(
                                    0,
                                )}`}
                            </Avatar>
                        </Button>
                        <Popover
                            open={menuOpen}
                            anchorEl={anchorRef.current}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            className={classes.popper}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleMenuClose}>
                                    <MenuList
                                        autoFocusItem={menuOpen}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                        className={classes.menuList}
                                    >
                                        <Box className={classes.menuBox}>
                                            <MenuItem disabled>
                                                {`${userData?.firstName} ${userData?.lastName}`}
                                            </MenuItem>
                                            <Divider className={classes.divider} />
                                        </Box>

                                        <MenuItem onClick={handleSignOut}>
                                            <ListItemIcon className={classes.menuItemIcon}>
                                                <ExitToAppIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit">
                                                {transSignOut}
                                            </Typography>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Popover>
                    </>
                )}
            </Box>
        </Toolbar>
    );
};

export default Header;
