import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
    MilestoneDeadlineBase,
    MilestoneType,
    ProjectMilestoneBaseResponse,
} from '../../../../generate/api';
import {
    selectIsLastReport,
    selectMilestones,
    selectReport,
    updateReportMilestoneDeadline,
} from '../../../../redux/projectInformation/projectInformationSlice';
import ReportTextareaComment from '../../components/ReportTextareaComment/ReportTextareaComment';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import TextualItems from '../../components/TextualItems/TextualItems';
import sectionTitleMessages from '../../MainForm/sectionTitleMessages';
import styleTable from '../../styles/table.module.css';
import messages from './messages';

interface IProgressDatepickerProps {
    code: string;
    type: 'is' | 'should';
}

const ProgressDatepicker: React.FC<IProgressDatepickerProps> = ({ code, type }) => {
    const dispatch = useDispatch();
    const dateFormat = 'YYYY-MM-DDTHH:mm:ss';
    const report = useSelector(selectReport);
    const isLastReport = useSelector(selectIsLastReport);
    const reduxMilestoneType = useSelector(selectMilestones)?.find(m => m.code === code);

    const [row, setRow] = useState<MilestoneDeadlineBase>();

    const reduxMilestone = report?.milestoneDeadlines?.find(
        m => m.projectMilestoneID === reduxMilestoneType?.projectMilestoneID,
    );

    // Data from redux store to local state
    useEffect(() => {
        if (reduxMilestone) {
            setRow({
                ...reduxMilestone,
                projectMilestoneID: reduxMilestoneType?.projectMilestoneID, // set projectMilestoneID to row based on reduxMilestoneType
            });
        }
    }, [report, reduxMilestoneType]);

    // Datepicker open
    const [open, setOpen] = useState(false);
    const handleTextFieldClick = () => {
        setOpen(true);
    };

    // Save date to redux store
    const handleDateChange = date => {
        const formattedDate = dayjs(date).format(dateFormat);
        if (type == 'is') setRow({ ...row, deadlineIs: formattedDate });
        else {
            setRow({ ...row, deadlineShould: formattedDate });
        }
    };

    // value for textField
    const value = type === 'is' ? row?.deadlineIs : row?.deadlineShould;

    useEffect(() => {
        const timeout = setTimeout(() => {
            // if row is not same as in reduxMilestone, update reduxMilestone
            if (
                row?.deadlineIs === reduxMilestone?.deadlineIs &&
                row?.deadlineShould === reduxMilestone?.deadlineShould
            )
                return;

            if (row) {
                dispatch(
                    updateReportMilestoneDeadline({
                        ...row,
                        projectMilestoneID: reduxMilestoneType?.projectMilestoneID,
                    }),
                );
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [row]);

    // style for textField
    const useStyles = makeStyles(() => ({
        textField: {
            border: 'none',
            outline: 'none',
            width: '100px',
            '& .MuiInputBase-root': {
                minWidth: 'auto',
            },
            '& input': {
                padding: '0',
                textAlign: 'center',
            },
            '& fieldset': {
                border: 'none',
            },
        },
    }));
    const classes = useStyles();

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    value={value || null}
                    onChange={handleDateChange}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    inputFormat="DD.MM.YYYY"
                    disabled={!isLastReport}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size="small"
                            margin="none"
                            fullWidth
                            autoComplete="off"
                            className={classes.textField}
                            value={value ? dayjs(value).format(dateFormat) : ''}
                            onClick={isLastReport ? handleTextFieldClick : undefined}
                            InputProps={{
                                readOnly: true,
                                endAdornment: null,
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
        </>
    );
};

/**
 * Get milestone rank order
 * @param milestone
 */
const getMilestoneRankOrder = (milestone: ProjectMilestoneBaseResponse): number => {
    let rank = milestone.order ?? 0;
    switch (milestone.type) {
        case MilestoneType.Project:
            rank += 100;
            break;
        case MilestoneType.Process:
            rank += 200;
            break;
        default:
            rank += 300;
            break;
    }
    return rank;
};

const TimeProgress = () => {
    const intl = useIntl();

    const reduxMilestones = useSelector(selectMilestones);

    const milestones = useMemo<ProjectMilestoneBaseResponse[]>(
        () =>
            reduxMilestones
                .filter(
                    p =>
                        p.type &&
                        (p.type == MilestoneType.Process || p.type == MilestoneType.Project),
                )
                .sort((a, b) => getMilestoneRankOrder(a) - getMilestoneRankOrder(b)),
        [reduxMilestones],
    );

    return (
        <section className="my-[100px]">
            <section id="section-04" className="scroll-my-5">
                <SectionHeader
                    mainSection
                    number={4}
                    title={intl.formatMessage({ ...sectionTitleMessages.timeProgress })}
                    aspect={'timeProgressOverallAspect'}
                />
                <div className="my-8">
                    <ReportTextareaComment
                        title={intl.formatMessage({
                            ...messages.timeProgressOverallComment,
                        })}
                        reduxTarget="projectPlanCreditComment"
                    />
                </div>
            </section>

            <section id="section-04-01" className="scroll-my-5 my-[50px]">
                <SectionHeader
                    number={4.1}
                    subSection
                    title={intl.formatMessage({
                        ...sectionTitleMessages.projectMilestonesEndDates,
                    })}
                    aspect={'projectMilestonesAspect'}
                />

                <div className="flex-1 border border-gray-200 rounded">
                    <table className={clsx(styleTable.table, styleTable.tableBordered)}>
                        <thead className={clsx(styleTable.tableRotateThead, 'text-sm')}>
                            <tr>
                                <th>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: intl.formatMessage({
                                                ...messages.milestones,
                                            }),
                                        }}
                                    />
                                </th>
                                {milestones.map((milestone, index) => {
                                    if (
                                        milestone.code &&
                                        Object.keys(messages).includes(milestone.code)
                                    ) {
                                        return (
                                            <React.Fragment key={index}>
                                                <th>
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: intl.formatMessage(
                                                                messages[milestone.code],
                                                            ),
                                                        }}
                                                    />
                                                </th>
                                            </React.Fragment>
                                        );
                                    } else {
                                        console.debug(
                                            'Missing translation for milestone: ',
                                            milestone,
                                        );
                                        return (
                                            <th key={index}>
                                                <span>{milestone.name}</span>
                                            </th>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            <tr className="bg-green-100">
                                <th>
                                    <FormattedMessage {...messages.should} />
                                </th>
                                {milestones.map((milestone, index) => {
                                    return (
                                        <td key={index}>
                                            <ProgressDatepicker
                                                type="is"
                                                code={milestone?.code as string}
                                            />
                                        </td>
                                    );
                                })}
                            </tr>
                            <tr className="bg-orange-50">
                                <th>
                                    <FormattedMessage {...messages.forecast} />
                                </th>
                                {milestones.map((milestone, index) => {
                                    return (
                                        <td key={index}>
                                            <ProgressDatepicker
                                                type="should"
                                                code={milestone?.code as string}
                                            />
                                        </td>
                                    );
                                })}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>

            <section id="section-04-02" className="scroll-my-5 my-[50px]">
                <SectionHeader
                    number={4.2}
                    subSection
                    title={intl.formatMessage({
                        ...sectionTitleMessages.comparisonActualTargetDatesCurrentProjectStatus,
                    })}
                    aspect="dateComparisionAspect"
                />
                <TextualItems code={'4.2'} />
            </section>
        </section>
    );
};

export default TimeProgress;
