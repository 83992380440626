import { Button } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IProjectInformationPage } from '../../ProjectInformation';
import messages from './messages';

export const ButtonsHeader: React.FC<{ setPage: React.ComponentState }> = ({ setPage }) => {
    const handleButtonExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log('## Export:', event.currentTarget.value as string);
    };
    const handleButtonImportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPage(event.currentTarget.value as IProjectInformationPage);
    };

    return (
        <>
            <Button
                variant="contained"
                className="mr-2 xl:mr-0 xl:ml-2"
                value={'pdf'}
                size={'large'}
                onClick={handleButtonExportClick}
            >
                <FormattedMessage {...messages.buttonPdfExport} />
            </Button>
            <Button
                variant="contained"
                className="mr-2 xl:mr-0 xl:ml-2"
                value={'xlsx'}
                onClick={handleButtonExportClick}
            >
                <FormattedMessage {...messages.buttonXlsxExport} />
            </Button>
            <Button
                variant="contained"
                className="mr-2 xl:mr-0 xl:ml-2"
                value="new-project"
                onClick={handleButtonImportClick}
            >
                <FormattedMessage {...messages.buttonXlsxImport} />
            </Button>
        </>
    );
};
