import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { AspectBaseResponse } from '../../../../generate/api';
import {
    selectCommonSettings,
    selectReport,
} from '../../../../redux/projectInformation/projectInformationSlice';
import StateSelector from '../../../Form/StateSelector/StateSelector';
import { AspectType } from '../../acpects.type';
import sectionTitleMessages from '../../MainForm/sectionTitleMessages';
import SectionStateSelector from '../SectionStateSelector/SectionStateSelector';
import style from './sectionHeader.module.css';

export interface ISectionHeaderProps {
    number?: number;
    title?: string;
    children?: React.ReactNode;
    mainSection?: boolean;
    subSection?: boolean;
    rightPart?: React.ReactNode;
    middlePart?: React.ReactNode;
    rightTitle?: string;
    previousAspect?: React.ReactNode;
    currentAspect?: React.ReactNode;
    aspect?: AspectType;
}

const SectionHeader: React.FC<ISectionHeaderProps> = ({
    number,
    title,
    children,
    mainSection,
    subSection,
    rightTitle,
    middlePart,
    previousAspect,
    currentAspect,
    aspect,
}) => {
    const settings = useSelector(selectCommonSettings);

    const reduxReportData = useSelector(selectReport);

    let oldStateID: number | undefined = undefined;

    if (aspect) {
        const currentAspectData = reduxReportData?.[aspect] as AspectBaseResponse | undefined;
        oldStateID = currentAspectData?.previousStatusID as number | undefined;
    }

    return (
        <header className={clsx(style.header, mainSection && style.headerMain)}>
            <h2 className={style.title}>
                {number && <span>{number} - </span>} {title}
            </h2>

            <div className="flex items-center gap-4">
                {middlePart && <div className="mr-5">{middlePart}</div>}
                {mainSection && <FormattedMessage {...sectionTitleMessages.overallAssessment} />}
                {subSection && <FormattedMessage {...sectionTitleMessages.assessment} />}
                {rightTitle && <div>{rightTitle}</div>}
                <div className="flex gap-3">
                    {previousAspect && settings.showPreviousVersion && <div>{previousAspect}</div>}
                    {currentAspect && <div>{currentAspect}</div>}

                    {aspect && (
                        <div className="flex items-center gap-2">
                            {settings.showPreviousVersion && (
                                <div>
                                    <StateSelector
                                        category="aspect"
                                        value={oldStateID ? oldStateID : undefined}
                                        oldState
                                    />
                                </div>
                            )}
                            <div>
                                <SectionStateSelector stateSelectorName={aspect} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default SectionHeader;
