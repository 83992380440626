import { SearchOutlined } from '@mui/icons-material';
import { ClickAwayListener, InputAdornment, TextField } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import style from './filterPopup.module.css';

const managers = [
    'Max Mustermann',
    'Dirk Pfeffer',
    'Peter Muster',
    'Benjamin Lang',
    'Dieter Egger',
];

const types = [
    'Scheunen, silobauten und treibhäuser',
    'Stallungen',
    'Herbergen, Jugendherbergen und massenun...',
    'Verkehrsanlagen',
    'Parkhäuser und tiefgaragen',
    'Zollanlagen (abfertigung)',
    'Tankstellen und wartehallen mit diensträumen',
    'Tribünenbauten und garderobengebäude',
    'Scheunen, silobauten und treibhäuser',
    'Stallungen',
    'Herbergen, Jugendherbergen und massenunt...',
    'Verkehrsanlagen',
    'Parkhäuser und tiefgaragen',
    'Zollanlagen (abfertigung)',
    'Tankstellen und wartehallen mit diensträumen',
    'Tribünenbauten und garderobengebäude',
    'Scheunen, silobauten und treibhäuser',
    'Stallungen',
];

const kanton = [
    'Zürich (ZH)',
    'Bern / Berne (BE)',
    'Luzern (LU)',
    'Uri (UR)',
    'Schwyz (SZ)',
    'Unterwalden (Obwalden (OW) / Nidwal...',
    'Glarus (GL)',
    'Zug (ZG)',
    'Freiburg / Fribourg (FR)',
    'Solothurn (SO)',
    'Basel (Basel Stadt (BS)/ Basel Land (BL))',
    'Schaffhausen (SH)',
    'Appenzell (Appenzell Ausserrhoden (AR)...',
    'Sankt Gallen (SG)',
    'Graubünden (GR)',
    'Aargau (AG)',
    'Thurgau (TG)',
    'Ticino (TI)',
];

const CheckboxGroup: React.FC<{ title: string; items: string[] }> = ({ title, items }) => {
    const [filteredItems, setFilteredItems] = useState<string[]>(items);

    const [searchText, setSearchText] = useState<string>();

    const handleChangeSearch = event => {
        const text = event.target.value;
        setSearchText(text);
        const filtered = items?.filter(x => x.toLowerCase().includes(text.toLowerCase()));
        setFilteredItems(filtered);
    };

    return (
        <>
            <div className="text-lg font-medium">{title}</div>
            <div>
                <TextField
                    value={searchText}
                    variant="outlined"
                    fullWidth
                    required
                    size="small"
                    onChange={handleChangeSearch}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={'end'}>
                                <SearchOutlined />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className="max-h-[300px] overflow-y-auto">
                {/*<FormGroup>*/}
                {/*    {filteredItems.map((item, index) => {*/}
                {/*        return (*/}
                {/*            <FormControlLabel*/}
                {/*                control={<Checkbox size="small" className="py-0" />}*/}
                {/*                label={item}*/}
                {/*                key={index}*/}
                {/*                className="items-start"*/}
                {/*            />*/}
                {/*        );*/}
                {/*    })}*/}
                {/*</FormGroup>*/}
                {filteredItems.map((item, index) => {
                    return (
                        <label key={index} className={style.labelRow}>
                            <div>
                                <input type="checkbox" className="mr-2" />
                            </div>
                            <div>{item}</div>
                        </label>
                    );
                })}
            </div>
        </>
    );
};

export const FilterPopup: React.FC<{ isOpen?: boolean }> = ({ isOpen = false }) => {
    const [open, setOpen] = useState<boolean>(isOpen);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);
    return (
        <>
            <div
                className={clsx(
                    'flex items-center bg-red-150 justify-center hover:bg-gray-100 cursor-pointer w-full h-full relative max-w-[100px]',
                    open && 'bg-primary text-white hover:bg-primary hover:text-white',
                )}
                onClick={() => setOpen(!open)}
            >
                <div>Filter</div>
            </div>
            {open && (
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <div className="absolute w-400px border-t-4 border-primary bg-white p-5 shadow-lg accent-primary">
                        <div className="flex border-b border-gray-200 pb-4 mb-4">
                            <div>
                                <label className="cursor-pointer hover:underline mr-4">
                                    <input type="radio" name="sort" className="mr-2" checked />
                                    <span>Sort by name ASC</span>
                                </label>
                            </div>
                            <div>
                                <label className="cursor-pointer hover:underline">
                                    <input type="radio" name="sort" className="mr-2" />
                                    <span>Sort by name DESC</span>
                                </label>
                            </div>
                        </div>

                        <div className="flex">
                            <div className="border-r border-gray-200 pr-5 mr-5">
                                <CheckboxGroup title="Managers" items={managers} />
                            </div>
                            <div className="border-r border-gray-200 pr-5 mr-5">
                                <CheckboxGroup title="Types" items={types} />
                            </div>
                            <div className="w-[150px] border-r border-gray-200 pr-5 mr-5">
                                <div className="text-lg font-medium mb-1">Phase</div>
                                <div className="mb-8">
                                    <label className={style.labelRow}>
                                        <div>
                                            <input type="checkbox" className="mr-2" />
                                        </div>
                                        <div>ar Phase</div>
                                    </label>
                                    <label className={style.labelRow}>
                                        <div>
                                            <input type="checkbox" className="mr-2" />
                                        </div>
                                        <div>SIA Phase</div>
                                    </label>
                                </div>

                                <div className="text-lg font-medium mb-1">Status</div>
                                <div>
                                    <label className={style.labelRow}>
                                        <div>
                                            <input type="checkbox" className="mr-2" />
                                        </div>
                                        <div>Open</div>
                                    </label>
                                    <label className={style.labelRow}>
                                        <div>
                                            <input type="checkbox" className="mr-2" />
                                        </div>
                                        <div>Close</div>
                                    </label>
                                </div>
                            </div>
                            <div className="border-gray-200">
                                <CheckboxGroup title="Kanton" items={kanton} />
                            </div>
                        </div>
                    </div>
                </ClickAwayListener>
            )}
        </>
    );
};
