export const propertiesToArray = obj => {
    const isObject = val => val && typeof val === 'object' && !Array.isArray(val);

    const addDelimiter = (a, b) => (a ? `${a}.${b}` : b);

    const paths = (obj2 = {}, head = '') => {
        return Object.entries(obj2).reduce((product, [key, value]) => {
            const fullPath = addDelimiter(head, key);
            return isObject(value)
                ? product.concat(paths(value as any, fullPath))
                : product.concat(fullPath);
        }, []);
    };

    return paths(obj);
};
