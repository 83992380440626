import React, { useEffect, useState } from 'react';

import { ProjectDetailResponse } from '../../generate/api';
import { projApi } from '../../packages/Api/data/projects/client';
import Icon from '../Icon/Icon';
import MainForm from './MainForm/MainForm';
import EntryPageSidebar from './MainForm/Sidebar';
import ProjectImport from './ProjectImport/ProjectImport';
import style from './styles.module.css';

export type IProjectInformationPage = 'new-project' | 'entry-page' | 'header-data';

export interface IEntryPageSidebarProps {
    onSectionClick: (section: string) => void;
}

const ProjectInformation: React.FC<{
    open?: boolean;
    isOpenCallback?: (isOpen: boolean) => void;
    newProject?: boolean;
    id?: React.ComponentState;
}> = ({ open, isOpenCallback, newProject, id }) => {
    const [page, setPage] = useState<IProjectInformationPage>('entry-page');

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            setIsOpen(open);
        }
    }, [open]);

    useEffect(() => {
        if (newProject) {
            setPage('new-project');
        }
    }, [newProject]);

    const handleClose = () => {
        setIsOpen(false);
        if (isOpenCallback) {
            isOpenCallback(false); // todo - not best way to do that
        }
    };

    const [detailData, setDetailData] = useState<ProjectDetailResponse>(); // todo ts

    useEffect(() => {
        projApi.getProjectDetails(id).then(response => {
            console.log(response.data);
            if (response.data) {
                setDetailData(response.data);
            } else {
                setDetailData(undefined);
            }
        });
    }, [id]);

    /**
     * Handle click on section in sidebar
     * @param section
     */
    const handleSectionClick = section => {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (!isOpen) {
        return <></>;
    }
    return (
        <div className={style.wrapperOuter}>
            <div className={style.wrapper}>
                <div className="flex h-full">
                    {page === 'entry-page' && (
                        <EntryPageSidebar onSectionClick={handleSectionClick} />
                    )}
                    <div className="flex-1 p-5 overflow-y-auto h-full">
                        {page === 'entry-page' && <MainForm data={detailData} setPage={setPage} />}
                        {page === 'new-project' && <ProjectImport />}
                    </div>
                </div>
            </div>
            <div className={style.close} onClick={handleClose}>
                <Icon name="times" size={20} />
            </div>
        </div>
    );
};

export default ProjectInformation;
