import { Button, MenuItem, TextField } from '@mui/material';
import Select from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { RiskBaseResponse } from '../../../../../generate/api';
import {
    createRisk,
    deleteRisk,
    selectIsLastReport,
    selectRisks,
    updateRisk,
} from '../../../../../redux/projectInformation/projectInformationSlice';
import ButtonDelete from '../../../components/ButtonDelete/ButtonDelete';
import styleTable from '../../../styles/table.module.css';
import messages from '../messages';
import { IRowProps } from '../Risks';

const Row: React.FC<IRowProps> = ({ formRowData, number }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [row, setRow] = useState<RiskBaseResponse>(formRowData);
    const isLastReport = useSelector(selectIsLastReport);
    const reduxRow = useSelector(selectRisks).find(risk => risk.riskID === row.riskID);

    useEffect(() => {
        setRow(formRowData);
    }, [formRowData]);

    const handleFieldChange = (fieldName, value) => {
        setRow({ ...row, [fieldName]: value });
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            // Compare if the reduxRow is different from the row
            if (
                reduxRow?.name === row.name &&
                reduxRow?.managementDescription === row.managementDescription &&
                reduxRow?.managementLikehood === row.managementLikehood &&
                reduxRow?.managementImpact === row.managementImpact
            )
                return;

            if (row?.riskID) {
                dispatch(updateRisk(row));
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [row]);

    const handleDeleteRow = () => {
        if (!formRowData.riskID) return;
        dispatch(deleteRisk(formRowData.riskID));
    };

    return (
        <tr>
            <th>{number}</th>
            <td>
                <TextField
                    value={row.name}
                    onChange={e => handleFieldChange('name', e.target.value)}
                    fullWidth
                    size="small"
                    margin="none"
                    placeholder={intl.formatMessage({ ...messages.placeholderRisk })}
                    disabled={!isLastReport}
                />
            </td>
            <td>
                <TextField
                    value={row.managementDescription}
                    onChange={e => handleFieldChange('managementDescription', e.target.value)}
                    fullWidth
                    size="small"
                    margin="none"
                    multiline
                    placeholder={intl.formatMessage({ ...messages.placeholderDescription })}
                    disabled={!isLastReport}
                />
            </td>
            <td>
                <Select
                    value={row.managementLikehood || ''}
                    onChange={e => handleFieldChange('managementLikehood', e.target.value)}
                    fullWidth
                    size="small"
                    margin="none"
                    displayEmpty
                    disabled={!isLastReport}
                >
                    <MenuItem value={''}>-</MenuItem>
                    <MenuItem value={'VeryUnlikely'}>
                        1 - <FormattedMessage {...messages.stateVeryUnlikely} />
                    </MenuItem>
                    <MenuItem value={'Unlikely'}>
                        2 - <FormattedMessage {...messages.stateUnlikely} />
                    </MenuItem>
                    <MenuItem value={'Rare'}>
                        3 - <FormattedMessage {...messages.stateRare} />
                    </MenuItem>
                    <MenuItem value={'Possible'}>
                        4 - <FormattedMessage {...messages.statePossible} />
                    </MenuItem>
                    <MenuItem value={'Probable'}>
                        5 - <FormattedMessage {...messages.stateProbable} />
                    </MenuItem>
                    <MenuItem value={'VeryLikely'}>
                        6 - <FormattedMessage {...messages.stateVeryLikely} />
                    </MenuItem>
                </Select>
            </td>
            <td>
                <Select
                    value={row.managementImpact || ''}
                    onChange={e => handleFieldChange('managementImpact', e.target.value)}
                    fullWidth
                    size="small"
                    margin="none"
                    displayEmpty
                    disabled={!isLastReport}
                >
                    <MenuItem value={''}>-</MenuItem>
                    <MenuItem value={'VeryHigh'}>
                        6 - <FormattedMessage {...messages.stateVeryHigh} />
                    </MenuItem>
                    <MenuItem value={'High'}>
                        5 - <FormattedMessage {...messages.stateHigh} />
                    </MenuItem>
                    <MenuItem value={'Occasional'}>
                        4 - <FormattedMessage {...messages.stateOccasional} />
                    </MenuItem>
                    <MenuItem value={'Moderate'}>
                        3 - <FormattedMessage {...messages.stateModerate} />
                    </MenuItem>
                    <MenuItem value={'Low'}>
                        2 - <FormattedMessage {...messages.stateLow} />
                    </MenuItem>
                    <MenuItem value={'VeryLow'}>
                        1 - <FormattedMessage {...messages.stateVeryLow} />
                    </MenuItem>
                </Select>
            </td>
            <td>
                <ButtonDelete onClick={handleDeleteRow} disabled={!isLastReport} />
            </td>
        </tr>
    );
};

const RiskManagementTable: React.FC = () => {
    const dispatch = useDispatch();
    const reduxRisksData = useSelector(selectRisks);
    const isLastReport = useSelector(selectIsLastReport);

    // Add a new row to the Redux
    const handleAddRow = () => {
        dispatch(
            createRisk({
                name: '',
            }),
        );
    };

    return (
        <>
            <div className={styleTable.tableOuterBorder}>
                <table className={styleTable.table}>
                    <thead>
                        <tr>
                            <th className="w-0">
                                <FormattedMessage {...messages.labelNo} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelRisk} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelDescription} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelProbability} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.labelImpact} />
                            </th>
                            <th className="w-0"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {reduxRisksData.map((row, index) => (
                            <Row formRowData={row} key={index} number={index + 1} />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-4">
                <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={handleAddRow}
                    disabled={!isLastReport}
                >
                    <FormattedMessage {...messages.buttonNewRow} />
                </Button>
            </div>
        </>
    );
};

export default RiskManagementTable;
