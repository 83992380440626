import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import config from '../../config/config';
import { ExternalSystemSummary } from '../../generate/api';
import { pocApi } from '../../packages/Api/data/externalSystems/client';
import Page from '../Page/Page';
import ExternalSystemInfo from './ExternalSystemInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dashboardBox: {
            height: '100%',
            overflowY: 'scroll',
            [theme.breakpoints.up('md')]: {},
        },
    }),
);

const PocPage = () => {
    const classes = useStyles();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();
    const [systemsList, setSystemsList] = useState<ExternalSystemSummary[]>([]);

    useEffect(() => {
        pocApi.getPocGetExternalSystemsSummary().then(response => {
            setSystemsList(response.data);
        });
    }, [keycloakInitialized]);

    return (
        <Page noPadding={true}>
            <Helmet>
                <title>{`${config?.template?.title} - Web Services`}</title>
            </Helmet>
            <Box className={classes.dashboardBox}>
                <div>
                    {systemsList.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ExternalSystemInfo item={item} />
                            </React.Fragment>
                        );
                    })}
                </div>
            </Box>
        </Page>
    );
};

export default PocPage;
