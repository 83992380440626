import { Button, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectReportPersonBaseResponse } from '../../../../generate/api';
import {
    createProjectReportPerson,
    deleteProjectReportPerson,
    selectCompanies,
    selectCompanyTypes,
    selectFunctions,
    selectIsLastReport,
    selectOrganizationUnits,
    selectPeople,
    selectProjectReportPeople,
    updateProjectReportPerson,
} from '../../../../redux/projectInformation/projectInformationSlice';
import ButtonDelete from '../../components/ButtonDelete/ButtonDelete';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import sectionTitleMessages from '../../MainForm/sectionTitleMessages';
import styleTable from '../../styles/table.module.css';
import messages from './messages';

interface IRowProps {
    formRowData: ProjectReportPersonBaseResponse;
}

const Row: React.FC<IRowProps> = ({ formRowData }) => {
    const dispatch = useDispatch();
    const people = useSelector(selectPeople);
    const functions = useSelector(selectFunctions);
    const companies = useSelector(selectCompanies);
    const organizationUnits = useSelector(selectOrganizationUnits);
    const companyTypes = useSelector(selectCompanyTypes);
    const isLastReport = useSelector(selectIsLastReport);

    const [row, setRow] = React.useState<ProjectReportPersonBaseResponse>(formRowData);
    const reduxRow = useSelector(selectProjectReportPeople).find(
        pendency => pendency.projectReportPersonID === row.projectReportPersonID,
    );

    const contractorTypeId = companyTypes.find(
        companyType => companyType.code === 'contractor',
    )?.companyTypeID;

    useEffect(() => {
        setRow(formRowData);
    }, [formRowData]);

    const handleFieldChange = (fieldName, value) => {
        setRow({ ...row, [fieldName]: value });
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (row?.projectReportPersonID) {
                // Compare if the row is the same as the row in redux, if it is, don't update
                if (
                    reduxRow?.functionID === row.functionID &&
                    reduxRow?.personID === row.personID &&
                    reduxRow?.organizationUnitID === row.organizationUnitID &&
                    reduxRow?.companyID === row.companyID
                )
                    return;

                dispatch(updateProjectReportPerson(row));
            }
        }, 500);
        return () => clearTimeout(timeout);
    }, [row]);

    const handleDeleteRow = () => {
        if (!row?.projectReportPersonID) return;
        dispatch(deleteProjectReportPerson(row.projectReportPersonID));
    };

    /**
     * Check if the organization unit needs to be changed because the company was changed
     */
    const [organizationUnitNeedsToBeChanged, setOrganizationUnitNeedsToBeChanged] =
        React.useState<boolean>(false);
    useEffect(() => {
        setOrganizationUnitNeedsToBeChanged(false);
        if (organizationUnits.length === 0 || !row.organizationUnitID) return; // If there are no organization units, don't check
        const isSome = organizationUnits.some(
            organizationUnit => organizationUnit.organizationUnitID === row.organizationUnitID,
        );
        if (!isSome) {
            setOrganizationUnitNeedsToBeChanged(true);
        }
    }, [row?.companyID, row.organizationUnitID, organizationUnits]);

    return (
        <tr>
            <td>
                <Select
                    value={row?.functionID || ''}
                    onChange={e => handleFieldChange('functionID', e.target.value as number)}
                    fullWidth
                    size="small"
                    margin="none"
                    displayEmpty
                    disabled={!isLastReport}
                >
                    <MenuItem value="">-</MenuItem>
                    {functions.map(item => {
                        return (
                            <MenuItem key={item.projectFunctionID} value={item.projectFunctionID}>
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </td>
            <td>
                <Select
                    value={
                        people.some(person => person.personID === row.personID) ? row.personID : ''
                    }
                    onChange={e => handleFieldChange('personID', e.target.value as number)}
                    fullWidth
                    size="small"
                    margin="none"
                    displayEmpty
                    disabled={!isLastReport}
                >
                    <MenuItem value="">-</MenuItem>
                    {people.map(item => {
                        return (
                            <MenuItem key={item.personID} value={item.personID}>
                                {item.firstName} {item.lastName} ({item.email})
                            </MenuItem>
                        );
                    })}
                </Select>
            </td>
            <td>
                {row.typeCode === 'owner' ? (
                    <>
                        {organizationUnitNeedsToBeChanged && (
                            <div className="text-red-600 text-sm mb-1">
                                <FormattedMessage {...messages.organizationUnitNeedToBeChanged} />
                            </div>
                        )}
                        <Select
                            value={
                                organizationUnits.some(
                                    organizationUnit =>
                                        organizationUnit.organizationUnitID ===
                                        row.organizationUnitID,
                                )
                                    ? row.organizationUnitID
                                    : ''
                            }
                            onChange={e => handleFieldChange('organizationUnitID', e.target.value)}
                            fullWidth
                            size="small"
                            margin="none"
                            displayEmpty
                            disabled={!isLastReport}
                            error={organizationUnitNeedsToBeChanged}
                        >
                            <MenuItem value="">-</MenuItem>
                            {organizationUnits.map(item => {
                                return (
                                    <MenuItem
                                        key={item.organizationUnitID}
                                        value={item.organizationUnitID}
                                    >
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </>
                ) : (
                    <Select
                        value={
                            companies.some(company => company.companyID === row.companyID)
                                ? row.companyID
                                : ''
                        }
                        onChange={e => handleFieldChange('companyID', e.target.value)}
                        fullWidth
                        size="small"
                        margin="none"
                        displayEmpty
                        disabled={!isLastReport}
                    >
                        <MenuItem value="">-</MenuItem>
                        {companies.map(item => {
                            if (item.companyTypeID !== contractorTypeId) return null;
                            return (
                                <MenuItem key={item.companyID} value={item.companyID}>
                                    {item.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                )}
            </td>
            <td>
                <ButtonDelete onClick={handleDeleteRow} disabled={!isLastReport} />
            </td>
        </tr>
    );
};

const ProjectTeam: React.FC = () => {
    // translations
    const intl = useIntl();

    // /Projects/{projectID}/ProjectReports/{entityID}/Partial

    /*
    "projectReportPeople": [
        {
        "projectReportPersonID": 5, // for create
          "personID": 2,
          "functionID": 2,
          "organizationUnitID": null,
          "typeCode": "owner"
        },
        {
          "personID": 1,
          "functionID": 2,
          "organizationUnitID": 5,
          "typeCode": "owner"
        },
        {
          "personID": 1,
          "functionID": 2,
          "companyID": 5,
          "typeCode": "contractor"
        }
    ],

    owner
    contractor


*/

    const reduxProjectReportPeopleData = useSelector(selectProjectReportPeople);
    const dispatch = useDispatch();

    // Add a new row
    const handleAddRow = (typeCode: 'owner' | 'contractor') => {
        dispatch(
            createProjectReportPerson({
                projectReportPersonID: 0 - reduxProjectReportPeopleData.length,
                typeCode: typeCode,
            }),
        );
    };

    return (
        <section id="section-003" className="scroll-my-5 my-[50px]">
            <SectionHeader title={intl.formatMessage({ ...sectionTitleMessages.projectTeam })} />

            <div className="border border-gray-200 rounded p-5">
                <div className="flex justify-between mb-5">
                    <div className="font-bold text-lg">
                        <FormattedMessage {...messages.projectTeamOwner} />
                    </div>
                    <div className="italic">
                        * <FormattedMessage {...messages.projectTeamDescription} />
                    </div>
                </div>

                <table className={styleTable.table}>
                    <thead>
                        <tr className="text-left">
                            <th>
                                <FormattedMessage {...messages.function} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.firstNameAndLastName} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.organizationUnit} />
                            </th>
                            <td className="w-0"></td>
                        </tr>
                    </thead>
                    <tbody>
                        {reduxProjectReportPeopleData?.map((row, index) => {
                            if (row.typeCode !== 'owner') return null;
                            return <Row key={index} formRowData={row} />;
                        })}
                    </tbody>
                </table>
                <div className="mt-4">
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={() => handleAddRow('owner')}
                    >
                        <FormattedMessage {...messages.newRow} />
                    </Button>
                </div>
            </div>

            <div className="border border-gray-200 rounded p-5 mt-5">
                <div className="flex justify-between mb-5">
                    <div className="font-bold text-lg">
                        <FormattedMessage {...messages.projectTeamContractor} />
                    </div>
                    <div className="italic">
                        * <FormattedMessage {...messages.projectTeamContractorDescription} />
                    </div>
                </div>

                <table className={styleTable.table}>
                    <thead>
                        <tr className="text-left">
                            <th>
                                <FormattedMessage {...messages.function} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.firstNameAndLastName} />
                            </th>
                            <th>
                                <FormattedMessage {...messages.company} />
                            </th>
                            <td className="w-0"></td>
                        </tr>
                    </thead>
                    <tbody>
                        {reduxProjectReportPeopleData?.map((row, index) => {
                            if (row.typeCode !== 'contractor') return null;
                            return <Row key={index} formRowData={row} />;
                        })}
                    </tbody>
                </table>

                <div className="mt-4">
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={() => handleAddRow('contractor')}
                    >
                        <FormattedMessage {...messages.newRow} />
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default ProjectTeam;
