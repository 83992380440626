import { defineMessages } from 'react-intl';

export default defineMessages({
    forceTooltip: {
        id: 'components.projectInformation.validationErrorsSummary.forceTooltip',
        defaultMessage:
            'Will ignore warnings during validation and force the report to be generated', // DE:
    },
    forceButton: {
        id: 'components.projectInformation.validationErrorsSummary.forceTooltip',
        defaultMessage: 'Force', // DE:
    },
});
