import { Autocomplete, Button, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import { SelectChangeEvent } from '@mui/material/Select';
import clsx from 'clsx';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    CompanyDetailResponse,
    CompanyRequest,
    OrganizationUnitBaseResponse,
    PersonBaseResponse,
} from '../../generate/api';
import { companiesApi } from '../../packages/Api/data/companies/client';
import {
    selectCompanyModal,
    selectCompanyTypes,
    selectCountries,
    selectPeople,
    setCompanyModal,
    setPersonModal,
} from '../../redux/projectInformation/projectInformationSlice';
import { store } from '../../redux/store';
import { generateNegativeID } from '../../utils/negativeID';
import { fetchAndStoreCompanyList, loadCompanyDetailData } from '../ProjectInformation/apiActions';
import ButtonDelete from '../ProjectInformation/components/ButtonDelete/ButtonDelete';
import tableStyles from '../ProjectInformation/styles/table.module.css';
import messages from './messages';
import style from './style.module.css';

export interface IOrganizationUnitRowProps {
    organizationUnit: OrganizationUnitBaseResponse;
    onChange: (organizationUnit: OrganizationUnitBaseResponse) => void;
    onDelete: (organizationUnit: OrganizationUnitBaseResponse) => void;
}

export interface IPersonRowProps {
    person: PersonBaseResponse;
}

interface CompanyFormErrors {
    name?: string;
    email?: string;
    countryID?: string;
}

const prepareCompanyRequest = (
    company?: CompanyDetailResponse | CompanyRequest,
): CompanyRequest => {
    return {
        ...company,
        people: (company?.people ?? []).map(person => person.personID as number),
    };
};

/**
 * Organization unit row
 */
const OrganizationUnitRow: React.FC<IOrganizationUnitRowProps> = ({
    organizationUnit,
    onChange,
    onDelete,
}) => {
    const [row, setRow] = useState<OrganizationUnitBaseResponse>();

    useEffect(() => {
        setRow(organizationUnit);
    }, [organizationUnit]);

    const handleFieldChange = e => {
        setRow({ ...row, name: e.target.value });
        onChange({ ...organizationUnit, name: e.target.value });
    };

    const handleDelete = () => {
        onDelete(organizationUnit);
    };

    return (
        <tr>
            <td>
                <TextField
                    value={row?.name || ''}
                    fullWidth
                    size="small"
                    margin="none"
                    onChange={handleFieldChange}
                />
            </td>
            <td>
                <ButtonDelete onClick={handleDelete} />
            </td>
        </tr>
    );
};

const CompanyForm: React.FC = ({}) => {
    /**
     * TODO
     *  [X] Add validation
     *  [ ] Add error messages
     *  [ ] Add loading
     *  [ ] Add error handling
     */

    const intl = useIntl();

    // Modal
    const modal = useSelector(selectCompanyModal);

    // Company ID
    const companyId = modal.companyID;

    // Countries
    const countries = useSelector(selectCountries);

    // People
    const people = useSelector(selectPeople);

    // Company types
    const companyTypes = useSelector(selectCompanyTypes);

    // Company data fetched from API
    const [companyData, setCompanyData] = useState<CompanyDetailResponse>();

    const formInitEmptyData: CompanyDetailResponse = {
        name: '',
        email: '',
        hubID: 1, // todo ?
        address: {
            line1: '', // todo - why required?
        },
    };

    // Fetch company detail data
    useEffect(() => {
        if (!companyId) {
            setCompanyData(formInitEmptyData);
            return;
        }
        companiesApi.getCompanyDetails(companyId).then(response => {
            setCompanyData(response.data);
            console.log('Company detail', response.data);
        });
    }, [companyId]);

    // Current company type
    const currentCompanyType = companyTypes.find(
        companyType => companyType.companyTypeID === companyData?.companyTypeID,
    );

    // Company type translated
    let currentCompanyTypeTranslated = '';
    if (currentCompanyType?.code === 'owner') {
        currentCompanyTypeTranslated = intl.formatMessage(messages.owner);
    } else if (currentCompanyType?.code === 'contractor') {
        currentCompanyTypeTranslated = intl.formatMessage(messages.contractor);
    }

    /**
     * -------------------------------------------
     * Form
     * -------------------------------------------
     */

    // Form is changed
    const [changed, setChanged] = useState(false);

    // Form initial data
    const [formInitialData, setFormInitialData] = useState<CompanyDetailResponse>();

    // Form data that is displayed in the form
    const [formData, setFormData] = useState<CompanyDetailResponse>();

    // compare form data with initial data and set changed flag
    useEffect(() => {
        if (JSON.stringify(formData) !== JSON.stringify(formInitialData)) {
            setChanged(true);
        } else {
            setChanged(false);
        }
    }, [formData]);

    // Set form data when company data changes
    useEffect(() => {
        setFormData(companyData);
        setFormInitialData(companyData);
    }, [companyData]);

    type FieldEvent = ChangeEvent<any> | SelectChangeEvent<any>;

    // Update form data when input changes
    // const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const handleInputChange = (e: FieldEvent) => {
        console.log('handleInputChange', e.target.name, e.target.value);
        const { name, value } = e.target;
        const [fieldName, nestedFieldName] = name.split('.');
        if (nestedFieldName) {
            setFormData(prevData => ({
                ...prevData,
                [fieldName]: {
                    ...(prevData?.[fieldName] || {}),
                    [nestedFieldName]: value,
                },
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                [fieldName]: value,
            }));
        }
    };

    // todo Debug
    useEffect(() => {
        console.log('formData', formData);
    }, [formData]);

    // Close modal
    const closeModal = () => {
        store.dispatch(setCompanyModal({ isOpen: false, companyID: undefined }));
    };
    const handleClickClose = () => {
        closeModal();
    };

    /**
     * Update company
     */
    const handleUpdate = () => {
        if (!companyId) return;
        if (!validateForm()) return;

        // In formData.organizationUnits remove parameter organizationUnitID if negative
        const updatedFormData = {
            ...formData,

            // remove parameter organizationUnitID if negative
            organizationUnits: formData?.organizationUnits?.map(item => {
                if (item.organizationUnitID !== undefined && item.organizationUnitID < 0) {
                    return { name: item.name };
                } else {
                    return item;
                }
            }),
        };

        companiesApi
            .updateCompany(companyId, prepareCompanyRequest(updatedFormData) as CompanyRequest)
            .then(response => {
                console.log(response.data);
                setChanged(false);
                setFormInitialData(response.data);
                setFormData(response.data);
                // todo notify
                loadCompanyDetailData(companyId).then(() => {
                    console.log('Company detail data updated');
                });
            });
    };

    /**
     * Create company
     */
    const handleCreate = () => {
        if (!validateForm()) return;

        const updatedFormData = {
            ...formData,
            companyTypeID: companyTypes?.find(c => c.code === 'owner')?.companyTypeID || undefined, // Define company type
        };

        companiesApi
            .addCompany(prepareCompanyRequest(updatedFormData) as CompanyRequest)
            .then(response => {
                console.log(response.data);
                fetchAndStoreCompanyList().then(r => {
                    console.log('Company list updated', r);
                });
                setFormInitialData(response.data);
                setFormData(response.data);
                store.dispatch(
                    setCompanyModal({ isOpen: true, companyID: response.data.companyID }),
                );
                // todo notify
            });
    };

    /**
     * -------------------------------------------
     * Organization units
     * -------------------------------------------
     */
    const [organizationUnits, setOrganizationUnits] = useState<OrganizationUnitBaseResponse[]>([]);
    useEffect(() => {
        setOrganizationUnits(companyData?.organizationUnits || []);
    }, [companyData]);

    useEffect(() => {
        setFormData(prevData => ({
            ...prevData,
            organizationUnits: organizationUnits,
        }));
    }, [organizationUnits]);

    const handleOrganizationUnitChange = (organizationUnit: OrganizationUnitBaseResponse) => {
        // update organization units
        setOrganizationUnits(prevOrganizationUnits => {
            const index = prevOrganizationUnits.findIndex(
                ou => ou.organizationUnitID === organizationUnit.organizationUnitID,
            );
            if (index !== -1) {
                return [
                    ...prevOrganizationUnits.slice(0, index),
                    organizationUnit,
                    ...prevOrganizationUnits.slice(index + 1),
                ];
            } else {
                return prevOrganizationUnits;
            }
        });
    };

    const handleOrganizationUnitDelete = organizationUnit => {
        // delete organization unit from the list
        setOrganizationUnits(prevOrganizationUnits => [
            ...prevOrganizationUnits.filter(
                ou => ou.organizationUnitID !== organizationUnit.organizationUnitID,
            ),
        ]);
    };

    const handleOrganizationUnitAdd = () => {
        if (!companyId) return;

        // add new organization unit to the list, organizationUintID is set to unique negative value
        const newOrganizationUnit: OrganizationUnitBaseResponse = {
            organizationUnitID: generateNegativeID(organizationUnits, 'organizationUnitID'),
            name: '',
        };
        setOrganizationUnits(prevOrganizationUnits => [
            ...prevOrganizationUnits,
            newOrganizationUnit,
        ]);
    };

    /**
     * -------------------------------------------
     * Person row
     * -------------------------------------------
     */
    const PersonRow: React.FC<IPersonRowProps> = ({ person }) => {
        const handleDelete = () => {
            setFormData({
                ...formData,
                people: formData?.people?.filter(p => p.personID !== person.personID),
            });
        };
        return (
            <tr>
                <td>{person.email}</td>
                <td>{person.firstName}</td>
                <td>{person.lastName}</td>
                <td>
                    <ButtonDelete onClick={handleDelete} />
                </td>
            </tr>
        );
    };

    /**
     * Available people for selection
     */
    const availablePeople = people.filter(
        person => !formData?.people?.find(p => p.personID === person.personID),
    );

    /**
     * Add person
     */
    const [selectedPerson, setSelectedPerson] = useState<PersonBaseResponse>();
    const handleAddPerson = () => {
        if (!selectedPerson) return; // no person selected
        if (formData?.people?.find(p => p.personID === selectedPerson.personID)) return; // already added
        setFormData({
            ...formData,
            people: [...(formData?.people || []), selectedPerson],
        });
    };

    /**
     * Create new person
     */
    const handleCreateNewPerson = () => {
        store.dispatch(setPersonModal({ isOpen: true, personID: undefined }));
    };

    const initialErrorFormData: CompanyFormErrors = {
        name: '',
        email: '',
        countryID: '',
    };

    /**
     * Errors for the form
     */
    const [errors, setErrors] = useState<CompanyFormErrors>({
        ...initialErrorFormData,
    });

    /**
     * Validates the form and returns true if there are no errors
     */
    const validateForm = () => {
        if (!formData) return false;

        const { name, email, address } = formData;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const newErrors: CompanyFormErrors = { ...initialErrorFormData };

        if (!email?.trim()) {
            newErrors.email = intl.formatMessage(messages.errorEmailRequired);
        } else if (!emailRegex.test(email)) {
            newErrors.email = intl.formatMessage(messages.errorEmailInvalid);
        }

        if (!name?.trim()) {
            newErrors.name = intl.formatMessage(messages.errorNameRequired);
        }

        if (!address?.countryID) {
            newErrors.countryID = intl.formatMessage(messages.errorCountryRequired);
        }

        setErrors(newErrors);

        // Return true if there are no errors, indicating the form is valid
        return Object.values(newErrors).every(error => !error);
    };

    // if modal not open
    if (!modal.isOpen) return null;

    return (
        <Modal open>
            <div className={style.modalOuter}>
                <div className={style.modal}>
                    <div className="w-full">
                        <div className="flex justify-between items-center mb-5">
                            <h1 className="text-4xl mt-0">{formData?.name || ''}</h1>
                            <div className="text-3xl text-neutral-400 mt-0 uppercase">
                                {currentCompanyTypeTranslated}
                            </div>
                        </div>
                        <div className="flex gap-10">
                            <div className="flex-1">
                                <div className="border border-gray-200 rounded p-5">
                                    <h2 className="text-xl mt-0">
                                        <FormattedMessage {...messages.companyDetails} />
                                    </h2>

                                    <label className="block mb-3">
                                        <div className="font-bold">
                                            <FormattedMessage {...messages.name} />
                                        </div>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            name={'name'}
                                            value={formData?.name || ''}
                                            onChange={handleInputChange}
                                            error={!!errors.name}
                                            helperText={errors.name}
                                        />
                                    </label>

                                    <label className="block mb-3">
                                        <div className="font-bold">
                                            <FormattedMessage {...messages.description} />
                                        </div>
                                        <TextField
                                            size="small"
                                            multiline
                                            maxRows={6}
                                            fullWidth
                                            value={formData?.description || ''}
                                            name={'description'}
                                            onChange={handleInputChange}
                                        />
                                    </label>

                                    <label className="block mb-3">
                                        <div className="font-bold">
                                            <FormattedMessage {...messages.email} />
                                        </div>
                                        <TextField
                                            size="small"
                                            type="email"
                                            fullWidth
                                            value={formData?.email || ''}
                                            name={'email'}
                                            onChange={handleInputChange}
                                            error={!!errors.email}
                                            helperText={errors.email}
                                        />
                                    </label>

                                    <label className="block">
                                        <div className="font-bold">
                                            <FormattedMessage {...messages.website} />
                                        </div>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            value={formData?.website || ''}
                                            name={'website'}
                                            onChange={handleInputChange}
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className="border border-gray-200 rounded p-5">
                                    <h2 className="text-xl mt-0">
                                        <FormattedMessage {...messages.address} />
                                    </h2>
                                    <label className="block mb-3">
                                        <div className="font-bold">
                                            <FormattedMessage {...messages.line1} />
                                        </div>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            value={formData?.address?.line1 || ''}
                                            name={'address.line1'}
                                            onChange={handleInputChange}
                                        />
                                    </label>
                                    <label className="block mb-3">
                                        <div className="font-bold">
                                            <FormattedMessage {...messages.line2} />
                                        </div>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            value={formData?.address?.line2 || ''}
                                            name={'address.line2'}
                                            onChange={handleInputChange}
                                        />
                                    </label>
                                    <label className="block mb-3">
                                        <div className="font-bold">
                                            <FormattedMessage {...messages.city} />
                                        </div>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            value={formData?.address?.city || ''}
                                            name={'address.city'}
                                            onChange={handleInputChange}
                                        />
                                    </label>
                                    <div className="flex gap-4 w-full">
                                        <label className="block flex-1">
                                            <div className="font-bold">
                                                <FormattedMessage {...messages.zipCode} />
                                            </div>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                value={formData?.address?.zipCode || ''}
                                                name={'address.zipCode'}
                                                onChange={handleInputChange}
                                            />
                                        </label>
                                        <label className="block flex-1">
                                            <div className="font-bold">
                                                <FormattedMessage {...messages.country} />
                                            </div>
                                            <Autocomplete
                                                options={countries}
                                                getOptionLabel={country => country.name || ''}
                                                value={
                                                    countries.find(
                                                        country =>
                                                            country.countryID ===
                                                            formData?.address?.countryID,
                                                    ) || null
                                                }
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        handleInputChange({
                                                            target: {
                                                                name: 'address.countryID',
                                                                value: newValue.countryID,
                                                            },
                                                        } as FieldEvent);
                                                    } else {
                                                        handleInputChange({
                                                            target: {
                                                                name: 'address.countryID',
                                                                value: null,
                                                            },
                                                        } as FieldEvent);
                                                    }
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        margin="dense"
                                                        size="small"
                                                        fullWidth
                                                        error={!!errors.countryID}
                                                        helperText={errors.countryID}
                                                    />
                                                )}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {companyId && (
                            <>
                                <div className="mt-10">
                                    <h2 className="text-xl">
                                        <FormattedMessage {...messages.organizationUnits} />
                                    </h2>
                                    <div className={tableStyles.tableOuterBorder}>
                                        <table
                                            className={clsx(
                                                tableStyles.table,
                                                tableStyles.tableBordered,
                                            )}
                                        >
                                            <thead>
                                                <tr className="text-left">
                                                    <th className="w-full">
                                                        <FormattedMessage {...messages.name} />
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/*{formData?.organizationUnits?.map(*/}
                                                {organizationUnits?.map(
                                                    (organizationUnit, index) => (
                                                        <OrganizationUnitRow
                                                            organizationUnit={organizationUnit}
                                                            key={index}
                                                            onChange={handleOrganizationUnitChange}
                                                            onDelete={handleOrganizationUnitDelete}
                                                        />
                                                    ),
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="mt-2">
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            onClick={handleOrganizationUnitAdd}
                                        >
                                            <FormattedMessage {...messages.buttonAdd} />
                                        </Button>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <h2 className="text-xl">
                                        <FormattedMessage {...messages.people} />
                                    </h2>
                                    <div className={tableStyles.tableOuterBorder}>
                                        <table
                                            className={clsx(
                                                tableStyles.table,
                                                tableStyles.tableBordered,
                                            )}
                                        >
                                            <thead>
                                                <tr className="text-left">
                                                    <th>
                                                        <FormattedMessage {...messages.email} />
                                                    </th>
                                                    <th>
                                                        <FormattedMessage {...messages.firstName} />
                                                    </th>
                                                    <th>
                                                        <FormattedMessage {...messages.lastName} />
                                                    </th>
                                                    <th className="w-0"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formData?.people?.map((person, index) => (
                                                    <PersonRow person={person} key={index} />
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="flex gap-2 mt-2">
                                        <Autocomplete
                                            options={availablePeople}
                                            className="flex-1"
                                            getOptionLabel={person =>
                                                `${person.email || ''} - ${person.firstName} ${
                                                    person.lastName
                                                }` || ''
                                            }
                                            onChange={(event, newValue) => {
                                                setSelectedPerson(newValue || undefined);
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    margin="none"
                                                    size="small"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            color="primary"
                                            onClick={handleAddPerson}
                                        >
                                            <FormattedMessage {...messages.buttonAdd} />
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            color="primary"
                                            onClick={handleCreateNewPerson}
                                        >
                                            <FormattedMessage {...messages.buttonNew} />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="flex gap-2 justify-end mt-10">
                            {companyId ? (
                                <>
                                    <Button
                                        variant="outlined"
                                        size="medium"
                                        color="primary"
                                        onClick={handleClickClose}
                                    >
                                        {changed ? (
                                            <FormattedMessage {...messages.buttonCancel} />
                                        ) : (
                                            <FormattedMessage {...messages.buttonClose} />
                                        )}
                                    </Button>

                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="primary"
                                        onClick={handleUpdate}
                                        disabled={!changed}
                                    >
                                        <FormattedMessage {...messages.buttonUpdate} />
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        variant="outlined"
                                        size="medium"
                                        color="primary"
                                        onClick={handleClickClose}
                                    >
                                        {changed ? (
                                            <FormattedMessage {...messages.buttonCancel} />
                                        ) : (
                                            <FormattedMessage {...messages.buttonClose} />
                                        )}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="primary"
                                        disabled={!changed}
                                        onClick={handleCreate}
                                    >
                                        <FormattedMessage {...messages.buttonCreateNewCompany} />
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CompanyForm;
