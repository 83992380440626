/* tslint:disable */
/* eslint-disable */
/**
 * Armasuisse Dashboard Web Application API
 * API definitions for Armasuisse Dashboard Web Application
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { StatusBase } from '../models';
// @ts-ignore
import { StatusBaseResponse } from '../models';
// @ts-ignore
import { StatusSetBase } from '../models';
// @ts-ignore
import { StatusSetBaseResponse } from '../models';
/**
 * StatusesApi - axios parameter creator
 * @export
 */
export const StatusesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new Status Set and returns newly created Status Set.
         * @summary Creates new Status Set.
         * @param {StatusSetBase} [statusSetBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStatusSet: async (statusSetBase?: StatusSetBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/StatusSets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusSetBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new Statuse and returns newly created Statuse.
         * @summary Creates new Statuse.
         * @param {StatusBase} [statusBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStatuse: async (statusBase?: StatusBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Status Set.
         * @summary Delete Status Set.
         * @param {number} entityID Status Set identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStatusSet: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteStatusSet', 'entityID', entityID)
            const localVarPath = `/StatusSets/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Statuse.
         * @summary Delete Statuse.
         * @param {number} entityID Statuse identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStatuse: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteStatuse', 'entityID', entityID)
            const localVarPath = `/Statuses/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Status Set.
         * @summary Get Status Set details.
         * @param {number} entityID Status Set identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusSetDetails: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getStatusSetDetails', 'entityID', entityID)
            const localVarPath = `/StatusSets/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Status Sets.
         * @summary Get list of Status Sets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusSetList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/StatusSets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Statuse.
         * @summary Get Statuse details.
         * @param {number} entityID Statuse identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatuseDetails: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getStatuseDetails', 'entityID', entityID)
            const localVarPath = `/Statuses/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Statuses.
         * @summary Get list of Statuses.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatuseList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Status Set and returns the modified Status Set.
         * @summary Edit Status Set.
         * @param {number} entityID Status Set identifier
         * @param {StatusSetBase} [statusSetBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusSet: async (entityID: number, statusSetBase?: StatusSetBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateStatusSet', 'entityID', entityID)
            const localVarPath = `/StatusSets/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusSetBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Statuse and returns the modified Statuse.
         * @summary Edit Statuse.
         * @param {number} entityID Statuse identifier
         * @param {StatusBase} [statusBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatuse: async (entityID: number, statusBase?: StatusBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateStatuse', 'entityID', entityID)
            const localVarPath = `/Statuses/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statusBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusesApi - functional programming interface
 * @export
 */
export const StatusesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusesApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new Status Set and returns newly created Status Set.
         * @summary Creates new Status Set.
         * @param {StatusSetBase} [statusSetBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStatusSet(statusSetBase?: StatusSetBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusSetBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStatusSet(statusSetBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates new Statuse and returns newly created Statuse.
         * @summary Creates new Statuse.
         * @param {StatusBase} [statusBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStatuse(statusBase?: StatusBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStatuse(statusBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Status Set.
         * @summary Delete Status Set.
         * @param {number} entityID Status Set identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStatusSet(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStatusSet(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Statuse.
         * @summary Delete Statuse.
         * @param {number} entityID Statuse identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStatuse(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStatuse(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Status Set.
         * @summary Get Status Set details.
         * @param {number} entityID Status Set identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusSetDetails(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusSetBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatusSetDetails(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Status Sets.
         * @summary Get list of Status Sets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusSetList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatusSetBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatusSetList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Statuse.
         * @summary Get Statuse details.
         * @param {number} entityID Statuse identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatuseDetails(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatuseDetails(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Statuses.
         * @summary Get list of Statuses.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatuseList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatusBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatuseList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Status Set and returns the modified Status Set.
         * @summary Edit Status Set.
         * @param {number} entityID Status Set identifier
         * @param {StatusSetBase} [statusSetBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStatusSet(entityID: number, statusSetBase?: StatusSetBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusSetBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStatusSet(entityID, statusSetBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Statuse and returns the modified Statuse.
         * @summary Edit Statuse.
         * @param {number} entityID Statuse identifier
         * @param {StatusBase} [statusBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStatuse(entityID: number, statusBase?: StatusBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusBaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStatuse(entityID, statusBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatusesApi - factory interface
 * @export
 */
export const StatusesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusesApiFp(configuration)
    return {
        /**
         * Creates new Status Set and returns newly created Status Set.
         * @summary Creates new Status Set.
         * @param {StatusSetBase} [statusSetBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStatusSet(statusSetBase?: StatusSetBase, options?: any): AxiosPromise<StatusSetBaseResponse> {
            return localVarFp.addStatusSet(statusSetBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates new Statuse and returns newly created Statuse.
         * @summary Creates new Statuse.
         * @param {StatusBase} [statusBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStatuse(statusBase?: StatusBase, options?: any): AxiosPromise<StatusBaseResponse> {
            return localVarFp.addStatuse(statusBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Status Set.
         * @summary Delete Status Set.
         * @param {number} entityID Status Set identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStatusSet(entityID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteStatusSet(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Statuse.
         * @summary Delete Statuse.
         * @param {number} entityID Statuse identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStatuse(entityID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteStatuse(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Status Set.
         * @summary Get Status Set details.
         * @param {number} entityID Status Set identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusSetDetails(entityID: number, options?: any): AxiosPromise<StatusSetBaseResponse> {
            return localVarFp.getStatusSetDetails(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Status Sets.
         * @summary Get list of Status Sets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusSetList(options?: any): AxiosPromise<Array<StatusSetBaseResponse>> {
            return localVarFp.getStatusSetList(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Statuse.
         * @summary Get Statuse details.
         * @param {number} entityID Statuse identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatuseDetails(entityID: number, options?: any): AxiosPromise<StatusBaseResponse> {
            return localVarFp.getStatuseDetails(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Statuses.
         * @summary Get list of Statuses.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatuseList(options?: any): AxiosPromise<Array<StatusBaseResponse>> {
            return localVarFp.getStatuseList(options).then((request) => request(axios, basePath));
        },
        /**
         * Update Status Set and returns the modified Status Set.
         * @summary Edit Status Set.
         * @param {number} entityID Status Set identifier
         * @param {StatusSetBase} [statusSetBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatusSet(entityID: number, statusSetBase?: StatusSetBase, options?: any): AxiosPromise<StatusSetBaseResponse> {
            return localVarFp.updateStatusSet(entityID, statusSetBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Statuse and returns the modified Statuse.
         * @summary Edit Statuse.
         * @param {number} entityID Statuse identifier
         * @param {StatusBase} [statusBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatuse(entityID: number, statusBase?: StatusBase, options?: any): AxiosPromise<StatusBaseResponse> {
            return localVarFp.updateStatuse(entityID, statusBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusesApi - object-oriented interface
 * @export
 * @class StatusesApi
 * @extends {BaseAPI}
 */
export class StatusesApi extends BaseAPI {
    /**
     * Creates new Status Set and returns newly created Status Set.
     * @summary Creates new Status Set.
     * @param {StatusSetBase} [statusSetBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusesApi
     */
    public addStatusSet(statusSetBase?: StatusSetBase, options?: AxiosRequestConfig) {
        return StatusesApiFp(this.configuration).addStatusSet(statusSetBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates new Statuse and returns newly created Statuse.
     * @summary Creates new Statuse.
     * @param {StatusBase} [statusBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusesApi
     */
    public addStatuse(statusBase?: StatusBase, options?: AxiosRequestConfig) {
        return StatusesApiFp(this.configuration).addStatuse(statusBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Status Set.
     * @summary Delete Status Set.
     * @param {number} entityID Status Set identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusesApi
     */
    public deleteStatusSet(entityID: number, options?: AxiosRequestConfig) {
        return StatusesApiFp(this.configuration).deleteStatusSet(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Statuse.
     * @summary Delete Statuse.
     * @param {number} entityID Statuse identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusesApi
     */
    public deleteStatuse(entityID: number, options?: AxiosRequestConfig) {
        return StatusesApiFp(this.configuration).deleteStatuse(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Status Set.
     * @summary Get Status Set details.
     * @param {number} entityID Status Set identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusesApi
     */
    public getStatusSetDetails(entityID: number, options?: AxiosRequestConfig) {
        return StatusesApiFp(this.configuration).getStatusSetDetails(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Status Sets.
     * @summary Get list of Status Sets.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusesApi
     */
    public getStatusSetList(options?: AxiosRequestConfig) {
        return StatusesApiFp(this.configuration).getStatusSetList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Statuse.
     * @summary Get Statuse details.
     * @param {number} entityID Statuse identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusesApi
     */
    public getStatuseDetails(entityID: number, options?: AxiosRequestConfig) {
        return StatusesApiFp(this.configuration).getStatuseDetails(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Statuses.
     * @summary Get list of Statuses.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusesApi
     */
    public getStatuseList(options?: AxiosRequestConfig) {
        return StatusesApiFp(this.configuration).getStatuseList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Status Set and returns the modified Status Set.
     * @summary Edit Status Set.
     * @param {number} entityID Status Set identifier
     * @param {StatusSetBase} [statusSetBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusesApi
     */
    public updateStatusSet(entityID: number, statusSetBase?: StatusSetBase, options?: AxiosRequestConfig) {
        return StatusesApiFp(this.configuration).updateStatusSet(entityID, statusSetBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Statuse and returns the modified Statuse.
     * @summary Edit Statuse.
     * @param {number} entityID Statuse identifier
     * @param {StatusBase} [statusBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusesApi
     */
    public updateStatuse(entityID: number, statusBase?: StatusBase, options?: AxiosRequestConfig) {
        return StatusesApiFp(this.configuration).updateStatuse(entityID, statusBase, options).then((request) => request(this.axios, this.basePath));
    }
}
