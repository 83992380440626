import React, { ReactNode } from 'react';

export type IIcon =
    | 'attachments'
    | 'arrow-alt-to-bottom'
    | 'arrow-from-left'
    | 'batch-update'
    | 'blade'
    | 'blade-solid'
    | 'calendar'
    | 'calendar-solid'
    | 'camera'
    | 'comments'
    | 'chart-pie'
    | 'chart-pie-solid'
    | 'chart'
    | 'chart-solid'
    | 'check'
    | 'check-solid'
    | 'chevron-left'
    | 'chevron-left-solid'
    | 'chevron-left-circle'
    | 'chevron-right-circle'
    | 'chevron-up'
    | 'chevron-up-circle'
    | 'chevron-down-circle'
    | 'chevron-light-down'
    | 'chevron-light-left'
    | 'chevron-light-right'
    | 'chevron-light-up'
    | 'clock'
    | 'clock-solid'
    | 'close'
    | 'close-solid'
    | 'clone'
    | 'cloud-download'
    | 'cloud-upload-alt'
    | 'cog'
    | 'cog-solid'
    | 'comment'
    | 'comment-solid'
    | 'comment-edit'
    | 'construction'
    | 'construction-solid'
    | 'copy'
    | 'cube'
    | 'cube-solid'
    | 'dollar'
    | 'dollar-solid'
    | 'dashboard-platform'
    | 'dashboard-money-sheet'
    | 'dashboard-platform-split'
    | 'dashboard-gantt'
    | 'ellipsis-h'
    | 'ellipsis-h-alt'
    | 'ellipsis-v'
    | 'ellipsis-v-alt'
    | 'exclamation-triangle'
    | 'eye'
    | 'eye-solid'
    | 'file'
    | 'file-solid'
    | 'file-image'
    | 'file-pdf'
    | 'file-pdf-solid'
    | 'file-upload'
    | 'file-x'
    | 'file-x-solid'
    | 'folder'
    | 'folder-open'
    | 'folder-plus'
    | 'filter'
    | 'gaeb'
    | 'gaeb-solid'
    | 'gantt'
    | 'grip-horizontal'
    | 'grip-lines'
    | 'hand'
    | 'hand-solid'
    | 'history'
    | 'info'
    | 'info-solid'
    | 'issues'
    | 'linked-document'
    | 'list'
    | 'list-solid'
    | 'list-border'
    | 'list-border-solid'
    | 'lock'
    | 'map'
    | 'maze'
    | 'maze-solid'
    | 'no-way'
    | 'no-way-solid'
    | 'not-found'
    | 'pencil-solid'
    | 'people'
    | 'people-solid'
    | 'person'
    | 'person-solid'
    | 'plot'
    | 'plus-circle'
    | 'plus-circle-solid'
    | 'print'
    | 'private'
    | 'public'
    | 'qr-code'
    | 'rfi'
    | 'ruler'
    | 'ruler-solid'
    | 'share-alt'
    | 'sign-out-alt'
    | 'settings'
    | 'splitview-2d'
    | 'splitview-3d'
    | 'splitview-both'
    | 'tabs'
    | 'tabs-solid'
    | 'target'
    | 'target-solid'
    | 'times'
    | 'trash'
    | 'trash-alt'
    | 'triangle-down'
    | 'triangle-left'
    | 'triangle-right'
    | 'triangle-up'
    | 'triangle-down-solid'
    | 'triangle-left-solid'
    | 'triangle-right-solid'
    | 'triangle-up-solid'
    | 'version'
    | 'version-solid'
    | 'weight'
    | 'weight-solid'
    | 'window-restore'
    | 'window-restore-solid';

export interface IIconNode {
    width: number;
    height: number;
    viewBox: string;
    svg: ReactNode;
}
/* tslint:disable:max-line-length */
const Icons: { [iconType in IIcon]: string | number | ReactNode | IIconNode } = {
    attachments: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M21.56,2H11.78A4.87,4.87,0,0,0,6.89,6.88V41.12A4.89,4.89,0,0,0,11.79,46H36.21a4.9,4.9,0,0,0,4.9-4.9h0V11.78L31.33,2H24V28.89a4.89,4.89,0,0,1-9.78,0V11.78h2.45V28.89a2.45,2.45,0,0,0,4.89,0Z" />
            </>
        ),
    },
    'arrow-alt-to-bottom': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M42.51,45.66a1.16,1.16,0,0,1-.83.34H6.32a1.18,1.18,0,0,1-1.18-1.18V44a1.15,1.15,0,0,1,.35-.84,1.12,1.12,0,0,1,.83-.34H41.68a1.12,1.12,0,0,1,.83.34,1.15,1.15,0,0,1,.35.84v.78A1.15,1.15,0,0,1,42.51,45.66ZM31.17,3.38a4.51,4.51,0,0,1,1.37,3.33v11h6.09a3.58,3.58,0,0,1,2.75,1.18,4.16,4.16,0,0,1,1.13,2.75,3.55,3.55,0,0,1-1.13,2.75l-14,14.15a4.76,4.76,0,0,1-6.68,0l-14-14.15a3.55,3.55,0,0,1-1.13-2.75,4.11,4.11,0,0,1,1.13-2.75,3.58,3.58,0,0,1,2.75-1.18h6.09v-11a4.51,4.51,0,0,1,1.37-3.33A4.54,4.54,0,0,1,20.17,2h7.66A4.54,4.54,0,0,1,31.17,3.38Zm-11,1.76a1.61,1.61,0,0,0-1.08.44,1.46,1.46,0,0,0-.49,1.13V20.86H9.37a.69.69,0,0,0-.69.49.76.76,0,0,0,.19.88L22.92,36.28a1.44,1.44,0,0,0,2.16,0l14-14a.75.75,0,0,0,.2-.88.69.69,0,0,0-.69-.49H29.4V6.71a1.46,1.46,0,0,0-.49-1.13,1.61,1.61,0,0,0-1.08-.44Z" />
            </>
        ),
    },
    'arrow-from-left': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M2.4,42.47a1.14,1.14,0,0,1-.35-.83V6.36A1.18,1.18,0,0,1,3.23,5.19H4A1.16,1.16,0,0,1,5.19,6.36V41.64a1.17,1.17,0,0,1-.34.83,1.14,1.14,0,0,1-.84.34H3.23A1.1,1.1,0,0,1,2.4,42.47ZM30.22,8.32a1.35,1.35,0,0,0-.83.3l-.69.78a.94.94,0,0,0-.39.79,1.23,1.23,0,0,0,.39.88L40.07,22.33H9.5a1.11,1.11,0,0,0-.83.35,1.14,1.14,0,0,0-.35.83v1A1.2,1.2,0,0,0,9.5,25.67H40.07L28.7,36.93a1.24,1.24,0,0,0-.39.88.94.94,0,0,0,.39.79l.69.78a1.35,1.35,0,0,0,.83.3,1.13,1.13,0,0,0,.83-.4l14.6-14.4A1.4,1.4,0,0,0,46,24a1.42,1.42,0,0,0-.3-.88L31.05,8.62A1.32,1.32,0,0,0,30.22,8.32Z" />
            </>
        ),
    },
    'batch-update': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M42,25.5,39.52,28v9H11.71L4.46,29.7V24.78l7.25-7.25h13.9l2.46-2.46H11.71a2.47,2.47,0,0,0-1.74.72L2.72,23A2.49,2.49,0,0,0,2,24.78V29.7a2.49,2.49,0,0,0,.72,1.74L10,38.69a2.47,2.47,0,0,0,1.74.72H39.52A2.46,2.46,0,0,0,42,37ZM15,24.63a3.69,3.69,0,0,0-5.22,5.22h0A3.69,3.69,0,0,0,15,24.63ZM13.3,26.37a1.23,1.23,0,0,1-1.71,1.77l0,0a1.23,1.23,0,0,1,1.74-1.74Zm24.45-7.95,5-5-1.68-1.68-4.92,5.06-4.92,4.92-4.92,4.92v1.58h1.58l4.92-4.92ZM23.88,26,41.26,8.59,46,13.33,28.62,30.72H23.88Z" />
            </>
        ),
    },
    blade: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M41.82,22.28a2.44,2.44,0,0,0,1.72,4.16H46V35a3.64,3.64,0,0,1-3.66,3.66H5.68a3.52,3.52,0,0,1-2.59-1.06A3.52,3.52,0,0,1,2,35V26.44H4.46a2.37,2.37,0,0,0,1.72-.72,2.41,2.41,0,0,0,0-3.44,2.37,2.37,0,0,0-1.72-.72H2V13a3.52,3.52,0,0,1,1.07-2.6A3.52,3.52,0,0,1,5.68,9.35H42.32A3.64,3.64,0,0,1,46,13v8.55H43.54A2.36,2.36,0,0,0,41.82,22.28ZM40.1,27.43a4.88,4.88,0,0,1,3.44-8.31V13a1.22,1.22,0,0,0-.34-.88,1.2,1.2,0,0,0-.88-.34H5.68a1.2,1.2,0,0,0-.88.34,1.22,1.22,0,0,0-.34.88v6.11A4.7,4.7,0,0,1,7.9,20.57a4.78,4.78,0,0,1,0,6.86,4.7,4.7,0,0,1-3.44,1.45V35a1.22,1.22,0,0,0,.34.88,1.2,1.2,0,0,0,.88.34H42.32a1.2,1.2,0,0,0,.88-.34,1.22,1.22,0,0,0,.34-.88V28.88A4.69,4.69,0,0,1,40.1,27.43ZM35.68,17.21a1.79,1.79,0,0,1,.53,1.29v11a1.83,1.83,0,0,1-1.83,1.83H13.62a1.83,1.83,0,0,1-1.83-1.83v-11a1.79,1.79,0,0,1,.53-1.29,1.77,1.77,0,0,1,1.3-.54H34.38A1.77,1.77,0,0,1,35.68,17.21Zm-1.91,1.91H14.23v9.76H33.77Z" />
            </>
        ),
    },
    'blade-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M43.37,26.59A3.52,3.52,0,0,0,46,27.66V35a3.65,3.65,0,0,1-3.66,3.66H5.7a3.54,3.54,0,0,1-2.59-1.07A3.52,3.52,0,0,1,2,35V27.66a3.52,3.52,0,0,0,2.59-1.07,3.67,3.67,0,0,0,0-5.18A3.52,3.52,0,0,0,2,20.34V13a3.52,3.52,0,0,1,1.07-2.59A3.54,3.54,0,0,1,5.7,9.36H42.3A3.65,3.65,0,0,1,46,13v7.32a3.52,3.52,0,0,0-2.59,1.07,3.67,3.67,0,0,0,0,5.18ZM38.11,14.77a1.8,1.8,0,0,0-1.3-.53H11.19a1.81,1.81,0,0,0-1.83,1.83V31.93a1.81,1.81,0,0,0,1.83,1.83H36.81a1.81,1.81,0,0,0,1.83-1.83V16.07A1.8,1.8,0,0,0,38.11,14.77ZM36.2,16.68V31.32H11.8V16.68Z" />
            </>
        ),
    },
    calendar: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M42.05,8.7a4,4,0,0,1,1.2,2.92V41.88A4.1,4.1,0,0,1,39.12,46H8.88a4.1,4.1,0,0,1-4.13-4.12V11.62A4.1,4.1,0,0,1,8.88,7.5H13V3a1,1,0,0,1,.3-.73A1,1,0,0,1,14,2h.69a1,1,0,0,1,1,1V7.5h16.5V3a1,1,0,0,1,.3-.73,1,1,0,0,1,.73-.3H34a1,1,0,0,1,1,1V7.5h4.12A4,4,0,0,1,42.05,8.7ZM7.89,10.64a1.3,1.3,0,0,0-.39,1v4.13h33V11.62a1.33,1.33,0,0,0-1.38-1.37H8.88A1.31,1.31,0,0,0,7.89,10.64ZM40.11,42.86a1.3,1.3,0,0,0,.39-1V18.5H7.5V41.88a1.33,1.33,0,0,0,1.38,1.37H39.12A1.31,1.31,0,0,0,40.11,42.86ZM18.2,29.2a1,1,0,0,0,.3-.73V25a1,1,0,0,0-1-1H14a1,1,0,0,0-.73.3A1,1,0,0,0,13,25v3.44a1,1,0,0,0,1,1h3.44A1,1,0,0,0,18.2,29.2Zm0,8.25a1,1,0,0,0,.3-.73V33.28a1,1,0,0,0-1-1H14a1,1,0,0,0-.73.3,1,1,0,0,0-.3.73v3.44a1,1,0,0,0,1,1h3.44A1,1,0,0,0,18.2,37.45Zm8.25-8.25a1,1,0,0,0,.3-.73V25a1,1,0,0,0-1-1H22.28a1,1,0,0,0-.73.3,1,1,0,0,0-.3.73v3.44a1,1,0,0,0,1,1h3.44A1,1,0,0,0,26.45,29.2Zm0,8.25a1,1,0,0,0,.3-.73V33.28a1,1,0,0,0-1-1H22.28a1,1,0,0,0-.73.3,1,1,0,0,0-.3.73v3.44a1,1,0,0,0,1,1h3.44A1,1,0,0,0,26.45,37.45ZM34.7,29.2a1,1,0,0,0,.3-.73V25a1,1,0,0,0-1-1H30.53a1,1,0,0,0-.73.3,1,1,0,0,0-.3.73v3.44a1,1,0,0,0,1,1H34A1,1,0,0,0,34.7,29.2Zm0,8.25a1,1,0,0,0,.3-.73V33.28a1,1,0,0,0-1-1H30.53a1,1,0,0,0-.73.3,1,1,0,0,0-.3.73v3.44a1,1,0,0,0,1,1H34A1,1,0,0,0,34.7,37.45Z" />
            </>
        ),
    },
    'calendar-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M5.81,15.76a1,1,0,0,1-1-1V11.64A4.1,4.1,0,0,1,8.9,7.52H13V3.06a1,1,0,0,1,1-1h3.43a1,1,0,0,1,.73.3,1,1,0,0,1,.3.73V7.52h11V3.06a1,1,0,0,1,.3-.73,1,1,0,0,1,.73-.3H34a1,1,0,0,1,1,1V7.52H39.1a4.1,4.1,0,0,1,4.12,4.12v3.09a1,1,0,0,1-1,1Zm36.38,2.75a1,1,0,0,1,1,1V41.85A4.1,4.1,0,0,1,39.1,46H8.9a4.1,4.1,0,0,1-4.12-4.12V19.54a1,1,0,0,1,1-1ZM15.46,24.3a1,1,0,0,0-.73-.3H11.3a1,1,0,0,0-1,1v3.43a1,1,0,0,0,1,1h3.43a1,1,0,0,0,1-1V25A1,1,0,0,0,15.46,24.3Zm0,11a1,1,0,0,0-.73-.31H11.3a1,1,0,0,0-.73.31,1,1,0,0,0-.3.72v3.44a1,1,0,0,0,.3.73,1,1,0,0,0,.73.3h3.43a1,1,0,0,0,.73-.3,1,1,0,0,0,.3-.73V36A1,1,0,0,0,15.46,35.29Zm11-11a1,1,0,0,0-.73-.3H22.28a1,1,0,0,0-.73.3,1,1,0,0,0-.3.73v3.43a1,1,0,0,0,.3.73,1,1,0,0,0,.73.3h3.44a1,1,0,0,0,.73-.3,1,1,0,0,0,.3-.73V25A1,1,0,0,0,26.45,24.3Zm0,11a1,1,0,0,0-.73-.31H22.28a1,1,0,0,0-1,1v3.44a1,1,0,0,0,1,1h3.44a1,1,0,0,0,1-1V36A1,1,0,0,0,26.45,35.29Zm11-11a1,1,0,0,0-.73-.3H33.27a1,1,0,0,0-1,1v3.43a1,1,0,0,0,1,1H36.7a1,1,0,0,0,1-1V25A1,1,0,0,0,37.43,24.3Zm0,11A1,1,0,0,0,36.7,35H33.27a1,1,0,0,0-.73.31,1,1,0,0,0-.3.72v3.44a1,1,0,0,0,.3.73,1,1,0,0,0,.73.3H36.7a1,1,0,0,0,.73-.3,1,1,0,0,0,.3-.73V36A1,1,0,0,0,37.43,35.29Z" />
            </>
        ),
    },
    camera: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M31.82,5.35A3.73,3.73,0,0,1,33.11,7l1.2,3.27h7.57A4.1,4.1,0,0,1,46,14.38V39.12a4.1,4.1,0,0,1-4.12,4.13H6.12A4.1,4.1,0,0,1,2,39.12V14.38a4.1,4.1,0,0,1,4.12-4.13h7.57l1-2.84a4.46,4.46,0,0,1,1.55-1.93,3.88,3.88,0,0,1,2.32-.73H29.84A3.36,3.36,0,0,1,31.82,5.35ZM18.59,7.5a1.28,1.28,0,0,0-.78.26,1.61,1.61,0,0,0-.51.6L15.58,13H6.12a1.33,1.33,0,0,0-1.37,1.38V39.12A1.33,1.33,0,0,0,6.12,40.5H41.88a1.33,1.33,0,0,0,1.37-1.38V14.38A1.33,1.33,0,0,0,41.88,13H32.42L30.53,7.93a.66.66,0,0,0-.69-.43ZM29.2,35.69A10.55,10.55,0,1,0,24,37.06,10.32,10.32,0,0,0,29.2,35.69Zm.13-14.27a7.48,7.48,0,0,1,0,10.66,7.48,7.48,0,0,1-10.66,0,7.48,7.48,0,0,1,0-10.66,7.48,7.48,0,0,1,10.66,0Z" />
            </>
        ),
    },
    comments: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M2.66,6.75A4.73,4.73,0,0,1,7.39,2H40.61a4.73,4.73,0,0,1,4.73,4.72V32.87a4.73,4.73,0,0,1-4.74,4.72H33.46a5.33,5.33,0,0,0-3.77,1.9l-4.27,5.69a1.67,1.67,0,0,1-2.84,0l-4.27-5.69a5.33,5.33,0,0,0-3.77-1.9H7.4a4.73,4.73,0,0,1-4.74-4.72Zm9.49,7.14h23.7v2.37H12.15Zm0,9.48h23.7v2.37H12.15Z" />
            </>
        ),
    },
    'chart-pie': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M34.65,38.46a1.22,1.22,0,0,1,.26.78,1.07,1.07,0,0,1-.35.79,19.08,19.08,0,0,1-21,3.14,20,20,0,0,1-8.29-6.9A18.9,18.9,0,0,1,2,25.36a19.13,19.13,0,0,1,2.44-9.51,19.33,19.33,0,0,1,6.68-7,19.68,19.68,0,0,1,9.3-3,1.2,1.2,0,0,1,.79.3,1,1,0,0,1,.35.74V25.36ZM7.41,34.35a16.63,16.63,0,0,0,6.51,5.94,16.27,16.27,0,0,0,8.6,1.83,15.36,15.36,0,0,0,8.73-3l-12-12.14a2.4,2.4,0,0,1-.39-.43,1.66,1.66,0,0,1,0-.53V8.77a17.29,17.29,0,0,0-7.07,3,16.2,16.2,0,0,0-5.06,5.89,16.54,16.54,0,0,0-1.84,7.68A16.12,16.12,0,0,0,7.41,34.35Zm17.29-31A1.17,1.17,0,0,1,25.48,3a18.85,18.85,0,0,1,9,2.8,20.11,20.11,0,0,1,6.63,6.63,18.73,18.73,0,0,1,2.79,9,1.18,1.18,0,0,1-.3.79,1,1,0,0,1-.74.35H24.35V4.06A1,1,0,0,1,24.7,3.32ZM45,25.36a1,1,0,0,1,.74.35,1.14,1.14,0,0,1,.31.79,19.65,19.65,0,0,1-1.44,6.33,20.27,20.27,0,0,1-3.45,5.63,1.06,1.06,0,0,1-1.57,0l-13.1-13.1ZM27.14,19.77h13.8a16.48,16.48,0,0,0-4.72-9.08A16.43,16.43,0,0,0,27.14,6ZM43,28.15H33.17l7,7A17.61,17.61,0,0,0,43,28.15Z" />
            </>
        ),
    },
    'chart-pie-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M33.5,38.48a1,1,0,0,1-.27.63,18.33,18.33,0,0,1-5.8,3.58,18.61,18.61,0,0,1-6.74,1.25,18.09,18.09,0,0,1-9.34-2.53,19.05,19.05,0,0,1-6.82-6.82A17.85,17.85,0,0,1,2,25.32a18.33,18.33,0,0,1,2.38-9.19,18.84,18.84,0,0,1,15.3-9.57,1,1,0,0,1,.7.27.83.83,0,0,1,.31.66V25.25L33.23,37.78A.82.82,0,0,1,33.5,38.48ZM24.43,22.75H42.18a.87.87,0,0,0,.67-.31,1,1,0,0,0,.27-.7,18.18,18.18,0,0,0-2.65-8.64,18.78,18.78,0,0,0-6.39-6.39,18.11,18.11,0,0,0-8.64-2.65,1,1,0,0,0-.7.28.83.83,0,0,0-.31.66Zm21.3,4.05a1,1,0,0,1,.27.7A18.43,18.43,0,0,1,41.25,39a1.1,1.1,0,0,1-.7.35.82.82,0,0,1-.7-.27L27.31,26.49H45.07A.88.88,0,0,1,45.73,26.8Z" />
            </>
        ),
    },
    chart: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M44.92,37.72a1,1,0,0,1,1,1v.68a1,1,0,0,1-1,1H3.08a1,1,0,0,1-1-1V8.57a1,1,0,0,1,1-1h.68a1,1,0,0,1,1,1V37.72Zm-1.71-25.3v8.06a2.74,2.74,0,0,1-.82,2,2.69,2.69,0,0,1-2,.81,2.78,2.78,0,0,1-2-.85l-3.08-3L27.09,27.6a.53.53,0,0,1-.39.17.3.3,0,0,1-.3-.17l-5.14-5.06L11.65,32.83a1.42,1.42,0,0,1-.38.13.4.4,0,0,1-.39-.13l-1.2-1.2a.5.5,0,0,1-.17-.34A.72.72,0,0,1,9.6,31L20.83,19a.5.5,0,0,1,.38-.17.83.83,0,0,1,.39.08l5.14,5.15,6.61-6.61-3-3.08a2.71,2.71,0,0,1-.85-2,2.8,2.8,0,0,1,.81-2,2.67,2.67,0,0,1,2-.85h8.06a2.78,2.78,0,0,1,2.83,2.82Zm-2.49,0a.31.31,0,0,0-.34-.34H32.32a.34.34,0,0,0-.35.22.33.33,0,0,0,.09.38l8.06,8.06a.32.32,0,0,0,.39.09.34.34,0,0,0,.21-.35Z" />
            </>
        ),
    },
    'chart-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M45.65,35.27A1,1,0,0,1,46,36v3.43a1,1,0,0,1-.3.73,1,1,0,0,1-.73.3H3.08a1,1,0,0,1-.73-.3,1,1,0,0,1-.3-.73V8.57a1,1,0,0,1,.3-.73,1,1,0,0,1,.73-.3H6.51a1,1,0,0,1,1,1V35H44.92A1,1,0,0,1,45.65,35.27Zm-14.11-25a2,2,0,0,0-1.45.6,2.08,2.08,0,0,0,0,2.92l2.83,2.83-6.18,6.26-4.8-4.81a.92.92,0,0,0-.68-.25,1.23,1.23,0,0,0-.77.25L10.28,28.2a.93.93,0,0,0-.25.69,1.27,1.27,0,0,0,.25.77l2.49,2.48a.82.82,0,0,0,.69.35,1.08,1.08,0,0,0,.77-.35l7-7L26,29.92a1.27,1.27,0,0,0,.77.25.93.93,0,0,0,.69-.25l9.43-9.35,2.83,2.83a2.06,2.06,0,0,0,3.51-1.46v-9.6a2,2,0,0,0-.6-1.46,2,2,0,0,0-1.45-.6Z" />
            </>
        ),
    },
    check: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M35,5a22.39,22.39,0,0,1,8,8,21.34,21.34,0,0,1,3,11,21.34,21.34,0,0,1-3,11,22.39,22.39,0,0,1-8,8,21.86,21.86,0,0,1-22,0,22.39,22.39,0,0,1-8-8A21.34,21.34,0,0,1,2,24,21.34,21.34,0,0,1,5,13a22.39,22.39,0,0,1,8-8A21.86,21.86,0,0,1,35,5ZM33.62,40.59a19.13,19.13,0,0,0,7-7A18.92,18.92,0,0,0,43.16,24a18.64,18.64,0,0,0-2.57-9.58,19.13,19.13,0,0,0-7-7A18.92,18.92,0,0,0,24,4.84a18.64,18.64,0,0,0-9.58,2.57,19.2,19.2,0,0,0-7,7A18.92,18.92,0,0,0,4.84,24a18.64,18.64,0,0,0,2.57,9.58,19.13,19.13,0,0,0,7,7A18.82,18.82,0,0,0,24,43.16,18.71,18.71,0,0,0,33.62,40.59Zm3.24-22.53a1.29,1.29,0,0,0-.27-.8l-.79-.71a.85.85,0,0,0-.71-.36,1.27,1.27,0,0,0-.8.27L19.83,30.92l-6.12-6.21a1.29,1.29,0,0,0-.8-.27,1,1,0,0,0-.71.27l-.79.71a1.29,1.29,0,0,0-.27.8,1,1,0,0,0,.27.71L19,34.64a1.1,1.1,0,0,0,.8.36.84.84,0,0,0,.71-.36L36.59,18.77A1,1,0,0,0,36.86,18.06Z" />
            </>
        ),
    },
    'check-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M43,35a22.24,22.24,0,0,1-8,8,21.34,21.34,0,0,1-11,3,21.34,21.34,0,0,1-11-3,22.24,22.24,0,0,1-8-8A21.34,21.34,0,0,1,2,24,21.34,21.34,0,0,1,5,13a22.24,22.24,0,0,1,8-8A21.34,21.34,0,0,1,24,2,21.34,21.34,0,0,1,35,5a22.24,22.24,0,0,1,8,8,21.34,21.34,0,0,1,3,11A21.34,21.34,0,0,1,43,35ZM37.75,19.3a1.17,1.17,0,0,0,.44-1,1.15,1.15,0,0,0-.44-1l-2-2a1.41,1.41,0,0,0-1-.45,1.37,1.37,0,0,0-1,.45L20.45,28.61,14.24,22.4a1.4,1.4,0,0,0-1-.44,1.44,1.44,0,0,0-1,.44l-1.95,2a1.31,1.31,0,0,0,0,2l9.23,9.22a1.15,1.15,0,0,0,1,.44,1.17,1.17,0,0,0,1-.44Z" />
            </>
        ),
    },
    'chevron-left': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M23.9,45.86a1.06,1.06,0,0,1-.78-.29L2.43,24.78A.91.91,0,0,1,2,24a.93.93,0,0,1,.39-.78L23.12,2.43a1.06,1.06,0,0,1,.78-.29,1.45,1.45,0,0,1,.88.29l.69.79a1,1,0,0,1,.29.78,1.39,1.39,0,0,1-.29.88L7.92,22.33H44.78a1.15,1.15,0,0,1,.84.35,1.17,1.17,0,0,1,.34.83v1a1.18,1.18,0,0,1-1.18,1.18H7.92L25.47,43.12a1.39,1.39,0,0,1,.29.88,1,1,0,0,1-.29.78l-.69.79A1.45,1.45,0,0,1,23.9,45.86Z" />
            </>
        ),
    },
    'chevron-left-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M25.08,44.72a2.37,2.37,0,0,1-3.34,0l-19-19a2.37,2.37,0,0,1,0-3.34l19-19a2.37,2.37,0,0,1,3.34,0l2.26,2.16A2.1,2.1,0,0,1,28,7.11a2.45,2.45,0,0,1-.74,1.67L15.46,20.07H43.64A2.36,2.36,0,0,1,46,22.43v3.14a2.36,2.36,0,0,1-2.36,2.36H15.46L27.24,39.22A2.45,2.45,0,0,1,28,40.89a2.1,2.1,0,0,1-.64,1.67Z" />
            </>
        ),
    },
    'chevron-left-circle': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M24.06,2.06a22,22,0,1,0,22,22h0A22,22,0,0,0,24.06,2.06Zm4.8,33.45h0a2.06,2.06,0,0,1-2.89,0L15.87,25.4a2,2,0,0,1-.06-2.82l9.94-10a2,2,0,0,1,3,2.81.46.46,0,0,1-.07.07l-8.45,8.58,8.66,8.58A2,2,0,0,1,28.86,35.51Z" />
            </>
        ),
    },
    'chevron-right-circle': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M24,46A22,22,0,1,0,2,24H2A22,22,0,0,0,24,46ZM19.2,12.55h0a2.06,2.06,0,0,1,2.89,0L32.19,22.66a2,2,0,0,1,.06,2.82l-9.94,10a2,2,0,0,1-3-2.81l.07-.07L27.87,24l-8.66-8.57A2,2,0,0,1,19.2,12.55Z" />
            </>
        ),
    },
    'chevron-up': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <path d="M5.43,27.29,3.25,25.11a2.35,2.35,0,0,1,0-3.32h0L22.33,2.69a2.35,2.35,0,0,1,3.32,0h0L44.75,21.78a2.35,2.35,0,0,1,0,3.32h0l-2.18,2.18a2.37,2.37,0,0,1-3.34,0l0,0L27.93,15.42V43.64A2.36,2.36,0,0,1,25.58,46H22.43a2.36,2.36,0,0,1-2.36-2.35h0V15.42L8.8,27.25a2.34,2.34,0,0,1-3.31.1Z" />
        ),
    },
    'chevron-up-circle': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M46,24A22,22,0,1,0,24,46h0A22,22,0,0,0,46,24ZM12.55,28.8h0a2.06,2.06,0,0,1,0-2.89L22.66,15.81a2,2,0,0,1,2.82-.06l10,9.94a2,2,0,0,1-2.81,3l-.07-.07L24,20.13l-8.57,8.66A2,2,0,0,1,12.55,28.8Z" />
            </>
        ),
    },
    'chevron-down-circle': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M2,24A22,22,0,1,0,24,2h0A22,22,0,0,0,2,24Zm33.45-4.8h0a2.06,2.06,0,0,1,0,2.89L25.34,32.19a2,2,0,0,1-2.82.06l-10-9.94a2,2,0,0,1,2.81-3l.07.07L24,27.87l8.57-8.66A2,2,0,0,1,35.45,19.2Z" />
            </>
        ),
    },
    'chevron-light-down': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M22.62,36.29,2.69,16A1.65,1.65,0,0,1,2,14.63a2.11,2.11,0,0,1,.69-1.54l1.2-1.21a1.62,1.62,0,0,1,1.38-.68,2.13,2.13,0,0,1,1.54.68L24,29.41,41.19,11.88a2.5,2.5,0,0,1,1.54-.51,1.88,1.88,0,0,1,1.38.51l1.37,1.21A2.45,2.45,0,0,1,46,14.63,1.86,1.86,0,0,1,45.48,16L25.55,36.29a2.61,2.61,0,0,1-3.1,0Z" />
            </>
        ),
    },
    'chevron-light-left': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M11.71,22.62,32,2.69A1.65,1.65,0,0,1,33.37,2a2.11,2.11,0,0,1,1.54.69l1.21,1.2a1.62,1.62,0,0,1,.68,1.38,2.13,2.13,0,0,1-.68,1.54L18.59,24,36.12,41.19a2.5,2.5,0,0,1,.51,1.54,1.88,1.88,0,0,1-.51,1.38l-1.21,1.37a2.45,2.45,0,0,1-1.54.52A1.86,1.86,0,0,1,32,45.48L11.71,25.55a2.61,2.61,0,0,1,0-3.1Z" />
            </>
        ),
    },
    'chevron-light-right': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M36.29,25.38,16,45.31a1.65,1.65,0,0,1-1.38.69,2.11,2.11,0,0,1-1.54-.69l-1.21-1.2a1.62,1.62,0,0,1-.68-1.38,2.13,2.13,0,0,1,.68-1.54L29.41,24,11.88,6.81a2.5,2.5,0,0,1-.51-1.54,1.88,1.88,0,0,1,.51-1.38l1.21-1.37A2.45,2.45,0,0,1,14.63,2,1.86,1.86,0,0,1,16,2.52L36.29,22.45a2.61,2.61,0,0,1,0,3.1Z" />
            </>
        ),
    },
    'chevron-light-up': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M25.38,11.71,45.31,32A1.65,1.65,0,0,1,46,33.37a2.11,2.11,0,0,1-.69,1.54l-1.2,1.21a1.62,1.62,0,0,1-1.38.68,2.13,2.13,0,0,1-1.54-.68L24,18.59,6.81,36.12a2.5,2.5,0,0,1-1.54.51,1.88,1.88,0,0,1-1.38-.51L2.52,34.91A2.45,2.45,0,0,1,2,33.37,1.86,1.86,0,0,1,2.52,32L22.45,11.71a2.61,2.61,0,0,1,3.1,0Z" />
            </>
        ),
    },
    clock: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M35,5a22.07,22.07,0,0,1,8,8,21.23,21.23,0,0,1,3,11,21.23,21.23,0,0,1-3,11,22.07,22.07,0,0,1-8,8,21.23,21.23,0,0,1-11,3,21.23,21.23,0,0,1-11-3,22.07,22.07,0,0,1-8-8,21.23,21.23,0,0,1-3-11A21.23,21.23,0,0,1,5,13a22.07,22.07,0,0,1,8-8,21.23,21.23,0,0,1,11-3A21.23,21.23,0,0,1,35,5Zm5.57,9.37a19,19,0,0,0-7-6.94,19.12,19.12,0,0,0-19.15,0,19,19,0,0,0-6.94,7,19.12,19.12,0,0,0,0,19.15,19,19,0,0,0,7,6.94,19.12,19.12,0,0,0,19.15,0,19,19,0,0,0,6.94-7A18.69,18.69,0,0,0,43.11,24,18.66,18.66,0,0,0,40.54,14.4ZM30.72,32a1,1,0,0,0,.71-.4l.71-1.06a1,1,0,0,0,.22-.76.94.94,0,0,0-.4-.66l-6.28-4.6V11.62a1,1,0,0,0-.31-.76,1.06,1.06,0,0,0-.75-.31H23.38a1,1,0,0,0-.75.31,1,1,0,0,0-.31.76V25.77a.89.89,0,0,0,.44.8l7.17,5.21A1.07,1.07,0,0,0,30.72,32Z" />
            </>
        ),
    },
    clone: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M29.88,26.46v1.06a.26.26,0,0,1-.26.26H10.36a.26.26,0,0,1-.26-.26V26.46a.27.27,0,0,1,.26-.27H29.62A.27.27,0,0,1,29.88,26.46Zm-.26-7.33H10.36a.26.26,0,0,0-.26.26v1.06a.27.27,0,0,0,.26.27H29.62a.27.27,0,0,0,.26-.27V19.39A.26.26,0,0,0,29.62,19.13Zm0-7.07H10.36a.27.27,0,0,0-.26.27v1.06a.26.26,0,0,0,.26.26H29.62a.26.26,0,0,0,.26-.26V12.33A.27.27,0,0,0,29.62,12.06Zm16.3,2.35v27.4a4.1,4.1,0,0,1-4.11,4.11H14.41a4.1,4.1,0,0,1-4.11-4.11V37.7H6.19a4,4,0,0,1-2.91-1.2,4,4,0,0,1-1.2-2.91V6.19a4,4,0,0,1,1.2-2.91,4,4,0,0,1,2.91-1.2h27.4a4,4,0,0,1,2.91,1.2,4,4,0,0,1,1.2,2.91V10.3h4.11a4.1,4.1,0,0,1,4.11,4.11ZM33.59,35A1.3,1.3,0,0,0,35,33.59V6.19a1.3,1.3,0,0,0-1.37-1.37H6.19A1.3,1.3,0,0,0,4.82,6.19v27.4A1.3,1.3,0,0,0,6.19,35Zm9.59-20.55A1.3,1.3,0,0,0,41.81,13H37.7V33.59a4.1,4.1,0,0,1-4.11,4.11H13v4.11a1.3,1.3,0,0,0,1.37,1.37h27.4a1.3,1.3,0,0,0,1.37-1.37Z" />
            </>
        ),
    },
    'clock-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M24,2A21.34,21.34,0,0,1,35,5a22.24,22.24,0,0,1,8,8,21.34,21.34,0,0,1,3,11,21.34,21.34,0,0,1-3,11,22.24,22.24,0,0,1-8,8,21.34,21.34,0,0,1-11,3,21.34,21.34,0,0,1-11-3,22.24,22.24,0,0,1-8-8A21.34,21.34,0,0,1,2,24,21.34,21.34,0,0,1,5,13a22.24,22.24,0,0,1,8-8A21.34,21.34,0,0,1,24,2Zm5.06,31.05a1,1,0,0,0,.79.18,1.1,1.1,0,0,0,.71-.45l2.49-3.37a1.08,1.08,0,0,0,.22-.8,1,1,0,0,0-.4-.71l-5.68-4.08V11.58a1.06,1.06,0,0,0-1.06-1.06H21.87a1,1,0,0,0-.75.31,1,1,0,0,0-.31.75v14.9a1.07,1.07,0,0,0,.44.89Z" />
            </>
        ),
    },
    'cloud-download': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M44.73,26.1A8.52,8.52,0,0,1,46,30.6,8.71,8.71,0,0,1,44.83,35a8.48,8.48,0,0,1-3.2,3.2A8.71,8.71,0,0,1,37.2,39.4H11.9A9.58,9.58,0,0,1,7,38.06a10.07,10.07,0,0,1-3.61-3.61A9.58,9.58,0,0,1,2,29.5a9.75,9.75,0,0,1,1.82-5.74A9.63,9.63,0,0,1,8.6,20.15a11.76,11.76,0,0,1,1.79-5.81,12,12,0,0,1,4.4-4.19A12.13,12.13,0,0,1,26.34,10a11.58,11.58,0,0,1,4.26,3.78A7.46,7.46,0,0,1,33.9,13a7.53,7.53,0,0,1,3.85,1A7.7,7.7,0,0,1,41.6,20.7a8,8,0,0,1-.28,2.13A8.92,8.92,0,0,1,44.73,26.1Zm-2.86,9.17a6.63,6.63,0,0,0,.35-9,6.6,6.6,0,0,0-4-2.23A5.2,5.2,0,0,0,39.4,20.7a5.27,5.27,0,0,0-1.62-3.88,5.35,5.35,0,0,0-4-1.62,5.24,5.24,0,0,0-4,1.79,9.71,9.71,0,0,0-3.57-4.47,9.85,9.85,0,0,0-10.52-.38,10.07,10.07,0,0,0-3.61,3.61A9.58,9.58,0,0,0,10.8,20.7a8.76,8.76,0,0,0,.07,1.17,7.58,7.58,0,0,0-4.74,2.58A7.32,7.32,0,0,0,4.2,29.5a7.53,7.53,0,0,0,1,3.85A7.7,7.7,0,0,0,11.9,37.2H37.2A6.33,6.33,0,0,0,41.87,35.27Zm-11.61-11a1.09,1.09,0,0,1,.2.62.76.76,0,0,1-.2.55l-6.81,6.74a.64.64,0,0,1-.55.28.83.83,0,0,1-.62-.28l-6.74-6.74a.76.76,0,0,1-.2-.55,1.09,1.09,0,0,1,.2-.62l.42-.41a.76.76,0,0,1,.55-.2,1.07,1.07,0,0,1,.62.2l4.67,4.75V18.23a.82.82,0,0,1,.83-.83h.55a.82.82,0,0,1,.82.83V28.61l4.68-4.75a1,1,0,0,1,.61-.2.76.76,0,0,1,.55.2Z" />
            </>
        ),
    },
    'cloud-upload-alt': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M44.73,26.1A8.49,8.49,0,0,1,46,30.6,8.71,8.71,0,0,1,44.83,35a8.48,8.48,0,0,1-3.2,3.2A8.71,8.71,0,0,1,37.2,39.4H11.9A9.58,9.58,0,0,1,7,38.06a10.07,10.07,0,0,1-3.61-3.61A9.58,9.58,0,0,1,2,29.5a9.75,9.75,0,0,1,1.82-5.74A9.63,9.63,0,0,1,8.6,20.15a11.76,11.76,0,0,1,1.79-5.81,12,12,0,0,1,4.4-4.19A12.13,12.13,0,0,1,26.34,10a11.58,11.58,0,0,1,4.26,3.78A7.46,7.46,0,0,1,33.9,13a7.7,7.7,0,0,1,7.7,7.7,8.4,8.4,0,0,1-.27,2.13A9,9,0,0,1,44.73,26.1Zm-2.85,9.18a6.65,6.65,0,0,0,.34-9,6.6,6.6,0,0,0-4-2.23A5.2,5.2,0,0,0,39.4,20.7a5.27,5.27,0,0,0-1.62-3.88,5.35,5.35,0,0,0-4-1.62,5.24,5.24,0,0,0-4,1.79,9.66,9.66,0,0,0-3.57-4.47,9.85,9.85,0,0,0-10.52-.38,10.07,10.07,0,0,0-3.61,3.61A9.58,9.58,0,0,0,10.8,20.7a8.76,8.76,0,0,0,.07,1.17,7.58,7.58,0,0,0-4.74,2.58A7.34,7.34,0,0,0,4.2,29.5a7.53,7.53,0,0,0,1,3.85A7.7,7.7,0,0,0,11.9,37.2H37.2A6.4,6.4,0,0,0,41.88,35.28ZM30.6,23.93A2.3,2.3,0,0,1,30,25.51a2,2,0,0,1-1.58.69H24V32a.82.82,0,0,1-.83.82h-.54A.82.82,0,0,1,21.8,32V26.2H17.4a2,2,0,0,1-1.58-.69,2.3,2.3,0,0,1-.62-1.58A2.08,2.08,0,0,1,16,22.35l5.5-5a2.16,2.16,0,0,1,2.88,0l5.5,5A2.08,2.08,0,0,1,30.6,23.93ZM28.4,24l-5.5-4.95L17.4,24Z" />
            </>
        ),
    },
    close: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M35,5a22.24,22.24,0,0,1,8,8,21.34,21.34,0,0,1,3,11,21.34,21.34,0,0,1-3,11,22.24,22.24,0,0,1-8,8,21.34,21.34,0,0,1-11,3,21.34,21.34,0,0,1-11-3,22.24,22.24,0,0,1-8-8A21.34,21.34,0,0,1,2,24,21.34,21.34,0,0,1,5,13a22.24,22.24,0,0,1,8-8A21.34,21.34,0,0,1,24,2,21.34,21.34,0,0,1,35,5ZM33.62,40.59a19.05,19.05,0,0,0,7-7A18.86,18.86,0,0,0,43.16,24a18.64,18.64,0,0,0-2.57-9.58,19.13,19.13,0,0,0-7-7A18.89,18.89,0,0,0,24,4.84a18.64,18.64,0,0,0-9.58,2.57,19.13,19.13,0,0,0-7,7A18.89,18.89,0,0,0,4.84,24a18.64,18.64,0,0,0,2.57,9.58,19.05,19.05,0,0,0,7,7A18.82,18.82,0,0,0,24,43.16,18.71,18.71,0,0,0,33.62,40.59Zm-.93-23.51a1,1,0,0,0-.26-.71l-.8-.8a1,1,0,0,0-.71-.26,1.34,1.34,0,0,0-.8.26L24,21.69l-6.12-6.12a1.34,1.34,0,0,0-.8-.26,1,1,0,0,0-.71.26l-.8.8a1,1,0,0,0-.26.71,1.34,1.34,0,0,0,.26.8L21.78,24l-6.21,6.12a1.36,1.36,0,0,0-.26.8,1,1,0,0,0,.26.71l.8.8a1,1,0,0,0,.71.26,1.34,1.34,0,0,0,.8-.26L24,26.22l6.12,6.21a1.34,1.34,0,0,0,.8.26,1,1,0,0,0,.71-.26l.8-.8a1,1,0,0,0,.26-.71,1.36,1.36,0,0,0-.26-.8L26.31,24l6.12-6.12A1.34,1.34,0,0,0,32.69,17.08Z" />
            </>
        ),
    },
    'close-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M24,2A21.29,21.29,0,0,1,35,5a22.34,22.34,0,0,1,8,8,21.39,21.39,0,0,1,3,11,21.39,21.39,0,0,1-3,11,22.34,22.34,0,0,1-8,8,21.29,21.29,0,0,1-11,3,21.29,21.29,0,0,1-11-3,22.34,22.34,0,0,1-8-8A21.39,21.39,0,0,1,2,24,21.39,21.39,0,0,1,5,13a22.34,22.34,0,0,1,8-8A21.29,21.29,0,0,1,24,2ZM34.81,29.76,29,24l5.85-5.76a1.21,1.21,0,0,0,.27-.75,1.24,1.24,0,0,0-.27-.76l-3.54-3.54a1,1,0,0,0-.71-.27,1.29,1.29,0,0,0-.8.27L24,19l-5.76-5.85a1.19,1.19,0,0,0-1.51,0l-3.54,3.54a1,1,0,0,0-.27.71,1.27,1.27,0,0,0,.27.8L19,24l-5.85,5.76a1.21,1.21,0,0,0-.27.75,1.24,1.24,0,0,0,.27.76l3.54,3.54a1,1,0,0,0,.71.27,1.29,1.29,0,0,0,.8-.27L24,29l5.76,5.85a1.19,1.19,0,0,0,1.51,0l3.54-3.54a1,1,0,0,0,.27-.71A1.27,1.27,0,0,0,34.81,29.76Z" />
            </>
        ),
    },
    cog: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M44,27.81,41.1,26.15a17.19,17.19,0,0,0,0-4.3L44,20.19a2.12,2.12,0,0,0,1-2.43A21.78,21.78,0,0,0,39.88,9a2.13,2.13,0,0,0-2.6-.38l-2.87,1.66a17.11,17.11,0,0,0-3.73-2.16V4.8a2.12,2.12,0,0,0-1.62-2.06,22.18,22.18,0,0,0-10.12,0A2.12,2.12,0,0,0,17.32,4.8V8.11a17.11,17.11,0,0,0-3.73,2.16L10.72,8.61A2.13,2.13,0,0,0,8.12,9a21.78,21.78,0,0,0-5.06,8.77,2.12,2.12,0,0,0,1,2.43L6.9,21.85a17.19,17.19,0,0,0,0,4.3L4,27.81a2.12,2.12,0,0,0-1,2.43A21.78,21.78,0,0,0,8.12,39a2.13,2.13,0,0,0,2.6.38l2.87-1.66a17.11,17.11,0,0,0,3.73,2.16V43.2a2.12,2.12,0,0,0,1.62,2.06,22.18,22.18,0,0,0,10.12,0,2.12,2.12,0,0,0,1.62-2.06V39.89a17.11,17.11,0,0,0,3.73-2.16l2.87,1.66a2.13,2.13,0,0,0,2.6-.38,21.78,21.78,0,0,0,5.06-8.77,2.12,2.12,0,0,0-1-2.43ZM38.2,36.66l-4.08-2.35a15.14,15.14,0,0,1-6.26,3.61v4.72a19.3,19.3,0,0,1-7.72,0V37.92a15.15,15.15,0,0,1-6.26-3.61L9.8,36.66A18.8,18.8,0,0,1,5.93,30L10,27.62a15.28,15.28,0,0,1,0-7.24L5.93,18A18.8,18.8,0,0,1,9.8,11.34l4.08,2.35a15.1,15.1,0,0,1,6.26-3.61V5.36a19.3,19.3,0,0,1,7.72,0v4.72a15.1,15.1,0,0,1,6.26,3.61l4.08-2.35A18.8,18.8,0,0,1,42.07,18L38,20.38a15.28,15.28,0,0,1,0,7.24L42.07,30a18.8,18.8,0,0,1-3.87,6.69ZM24,15.54A8.46,8.46,0,1,0,32.46,24,8.48,8.48,0,0,0,24,15.54Zm0,14.1A5.64,5.64,0,1,1,29.64,24,5.65,5.65,0,0,1,24,29.64Z" />
            </>
        ),
    },
    'cog-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M44.55,29.29l-3.78-2.18a17.22,17.22,0,0,0,0-6.24l3.78-2.18A1.08,1.08,0,0,0,45,17.44,22.23,22.23,0,0,0,40.18,9a1.07,1.07,0,0,0-1.31-.2L35.08,11a16.87,16.87,0,0,0-5.4-3.12V3.54a1.07,1.07,0,0,0-.83-1,22.4,22.4,0,0,0-9.71,0,1.07,1.07,0,0,0-.83,1V7.91A17.28,17.28,0,0,0,12.91,11L9.13,8.84a1.07,1.07,0,0,0-1.31.21A22,22,0,0,0,3,17.45a1.07,1.07,0,0,0,.49,1.25l3.78,2.18a17.22,17.22,0,0,0,0,6.24L3.45,29.3A1.08,1.08,0,0,0,3,30.55,22.1,22.1,0,0,0,7.82,39a1.07,1.07,0,0,0,1.31.21L12.92,37a16.87,16.87,0,0,0,5.4,3.12v4.37a1.07,1.07,0,0,0,.83,1,22.35,22.35,0,0,0,9.7,0,1.07,1.07,0,0,0,.84-1V40.09A17.28,17.28,0,0,0,35.09,37l3.78,2.19A1.08,1.08,0,0,0,40.19,39a22.08,22.08,0,0,0,4.86-8.4A1.11,1.11,0,0,0,44.55,29.29ZM24,31.1A7.11,7.11,0,1,1,31.1,24,7.12,7.12,0,0,1,24,31.1Z" />
            </>
        ),
    },
    comment: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M35,7.16a20.7,20.7,0,0,1,8,6.48,15.12,15.12,0,0,1,0,18,20.7,20.7,0,0,1-8,6.48A25.15,25.15,0,0,1,24,40.5a27.35,27.35,0,0,1-8-1.2,20.36,20.36,0,0,1-12,4A2,2,0,0,1,3,42.91,2.14,2.14,0,0,1,2.17,42,1.85,1.85,0,0,1,2,40.84a1.9,1.9,0,0,1,.52-1,20.31,20.31,0,0,0,1.5-1.94,17.13,17.13,0,0,0,2.49-4.47A15.35,15.35,0,0,1,2,22.63a15,15,0,0,1,3-9,20.7,20.7,0,0,1,8-6.48A25.15,25.15,0,0,1,24,4.75,25.15,25.15,0,0,1,35,7.16ZM33.67,35.73a17.53,17.53,0,0,0,7-5.5,12.5,12.5,0,0,0,0-15.21,17.62,17.62,0,0,0-7-5.5A23,23,0,0,0,24,7.5a23,23,0,0,0-9.67,2,17.62,17.62,0,0,0-7,5.5,12.51,12.51,0,0,0-2.58,7.61,12.79,12.79,0,0,0,3.78,8.93l1.2,1.29-.6,1.63a22.27,22.27,0,0,1-3.52,5.93,18.27,18.27,0,0,0,8.85-3.35l1.12-.86,1.29.43A22.77,22.77,0,0,0,24,37.75,23,23,0,0,0,33.67,35.73Zm1.12-18.39a.66.66,0,0,1,.21.47v1.38a.66.66,0,0,1-.21.47.67.67,0,0,1-.48.22H13.69a.67.67,0,0,1-.48-.22.66.66,0,0,1-.21-.47V17.81a.66.66,0,0,1,.21-.47.71.71,0,0,1,.48-.22H34.31A.71.71,0,0,1,34.79,17.34Zm-8.25,8.25a.66.66,0,0,1,.21.47v1.38a.72.72,0,0,1-.69.68H13.69a.72.72,0,0,1-.69-.68V26.06a.66.66,0,0,1,.21-.47.7.7,0,0,1,.48-.21H26.06A.7.7,0,0,1,26.54,25.59Z" />
            </>
        ),
    },
    'comment-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M24,4.78a25.24,25.24,0,0,1,11,2.4,20.71,20.71,0,0,1,8,6.48,15.06,15.06,0,0,1,0,17.93,20.71,20.71,0,0,1-8,6.48,25.1,25.1,0,0,1-11,2.41,26.26,26.26,0,0,1-9.18-1.63,23.53,23.53,0,0,1-4.72,2.83,18.61,18.61,0,0,1-7.38,1.54.6.6,0,0,1-.6-.43A.74.74,0,0,1,2.2,42a18.39,18.39,0,0,0,2-2.49,18.38,18.38,0,0,0,2.74-5.66,18.72,18.72,0,0,1-3.6-5.24,14.29,14.29,0,0,1-1.29-6,15,15,0,0,1,3-9,20.71,20.71,0,0,1,8-6.48A25.24,25.24,0,0,1,24,4.78ZM35,19.19V17.82a.66.66,0,0,0-.21-.47.67.67,0,0,0-.47-.22H13.7a.67.67,0,0,0-.47.22.66.66,0,0,0-.21.47v1.37a.68.68,0,0,0,.21.48.66.66,0,0,0,.47.21H34.3a.66.66,0,0,0,.47-.21A.68.68,0,0,0,35,19.19Zm-8.23,8.24V26.06a.63.63,0,0,0-.22-.47.67.67,0,0,0-.47-.22H13.7a.67.67,0,0,0-.47.22.66.66,0,0,0-.21.47v1.37a.66.66,0,0,0,.21.47.63.63,0,0,0,.47.22H26.06a.76.76,0,0,0,.69-.69Z" />
            </>
        ),
    },
    'comment-edit': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M24,4.75A25.15,25.15,0,0,1,35,7.16a20.7,20.7,0,0,1,8,6.48,15.12,15.12,0,0,1,0,18,20.7,20.7,0,0,1-8,6.48A25.15,25.15,0,0,1,24,40.5a27.35,27.35,0,0,1-8-1.2,20.36,20.36,0,0,1-12,4A1.94,1.94,0,0,1,3,42.91,2.09,2.09,0,0,1,2.17,42,1.85,1.85,0,0,1,2,40.84a1.9,1.9,0,0,1,.52-1,20.31,20.31,0,0,0,1.5-1.94,17.13,17.13,0,0,0,2.49-4.47A15.38,15.38,0,0,1,2,22.62a15,15,0,0,1,3-9,20.7,20.7,0,0,1,8-6.48A25.15,25.15,0,0,1,24,4.75Zm0,33a23,23,0,0,0,9.67-2,17.62,17.62,0,0,0,7-5.5,12.5,12.5,0,0,0,0-15.21,17.62,17.62,0,0,0-7-5.5A23,23,0,0,0,24,7.5a23,23,0,0,0-9.67,2,17.62,17.62,0,0,0-7,5.5,12.5,12.5,0,0,0-2.58,7.6,12.79,12.79,0,0,0,3.78,8.94l1.2,1.29-.6,1.63a22.27,22.27,0,0,1-3.52,5.93,18.27,18.27,0,0,0,8.85-3.35l1.12-.86,1.29.43A22.77,22.77,0,0,0,24,37.75Zm6.88-24,2,2a2.82,2.82,0,0,1,0,3.87L22,30.45a2,2,0,0,1-.68.34l-5.25,1.37-.34.09a1.15,1.15,0,0,1-.95-.43,1.11,1.11,0,0,1-.34-1.29l1.38-5.24a1.25,1.25,0,0,1,.34-.69L27,13.77A2.63,2.63,0,0,1,28.94,13,2.67,2.67,0,0,1,30.88,13.77ZM20.3,28.3l6.45-6.45-2-2-6.44,6.44L17.64,29Zm8.43-8.42,2.15-2.15-2-2L26.75,17.9Z" />
            </>
        ),
    },
    construction: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M45.38,35.82a3.56,3.56,0,0,1,.14,3.79A3.59,3.59,0,0,1,44.11,41a4,4,0,0,1-2,.58H5.85a3.94,3.94,0,0,1-2-.58,3.75,3.75,0,0,1-1.44-1.41A3.69,3.69,0,0,1,2,37.82a3.48,3.48,0,0,1,.62-2L20.77,8.12a4,4,0,0,1,1.41-1.2,3.45,3.45,0,0,1,3.64,0,4,4,0,0,1,1.41,1.2ZM42.63,38a.24.24,0,0,0,.07-.2.46.46,0,0,0-.07-.21L24.48,10a.55.55,0,0,0-1,0L5.37,37.61a.46.46,0,0,0-.07.21.24.24,0,0,0,.07.2.48.48,0,0,0,.48.28h36.3A.48.48,0,0,0,42.63,38ZM23,30.88a2.1,2.1,0,0,1,1,1.85V35a1.1,1.1,0,1,1-2.2,0V32.73l-3.16-2.06-1.31,4.6a1,1,0,0,1-1,.83A.61.61,0,0,1,16,36a1,1,0,0,1-.82-1,.54.54,0,0,1,.07-.27l2-7.5Zm9.28-.62a1.13,1.13,0,0,1,1.86,0l3.71,5.84H26.61l.62-1.79a1.18,1.18,0,0,1,1.24-.89h1.44l-4.81-3L18,26.06a1.07,1.07,0,0,1-.55-1,1.32,1.32,0,0,1,.21-.69l.82-1a3.49,3.49,0,0,1,1.17-.93A3.26,3.26,0,0,1,21,22.07a5.5,5.5,0,0,1,.76.07l1.65.49a3.14,3.14,0,0,1,1.58.89,3.19,3.19,0,0,1,.89,1.65l.69,3.64,4.68,2.89Zm-6.6-9.56a2,2,0,1,1,.55-1.38A1.85,1.85,0,0,1,25.65,20.7Z" />
            </>
        ),
    },
    'construction-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M45.38,35.83a3.42,3.42,0,0,1,.62,2,3.51,3.51,0,0,1-.48,1.79A3.59,3.59,0,0,1,44.11,41a4,4,0,0,1-2,.58H5.85a3.94,3.94,0,0,1-2-.58,3.75,3.75,0,0,1-1.44-1.41A3.69,3.69,0,0,1,2,37.82a3.42,3.42,0,0,1,.62-2L20.77,8.12a4,4,0,0,1,1.41-1.2,3.45,3.45,0,0,1,3.64,0,4,4,0,0,1,1.41,1.2ZM42.63,38a.24.24,0,0,0,.07-.2.46.46,0,0,0-.07-.21L24.48,10a.55.55,0,0,0-1,0L5.37,37.61a.46.46,0,0,0-.07.21.24.24,0,0,0,.07.2.48.48,0,0,0,.48.28h36.3A.48.48,0,0,0,42.63,38ZM23,30.88a2.1,2.1,0,0,1,1,1.85V35a1.1,1.1,0,1,1-2.2,0V32.73l-3.16-2.06-1.31,4.6a1,1,0,0,1-1,.83A.54.54,0,0,1,16,36a1,1,0,0,1-.83-1,.54.54,0,0,1,.07-.27l2-7.5Zm9.28-.62a1.13,1.13,0,0,1,1.86,0l3.71,5.84H26.61l.62-1.79a1.18,1.18,0,0,1,1.24-.89h1.44l-4.81-3L18,26.06a1.07,1.07,0,0,1-.55-1,1.32,1.32,0,0,1,.21-.69l.82-1a3.49,3.49,0,0,1,1.17-.93A3.24,3.24,0,0,1,21,22.08a5.27,5.27,0,0,1,.76.06l1.65.48a3.24,3.24,0,0,1,1.58.9,3.28,3.28,0,0,1,.9,1.65l.68,3.64,4.68,2.89Zm-6.6-9.56a2,2,0,1,1,.55-1.37A1.86,1.86,0,0,1,25.65,20.7Z" />
            </>
        ),
    },
    copy: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M43.25,10.59v23a4.1,4.1,0,0,1-4.13,4.13H32.25v4.13A4.1,4.1,0,0,1,28.12,46H8.88a4.1,4.1,0,0,1-4.13-4.12V14.38a4.1,4.1,0,0,1,4.13-4.13h6.87V6.12A4.1,4.1,0,0,1,19.88,2H34.66a4,4,0,0,1,2.92,1.2l4.47,4.47A4,4,0,0,1,43.25,10.59ZM29.5,37.75H19.88a4.1,4.1,0,0,1-4.13-4.13V13H8.88A1.33,1.33,0,0,0,7.5,14.38v27.5a1.33,1.33,0,0,0,1.38,1.37H28.12a1.33,1.33,0,0,0,1.38-1.37ZM40.5,13H34.31a2,2,0,0,1-2.06-2.06V4.75H19.88A1.33,1.33,0,0,0,18.5,6.12v27.5A1.33,1.33,0,0,0,19.88,35H39.12a1.33,1.33,0,0,0,1.38-1.38ZM35,10.25h5.5a2.18,2.18,0,0,0-.43-.6L35.6,5.18a2.18,2.18,0,0,0-.6-.43Z" />
            </>
        ),
    },
    cube: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M25.46,2.7,43.29,9.38a4.53,4.53,0,0,1,1.93,1.55A3.92,3.92,0,0,1,46,13.24V32.53a4.14,4.14,0,0,1-.6,2.19,3.88,3.88,0,0,1-1.72,1.5L25.8,45.13a4,4,0,0,1-3.6,0L4.37,36.22a3.83,3.83,0,0,1-1.72-1.5,4.14,4.14,0,0,1-.6-2.19V13.24a3.92,3.92,0,0,1,.73-2.31A4.53,4.53,0,0,1,4.71,9.38L22.54,2.7A4.19,4.19,0,0,1,25.46,2.7ZM4.8,12.3,24,20.7l19.2-8.4L24,5.1Zm0,21.09L22.63,42.3V23L4.8,15.3Zm38.4,0V15.3L25.37,23V42.3Z" />
            </>
        ),
    },
    'cube-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M22.54,2.59a4.19,4.19,0,0,1,2.92,0L43.39,9.31a4.49,4.49,0,0,1,1.94,1.55,3.91,3.91,0,0,1,.73,2.33V32.57a4.15,4.15,0,0,1-.6,2.2,3.93,3.93,0,0,1-1.73,1.51l-17.92,9a4,4,0,0,1-3.62,0l-17.92-9a3.93,3.93,0,0,1-1.73-1.51,4.15,4.15,0,0,1-.6-2.2V13.19a3.91,3.91,0,0,1,.73-2.33A4.49,4.49,0,0,1,4.61,9.31ZM24,7.93,7.46,14.13v.09L24,20.94l16.54-6.72v-.09Zm2.76,30.68,13.78-6.9V20.17l-13.78,5.6Z" />
            </>
        ),
    },
    dollar: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M44.58,3.42A4.51,4.51,0,0,1,46,6.75v34.5a4.68,4.68,0,0,1-4.7,4.7H6.75a4.54,4.54,0,0,1-3.33-1.37,4.5,4.5,0,0,1-1.37-3.33V6.75A4.51,4.51,0,0,1,3.42,3.42,4.54,4.54,0,0,1,6.75,2.05h34.5A4.54,4.54,0,0,1,44.58,3.42ZM42.82,6.75a1.51,1.51,0,0,0-1.57-1.57H6.75A1.51,1.51,0,0,0,5.18,6.75v34.5a1.51,1.51,0,0,0,1.57,1.57h34.5a1.51,1.51,0,0,0,1.57-1.57ZM31.06,25.57a5.76,5.76,0,0,1,1.17,3.53A5.86,5.86,0,0,1,26.35,35h-.78V38.9a.84.84,0,0,1-.79.78H23.22a.84.84,0,0,1-.79-.78V35h-.49a8,8,0,0,1-5.09-1.77.61.61,0,0,1-.3-.54,1.12,1.12,0,0,1,.2-.63L17.92,31a.58.58,0,0,1,.49-.3.66.66,0,0,1,.49.2,5.08,5.08,0,0,0,3,1h4.41a2.7,2.7,0,0,0,1.91-.78,2.57,2.57,0,0,0,.84-2,2.67,2.67,0,0,0-.54-1.62,2.89,2.89,0,0,0-1.42-1l-7.06-2a5.42,5.42,0,0,1-3.14-2.06,5.74,5.74,0,0,1-1.17-3.53A5.86,5.86,0,0,1,21.65,13h.78V9.1a.84.84,0,0,1,.79-.78h1.56a.84.84,0,0,1,.79.78V13h.49a8,8,0,0,1,5.09,1.77.61.61,0,0,1,.3.54,1.1,1.1,0,0,1-.2.63L30.08,17a.92.92,0,0,1-.49.25.54.54,0,0,1-.49-.15,5.15,5.15,0,0,0-3-1H21.65a2.73,2.73,0,0,0-2.21,4.36,2.36,2.36,0,0,0,1.42.93l7.06,2.06A5.46,5.46,0,0,1,31.06,25.57Z" />
            </>
        ),
    },
    'dollar-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M41.24,2.06a4.5,4.5,0,0,1,3.33,1.37,4.54,4.54,0,0,1,1.37,3.33V41.24a4.68,4.68,0,0,1-4.7,4.7H6.76a4.5,4.5,0,0,1-3.33-1.37,4.54,4.54,0,0,1-1.37-3.33V6.76A4.54,4.54,0,0,1,3.43,3.43,4.5,4.5,0,0,1,6.76,2.06ZM26.35,36.54a6.26,6.26,0,0,0,4.7-2.06,6.83,6.83,0,0,0,2-4.9,7.11,7.11,0,0,0-1.32-4.21,6.64,6.64,0,0,0-3.48-2.55l-7-2.15a1.82,1.82,0,0,1-1.08-.83,2.78,2.78,0,0,1-.39-1.47,2.29,2.29,0,0,1,.59-1.52,1.76,1.76,0,0,1,1.37-.69h4.41a4.07,4.07,0,0,1,2,.49,1.92,1.92,0,0,0,1,.3,1.31,1.31,0,0,0,.94-.39l1.17-1.18a1.5,1.5,0,0,0,.44-1.22,1.62,1.62,0,0,0-.73-1.23,8.7,8.7,0,0,0-4.51-1.47V9.89a1.53,1.53,0,0,0-.44-1.12,1.5,1.5,0,0,0-1.13-.44H23.22a1.5,1.5,0,0,0-1.13.44,1.53,1.53,0,0,0-.44,1.12v1.57A6.26,6.26,0,0,0,17,13.52a6.83,6.83,0,0,0-2,4.9,7.11,7.11,0,0,0,1.32,4.21,6.64,6.64,0,0,0,3.48,2.55l7.05,2.15a1.82,1.82,0,0,1,1.08.83,2.78,2.78,0,0,1,.39,1.47,2.29,2.29,0,0,1-.59,1.52,1.76,1.76,0,0,1-1.37.69H21.94a4.07,4.07,0,0,1-2-.49,1.92,1.92,0,0,0-1-.3,1.31,1.31,0,0,0-.94.39l-1.17,1.18a1.5,1.5,0,0,0-.44,1.22,1.62,1.62,0,0,0,.73,1.23,8.7,8.7,0,0,0,4.51,1.47v1.57a1.53,1.53,0,0,0,.44,1.12,1.5,1.5,0,0,0,1.13.44h1.56a1.5,1.5,0,0,0,1.13-.44,1.53,1.53,0,0,0,.44-1.12Z" />
            </>
        ),
    },
    'dashboard-platform': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path
                    d="M20.245-198.321l-2.68-10L1.016-203.887l2.68,10L0-192.9l9.386,35.027,7.853-2.1-.677-2.527-5.326,1.427L7.981-173.22l3.7-.99L11-176.737l-3.7.99-4.1-15.3,1.168-.313h0l2.527-.677h0l3.949-1.058-.677-2.527-3.949,1.058-2-7.473,11.495-3.08,2,7.473-2.116.567.677,2.527,10.92-2.926,4.1,15.3-15.761,4.223,1.8,6.711,2.527-.677L18.742-176.1l13.234-3.546,2.523-.676h0l-4.776-17.826L43.5-201.844l2.41,8.995-9.226,2.472.677,2.527,9.226-2.472,3.925,14.647L18.45-167.084l1.7,6.332,2.527-.677-1.019-3.8,32.066-8.592-8.366-31.223Z"
                    transform="translate(0 208.321)"
                />
            </>
        ),
    },
    'dashboard-money-sheet': {
        width: 48,
        height: 48,
        viewBox: '-10 -2 50 50',
        svg: (
            <>
                <path
                    d="M33.387-153.283v1.563a4.8,4.8,0,0,1-4.8,4.8H4.8a4.8,4.8,0,0,1-4.8-4.8v-37.356a4.8,4.8,0,0,1,4.8-4.8H23.257l10.13,11.1v13.709a9.676,9.676,0,0,1,4.072,7.891A9.676,9.676,0,0,1,33.387-153.283Zm-2.616-17.1a9.657,9.657,0,0,0-2.881-.473v-6.357H5.5v21.858H20.032a9.672,9.672,0,0,0,7.742,3.868,9.666,9.666,0,0,0,3-.473v.243a2.184,2.184,0,0,1-2.183,2.183H4.8a2.184,2.184,0,0,1-2.183-2.183v-37.356A2.184,2.184,0,0,1,4.8-191.259H22.1l8.668,9.5Zm-2.5,4.738a3.609,3.609,0,0,0-2.146.616A3.529,3.529,0,0,0,25.19-164a3.3,3.3,0,0,0-.5,1.316h5.189l-.205,1.02H24.566q-.008.16-.008.3,0,.586.008.7h4.9l-.213,1.02H24.726a3.418,3.418,0,0,0,1.537,2.473,3.617,3.617,0,0,0,1.895.525,3.13,3.13,0,0,0,2.222-.746v1.567a4.929,4.929,0,0,1-2.1.441,4.579,4.579,0,0,1-4.649-2.861,7.863,7.863,0,0,1-.418-1.4H22.048l.213-1.02h.8q-.023-.32-.023-.692,0-.152.008-.312h-1l.213-1.02h.883a4.835,4.835,0,0,1,2.458-3.561,5.539,5.539,0,0,1,2.7-.67,4.342,4.342,0,0,1,2.381.616l-.3,1.415A3.216,3.216,0,0,0,28.272-165.648ZM16.021-156.7v-3.775H6.842v3.775Zm2.093-3.775a9.619,9.619,0,0,0,1.069,3.775H17.365v-3.775Zm1.049-5.131a9.618,9.618,0,0,0-1.052,3.787h-.745v-3.787ZM6.842-161.821h9.179v-3.787H6.842Zm19.405-8.918A9.687,9.687,0,0,0,20-166.952H17.365v-3.787ZM6.842-166.952h9.179v-3.787H6.842Zm0-8.919v3.787h9.179v-3.787Zm10.524,0v3.787h9.179v-3.787Z"
                    transform="translate(0 193.875)"
                />
            </>
        ),
    },
    'dashboard-platform-split': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path
                    d="M26.267-250.108v58.273a1.309,1.309,0,0,0,1.308,1.308,1.309,1.309,0,0,0,1.308-1.308v-58.273a1.309,1.309,0,0,0-1.308-1.308A1.309,1.309,0,0,0,26.267-250.108Zm-2.853,15.557v-2.709l-3.169.849-2.68-10L1.016-241.976l2.68,10-3.7.99,9.386,35.027,7.853-2.1-.677-2.527-5.326,1.427L7.981-211.309l3.7-.99L11-214.827l-3.7.99-4.1-15.3,7.644-2.048-.677-2.527-3.949,1.058-2-7.473,11.495-3.08,2,7.473-2.116.567.677,2.527Zm0,28.048-4.964,1.33,1.7,6.332,2.527-.677-1.019-3.8,1.76-.472Zm8.322-32.986v2.709L43.5-239.934l2.41,8.995-9.226,2.472.677,2.527,9.226-2.472,3.925,14.647-18.779,5.032v2.709l21.984-5.891-8.366-31.223Zm-8.322,21.336-7.877,2.111,1.8,6.711,2.527-.677-1.121-4.184,4.672-1.252Zm11.089-.263-2.767.741v-11.067Z"
                    transform="translate(0 251.416)"
                />
            </>
        ),
    },
    'dashboard-gantt': {
        width: 40,
        height: 40,
        viewBox: '-7 -3 60 60',
        svg: (
            <>
                <path
                    d="M0-191.018v43.962a1.308,1.308,0,0,0,1.308,1.308H47.671a1.309,1.309,0,0,0,1.308-1.308,1.309,1.309,0,0,0-1.308-1.308H2.616v-42.654a1.309,1.309,0,0,0-1.308-1.308A1.309,1.309,0,0,0,0-191.018ZM45.366-153.85a2.29,2.29,0,0,0-2.289-2.289H41.81a2.29,2.29,0,0,0-2.289,2.289,2.29,2.29,0,0,0,2.289,2.289h1.267A2.29,2.29,0,0,0,45.366-153.85Zm-3.637-6.636a2.29,2.29,0,0,0-2.289-2.289H35.25a2.29,2.29,0,0,0-2.289,2.289A2.29,2.29,0,0,0,35.25-158.2h4.19A2.29,2.29,0,0,0,41.729-160.486Zm-2.209-6.636a2.291,2.291,0,0,0-2.289-2.289H21.941a2.29,2.29,0,0,0-2.289,2.289,2.29,2.29,0,0,0,2.289,2.289H37.231A2.291,2.291,0,0,0,39.521-167.123Zm-2.4-6.636a2.29,2.29,0,0,0-2.289-2.289h-9.76a2.29,2.29,0,0,0-2.289,2.289,2.29,2.29,0,0,0,2.289,2.289h9.76A2.29,2.29,0,0,0,37.12-173.759ZM33.279-180.4a2.291,2.291,0,0,0-2.289-2.289H12.7a2.29,2.29,0,0,0-2.289,2.289,2.29,2.29,0,0,0,2.289,2.289H30.99A2.291,2.291,0,0,0,33.279-180.4Zm-16.631-6.636a2.29,2.29,0,0,0-2.289-2.289H7.8a2.29,2.29,0,0,0-2.289,2.289A2.29,2.29,0,0,0,7.8-184.743h6.56A2.29,2.29,0,0,0,16.648-187.032Z"
                    transform="translate(0 192.326)"
                />
            </>
        ),
    },

    'ellipsis-h': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M39.61,17.61A6.37,6.37,0,0,1,46,24a6.39,6.39,0,0,1-10.91,4.52,6.42,6.42,0,0,1,0-9A6.13,6.13,0,0,1,39.61,17.61ZM30.39,24A6.38,6.38,0,0,0,24,17.61,6.38,6.38,0,0,0,17.61,24,6.38,6.38,0,0,0,24,30.39,6.38,6.38,0,0,0,30.39,24Zm-22-6.39a6.13,6.13,0,0,1,4.52,1.87,6.42,6.42,0,0,1,0,9,6.13,6.13,0,0,1-4.52,1.87,6.14,6.14,0,0,1-4.53-1.87,6.42,6.42,0,0,1,0-9A6.14,6.14,0,0,1,8.39,17.61Z" />
            </>
        ),
    },
    'ellipsis-h-alt': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M8.39,17.61a6.13,6.13,0,0,1,4.52,1.87,6.42,6.42,0,0,1,0,9,6.13,6.13,0,0,1-4.52,1.87,6.14,6.14,0,0,1-4.53-1.87,6.42,6.42,0,0,1,0-9A6.14,6.14,0,0,1,8.39,17.61Zm0,9.94a3.55,3.55,0,1,0,0-7.1,3.58,3.58,0,0,0-2.53,6.08A3.43,3.43,0,0,0,8.39,27.55ZM24,17.61A6.38,6.38,0,0,1,30.39,24,6.38,6.38,0,0,1,24,30.39,6.38,6.38,0,0,1,17.61,24,6.38,6.38,0,0,1,24,17.61Zm0,9.94a3.58,3.58,0,0,0,2.53-6.08,3.58,3.58,0,0,0-5.06,5.06A3.43,3.43,0,0,0,24,27.55Zm15.61-9.94A6.37,6.37,0,0,1,46,24a6.39,6.39,0,0,1-10.91,4.52,6.42,6.42,0,0,1,0-9A6.13,6.13,0,0,1,39.61,17.61Zm0,9.94a3.58,3.58,0,0,0,2.53-6.08,3.58,3.58,0,1,0-2.53,6.08Z" />
            </>
        ),
    },
    'ellipsis-v': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M17.61,8.39a6.14,6.14,0,0,1,1.87-4.53,6.42,6.42,0,0,1,9,0,6.14,6.14,0,0,1,1.87,4.53,6.13,6.13,0,0,1-1.87,4.52,6.42,6.42,0,0,1-9,0A6.13,6.13,0,0,1,17.61,8.39ZM24,17.61A6.38,6.38,0,0,0,17.61,24,6.38,6.38,0,0,0,24,30.39,6.38,6.38,0,0,0,30.39,24,6.38,6.38,0,0,0,24,17.61Zm-6.39,22A6.37,6.37,0,0,1,24,33.23a6.39,6.39,0,0,1,4.52,10.91,6.42,6.42,0,0,1-9,0A6.14,6.14,0,0,1,17.61,39.61Z" />
            </>
        ),
    },
    'ellipsis-v-alt': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M17.61,39.61A6.37,6.37,0,0,1,24,33.23a6.39,6.39,0,0,1,4.52,10.91,6.42,6.42,0,0,1-9,0A6.14,6.14,0,0,1,17.61,39.61Zm9.94,0a3.48,3.48,0,1,0-1,2.53A3.45,3.45,0,0,0,27.55,39.61ZM17.61,24A6.38,6.38,0,0,1,24,17.61,6.38,6.38,0,0,1,30.39,24,6.38,6.38,0,0,1,24,30.39,6.38,6.38,0,0,1,17.61,24Zm9.94,0a3.58,3.58,0,0,0-6.08-2.53,3.58,3.58,0,0,0,5.06,5.06A3.43,3.43,0,0,0,27.55,24ZM17.61,8.39a6.14,6.14,0,0,1,1.87-4.53,6.42,6.42,0,0,1,9,0,6.14,6.14,0,0,1,1.87,4.53,6.13,6.13,0,0,1-1.87,4.52,6.42,6.42,0,0,1-9,0A6.13,6.13,0,0,1,17.61,8.39Zm9.94,0a3.58,3.58,0,0,0-6.08-2.53,3.43,3.43,0,0,0-1,2.53,3.55,3.55,0,1,0,7.1,0Z" />
            </>
        ),
    },
    'exclamation-triangle': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M45.94,40.56a3.69,3.69,0,0,1-1.23,2.14,3.48,3.48,0,0,1-2.37.88H5.66a3.48,3.48,0,0,1-2.37-.88,3.69,3.69,0,0,1-1.23-2.14,4,4,0,0,1,.38-2.49L20.79,6.28a3.43,3.43,0,0,1,1.95-1.65,3.89,3.89,0,0,1,2.52,0,3.43,3.43,0,0,1,1.95,1.65L45.56,38.07A4,4,0,0,1,45.94,40.56ZM25.07,7.5a1.24,1.24,0,0,0-2.14,0L4.58,39.3a1.24,1.24,0,0,0,0,1.22,1.12,1.12,0,0,0,1,.61H42.34a1.12,1.12,0,0,0,1-.61,1.24,1.24,0,0,0,0-1.22Zm-2.6,26a2.22,2.22,0,0,0,0,3.06,2.22,2.22,0,0,0,3.06,0,2.16,2.16,0,0,0-3.06-3.06Zm0,0a2.22,2.22,0,0,0,0,3.06,2.22,2.22,0,0,0,3.06,0,2.16,2.16,0,0,0-3.06-3.06Zm2.37-16.82a1,1,0,0,1,.73.27.67.67,0,0,1,.19.65l-.54,12.84a.91.91,0,0,1-.26.65.89.89,0,0,1-.65.27h-.62a.89.89,0,0,1-.65-.27.83.83,0,0,1-.26-.57l-.54-12.92a.67.67,0,0,1,.19-.65,1,1,0,0,1,.73-.27Z" />
            </>
        ),
    },
    eye: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M46,24a3.39,3.39,0,0,1-.53,1.83,24.35,24.35,0,0,1-8.94,8.86A24.25,24.25,0,0,1,24,38.06a24.25,24.25,0,0,1-12.53-3.37,24.35,24.35,0,0,1-8.94-8.86,3.42,3.42,0,0,1,0-3.66A24.94,24.94,0,0,1,24,9.94,24.94,24.94,0,0,1,45.47,22.17,3.39,3.39,0,0,1,46,24Zm-2.6-.61a21.23,21.23,0,0,0-4.2-5.16,23.44,23.44,0,0,0-5.58-3.7,11.28,11.28,0,0,1,2.6,7.33,11.45,11.45,0,0,1-1.64,6,12.21,12.21,0,0,1-10.58,6,12.21,12.21,0,0,1-10.58-6,11.66,11.66,0,0,1,.95-13.29A22.86,22.86,0,0,0,8.8,18.27a22.44,22.44,0,0,0-4.2,5.12,1.05,1.05,0,0,0,0,1.22,22,22,0,0,0,8.17,8,22.16,22.16,0,0,0,11.23,3,22.42,22.42,0,0,0,11.23-2.94,21.58,21.58,0,0,0,8.17-8.06A1.05,1.05,0,0,0,43.4,23.39Zm-10.92-6.3a9.46,9.46,0,0,0-3.55-3.44,10.27,10.27,0,0,0-9.86,0,9.46,9.46,0,0,0-3.55,3.44,9.42,9.42,0,0,0,0,9.55,9.43,9.43,0,0,0,3.55,3.43,10.27,10.27,0,0,0,9.86,0,9.43,9.43,0,0,0,3.55-3.43,9.42,9.42,0,0,0,0-9.55ZM20.52,25a5,5,0,0,0,7,0,5,5,0,0,0,0-7A4.74,4.74,0,0,0,24,16.67a4.49,4.49,0,0,0-2.29.61,1.95,1.95,0,0,1,2,2,1.94,1.94,0,0,1-.57,1.42,2,2,0,0,1-3.4-1.42,4.55,4.55,0,0,0-.61,2.3A4.72,4.72,0,0,0,20.52,25Z" />
            </>
        ),
    },
    'eye-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M45.47,22.15a3.49,3.49,0,0,1,0,3.7,24.57,24.57,0,0,1-8.94,8.94A24.17,24.17,0,0,1,24,38.18a24.17,24.17,0,0,1-12.53-3.39,24.57,24.57,0,0,1-8.94-8.94,3.49,3.49,0,0,1,0-3.7,24.57,24.57,0,0,1,8.94-8.94A24.17,24.17,0,0,1,24,9.82a24.17,24.17,0,0,1,12.53,3.39A24.57,24.57,0,0,1,45.47,22.15ZM24,34.48a9.77,9.77,0,0,0,5.19-1.43A10.89,10.89,0,0,0,33,29.24a10.44,10.44,0,0,0,0-10.48A10.89,10.89,0,0,0,29.19,15a10.13,10.13,0,0,0-10.38,0A10.89,10.89,0,0,0,15,18.76a10.44,10.44,0,0,0,0,10.48,10.89,10.89,0,0,0,3.79,3.81A9.77,9.77,0,0,0,24,34.48ZM31.94,24a7.71,7.71,0,0,0-2.33-5.66A7.59,7.59,0,0,0,24,16a7.87,7.87,0,0,0-3.74.92,3.18,3.18,0,0,1,2.33,1,3.25,3.25,0,0,1,.95,2.35,3.22,3.22,0,0,1-.95,2.35,3.28,3.28,0,0,1-4.66,0,3.19,3.19,0,0,1-1-2.35A8.14,8.14,0,0,0,16.06,24a7.71,7.71,0,0,0,2.33,5.66,7.87,7.87,0,0,0,11.22,0A7.71,7.71,0,0,0,31.94,24Z" />
            </>
        ),
    },
    file: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M40.48,13.36V41.85A4.1,4.1,0,0,1,36.36,46H11.64a4.1,4.1,0,0,1-4.12-4.12V6.15a4,4,0,0,1,1.2-2.92A4,4,0,0,1,11.64,2H29.15a4,4,0,0,1,2.92,1.2l7.21,7.21A4,4,0,0,1,40.48,13.36ZM37.34,42.84a1.34,1.34,0,0,0,.39-1V15.76H28.81a2,2,0,0,1-2.06-2.06V4.78H11.64a1.32,1.32,0,0,0-1.37,1.37v35.7a1.32,1.32,0,0,0,1.37,1.37H36.36A1.33,1.33,0,0,0,37.34,42.84ZM30.09,5.21a2,2,0,0,0-.6-.43V13h8.24a2.4,2.4,0,0,0-.43-.61Z" />
            </>
        ),
    },
    'file-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M26.75,13.69a2,2,0,0,0,2.06,2.06H40.5V43.94A2,2,0,0,1,38.44,46H9.56A2,2,0,0,1,7.5,43.94V4.06A2,2,0,0,1,9.56,2H26.75ZM40.5,12.48A2,2,0,0,0,39.9,11L31.48,2.6A2,2,0,0,0,30,2H29.5V13h11Z" />
            </>
        ),
    },
    'file-pdf': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M40.48,13.36V41.85A4.1,4.1,0,0,1,36.36,46H11.64a4.1,4.1,0,0,1-4.12-4.12V6.15a4,4,0,0,1,1.2-2.92A4,4,0,0,1,11.64,2H29.15a4,4,0,0,1,2.92,1.2l7.21,7.21A4,4,0,0,1,40.48,13.36ZM37.34,42.84a1.34,1.34,0,0,0,.39-1V15.76H28.81a2,2,0,0,1-1.46-.6,1.94,1.94,0,0,1-.6-1.46V4.78H11.64a1.32,1.32,0,0,0-1.37,1.37v35.7a1.32,1.32,0,0,0,1.37,1.37H36.36A1.33,1.33,0,0,0,37.34,42.84ZM35,29.84a1.91,1.91,0,0,1-.47,1.5,2,2,0,0,1-1.63.64,9.09,9.09,0,0,1-2.62-.47,14,14,0,0,1-2.91-1.25A56.26,56.26,0,0,0,21.6,31.9l-1.29.42a24.61,24.61,0,0,1-2.7,4,4,4,0,0,1-2.36,1.46,2,2,0,0,1-1.68-.56A1.66,1.66,0,0,1,13,35.76q.51-2.15,5.06-4.55a60.24,60.24,0,0,0,3.6-7.55,31.28,31.28,0,0,1-.77-3.87A6.5,6.5,0,0,1,21,17a1.45,1.45,0,0,1,1.07-1.07,2.33,2.33,0,0,1,1.76.09,1.87,1.87,0,0,1,1,1.58,6.48,6.48,0,0,1,0,2.58,18.74,18.74,0,0,1-.52,2.66l-.08.26A9.37,9.37,0,0,0,28.12,28q5.05-.69,6.35.6A2,2,0,0,1,35,29.84ZM15.25,35.93a5.8,5.8,0,0,0,.94-1,15.79,15.79,0,0,0,1.63-2.23l-.26.26a9.75,9.75,0,0,0-2.74,3.26C14.82,36.25,15,36.16,15.25,35.93Zm6.43-5.67A22.07,22.07,0,0,1,26,28.89a10.9,10.9,0,0,1-3-3.6,45.76,45.76,0,0,1-2.4,5.49ZM23,17.13c-.12,0-.17.52-.17,1.55a7.43,7.43,0,0,0,.34,2.49,4.66,4.66,0,0,0,.39-2.4c0-1.15-.22-1.72-.56-1.72ZM30.09,5.21a2,2,0,0,0-.6-.43V13h8.24a2.4,2.4,0,0,0-.43-.61Zm3.56,24.67a3.27,3.27,0,0,0-1.07-.39,10,10,0,0,0-2.57,0,10.1,10.1,0,0,0,2.4.77,2.2,2.2,0,0,0,1,.09.36.36,0,0,0,.26-.09C33.87,30.15,33.85,30,33.65,29.88Z" />
            </>
        ),
    },
    'file-pdf-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M40.48,15.76V43.91A2,2,0,0,1,38.42,46H9.58a2,2,0,0,1-2.06-2.06V4.09A2,2,0,0,1,9.58,2H26.75V13.7a2,2,0,0,0,2.06,2.06Zm-16,10.13.08-.09A21.12,21.12,0,0,0,25,23.06a6.85,6.85,0,0,0,0-2.66,2.14,2.14,0,0,0-1.16-1.63A2.4,2.4,0,0,0,22,18.64a1.48,1.48,0,0,0-1.12,1.15,6.54,6.54,0,0,0-.09,2.84,30.71,30.71,0,0,0,.78,3.77,78.39,78.39,0,0,1-3.52,7.38,14.12,14.12,0,0,0-3.86,2.58c-1.26,1.26-1.55,2.34-.86,3.26a2,2,0,0,0,.9.64,2.88,2.88,0,0,0,1,.22,3.51,3.51,0,0,0,2.36-1.25,20.65,20.65,0,0,0,2.87-4.07l1.29-.43a36.09,36.09,0,0,1,5.49-1.55,12.72,12.72,0,0,0,2.88,1.2,9.54,9.54,0,0,0,2.61.43,2.15,2.15,0,0,0,1.72-.68A2.18,2.18,0,0,0,35,32.54a2.11,2.11,0,0,0-.56-1.42c-.92-.86-3-1.06-6.35-.6A9.13,9.13,0,0,1,24.43,25.89Zm-9.1,12.66a4.54,4.54,0,0,0,1-1c.51-.63,1-1.37,1.63-2.23l-.35.26a10.8,10.8,0,0,0-2.66,3.17C14.9,38.82,15.05,38.75,15.33,38.55Zm5.24-5.11,1.2-.51A31,31,0,0,1,26,31.55,10.12,10.12,0,0,1,23,28,48.34,48.34,0,0,1,20.57,33.44ZM22.8,21.51c0-1,.06-1.54.17-1.54.34,0,.52.55.52,1.67A5.69,5.69,0,0,1,23.14,24,7.43,7.43,0,0,1,22.8,21.51ZM31.47,2.63A2,2,0,0,0,30,2h-.52V13h11V12.5a2,2,0,0,0-.6-1.46Zm2,30a1.73,1.73,0,0,0-1-.34,12,12,0,0,0-2.57-.09,21,21,0,0,0,2.4.86,2.89,2.89,0,0,0,1,0l.26-.08C33.64,32.87,33.61,32.75,33.44,32.58Z" />
            </>
        ),
    },
    'file-image': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <path d="M39.29,10.42,32.08,3.21A4.13,4.13,0,0,0,29.16,2H11.63A4.15,4.15,0,0,0,7.5,6.13V41.88A4.13,4.13,0,0,0,11.63,46H36.37a4.13,4.13,0,0,0,4.13-4.12V13.34a4.18,4.18,0,0,0-1.22-2.92ZM36,13H29.5V6.47ZM11.63,41.88V6.13H25.38v8.94a2,2,0,0,0,2.05,2.06h8.94V41.88Zm2.75-4.13H33.62v-11l-2-2a1,1,0,0,0-1.46,0h0l-7.51,7.52-3.4-3.39a1,1,0,0,0-1.45,0h0l-3.39,3.39ZM18.5,17.13a4.13,4.13,0,1,0,4.13,4.12,4.12,4.12,0,0,0-4.13-4.12Z" />
        ),
    },
    'file-upload': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M40.5,13.34V41.88A4.1,4.1,0,0,1,36.38,46H11.62A4.1,4.1,0,0,1,7.5,41.88V6.12A4.1,4.1,0,0,1,11.62,2H29.16a4,4,0,0,1,2.92,1.2l7.22,7.22A4,4,0,0,1,40.5,13.34Zm-2.75,2.41H28.81a2,2,0,0,1-2.06-2.06V4.75H11.62a1.33,1.33,0,0,0-1.37,1.37V41.88a1.33,1.33,0,0,0,1.37,1.37H36.38a1.33,1.33,0,0,0,1.37-1.37ZM24,19.88a2.19,2.19,0,0,1,1.72.77l5.84,6.1A2.73,2.73,0,0,1,32.21,28,2.33,2.33,0,0,1,32,29.41a2.59,2.59,0,0,1-.86,1.08,2.15,2.15,0,0,1-1.25.39H25.38v7.56a.67.67,0,0,1-.22.47.66.66,0,0,1-.47.21H23.31a.66.66,0,0,1-.47-.21.67.67,0,0,1-.22-.47V30.88H18.07a2.15,2.15,0,0,1-1.25-.39A2.59,2.59,0,0,1,16,29.41,2.33,2.33,0,0,1,15.79,28a2.73,2.73,0,0,1,.65-1.29l5.84-6.1A2.19,2.19,0,0,1,24,19.88Zm5.07,8.24L24,22.8l-5.07,5.32ZM29.5,13h8.25a2.18,2.18,0,0,0-.43-.6L30.1,5.18a1.21,1.21,0,0,0-.6-.34Z" />
            </>
        ),
    },
    'file-x': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M40.5,13.34V41.88A4.1,4.1,0,0,1,36.38,46H11.62A4.1,4.1,0,0,1,7.5,41.88V6.12A4,4,0,0,1,8.7,3.2,4,4,0,0,1,11.62,2H29.16a4,4,0,0,1,2.92,1.2l7.22,7.22A4,4,0,0,1,40.5,13.34ZM37.36,42.86a1.32,1.32,0,0,0,.39-1V15.75H28.81a2,2,0,0,1-2.06-2.06V4.75H11.62a1.33,1.33,0,0,0-1.37,1.37V41.88a1.32,1.32,0,0,0,.39,1,1.3,1.3,0,0,0,1,.39H36.38A1.3,1.3,0,0,0,37.36,42.86ZM30.7,36.2a.91.91,0,0,1,.05,1,1,1,0,0,1-.91.52H29.5a1.08,1.08,0,0,1-.86-.43l-.77-1.12a53.46,53.46,0,0,1-3.87-6,19.35,19.35,0,0,1-1.55,2.4c-1.09,1.55-2.12,3.12-3.09,4.73a1.08,1.08,0,0,1-.86.43h-.34a1,1,0,0,1-.91-.52,1,1,0,0,1,.05-1.11l5.07-7.65-4.39-7a.9.9,0,0,1,0-1,1,1,0,0,1,.9-.52h.35a.91.91,0,0,1,.86.52l2.06,3.18c.57.86,1.17,1.86,1.8,3l.09.17a24.24,24.24,0,0,1,2-3.44l2-2.92a.91.91,0,0,1,.86-.52h.35a1,1,0,0,1,.9.52.92.92,0,0,1,0,1l-4.3,7Zm-.6-31a2.18,2.18,0,0,0-.6-.43V13h8.25a2.42,2.42,0,0,0-.43-.6Z" />
            </>
        ),
    },
    'file-x-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M26.74,13.74a2,2,0,0,0,.59,1.45,2,2,0,0,0,1.46.6H40.42V43.84a2,2,0,0,1-2.06,2H9.64a2,2,0,0,1-2.06-2V4.16A2,2,0,0,1,9.64,2.11h17.1Zm5.13,9.06v.09a1.06,1.06,0,0,0,0-1.11,1,1,0,0,0-.9-.52H28a.9.9,0,0,0-.86.52L24,27.68a28.79,28.79,0,0,0-1.37-2.74c-.51-.91-1.11-2-1.79-3.16a.9.9,0,0,0-.86-.52H17a1,1,0,0,0-.9.56,1,1,0,0,0,0,1.07l5.13,8-5.13,8a.91.91,0,0,0,0,1,1,1,0,0,0,.9.52h3a.9.9,0,0,0,.86-.52l1.62-2.82L24,34l3.16,5.9a.9.9,0,0,0,.86.52h3a1,1,0,0,0,.9-.52,1.06,1.06,0,0,0,0-1.11l-5.13-7.95Zm8.55-10.26a2,2,0,0,0-.6-1.45L31.44,2.71A2,2,0,0,0,30,2.11h-.51v11h11Z" />
            </>
        ),
    },
    folder: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <path d="M41.87,13H25.38L19.87,7.5H6.13A4.13,4.13,0,0,0,2,11.63V36.37A4.13,4.13,0,0,0,6.12,40.5H41.87A4.13,4.13,0,0,0,46,36.38h0V17.12A4.13,4.13,0,0,0,41.87,13Z" />
        ),
    },
    'folder-open': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M42.28,21.56H38.63V17.91A3.66,3.66,0,0,0,35,14.25H22.78L17.91,9.37H5.72A3.66,3.66,0,0,0,2.06,13V35a3.66,3.66,0,0,0,3.66,3.66H35.05a6.13,6.13,0,0,0,5.23-3l5.13-8.57A3.66,3.66,0,0,0,42.28,21.56ZM5.72,11.81H16.9l4.87,4.88H35a1.22,1.22,0,0,1,1.22,1.22v3.65H15.1a6.08,6.08,0,0,0-5.22,3l-5.38,9V13A1.22,1.22,0,0,1,5.72,11.81Zm37.61,14-5.14,8.56a3.69,3.69,0,0,1-3.14,1.78H5.48l6.24-10.41A3.64,3.64,0,0,1,14.86,24H42.28a1.22,1.22,0,0,1,1,1.85Z" />
            </>
        ),
    },
    'folder-plus': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M41.88,12.88H25.38l-4.7-4.75a2.75,2.75,0,0,0-1.94-.81H6.12A4.14,4.14,0,0,0,2,11.49v25a4.14,4.14,0,0,0,4.12,4.17H41.88A4.14,4.14,0,0,0,46,36.51V17.05A4.14,4.14,0,0,0,41.88,12.88Zm1.37,23.63a1.38,1.38,0,0,1-1.37,1.39H6.12a1.38,1.38,0,0,1-1.37-1.39v-25A1.38,1.38,0,0,1,6.12,10.1H18.74l4.69,4.74a2.77,2.77,0,0,0,1.95.82h16.5a1.38,1.38,0,0,1,1.37,1.39ZM31.18,25.39H25.33V19.48a1,1,0,0,0-1-1h-.69a1,1,0,0,0-1,1v5.91H16.74a1,1,0,0,0-1,1v.7a1,1,0,0,0,1,1h5.84v5.91a1,1,0,0,0,1,1h.69a1,1,0,0,0,1-1V28.17h5.85a1,1,0,0,0,1-1v-.7A1,1,0,0,0,31.18,25.39Z" />
            </>
        ),
    },
    filter: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path
                    className="cls-1"
                    d="M46,7.27a1.6,1.6,0,0,0-1.54-1H3.57A1.8,1.8,0,0,0,2,7.27a2.1,2.1,0,0,0,.09,1.88L18.78,27.27V39.93a1.78,1.78,0,0,0,1,1.61,1.51,1.51,0,0,0,.68.18,1.66,1.66,0,0,0,1.2-.54l6.84-3A1.8,1.8,0,0,0,29,36.87V27.19l16.83-18A2.1,2.1,0,0,0,46,7.27Z"
                />
            </>
        ),
    },
    gaeb: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M16.32,29.24h2.33V31.6a2,2,0,0,1-.46.38,2.6,2.6,0,0,1-.75.31,3.8,3.8,0,0,1-1.06.13A2.66,2.66,0,0,1,15,32.09a2.29,2.29,0,0,1-.89-1,3.39,3.39,0,0,1-.32-1.53v-.34a3.6,3.6,0,0,1,.31-1.53,2.17,2.17,0,0,1,.87-1,2.39,2.39,0,0,1,1.29-.34,2.36,2.36,0,0,1,1.67.53,2.17,2.17,0,0,1,.67,1.43H17.42a1.21,1.21,0,0,0-.32-.7,1.07,1.07,0,0,0-.76-.24,1,1,0,0,0-.91.46,2.42,2.42,0,0,0-.32,1.36v.34A2.27,2.27,0,0,0,15.45,31a1.19,1.19,0,0,0,1,.45,1.28,1.28,0,0,0,.92-.27v-1H16.32Zm6.12-2.75,2.19,5.85H23.28l-.38-1.16H20.82l-.38,1.16H19.1l2.17-5.85h1.17Zm.12,3.67L21.86,28l-.7,2.14Zm6.53-2.65v-1H25.14v5.85h3.95v-1H26.4v-1.5h2.29v-1H26.4V27.51Zm1.16,4.83h-.48V26.49h2.07a2.83,2.83,0,0,1,1.63.4,1.51,1.51,0,0,1,.36,1.95,1.28,1.28,0,0,1-.62.49,1.21,1.21,0,0,1,.72.48,1.37,1.37,0,0,1,.24.8,1.56,1.56,0,0,1-.55,1.29,2.49,2.49,0,0,1-1.59.44ZM31,28.93h.81a1.2,1.2,0,0,0,.7-.17.61.61,0,0,0,.24-.53.65.65,0,0,0-.23-.55,1.13,1.13,0,0,0-.71-.17H31Zm0,2.39h1a.93.93,0,0,0,.66-.2.65.65,0,0,0,.22-.52c0-.53-.28-.79-.83-.79H31Zm9.47-18V41.88A4.13,4.13,0,0,1,36.37,46H11.63A4.13,4.13,0,0,1,7.5,41.88V6.13A4.14,4.14,0,0,1,11.63,2H29.16a4.15,4.15,0,0,1,2.92,1.21l7.21,7.21A4.15,4.15,0,0,1,40.5,13.34ZM29.5,13h8.2a1.37,1.37,0,0,0-.36-.64L30.13,5.16a1.34,1.34,0,0,0-.63-.36Zm8.25,2.75H28.81a2.06,2.06,0,0,1-2.06-2.07V4.76H11.63a1.38,1.38,0,0,0-1.38,1.37V41.88a1.38,1.38,0,0,0,1.38,1.37H36.37a1.38,1.38,0,0,0,1.38-1.37Z" />
            </>
        ),
    },
    'gaeb-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M38.4,46H9.6a2.07,2.07,0,0,1-2.06-2.06V4.11A2.07,2.07,0,0,1,9.6,2.05H26.74V13.71a2.06,2.06,0,0,0,2.06,2.06H40.46V43.89A2.07,2.07,0,0,1,38.4,46ZM15.86,26.3a2.5,2.5,0,0,0-1.3.34,2.18,2.18,0,0,0-.86,1,3.52,3.52,0,0,0-.31,1.53v.35A3.33,3.33,0,0,0,13.71,31a2.22,2.22,0,0,0,.89,1,2.72,2.72,0,0,0,1.34.33,3.73,3.73,0,0,0,1-.13,2.48,2.48,0,0,0,.75-.31,2.08,2.08,0,0,0,.46-.37V29.15H15.87v.92h1.07v1a1.29,1.29,0,0,1-.93.28,1.18,1.18,0,0,1-1-.46,2.34,2.34,0,0,1-.34-1.36v-.35A2.39,2.39,0,0,1,15,27.78a1,1,0,0,1,.91-.46,1.12,1.12,0,0,1,.76.24,1.22,1.22,0,0,1,.32.7H18.2a2.26,2.26,0,0,0-.67-1.44A2.45,2.45,0,0,0,15.86,26.3Zm13.46.09v5.84h2.26a2.48,2.48,0,0,0,1.59-.44,1.56,1.56,0,0,0,.55-1.29,1.44,1.44,0,0,0-.24-.8,1.24,1.24,0,0,0-.72-.48,1.16,1.16,0,0,0,.62-.49A1.29,1.29,0,0,0,33.6,28a1.39,1.39,0,0,0-.57-1.2,2.75,2.75,0,0,0-1.63-.4Zm-4.62,0v5.84h3.95v-1H26v-1.5h2.28v-1H26V27.41h2.68v-1Zm-4.32,4.69h2.08l.38,1.15h1.34L22,26.39H20.83l-2.17,5.84H20l.38-1.16Zm11.2.14h-1V29.71h1.06a.72.72,0,0,1,.82.79.65.65,0,0,1-.23.52A.89.89,0,0,1,31.58,31.22Zm-9.46-1.16H20.71l.7-2.14.7,2.13Zm9.27-1.23h-.81V27.41h.81a1.17,1.17,0,0,1,.71.17.68.68,0,0,1,.23.55.65.65,0,0,1-.23.52,1.28,1.28,0,0,1-.71.18ZM40.46,13h-11v-11H30a2.09,2.09,0,0,1,1.46.6l8.39,8.41a2.05,2.05,0,0,1,.6,1.45Z" />
            </>
        ),
    },
    gantt: {
        width: 30,
        height: 30,
        viewBox: '0 0 30 30',
        svg: (
            <>
                <g transform="translate(4.105 4)">
                    <path
                        d="M0,23.218a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V22.218A1,1,0,0,1,0,23.218Z"
                        transform="translate(0 0)"
                    />
                    <path
                        d="M23.056,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H23.056a1,1,0,0,1,1,1A1,1,0,0,1,23.056,1Z"
                        transform="translate(0 22.218)"
                    />
                    <path
                        d="M5.45,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H5.45a1,1,0,0,1,1,1A1,1,0,0,1,5.45,1Z"
                        transform="translate(3.773 4.192)"
                    />
                    <path
                        d="M5.03,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H5.03a1,1,0,0,1,1,1A1,1,0,0,1,5.03,1Z"
                        transform="translate(5.869 8.803)"
                    />
                    <path
                        d="M9.222,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H9.222a1,1,0,0,1,1,1A1,1,0,0,1,9.222,1Z"
                        transform="translate(5.869 13.415)"
                    />
                    <path
                        d="M9.222,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H9.222a1,1,0,0,1,1,1A1,1,0,0,1,9.222,1Z"
                        transform="translate(12.576 18.026)"
                    />
                </g>
            </>
        ),
    },
    'grip-horizontal': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M43.6,8.32H35.76a2.35,2.35,0,0,0-2.35,2.35v7.84a2.35,2.35,0,0,0,2.35,2.35H43.6A2.35,2.35,0,0,0,46,18.51V10.67A2.35,2.35,0,0,0,43.6,8.32Zm-.78,9.41H36.55V11.45h6.27ZM27.92,8.32H20.08a2.35,2.35,0,0,0-2.35,2.35v7.84a2.35,2.35,0,0,0,2.35,2.35h7.84a2.35,2.35,0,0,0,2.35-2.35V10.67A2.35,2.35,0,0,0,27.92,8.32Zm-.78,9.41H20.86V11.45h6.28ZM12.24,8.32H4.4a2.35,2.35,0,0,0-2.35,2.35v7.84A2.35,2.35,0,0,0,4.4,20.86h7.84a2.35,2.35,0,0,0,2.35-2.35V10.67A2.35,2.35,0,0,0,12.24,8.32Zm-.79,9.41H5.18V11.45h6.27ZM43.6,27.14H35.76a2.35,2.35,0,0,0-2.35,2.35v7.84a2.35,2.35,0,0,0,2.35,2.35H43.6A2.35,2.35,0,0,0,46,37.33V29.49A2.35,2.35,0,0,0,43.6,27.14Zm-.78,9.41H36.55V30.27h6.27Zm-14.9-9.41H20.08a2.35,2.35,0,0,0-2.35,2.35v7.84a2.35,2.35,0,0,0,2.35,2.35h7.84a2.35,2.35,0,0,0,2.35-2.35V29.49A2.35,2.35,0,0,0,27.92,27.14Zm-.78,9.41H20.86V30.27h6.28Zm-14.9-9.41H4.4a2.35,2.35,0,0,0-2.35,2.35v7.84A2.35,2.35,0,0,0,4.4,39.68h7.84a2.35,2.35,0,0,0,2.35-2.35V29.49A2.35,2.35,0,0,0,12.24,27.14Zm-.79,9.41H5.18V30.27h6.27Z" />
            </>
        ),
    },
    'grip-lines': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M45.18,17.72H2.82a.79.79,0,0,0-.79.79v1.57a.78.78,0,0,0,.79.78H45.18a.78.78,0,0,0,.79-.78V18.51A.79.79,0,0,0,45.18,17.72Zm0,9.42H2.82a.78.78,0,0,0-.79.78v1.57a.79.79,0,0,0,.79.79H45.18a.79.79,0,0,0,.79-.79V27.92A.78.78,0,0,0,45.18,27.14Z" />
            </>
        ),
    },
    hand: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M39.68,12.23a5.2,5.2,0,0,1,2.57,1.85,5,5,0,0,1,1,3V28.9a18,18,0,0,1-.43,3.87l-2.75,11.6a2,2,0,0,1-.73,1.2,2,2,0,0,1-1.24.43H18.15a2,2,0,0,1-1.72-.86L5.69,30.19a5.27,5.27,0,0,1-.85-3.91,4.91,4.91,0,0,1,2-3.31,4.91,4.91,0,0,1,3-1,5.54,5.54,0,0,1,3,.86V10.26a4.9,4.9,0,0,1,1.07-3.09,5.17,5.17,0,0,1,2.71-1.85,4.8,4.8,0,0,1,3.27.21,4.66,4.66,0,0,1,2.7-3.09,5.16,5.16,0,0,1,4.08,0,4.77,4.77,0,0,1,2.84,3.05,4.91,4.91,0,0,1,3.31-.22,5,5,0,0,1,2.7,1.85,5.15,5.15,0,0,1,1,3.18v2A5.24,5.24,0,0,1,39.68,12.23Zm.81,4.9a2.09,2.09,0,0,0-1.2-2.1,2.55,2.55,0,0,0-2.36,0,2.2,2.2,0,0,0-1.16,2.15V24a.67.67,0,0,1-.22.47.66.66,0,0,1-.47.21h-.69a.66.66,0,0,1-.47-.21A.63.63,0,0,1,33.7,24V10.26a2.22,2.22,0,0,0-1.16-2.11,2.39,2.39,0,0,0-2.36,0A2.19,2.19,0,0,0,29,10.26V24a.63.63,0,0,1-.22.47.66.66,0,0,1-.47.21h-.6a.66.66,0,0,1-.47-.21A.63.63,0,0,1,27,24V7.25A2.2,2.2,0,0,0,25.8,5.1a2.66,2.66,0,0,0-2.41,0,2.1,2.1,0,0,0-1.2,2.11V24a.75.75,0,0,1-.17.47.64.64,0,0,1-.52.21h-.6a.66.66,0,0,1-.47-.21.67.67,0,0,1-.22-.47V10.34A2.18,2.18,0,0,0,19.05,8.2a2.47,2.47,0,0,0-2.36,0,2.12,2.12,0,0,0-1.2,2.06V28.64a.71.71,0,0,1-1.29.43l-2.41-3.35a2.17,2.17,0,0,0-1.54-1,2.42,2.42,0,0,0-1.76.42,2.15,2.15,0,0,0-1,1.55,2.79,2.79,0,0,0,.39,1.85L18.5,43.25H37.57l2.58-11.08a15.79,15.79,0,0,0,.34-3.27Z" />
            </>
        ),
    },
    'hand-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M39.9,13a3.14,3.14,0,0,1,2.36,1,3.37,3.37,0,0,1,1,2.41V29.33a16,16,0,0,1-.43,3.78L40.5,42.82a3.63,3.63,0,0,1-1.42,2.28,4,4,0,0,1-2.53.9h-17a4.24,4.24,0,0,1-1.85-.43,3.87,3.87,0,0,1-1.5-1.29L5.44,29.5a3.45,3.45,0,0,1-.65-2.62,3.23,3.23,0,0,1,1.38-2.24A3.46,3.46,0,0,1,8.75,24a3.16,3.16,0,0,1,2.19,1.33l2.75,3.79V8.88a3.37,3.37,0,0,1,1-2.41,3.19,3.19,0,0,1,2.4-1,3.33,3.33,0,0,1,2.45,1,3.39,3.39,0,0,1,1,2.49V24h.69V5.44a3.37,3.37,0,0,1,1-2.41,3.19,3.19,0,0,1,2.4-1,3.32,3.32,0,0,1,2.45,1,3.4,3.4,0,0,1,1,2.49V24h.68V8.79a3.37,3.37,0,0,1,1-2.41,3.24,3.24,0,0,1,2.41-1,3.39,3.39,0,0,1,2.45,1,3.44,3.44,0,0,1,1,2.5V24h.69V16.52A3.52,3.52,0,0,1,39.9,13Z" />
            </>
        ),
    },
    history: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M4,3.48A1.06,1.06,0,0,1,5.07,4.54V13A21.77,21.77,0,0,1,24,2.07a21.38,21.38,0,0,1,11,3A21.9,21.9,0,0,1,45.93,24a21.16,21.16,0,0,1-3,11,22.35,22.35,0,0,1-8,8A21.83,21.83,0,0,1,16,44.43a21.87,21.87,0,0,1-6.72-4.16A.86.86,0,0,1,9,39.52a1,1,0,0,1,.26-.75l.62-.62a1,1,0,0,1,.75-.35,1.18,1.18,0,0,1,.75.26,19.15,19.15,0,0,0,5.8,3.58A18.26,18.26,0,0,0,24,42.93a18.48,18.48,0,0,0,9.46-2.52,18.94,18.94,0,0,0,6.9-6.9A18.49,18.49,0,0,0,42.93,24a18.48,18.48,0,0,0-2.52-9.46,18.94,18.94,0,0,0-6.9-6.9A18.49,18.49,0,0,0,24,5.07,18.82,18.82,0,0,0,7.2,15.33h8.66a1.06,1.06,0,0,1,1.07,1.06v.89a1.06,1.06,0,0,1-.31.75,1,1,0,0,1-.76.31H3.13A1,1,0,0,1,2.38,18a1,1,0,0,1-.31-.75V4.54A1.06,1.06,0,0,1,3.13,3.48ZM32,30.81a1.09,1.09,0,0,0,.22-.8.94.94,0,0,0-.4-.7l-6.37-4.6V11.62a1,1,0,0,0-1.06-1.06h-.7a1,1,0,0,0-1.06,1.06v14.5l7.51,5.49a1,1,0,0,0,.8.17,1.14,1.14,0,0,0,.71-.44Z" />
            </>
        ),
    },
    info: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M35.06,5a22.31,22.31,0,0,1,8,8,21.34,21.34,0,0,1,3,11,21.31,21.31,0,0,1-3,11,22.24,22.24,0,0,1-8,8,21.34,21.34,0,0,1-11,3,21.34,21.34,0,0,1-11-3,22.24,22.24,0,0,1-8-8,21.31,21.31,0,0,1-3-11,21.34,21.34,0,0,1,3-11,22.31,22.31,0,0,1,8-8,21.34,21.34,0,0,1,11-3A21.34,21.34,0,0,1,35.06,5ZM14.44,7.47a19.25,19.25,0,0,0-7,7A18.71,18.71,0,0,0,4.9,24.06a18.77,18.77,0,0,0,2.57,9.63,19.22,19.22,0,0,0,7,7,18.71,18.71,0,0,0,9.62,2.57,18.77,18.77,0,0,0,9.63-2.57,19.27,19.27,0,0,0,7-7,18.77,18.77,0,0,0,2.57-9.63,18.71,18.71,0,0,0-2.57-9.62,19.13,19.13,0,0,0-7-7A18.82,18.82,0,0,0,24.06,4.9,18.71,18.71,0,0,0,14.44,7.47Zm5.67,25.42a1.06,1.06,0,0,0-.31.75v.71a1.06,1.06,0,0,0,1.07,1.07h6.38a1.07,1.07,0,0,0,.76-.31,1,1,0,0,0,.31-.76v-.71a1.06,1.06,0,0,0-1.07-1.06H26.19V20.16a1,1,0,0,0-.31-.76,1.06,1.06,0,0,0-.75-.31H20.87a1.06,1.06,0,0,0-1.07,1.07v.71a1,1,0,0,0,.31.75,1,1,0,0,0,.76.31h1.06V32.58H20.87A1,1,0,0,0,20.11,32.89Zm5.95-20.76a2.84,2.84,0,0,1-2,4.83,2.71,2.71,0,0,1-2-.84,2.77,2.77,0,0,1,0-4,2.71,2.71,0,0,1,2-.84A2.75,2.75,0,0,1,26.06,12.13Z" />
            </>
        ),
    },
    'info-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M24,2A21.34,21.34,0,0,1,35,5a22.24,22.24,0,0,1,8,8,21.34,21.34,0,0,1,3,11,21.34,21.34,0,0,1-3,11,22.24,22.24,0,0,1-8,8,21.34,21.34,0,0,1-11,3,21.34,21.34,0,0,1-11-3,22.24,22.24,0,0,1-8-8A21.34,21.34,0,0,1,2,24,21.34,21.34,0,0,1,5,13a22.24,22.24,0,0,1,8-8A21.34,21.34,0,0,1,24,2Zm5,32.29V32.16a1,1,0,0,0-.31-.75,1,1,0,0,0-.76-.31H26.84V22.23a1.07,1.07,0,0,0-.31-.76,1,1,0,0,0-.76-.31H20.1a1,1,0,0,0-.76.31,1.07,1.07,0,0,0-.31.76v2.12a1.07,1.07,0,0,0,.31.76,1,1,0,0,0,.76.31h1.06V31.1H20.1a1,1,0,0,0-.76.31,1,1,0,0,0-.31.75v2.13a1.06,1.06,0,0,0,1.07,1.06h7.8A1.06,1.06,0,0,0,29,34.29ZM24,11.76a3.79,3.79,0,0,0-3.73,3.72,3.71,3.71,0,0,0,6.35,2.62,3.64,3.64,0,0,0,0-5.23A3.59,3.59,0,0,0,24,11.76Z" />
            </>
        ),
    },
    issues: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M40.14,7.58l.5-3.69a1.62,1.62,0,0,0-1.71-1.83,2.23,2.23,0,0,0-2.2,1.83l-.27,2a9.56,9.56,0,0,0-1.93-.18h-2l.25-1.83a1.62,1.62,0,0,0-1.71-1.83,2.24,2.24,0,0,0-2.2,1.83l-.25,1.83H24.75L25,3.89a1.62,1.62,0,0,0-1.71-1.83,2.24,2.24,0,0,0-2.2,1.83l-.25,1.83H18.89a10.2,10.2,0,0,0-2,.18l.27-2a1.62,1.62,0,0,0-1.71-1.83,2.24,2.24,0,0,0-2.2,1.83h0l-.5,3.69a10.54,10.54,0,0,0-4.88,7.28l-3,21.94c-.67,5.05,3.15,9.14,8.55,9.14H29.11c5.4,0,10.32-4.09,11-9.14l3-21.94a7.76,7.76,0,0,0-2.93-7.28ZM36.21,36.8a6.72,6.72,0,0,1-6.61,5.49H14A4.86,4.86,0,0,1,8.84,36.8l3-21.94a6.7,6.7,0,0,1,6.6-5.49H34a4.86,4.86,0,0,1,5.13,5.49ZM35,16.69a2.22,2.22,0,0,1-2.2,1.82H17.17a1.61,1.61,0,0,1-1.71-1.82,2.23,2.23,0,0,1,2.2-1.83H33.3A1.61,1.61,0,0,1,35,16.69ZM34,24a2.24,2.24,0,0,1-2.2,1.83H16.18A1.62,1.62,0,0,1,14.47,24a2.25,2.25,0,0,1,2.21-1.83H32.31A1.62,1.62,0,0,1,34,24Zm-8.8,7.31A2.24,2.24,0,0,1,23,33.14H15.2a1.61,1.61,0,0,1-1.71-1.82,2.23,2.23,0,0,1,2.2-1.83h7.82A1.62,1.62,0,0,1,25.22,31.31Z" />
            </>
        ),
    },
    'linked-document': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M13,46a6.28,6.28,0,0,1-6.27-6.3V8.31A6.31,6.31,0,0,1,13,2h18.4l9.88,9.78V39.73A6.31,6.31,0,0,1,35,46ZM13,5.14A3.16,3.16,0,0,0,9.86,8.31V39.7A3.14,3.14,0,0,0,13,42.86H35a3.15,3.15,0,0,0,3.12-3.14V13.09l-8-7.95Z" />
                <path d="M28.71,2,41.29,14.57H28.71Z" />
            </>
        ),
    },
    list: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M7.76,8a3,3,0,0,1,.9,2.19,3,3,0,0,1-.9,2.2,3,3,0,0,1-2.2.91,3,3,0,0,1-2.2-.91,3,3,0,0,1-.9-2.2A3,3,0,0,1,3.36,8a3,3,0,0,1,2.2-.91A3,3,0,0,1,7.76,8Zm0,13.78a3.1,3.1,0,0,1-2.2,5.3,3.1,3.1,0,1,1,0-6.2A3,3,0,0,1,7.76,21.8Zm0,13.79a3.13,3.13,0,0,1,0,4.39,3,3,0,0,1-2.2.91A3,3,0,0,1,3.36,40a3.13,3.13,0,0,1,0-4.39,3,3,0,0,1,2.2-.91A3,3,0,0,1,7.76,35.59Zm37.61-24a.5.5,0,0,0,.17-.34V9.18A.62.62,0,0,0,45,8.66H13a.65.65,0,0,0-.52.52v2.07a.48.48,0,0,0,.18.34.46.46,0,0,0,.34.17H45A.48.48,0,0,0,45.37,11.59Zm0,13.79a.52.52,0,0,1-.35.17H13a.5.5,0,0,1-.34-.17.48.48,0,0,1-.18-.35V23a.62.62,0,0,1,.52-.52H45a.59.59,0,0,1,.52.52V25A.51.51,0,0,1,45.37,25.38Zm0,13.78a.5.5,0,0,1-.35.18H13a.48.48,0,0,1-.34-.18.46.46,0,0,1-.18-.34V36.75a.48.48,0,0,1,.18-.34.46.46,0,0,1,.34-.17H45a.48.48,0,0,1,.35.17.5.5,0,0,1,.17.34v2.07A.48.48,0,0,1,45.37,39.16Z" />
            </>
        ),
    },
    'list-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M9.05,13.17a4,4,0,0,1-2.93,1.21A4,4,0,0,1,3.2,13.17,4,4,0,0,1,2,10.25,4,4,0,0,1,3.2,7.33,4,4,0,0,1,6.12,6.12,4,4,0,0,1,9.05,7.33a4,4,0,0,1,1.2,2.92A4,4,0,0,1,9.05,13.17ZM3.2,21.08a4.15,4.15,0,0,0,0,5.84,4,4,0,0,0,2.92,1.2,4,4,0,0,0,2.93-1.2,4.15,4.15,0,0,0,0-5.84,4,4,0,0,0-2.93-1.2A4,4,0,0,0,3.2,21.08Zm0,13.75a4.15,4.15,0,0,0,0,5.84,4,4,0,0,0,2.92,1.21,4,4,0,0,0,2.93-1.21,4.15,4.15,0,0,0,0-5.84,4,4,0,0,0-2.93-1.21A4,4,0,0,0,3.2,34.83ZM44.62,13.34A1.32,1.32,0,0,0,46,12V8.53a1.32,1.32,0,0,0-1.38-1.37H14.38A1.32,1.32,0,0,0,13,8.53V12a1.32,1.32,0,0,0,1.38,1.37Zm0,13.75A1.32,1.32,0,0,0,46,25.72V22.28a1.32,1.32,0,0,0-1.38-1.37H14.38A1.32,1.32,0,0,0,13,22.28v3.44a1.32,1.32,0,0,0,1.38,1.37Zm0,13.75A1.32,1.32,0,0,0,46,39.47V36a1.32,1.32,0,0,0-1.38-1.37H14.38A1.32,1.32,0,0,0,13,36v3.44a1.32,1.32,0,0,0,1.38,1.37Z" />
            </>
        ),
    },
    'list-border': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M44.8,6A4,4,0,0,1,46,8.88V39.12a4.1,4.1,0,0,1-4.12,4.13H6.13A4.1,4.1,0,0,1,2,39.12V8.88A4.1,4.1,0,0,1,6.13,4.75H41.88A4,4,0,0,1,44.8,6ZM6.13,7.5A1.33,1.33,0,0,0,4.75,8.88V39.12A1.33,1.33,0,0,0,6.13,40.5H41.88a1.33,1.33,0,0,0,1.37-1.38V8.88A1.33,1.33,0,0,0,41.88,7.5Zm8.8,6.32a2.68,2.68,0,0,1,0,3.86,2.68,2.68,0,0,1-3.86,0,2.68,2.68,0,0,1,0-3.86,2.68,2.68,0,0,1,3.86,0Zm0,8.25A2.75,2.75,0,0,1,13,26.75a2.73,2.73,0,0,1-1.93-4.68,2.68,2.68,0,0,1,3.86,0Zm0,8.25A2.75,2.75,0,0,1,13,35a2.75,2.75,0,1,1,1.93-4.68Zm22.65-13.2a.5.5,0,0,1-.35.18H19a.5.5,0,0,1-.35-.18.48.48,0,0,1-.17-.34V14.72A.62.62,0,0,1,19,14.2H37.23a.62.62,0,0,1,.52.52v2.06A.48.48,0,0,1,37.58,17.12Zm0,8.26a.52.52,0,0,1-.35.17H19A.59.59,0,0,1,18.5,25V23a.62.62,0,0,1,.52-.52H37.23a.62.62,0,0,1,.52.52V25A.52.52,0,0,1,37.58,25.38Zm0,8.24a.5.5,0,0,1-.35.18H19a.5.5,0,0,1-.35-.18.48.48,0,0,1-.17-.34V31.22A.62.62,0,0,1,19,30.7H37.23a.62.62,0,0,1,.52.52v2.06A.48.48,0,0,1,37.58,33.62Z" />
            </>
        ),
    },
    'list-border-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M6.12,43.25A4.1,4.1,0,0,1,2,39.12V8.88A4.1,4.1,0,0,1,6.12,4.75H41.88A4.1,4.1,0,0,1,46,8.88V39.12a4.1,4.1,0,0,1-4.12,4.13ZM10.55,13.3a3.53,3.53,0,0,0,0,4.9,3.33,3.33,0,0,0,2.45,1,3.38,3.38,0,0,0,3.44-3.44A3.38,3.38,0,0,0,13,12.31,3.33,3.33,0,0,0,10.55,13.3Zm0,8.25A3.44,3.44,0,0,0,13,27.44a3.44,3.44,0,1,0,0-6.88A3.33,3.33,0,0,0,10.55,21.55Zm0,8.25A3.44,3.44,0,0,0,13,35.69a3.44,3.44,0,1,0,0-6.88A3.33,3.33,0,0,0,10.55,29.8Zm27.2-15.42a1,1,0,0,0-.3-.74,1,1,0,0,0-.73-.3H19.53a1,1,0,0,0-.73.3,1,1,0,0,0-.3.74v2.74a1,1,0,0,0,.3.74,1,1,0,0,0,.73.3H36.72a1,1,0,0,0,.73-.3,1,1,0,0,0,.3-.74Zm0,8.24a1,1,0,0,0-.3-.73,1,1,0,0,0-.73-.3H19.53a1,1,0,0,0-.73.3,1,1,0,0,0-.3.73v2.76a1,1,0,0,0,.3.73,1,1,0,0,0,.73.3H36.72a1,1,0,0,0,.73-.3,1,1,0,0,0,.3-.73Zm0,8.26a1,1,0,0,0-.3-.74,1,1,0,0,0-.73-.3H19.53a1,1,0,0,0-.73.3,1,1,0,0,0-.3.74v2.74a1,1,0,0,0,.3.74,1,1,0,0,0,.73.3H36.72a1,1,0,0,0,.73-.3,1,1,0,0,0,.3-.74Z" />
            </>
        ),
    },
    lock: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M42.05,22.45a4,4,0,0,1,1.2,2.93v16.5A4.1,4.1,0,0,1,39.12,46H8.88a4.1,4.1,0,0,1-4.13-4.12V25.38a4.1,4.1,0,0,1,4.13-4.13h1.37v-5.5A13.44,13.44,0,0,1,12.1,8.88,13.74,13.74,0,0,1,24,2a13.07,13.07,0,0,1,6.88,1.89A14.07,14.07,0,0,1,35.9,9a13.47,13.47,0,0,1,1.85,6.88v5.41h1.37A4,4,0,0,1,42.05,22.45ZM40.11,42.86a1.3,1.3,0,0,0,.39-1V25.38A1.33,1.33,0,0,0,39.12,24H8.88A1.33,1.33,0,0,0,7.5,25.38v16.5a1.33,1.33,0,0,0,1.38,1.37H39.12A1.31,1.31,0,0,0,40.11,42.86ZM13,21.25H35v-5.5a10.92,10.92,0,0,0-1.46-5.54,10.67,10.67,0,0,0-4-4,11.24,11.24,0,0,0-11.08,0,10.67,10.67,0,0,0-4,4A10.92,10.92,0,0,0,13,15.75Z" />
            </>
        ),
    },
    map: {
        width: 30,
        height: 30,
        viewBox: '0 0 30 30',
        svg: (
            <>
                <g transform="translate(1 2.097)">
                    <path d="M13.157,2a11.157,11.157,0,0,1,7.889,19.047A11.157,11.157,0,0,1,5.268,5.268,11.084,11.084,0,0,1,13.157,2Zm0,20.315A9.157,9.157,0,1,0,4,13.157,9.168,9.168,0,0,0,13.157,22.315Z" />
                    <path
                        d="M23.315,19H3a1,1,0,0,1,0-2H23.315a1,1,0,0,1,0,2Z"
                        transform="translate(0 -4.843)"
                    />
                    <path
                        d="M16.063,2a1,1,0,0,1,.738.326,16.535,16.535,0,0,1,4.324,10.811q0,.021,0,.042A16.535,16.535,0,0,1,16.8,23.989a1,1,0,0,1-1.477,0A16.535,16.535,0,0,1,11,13.178q0-.021,0-.042A16.535,16.535,0,0,1,15.325,2.326,1,1,0,0,1,16.063,2Zm3.063,11.157a14.535,14.535,0,0,0-3.063-8.608A14.535,14.535,0,0,0,13,13.157a14.535,14.535,0,0,0,3.063,8.608A14.535,14.535,0,0,0,19.126,13.157Z"
                        transform="translate(-2.906)"
                    />
                </g>
            </>
        ),
    },
    maze: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path
                    d="M1.8,1.8v44.3h44.3V1.8H1.8z M43,43H25.6v-6.3H43V43z M43,16.1H31.9v-4.7h-3.2v15.8h3.2v-7.9H43v14.2H16.1v3.2h6.3V43H5
		V17.7h14.2v-3.2H5V5h38V16.1z"
                />
            </>
        ),
    },
    'maze-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M46.06,46.06h-44v-44h44ZM15.6,34.21V37.6h6.77v6.77h3.38V37.6H44.37V34.21ZM29.14,10.52V27.45h3.38V19H44.37V15.6H32.52V10.52ZM3.75,13.91v3.38H19V13.91Z" />
            </>
        ),
    },
    'no-way': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M46,24a21.34,21.34,0,0,1-3,11,22.24,22.24,0,0,1-8,8,21.34,21.34,0,0,1-11,3,21.34,21.34,0,0,1-11-3,22.24,22.24,0,0,1-8-8A21.34,21.34,0,0,1,2,24,21.34,21.34,0,0,1,5,13a22.24,22.24,0,0,1,8-8A21.34,21.34,0,0,1,24,2,21.34,21.34,0,0,1,35,5a22.24,22.24,0,0,1,8,8A21.34,21.34,0,0,1,46,24Zm-2.84,0a18.71,18.71,0,0,0-2.57-9.62,19.13,19.13,0,0,0-7-7A18.89,18.89,0,0,0,24,4.84a18.64,18.64,0,0,0-9.58,2.57,19.13,19.13,0,0,0-7,7A18.89,18.89,0,0,0,4.84,24a18.64,18.64,0,0,0,2.57,9.58A19.24,19.24,0,0,0,24,43.16a18.64,18.64,0,0,0,9.58-2.57,19.05,19.05,0,0,0,7-7A18.8,18.8,0,0,0,43.16,24ZM13.71,25.6a1.06,1.06,0,0,1-1.06-1.07V23.47a1.06,1.06,0,0,1,1.06-1.07H34.29a1.06,1.06,0,0,1,1.06,1.07v1.06a1.06,1.06,0,0,1-1.06,1.07Z" />
            </>
        ),
    },
    'no-way-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M24,2.06A21.24,21.24,0,0,1,35,5a22.23,22.23,0,0,1,8,8,21.29,21.29,0,0,1,3,11,21.24,21.24,0,0,1-3,11,22.18,22.18,0,0,1-8,8,21.24,21.24,0,0,1-11,3,21.29,21.29,0,0,1-11-3,22.23,22.23,0,0,1-8-8,21.24,21.24,0,0,1-3-11A21.29,21.29,0,0,1,5,13a22.28,22.28,0,0,1,8-8A21.29,21.29,0,0,1,24,2.06ZM12.33,27.57H35.71a1.06,1.06,0,0,0,1.07-1.07v-5a1.06,1.06,0,0,0-.31-.75,1,1,0,0,0-.76-.31H12.33a1.06,1.06,0,0,0-1.06,1.06v5a1,1,0,0,0,.31.76A1.06,1.06,0,0,0,12.33,27.57Z" />
            </>
        ),
    },
    'not-found': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M24,2.06A21.24,21.24,0,0,1,35,5a22.23,22.23,0,0,1,8,8,21.29,21.29,0,0,1,3,11,21.24,21.24,0,0,1-3,11,22.18,22.18,0,0,1-8,8,21.24,21.24,0,0,1-11,3,21.29,21.29,0,0,1-11-3,22.23,22.23,0,0,1-8-8,21.24,21.24,0,0,1-3-11A21.29,21.29,0,0,1,5,13a22.28,22.28,0,0,1,8-8A21.29,21.29,0,0,1,24,2.06ZM12.33,27.57H35.71a1.06,1.06,0,0,0,1.07-1.07v-5a1.06,1.06,0,0,0-.31-.75,1,1,0,0,0-.76-.31H12.33a1.06,1.06,0,0,0-1.06,1.06v5a1,1,0,0,0,.31.76A1.06,1.06,0,0,0,12.33,27.57Z" />
            </>
        ),
    },
    'pencil-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path
                    className="cls-2"
                    d="M46.06,10.78a5.33,5.33,0,0,1-1.63,3.91L14.19,44.94l-9.8,1.11a2.1,2.1,0,0,1-2.32-2.32l1.12-9.79L33.43,3.69a5.5,5.5,0,0,1,7.82,0l3.18,3.18A5.34,5.34,0,0,1,46.06,10.78ZM14.61,40.64v-3H10.49V33.51h-3L5.77,35.23,5.25,40.3l2.58,2.57,5.07-.51Zm1.21-9.71a1.13,1.13,0,0,1,.43-.94L31.71,14.43l-2.57-2.57L9.89,31.1h3v4.13H17v3L36.27,19l-2.58-2.58L18.14,31.88a1.17,1.17,0,0,1-.95.42,1.15,1.15,0,0,1-.94-.42A1.15,1.15,0,0,1,15.82,30.93ZM43.31,10.78a2.63,2.63,0,0,0-.77-1.93L39.27,5.58a2.8,2.8,0,0,0-3.86,0l-4.3,4.3L38.24,17l4.3-4.29A2.65,2.65,0,0,0,43.31,10.78Z"
                />
            </>
        ),
    },
    people: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M11.07,26.41A3,3,0,0,0,10,26.2H7.23a2.83,2.83,0,0,0-2.14,1A3.33,3.33,0,0,0,4.2,29.5a1.06,1.06,0,0,1-1.1,1.1A1.06,1.06,0,0,1,2,29.5a5.41,5.41,0,0,1,1.55-3.88A4.9,4.9,0,0,1,7.23,24H10a4.92,4.92,0,0,1,2.81.89A11.47,11.47,0,0,0,11.07,26.41ZM4.72,20.18a5.46,5.46,0,0,1,0-7.76A5.27,5.27,0,0,1,8.6,10.8a5.27,5.27,0,0,1,3.88,1.62,5.46,5.46,0,0,1,0,7.76A5.27,5.27,0,0,1,8.6,21.8,5.27,5.27,0,0,1,4.72,20.18ZM6.26,14a3.33,3.33,0,0,0,0,4.68,3.18,3.18,0,0,0,2.34,1,3.28,3.28,0,0,0,3.3-3.3A3.28,3.28,0,0,0,8.6,13,3.18,3.18,0,0,0,6.26,14ZM33.11,26.2a6.64,6.64,0,0,1,2.71,2.41A7.55,7.55,0,0,1,37.2,33V36.1a3.28,3.28,0,0,1-3.3,3.3H14.1a3.28,3.28,0,0,1-3.3-3.3V33a7.55,7.55,0,0,1,1.38-4.4,6.64,6.64,0,0,1,2.71-2.41,8.1,8.1,0,0,1,3.61-.82,9.19,9.19,0,0,1,2.54.41,12,12,0,0,0,3,.41,12,12,0,0,0,3-.41,9.19,9.19,0,0,1,2.54-.41A8.1,8.1,0,0,1,33.11,26.2ZM35,33a5.63,5.63,0,0,0-1-3.17,5.11,5.11,0,0,0-1.93-1.68,5.75,5.75,0,0,0-2.61-.58,7.88,7.88,0,0,0-2.06.34,12.57,12.57,0,0,1-6.88,0,7.88,7.88,0,0,0-2.06-.34,6,6,0,0,0-2.65.58A4.53,4.53,0,0,0,14,29.84,5.63,5.63,0,0,0,13,33V36.1a1.06,1.06,0,0,0,1.1,1.1H33.9A1.06,1.06,0,0,0,35,36.1ZM20.15,23A7.7,7.7,0,0,1,16.3,16.3a7.53,7.53,0,0,1,1-3.85,7.63,7.63,0,0,1,2.82-2.82,7.71,7.71,0,0,1,7.7,0,7.63,7.63,0,0,1,2.82,2.82,7.71,7.71,0,0,1,0,7.7A7.63,7.63,0,0,1,27.85,23a7.71,7.71,0,0,1-7.7,0Zm0-10.55a5.46,5.46,0,0,0,0,7.76,5.46,5.46,0,0,0,7.76,0,5.46,5.46,0,0,0,0-7.76,5.46,5.46,0,0,0-7.76,0Zm15.4,7.76a5.46,5.46,0,0,1,0-7.76A5.5,5.5,0,0,1,44.9,16.3a5.49,5.49,0,0,1-9.38,3.88Zm8.93,5.44A5.41,5.41,0,0,1,46,29.5a1.1,1.1,0,1,1-2.2,0,3.33,3.33,0,0,0-.89-2.34,2.83,2.83,0,0,0-2.14-1H38a3,3,0,0,0-1.09.21,11.47,11.47,0,0,0-1.72-1.52A4.92,4.92,0,0,1,38,24h2.75A4.9,4.9,0,0,1,44.45,25.62ZM37.06,14a3.33,3.33,0,0,0,0,4.68A3.3,3.3,0,0,0,42.7,16.3,3.31,3.31,0,0,0,37.06,14Z" />
            </>
        ),
    },
    'people-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M13.89,25.31a9.57,9.57,0,0,0-3.43,3.09,10.18,10.18,0,0,0-1.72,4.4H4.2a2.14,2.14,0,0,1-1.55-.65A2.14,2.14,0,0,1,2,30.6V28.4a4.23,4.23,0,0,1,1.31-3.09A4.23,4.23,0,0,1,6.4,24h4.4A4.23,4.23,0,0,1,13.89,25.31ZM8.6,21.8a4.23,4.23,0,0,1-3.09-1.31,4.3,4.3,0,0,1,0-6.18,4.3,4.3,0,0,1,6.18,0,4.3,4.3,0,0,1,0,6.18A4.23,4.23,0,0,1,8.6,21.8Zm20.69,4.4a7.65,7.65,0,0,1,4,1.07,8,8,0,0,1,2.88,2.88,7.74,7.74,0,0,1,1.07,4v2a3.28,3.28,0,0,1-3.3,3.3H14.1a3.28,3.28,0,0,1-3.3-3.3v-2a7.74,7.74,0,0,1,1.07-4,8,8,0,0,1,2.88-2.88,7.65,7.65,0,0,1,4-1.07h.55a10.76,10.76,0,0,0,9.48,0ZM24,24a7.7,7.7,0,0,1-7.7-7.7,7.7,7.7,0,0,1,14.37-3.85A7.7,7.7,0,0,1,27.85,23,7.56,7.56,0,0,1,24,24Zm17.6,0A4.47,4.47,0,0,1,46,28.4v2.2a2.22,2.22,0,0,1-2.2,2.2H39.26a10.18,10.18,0,0,0-1.72-4.4,9.57,9.57,0,0,0-3.43-3.09A4.23,4.23,0,0,1,37.2,24Zm-2.2-2.2A4.47,4.47,0,0,1,35,17.4a4.4,4.4,0,1,1,7.49,3.09A4.23,4.23,0,0,1,39.4,21.8Z" />
            </>
        ),
    },
    person: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M30.54,38.73l1.58,1.59a3.18,3.18,0,0,1-2.61,1.3H5.27a3.22,3.22,0,0,1-2.34-1,3.18,3.18,0,0,1-1-2.34V35.43a9,9,0,0,1,1.24-4.61,9.21,9.21,0,0,1,8-4.62,7.91,7.91,0,0,1,2.35.48c.64.19,1.17.33,1.58.42a11.42,11.42,0,0,0,2.27.2,11.42,11.42,0,0,0,2.27-.2c.42-.09.94-.23,1.59-.42a7.8,7.8,0,0,1,2.34-.48,9,9,0,0,1,2.61.42V29a6.62,6.62,0,0,0-2.61-.55,6.79,6.79,0,0,0-1.72.34,14.43,14.43,0,0,1-4.48.76,14.37,14.37,0,0,1-4.47-.76,6.92,6.92,0,0,0-1.73-.34,6.73,6.73,0,0,0-4.95,2.06,6.76,6.76,0,0,0-2.07,5v2.89a1.09,1.09,0,0,0,.31.79,1.06,1.06,0,0,0,.79.31H29.51A1,1,0,0,0,30.54,38.73ZM13,22.83a8.62,8.62,0,0,1-3.2-3.2,9,9,0,0,1,0-8.88A8.55,8.55,0,0,1,13,7.55a8.72,8.72,0,0,1,4.44-1.17A8.56,8.56,0,0,1,21.8,7.55a8.84,8.84,0,0,1,3.2,3.2,8.81,8.81,0,0,1,0,8.88,8.92,8.92,0,0,1-3.2,3.2A8.56,8.56,0,0,1,17.39,24,8.72,8.72,0,0,1,13,22.83Zm-.24-12.32a6.34,6.34,0,0,0-1.93,4.68,6.59,6.59,0,0,0,6.61,6.61,6.34,6.34,0,0,0,4.68-1.93A6.35,6.35,0,0,0,24,15.19a6.59,6.59,0,0,0-6.61-6.61A6.35,6.35,0,0,0,12.71,10.51ZM46,33.05a2.11,2.11,0,0,1-.62,1.55L39,41a2.25,2.25,0,0,1-3.1,0l-6.2-6.26a4.1,4.1,0,0,1-1.3-3.1V26.2a2.12,2.12,0,0,1,.65-1.55A2.14,2.14,0,0,1,30.61,24h5.44a4.2,4.2,0,0,1,3.1,1.24l6.26,6.26A2.13,2.13,0,0,1,46,33.05Zm-2.2,0-6.2-6.2a2.15,2.15,0,0,0-1.58-.62H30.61v5.44a2.32,2.32,0,0,0,.62,1.59l6.26,6.19Zm-9.12-4.3a1.09,1.09,0,0,1,.31.79,1.1,1.1,0,0,1-.31.79,1.09,1.09,0,0,1-.8.31,1.06,1.06,0,0,1-.79-.31,1.1,1.1,0,0,1-.31-.79,1.09,1.09,0,0,1,.31-.79,1.06,1.06,0,0,1,.79-.31A1.09,1.09,0,0,1,34.71,28.72Z" />
            </>
        ),
    },
    'person-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M26.2,31.63a6.4,6.4,0,0,0,1.92,4.68l4,4A3.19,3.19,0,0,1,29.5,41.6H5.3a3.18,3.18,0,0,1-2.34-1A3.18,3.18,0,0,1,2,38.3V35.41a9,9,0,0,1,1.24-4.6,9.08,9.08,0,0,1,3.37-3.37,9,9,0,0,1,4.6-1.24h1.17a12,12,0,0,0,10,0h1.17a9.75,9.75,0,0,1,2.61.41ZM17.4,24A8.54,8.54,0,0,1,13,22.8a8.84,8.84,0,0,1-3.2-3.2A8.55,8.55,0,0,1,8.6,15.2a8.71,8.71,0,0,1,1.17-4.43A8.48,8.48,0,0,1,13,7.57a9,9,0,0,1,8.86,0,8.48,8.48,0,0,1,3.2,3.2A8.71,8.71,0,0,1,26.2,15.2,8.55,8.55,0,0,1,25,19.6a8.84,8.84,0,0,1-3.2,3.2A8.54,8.54,0,0,1,17.4,24Zm28,7.49a2.25,2.25,0,0,1,0,3.1L39,41a2.25,2.25,0,0,1-3.1,0l-6.25-6.26a4.16,4.16,0,0,1-1.24-3.09V26.2A2.19,2.19,0,0,1,29,24.65,2.1,2.1,0,0,1,30.6,24H36a4.07,4.07,0,0,1,3.09,1.31ZM32.8,30.05a1.64,1.64,0,1,0-1.17-.48A1.59,1.59,0,0,0,32.8,30.05Z" />
            </>
        ),
    },
    plot: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M38.1,14l.44-3.32c.5-3.67-2.28-6.64-6.2-6.64H21.69a8.12,8.12,0,0,0-8,6.64L13.26,14c-4.9,0-9.37,3.72-10,8.3l-1.12,8.3c-.61,4.58,2.86,8.3,7.75,8.3a4.41,4.41,0,0,0,4.66,5H28.75a6.09,6.09,0,0,0,6-5h0c4.9,0,9.37-3.72,10-8.3l1.12-8.3C46.46,17.76,43,14.05,38.1,14ZM17.25,10.72a4.07,4.07,0,0,1,4-3.32H31.89A2.94,2.94,0,0,1,35,10.72h0L34.55,14H16.8Zm14,28.22a2,2,0,0,1-2,1.66H15a1.47,1.47,0,0,1-1.55-1.66l.9-6.64a2,2,0,0,1,2-1.66h14.2a1.47,1.47,0,0,1,1.55,1.66Zm10-8.3a6.1,6.1,0,0,1-6,5l.45-3.32a4.41,4.41,0,0,0-4.65-5H16.79a6.09,6.09,0,0,0-6,5l-.45,3.32a4.41,4.41,0,0,1-4.65-5l1.12-8.3a6.09,6.09,0,0,1,6-5H37.65a4.41,4.41,0,0,1,4.65,5Z" />
            </>
        ),
    },
    'plus-circle': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M35.35,23.47v1.06a1.06,1.06,0,0,1-1.06,1.07H25.6v8.69a1.06,1.06,0,0,1-1.07,1.06H23.47a1.06,1.06,0,0,1-1.07-1.06V25.6H13.71a1.06,1.06,0,0,1-1.06-1.07V23.47a1.06,1.06,0,0,1,1.06-1.07H22.4V13.71a1.06,1.06,0,0,1,1.07-1.06h1.06a1.06,1.06,0,0,1,1.07,1.06V22.4h8.69A1.06,1.06,0,0,1,35.35,23.47ZM46,24A22,22,0,1,1,24,2,22,22,0,0,1,46,24Zm-2.84,0A19.16,19.16,0,1,0,24,43.16,19.16,19.16,0,0,0,43.16,24Z" />
            </>
        ),
    },
    'plus-circle-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M24,2.05A22,22,0,1,0,46,24,22,22,0,0,0,24,2.05ZM36.74,26.48a1.06,1.06,0,0,1-1.06,1.06H27.54v8.14a1.06,1.06,0,0,1-1.06,1.06h-5a1.06,1.06,0,0,1-1.06-1.06V27.54H12.32a1.06,1.06,0,0,1-1.06-1.06v-5a1.06,1.06,0,0,1,1.06-1.06h8.14V12.32a1.06,1.06,0,0,1,1.06-1.06h5a1.06,1.06,0,0,1,1.06,1.06v8.14h8.14a1.06,1.06,0,0,1,1.06,1.06Z" />
            </>
        ),
    },
    print: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M36.78,13V9.39a7.3,7.3,0,0,0-7.3-7.3h-11a7.3,7.3,0,0,0-7.3,7.3V13a9.14,9.14,0,0,0-9.13,9.13v9.14a9.14,9.14,0,0,0,9.13,9.12,5.48,5.48,0,0,0,5.48,5.48H31.31a5.47,5.47,0,0,0,5.47-5.48,9.14,9.14,0,0,0,9.13-9.12V22.17A9.14,9.14,0,0,0,36.78,13ZM14.87,9.39a3.66,3.66,0,0,1,3.65-3.65h11a3.65,3.65,0,0,1,3.65,3.65V13H14.87Zm18.26,31a1.83,1.83,0,0,1-1.82,1.83H16.7a1.83,1.83,0,0,1-1.83-1.83v-7.3a1.82,1.82,0,0,1,1.83-1.82H31.31a1.82,1.82,0,0,1,1.82,1.82Zm9.13-9.12a5.47,5.47,0,0,1-5.48,5.47V33.13a5.48,5.48,0,0,0-5.47-5.48H16.7a5.48,5.48,0,0,0-5.48,5.48v3.65a5.47,5.47,0,0,1-5.48-5.47V22.17a5.47,5.47,0,0,1,5.48-5.47H36.78a5.47,5.47,0,0,1,5.48,5.47ZM36.78,24A1.83,1.83,0,0,1,35,25.83H31.3a1.83,1.83,0,0,1,0-3.66H35A1.82,1.82,0,0,1,36.78,24Z" />
            </>
        ),
    },
    private: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <path d="M2.06,28.4v8.8h13.2V24H6.46A4.4,4.4,0,0,0,2.06,28.4Zm11,6.6H4.26V28.4a2.21,2.21,0,0,1,2.2-2.2h6.6Zm29.7-13.2h-2.2V14.7a8.57,8.57,0,0,0-8.8-8.3A8.57,8.57,0,0,0,23,14.7v7.1h-2.2a3.3,3.3,0,0,0-3.3,3.3V38.3a3.3,3.3,0,0,0,3.3,3.3h22a3.3,3.3,0,0,0,3.3-3.3V25.1A3.3,3.3,0,0,0,42.76,21.8Zm-17.6-7.1a6.37,6.37,0,0,1,6.6-6.1,6.37,6.37,0,0,1,6.6,6.1v7.1H25.16Zm18.7,23.6a1.1,1.1,0,0,1-1.1,1.1h-22a1.1,1.1,0,0,1-1.1-1.1V25.1a1.1,1.1,0,0,1,1.1-1.1h22a1.1,1.1,0,0,1,1.1,1.1ZM12,10.8a5.5,5.5,0,1,0,5.5,5.5A5.51,5.51,0,0,0,12,10.8Zm0,8.8a3.3,3.3,0,1,1,3.3-3.3A3.3,3.3,0,0,1,12,19.6Z" />
        ),
    },
    public: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <path d="M24,2A22,22,0,1,0,46,24,22,22,0,0,0,24,2ZM21.53,43.63A19.77,19.77,0,0,1,4.2,24a20.19,20.19,0,0,1,.52-4.43L16.58,31.43V33.9a5,5,0,0,0,5,5v4.78ZM38.6,37.34a4.91,4.91,0,0,0-4.7-3.44H31.42V26.47A2.48,2.48,0,0,0,29,24H14.1V19.05h5a2.48,2.48,0,0,0,2.48-2.47V11.63h4.94a5,5,0,0,0,5-5v-1A19.75,19.75,0,0,1,38.6,37.34Z" />
        ),
    },
    'qr-code': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <path d="M2.05,20.86H20.86V2.05H2.05ZM8.32,8.32h6.27v6.27H8.32ZM27.14,2.05V20.86H46V2.05ZM39.68,14.59H33.41V8.32h6.27ZM2.05,46H20.86V27.13H2.05ZM8.32,33.41h6.27v6.27H8.32Zm34.49-6.27H46V39.68H36.54V36.54H33.41V46H27.14V27.14h9.4v3.13h6.27Zm0,15.68H46V46H42.81Zm-6.27,0h3.14V46H36.54Z" />
        ),
    },
    rfi: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M41.85,4.78H6.15A4.12,4.12,0,0,0,2,8.9V39.1a4.12,4.12,0,0,0,4.12,4.12h35.7A4.12,4.12,0,0,0,46,39.1V8.9A4.12,4.12,0,0,0,41.85,4.78ZM43.22,39.1a1.38,1.38,0,0,1-1.38,1.37H6.1A1.38,1.38,0,0,1,4.73,39.1V8.86A1.38,1.38,0,0,1,6.1,7.48H41.84a1.38,1.38,0,0,1,1.38,1.38Zm-11-20.7h2.41V29.6H32.24Zm-11,5.33a3.32,3.32,0,0,0,.52-1.94,3.3,3.3,0,0,0-.5-1.85,3.08,3.08,0,0,0-1.44-1.15,5.74,5.74,0,0,0-2.23-.39H13.35V29.6h2.42V25.55h1.74l2.06,4.05h2.58v-.12l-2.39-4.55A3.4,3.4,0,0,0,21.23,23.73Zm-2.34-.56a1.82,1.82,0,0,1-1.31.43H15.77V20.36h1.81a1.78,1.78,0,0,1,1.31.43,1.83,1.83,0,0,1,0,2.38Zm7-4.77h4.93v2H25.88v2.76h4.4v1.94h-4.4V29.6H23.46V18.4Z" />
            </>
        ),
    },
    ruler: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M45.18,30.32A2.62,2.62,0,0,1,46,32.25v11A2.8,2.8,0,0,1,43.25,46H4.75a2.6,2.6,0,0,1-1.93-.82A2.62,2.62,0,0,1,2,43.25V4.75a2.62,2.62,0,0,1,.82-1.93A2.6,2.6,0,0,1,4.75,2h11a2.62,2.62,0,0,1,1.93.82,2.62,2.62,0,0,1,.82,1.93V29.5H43.25A2.62,2.62,0,0,1,45.18,30.32Zm-40.43,11,11-11V26.75H10.94a.72.72,0,0,1-.69-.69V24.69a.72.72,0,0,1,.69-.69h4.81V19.88H10.94a.67.67,0,0,1-.48-.22.66.66,0,0,1-.21-.47V17.81a.66.66,0,0,1,.21-.47.71.71,0,0,1,.48-.22h4.81V13H10.94a.72.72,0,0,1-.69-.69V10.94a.72.72,0,0,1,.69-.69h4.81V4.75h-11Zm38.5-9h-5.5v4.81a.72.72,0,0,1-.69.69H35.69a.72.72,0,0,1-.69-.69V32.25H30.88v4.81a.71.71,0,0,1-.22.48.66.66,0,0,1-.47.21H28.81a.66.66,0,0,1-.47-.21.7.7,0,0,1-.21-.48V32.25H24v4.81a.72.72,0,0,1-.69.69H21.94a.72.72,0,0,1-.69-.69V32.25H17.73l-11,11H43.25Z" />
            </>
        ),
    },
    'ruler-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M15.77,26.74v3.52L2.22,43.9l-.09-.18a2,2,0,0,1-.08-.51V4.79a2.65,2.65,0,0,1,.81-1.93,2.65,2.65,0,0,1,1.93-.81H13A2.63,2.63,0,0,1,15,2.86a2.62,2.62,0,0,1,.82,1.93V7.53H11a.69.69,0,0,0-.47.22.66.66,0,0,0-.21.47V9.59a.66.66,0,0,0,.21.47.65.65,0,0,0,.47.22h4.81v5.49H11a.67.67,0,0,0-.47.21.66.66,0,0,0-.21.47v1.38a.66.66,0,0,0,.21.47.67.67,0,0,0,.47.21h4.81V24H11a.72.72,0,0,0-.68.69v1.37a.66.66,0,0,0,.21.47.67.67,0,0,0,.47.21Zm27.44,5.49A2.78,2.78,0,0,1,46,35v8.23A2.77,2.77,0,0,1,43.21,46H4.79a2.67,2.67,0,0,1-.51-.08l-.18-.09L17.74,32.23h3.52V37a.67.67,0,0,0,.21.47.66.66,0,0,0,.47.21h1.37A.72.72,0,0,0,24,37V32.23h5.49V37a.72.72,0,0,0,.68.68h1.38a.66.66,0,0,0,.47-.21.67.67,0,0,0,.21-.47V32.23h5.49V37a.65.65,0,0,0,.22.47.66.66,0,0,0,.47.21h1.37a.66.66,0,0,0,.47-.21.69.69,0,0,0,.22-.47V32.23Z" />
            </>
        ),
    },
    'share-alt': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M43.25,37.75A8.23,8.23,0,0,1,35,46a8.23,8.23,0,0,1-8.25-8.25,8.71,8.71,0,0,1,.6-3.18L19.19,29.5a8.87,8.87,0,0,1-2.8,2,8,8,0,0,1-3.39.73,7.92,7.92,0,0,1-5.84-2.41A7.92,7.92,0,0,1,4.75,24a7.92,7.92,0,0,1,2.41-5.84A7.92,7.92,0,0,1,13,15.75a8,8,0,0,1,3.39.73,8.87,8.87,0,0,1,2.8,2l8.16-5.07a8.71,8.71,0,0,1-.6-3.18,7.92,7.92,0,0,1,2.41-5.84,8.28,8.28,0,0,1,11.68,0,7.92,7.92,0,0,1,2.41,5.84,7.92,7.92,0,0,1-2.41,5.84,8.27,8.27,0,0,1-9.23,1.68,8.87,8.87,0,0,1-2.8-2l-8.16,5.07a8.73,8.73,0,0,1,0,6.36l8.16,5.07a8.87,8.87,0,0,1,2.8-2,8.27,8.27,0,0,1,9.23,1.68A7.92,7.92,0,0,1,43.25,37.75ZM13,18.5a5.29,5.29,0,0,0-3.87,1.63,5.41,5.41,0,0,0,0,7.74,5.41,5.41,0,0,0,7.74,0,5.41,5.41,0,0,0,0-7.74A5.29,5.29,0,0,0,13,18.5ZM35,4.75a5.29,5.29,0,0,0-3.87,1.63,5.29,5.29,0,0,0-1.63,3.87,5.29,5.29,0,0,0,1.63,3.87,5.41,5.41,0,0,0,7.74,0,5.29,5.29,0,0,0,1.63-3.87,5.29,5.29,0,0,0-1.63-3.87A5.29,5.29,0,0,0,35,4.75Zm0,27.5a5.47,5.47,0,0,0-3.87,9.37,5.47,5.47,0,1,0,7.74-7.74A5.29,5.29,0,0,0,35,32.25Z" />
            </>
        ),
    },
    'sign-out-alt': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M3.2,8.7A4,4,0,0,1,6.12,7.5H17.47a1,1,0,0,1,1,1v.69a1,1,0,0,1-1,1H6.12a1.33,1.33,0,0,0-1.37,1.37V36.38a1.33,1.33,0,0,0,1.37,1.37H17.47a1,1,0,0,1,1,1v.69a1,1,0,0,1-1,1H6.12A4.1,4.1,0,0,1,2,36.38V11.62A4,4,0,0,1,3.2,8.7Zm11,9a4,4,0,0,1,2.92-1.21h9.63V11.2a3.11,3.11,0,0,1,1-2.41,3.64,3.64,0,0,1,2.41-1,3.12,3.12,0,0,1,2.4,1L44.8,21.08a4.15,4.15,0,0,1,0,5.84L32.59,39.21a3.12,3.12,0,0,1-2.4,1,3.64,3.64,0,0,1-2.41-1,3.11,3.11,0,0,1-1-2.41V31.48H17.12A4.11,4.11,0,0,1,13,27.35v-6.7A4,4,0,0,1,14.2,17.73Zm1.55,9.62a1.38,1.38,0,0,0,.39.95,1.26,1.26,0,0,0,1,.43H29.5V36.8a.62.62,0,0,0,.43.61.67.67,0,0,0,.77-.18L42.82,25a1.26,1.26,0,0,0,0-1.9L30.7,10.77a.67.67,0,0,0-.77-.18.62.62,0,0,0-.43.61v8.07H17.12a1.26,1.26,0,0,0-1,.43,1.38,1.38,0,0,0-.39.95Z" />
            </>
        ),
    },
    settings: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M25,16.53a8.81,8.81,0,0,0-8.68,7.22c-.54,4,2.48,7.21,6.74,7.21s8.15-3.23,8.68-7.21h0C32.28,19.76,29.26,16.53,25,16.53ZM23.54,27.35a3.19,3.19,0,0,1-3.37-3.6,4.42,4.42,0,0,1,4.35-3.61,3.2,3.2,0,0,1,3.37,3.61A4.42,4.42,0,0,1,23.54,27.35Zm18-.18-.8-.46a15.78,15.78,0,0,0,.8-5.93l.92-.46c3-1.51,4.34-4.83,3.08-7.41s-4.65-3.44-7.6-2L37,11.4a15,15,0,0,0-5.1-3L32,7.51A4.79,4.79,0,0,0,26.94,2.1a6.62,6.62,0,0,0-6.51,5.41l-.12.93a20.53,20.53,0,0,0-5.89,3l-.8-.47a6.51,6.51,0,0,0-8.18,2C3.5,15.52,4,18.83,6.57,20.32l.79.46a15.6,15.6,0,0,0-.79,5.94l-.92.46c-3,1.48-4.39,4.78-3.16,7.38s4.65,3.5,7.62,2l.06,0,.92-.47a14.74,14.74,0,0,0,5.1,3l-.13.93a4.79,4.79,0,0,0,5.06,5.41A6.61,6.61,0,0,0,27.63,40l.12-.93a20.53,20.53,0,0,0,5.89-3l.8.47a6.48,6.48,0,0,0,8.18-1.95c2-2.58,1.48-5.89-1.08-7.4l0,0Zm-4-6.81a12.22,12.22,0,0,1-.91,6.77,1.57,1.57,0,0,0,.62,2l1.94,1.13a1.63,1.63,0,0,1,.37,2.46,2.16,2.16,0,0,1-2.72.67l-1.94-1.14a2.11,2.11,0,0,0-2.38.3A16.1,16.1,0,0,1,25.76,36a2.19,2.19,0,0,0-1.68,1.75L23.77,40a2.19,2.19,0,0,1-2.17,1.8A1.59,1.59,0,0,1,19.92,40l.3-2.26A1.57,1.57,0,0,0,19,36a11.51,11.51,0,0,1-5.8-3.39,1.93,1.93,0,0,0-2.3-.29L8.67,33.42a1.85,1.85,0,0,1-2.55-.66,1.94,1.94,0,0,1,1-2.47L9.4,29.17a2,2,0,0,0,1.17-2.05,12.27,12.27,0,0,1,.91-6.76,1.58,1.58,0,0,0-.62-2L8.92,17.19a1.63,1.63,0,0,1-.34-2.47,2.17,2.17,0,0,1,2.69-.65l1.94,1.13a2.13,2.13,0,0,0,2.38-.29,16.1,16.1,0,0,1,6.71-3.38A2.19,2.19,0,0,0,24,9.77l.31-2.26a2.2,2.2,0,0,1,2.17-1.8,1.59,1.59,0,0,1,1.68,1.8l-.3,2.26a1.55,1.55,0,0,0,1.21,1.75,11.65,11.65,0,0,1,5.8,3.39,1.93,1.93,0,0,0,2.3.3l2.24-1.13a1.85,1.85,0,0,1,2.55.66,1.93,1.93,0,0,1-1,2.46h0l-2.24,1.13a2,2,0,0,0-1.17,2Z" />
            </>
        ),
    },
    'splitview-3d': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M2,36.74V33.53A17.13,17.13,0,0,0,7.84,35a5.66,5.66,0,0,0,4-1.45,4.87,4.87,0,0,0,1.55-3.73q0-5.44-7.64-5.45H4.39V22L5.6,22q7.17,0,7.17-5.05c0-2.64-1.57-4-4.72-4a13.58,13.58,0,0,0-5.7,1.46v-3a18.48,18.48,0,0,1,5.94-1q7.94,0,7.94,6,0,4.56-5.4,6.51,6.26,1.44,6.26,6.85a7.15,7.15,0,0,1-2.42,5.73,9.76,9.76,0,0,1-6.62,2.08A28,28,0,0,1,2,36.74Zm22.34.2V11.06h8.6a18.73,18.73,0,0,1,6.22.86,9.7,9.7,0,0,1,4.1,2.9A12.9,12.9,0,0,1,46,23.39q0,6.45-3.41,10T33,36.94ZM28,34.19h4.7q5,0,7.14-2.71a12.24,12.24,0,0,0,2.25-7.74,10.72,10.72,0,0,0-2.22-7.17,7,7,0,0,0-3.21-2.18A20.27,20.27,0,0,0,31,13.81H28Z" />
            </>
        ),
    },
    'splitview-2d': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M2,37v-3q1.47-3.46,6-7.53L10,24.8q3.75-3.39,3.75-6.72a4.41,4.41,0,0,0-1.28-3.35A4.87,4.87,0,0,0,8.91,13.5a13.19,13.19,0,0,0-6.24,2.05v-3A15.61,15.61,0,0,1,9.4,11a8.31,8.31,0,0,1,5.74,1.93A6.49,6.49,0,0,1,17.3,18a7.55,7.55,0,0,1-1.09,4.06,20.47,20.47,0,0,1-4.06,4.3l-1.31,1.11q-4.08,3.46-4.71,6.61H17.18v3Zm22.62,0V11.6h8.46a18.38,18.38,0,0,1,6.11.84,9.4,9.4,0,0,1,4,2.85A12.62,12.62,0,0,1,46,23.72q0,6.34-3.35,9.83T33.17,37Zm3.61-2.7H32.9q5,0,7-2.66a12.05,12.05,0,0,0,2.22-7.62A10.56,10.56,0,0,0,40,17a6.82,6.82,0,0,0-3.17-2.14,20,20,0,0,0-5.53-.57h-3Z" />
            </>
        ),
    },
    'splitview-both': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M2,38.84V37.4a11.22,11.22,0,0,1,2.9-3.65l.94-.84a4.54,4.54,0,0,0,1.81-3.25A2.13,2.13,0,0,0,7,28a2.37,2.37,0,0,0-1.7-.59,6.36,6.36,0,0,0-3,1V27a7.65,7.65,0,0,1,3.25-.77,4,4,0,0,1,2.78.93,3.15,3.15,0,0,1,1.05,2.47,3.61,3.61,0,0,1-.53,2,9.9,9.9,0,0,1-2,2.08l-.63.54A6.19,6.19,0,0,0,4,37.4H9.33v1.44Zm11,0V26.52h4.1a8.67,8.67,0,0,1,3,.41A4.55,4.55,0,0,1,22,28.31a6.11,6.11,0,0,1,1.31,4.08,6.62,6.62,0,0,1-1.62,4.76,6.08,6.08,0,0,1-4.57,1.69Zm1.75-1.31h2.24a4.05,4.05,0,0,0,3.4-1.29,5.85,5.85,0,0,0,1.07-3.69,5.11,5.11,0,0,0-1.05-3.41,3.24,3.24,0,0,0-1.54-1,9.38,9.38,0,0,0-2.68-.28H14.7ZM25.05,21.7V20.17a8.26,8.26,0,0,0,2.78.7,2.68,2.68,0,0,0,1.91-.69,2.32,2.32,0,0,0,.73-1.78q0-2.59-3.63-2.6h-.65V14.7h.57c2.28,0,3.42-.8,3.42-2.41q0-1.89-2.25-1.89a6.54,6.54,0,0,0-2.72.69V9.65A9.11,9.11,0,0,1,28,9.16q3.78,0,3.78,2.86c0,1.45-.85,2.48-2.57,3.09q3,.69,3,3.27a3.4,3.4,0,0,1-1.15,2.73,4.65,4.65,0,0,1-3.15,1A13.78,13.78,0,0,1,25.05,21.7Zm10.64.09V9.47h4.09a8.93,8.93,0,0,1,3,.41,4.55,4.55,0,0,1,1.95,1.38A6.11,6.11,0,0,1,46,15.34a6.6,6.6,0,0,1-1.62,4.76,6,6,0,0,1-4.57,1.69Zm1.74-1.31h2.24a4.05,4.05,0,0,0,3.4-1.29,5.79,5.79,0,0,0,1.07-3.69,5.13,5.13,0,0,0-1-3.41,3.24,3.24,0,0,0-1.54-1,9.38,9.38,0,0,0-2.68-.28H37.43ZM34.27,33.57,11.53,10.84l-2,2L32.26,35.58Z" />
            </>
        ),
    },
    tabs: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M44.8,3.2A4,4,0,0,1,46,6.12v27.5a4.1,4.1,0,0,1-4.12,4.13H37.75v4.13A4.1,4.1,0,0,1,33.62,46H6.12A4.1,4.1,0,0,1,2,41.88V14.38a4.1,4.1,0,0,1,4.12-4.13h4.13V6.12a4,4,0,0,1,1.2-2.92A4,4,0,0,1,14.38,2h27.5A4,4,0,0,1,44.8,3.2Zm-40,18.05H35V14.38A1.33,1.33,0,0,0,33.62,13H6.12a1.33,1.33,0,0,0-1.37,1.38ZM35,24H4.75V41.88a1.33,1.33,0,0,0,1.37,1.37h27.5A1.33,1.33,0,0,0,35,41.88ZM43.25,6.12a1.33,1.33,0,0,0-1.37-1.37H14.38A1.33,1.33,0,0,0,13,6.12v4.13H33.62a4.1,4.1,0,0,1,4.13,4.13V35h4.13a1.33,1.33,0,0,0,1.37-1.38Z" />
            </>
        ),
    },
    'tabs-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M35,41.8a4.1,4.1,0,0,1-4.11,4.11H6.17a4,4,0,0,1-2.91-1.2,4,4,0,0,1-1.2-2.91V17.13a4,4,0,0,1,1.2-2.91A4,4,0,0,1,6.17,13H30.84A4.1,4.1,0,0,1,35,17.13Zm-6.12-23a1,1,0,0,0-.73-.29H8.57a1,1,0,0,0-.73.29,1,1,0,0,0-.3.73V24H29.13V19.53A1.06,1.06,0,0,0,28.83,18.8Zm17.08,12A4.1,4.1,0,0,1,41.8,35H37.69V17.13a6.88,6.88,0,0,0-6.85-6.85H13V6.17a4,4,0,0,1,1.2-2.91,4,4,0,0,1,2.91-1.2H41.8a4,4,0,0,1,2.91,1.2,4,4,0,0,1,1.2,2.91Z" />
            </>
        ),
    },
    target: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M45.86,22.8a.5.5,0,0,1,.17.34v1.72a.48.48,0,0,1-.17.34.5.5,0,0,1-.35.18h-3a17.36,17.36,0,0,1-2.62,8.34,19.08,19.08,0,0,1-6.16,6.16,17.36,17.36,0,0,1-8.34,2.62v3a.62.62,0,0,1-.52.52H23.14a.48.48,0,0,1-.34-.17.5.5,0,0,1-.18-.35v-3a17.36,17.36,0,0,1-8.34-2.62,19.08,19.08,0,0,1-6.16-6.16A17.25,17.25,0,0,1,5.5,25.38h-3a.5.5,0,0,1-.35-.18A.48.48,0,0,1,2,24.86V23.14a.5.5,0,0,1,.17-.34.5.5,0,0,1,.35-.18h3a17.25,17.25,0,0,1,2.62-8.34,19.08,19.08,0,0,1,6.16-6.16A17.36,17.36,0,0,1,22.62,5.5v-3a.5.5,0,0,1,.18-.35A.48.48,0,0,1,23.14,2h1.72a.5.5,0,0,1,.34.17.5.5,0,0,1,.18.35v3a17.36,17.36,0,0,1,8.34,2.62,19.08,19.08,0,0,1,6.16,6.16,17.36,17.36,0,0,1,2.62,8.34h3A.5.5,0,0,1,45.86,22.8ZM32.35,37.51a15.9,15.9,0,0,0,5.16-5.16,14.91,14.91,0,0,0,2.24-7h-5.6a.48.48,0,0,1-.34-.18.45.45,0,0,1-.17-.34V23.14a.46.46,0,0,1,.17-.34.48.48,0,0,1,.34-.18h5.6a14.94,14.94,0,0,0-2.24-7,15.9,15.9,0,0,0-5.16-5.16,14.94,14.94,0,0,0-7-2.24v5.6a.48.48,0,0,1-.18.34.46.46,0,0,1-.34.17H23.14a.45.45,0,0,1-.34-.17.48.48,0,0,1-.18-.34V8.25a14.91,14.91,0,0,0-7,2.24,15.9,15.9,0,0,0-5.16,5.16,14.94,14.94,0,0,0-2.24,7h5.6a.5.5,0,0,1,.34.18.5.5,0,0,1,.17.34v1.72a.48.48,0,0,1-.17.34.5.5,0,0,1-.34.18H8.25a14.91,14.91,0,0,0,2.24,7,15.9,15.9,0,0,0,5.16,5.16,14.91,14.91,0,0,0,7,2.24v-5.6a.5.5,0,0,1,.18-.34.48.48,0,0,1,.34-.17h1.72a.5.5,0,0,1,.34.17.5.5,0,0,1,.18.34v5.6A14.94,14.94,0,0,0,32.35,37.51Zm-6.89-15a2.05,2.05,0,0,0-2.92,0,2.06,2.06,0,1,0,2.92,2.92,2.05,2.05,0,0,0,0-2.92Z" />
            </>
        ),
    },
    'target-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M45,21.25a1,1,0,0,1,.73.3,1,1,0,0,1,.3.73v3.44a1,1,0,0,1-1,1H42.39A18.82,18.82,0,0,1,26.75,42.39V45a1,1,0,0,1-1,1H22.28a1,1,0,0,1-.73-.3,1,1,0,0,1-.3-.73V42.39A18.82,18.82,0,0,1,5.61,26.75H3a1,1,0,0,1-.73-.3,1,1,0,0,1-.3-.73V22.28a1,1,0,0,1,.3-.73,1,1,0,0,1,.73-.3H5.61A18.82,18.82,0,0,1,21.25,5.61V3a1,1,0,0,1,.3-.73,1,1,0,0,1,.73-.3h3.44a1,1,0,0,1,.73.3,1,1,0,0,1,.3.73V5.61A18.82,18.82,0,0,1,42.39,21.25ZM26.75,36.8a13.22,13.22,0,0,0,10-10H33.28a1,1,0,0,1-.73-.3,1,1,0,0,1-.3-.73V22.28a1,1,0,0,1,.3-.73,1,1,0,0,1,.73-.3H36.8a13.22,13.22,0,0,0-10-10v3.52a1,1,0,0,1-1,1H22.28a1,1,0,0,1-.73-.3,1,1,0,0,1-.3-.73V11.2a13.22,13.22,0,0,0-10,10.05h3.52a1,1,0,0,1,.73.3,1,1,0,0,1,.3.73v3.44a1,1,0,0,1-.3.73,1,1,0,0,1-.73.3H11.2a13.22,13.22,0,0,0,10.05,10V33.28a1,1,0,0,1,.3-.73,1,1,0,0,1,.73-.3h3.44a1,1,0,0,1,.73.3,1,1,0,0,1,.3.73Zm0-12.8a2.73,2.73,0,0,0-4.68-1.93,2.73,2.73,0,1,0,3.86,3.86A2.62,2.62,0,0,0,26.75,24Z" />
            </>
        ),
    },
    times: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M28.67,24l17,17a1,1,0,0,1,.28.76,1,1,0,0,1-.28.75l-3.16,3.16a1,1,0,0,1-.75.28,1,1,0,0,1-.76-.28l-17-17-17,17a1,1,0,0,1-.76.28,1,1,0,0,1-.75-.28L2.28,42.56A1,1,0,0,1,2,41.81a1,1,0,0,1,.28-.76l17-17L2.28,7A1,1,0,0,1,2,6.19a1,1,0,0,1,.28-.75L5.44,2.28A1,1,0,0,1,6.19,2,1,1,0,0,1,7,2.28L24,19.32l17-17A1,1,0,0,1,41.81,2a1,1,0,0,1,.75.28l3.16,3.16a1,1,0,0,1,.28.75,1,1,0,0,1-.28.76L42.84,9.84Z" />
            </>
        ),
    },
    trash: {
        width: 14,
        height: 14,
        viewBox: '0 0 14 14',
        svg: (
            <>
                <g transform="translate(-3.159 -2.288)">
                    <path
                        d="M4.5,9H15.817"
                        transform="translate(0 -3.485)"
                        fill="none"
                        stroke="#007ec4"
                    />
                    <path
                        d="M16.3,5.515v8.8a1.257,1.257,0,0,1-1.257,1.257H8.757A1.257,1.257,0,0,1,7.5,14.317v-8.8m1.886,0V4.257A1.257,1.257,0,0,1,10.644,3h2.515a1.257,1.257,0,0,1,1.257,1.257V5.515"
                        transform="translate(-1.743 0)"
                        fill="none"
                        stroke="#007ec4"
                    />
                    <path
                        d="M15,16.5v3.772"
                        transform="translate(-6.099 -7.841)"
                        fill="none"
                        stroke="#007ec4"
                    />
                    <path
                        d="M21,16.5v3.772"
                        transform="translate(-9.584 -7.841)"
                        fill="none"
                        stroke="#007ec4"
                    />
                </g>
            </>
        ),
    },
    'trash-alt': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M41.19,7.5a2,2,0,0,1,2.06,2.06v.17a.51.51,0,0,1-.17.35.52.52,0,0,1-.35.17H40.5V41.88A4.1,4.1,0,0,1,36.38,46H11.62A4.1,4.1,0,0,1,7.5,41.88V10.25H5.27a.52.52,0,0,1-.35-.17.51.51,0,0,1-.17-.35V9.56A2,2,0,0,1,6.81,7.5h7.56L17.3,3.63A3.86,3.86,0,0,1,20.56,2h6.88A3.86,3.86,0,0,1,30.7,3.63L33.63,7.5Zm-3.44,2.75H10.25V41.88a1.33,1.33,0,0,0,1.37,1.37H36.38a1.33,1.33,0,0,0,1.37-1.37ZM16.05,38.82a1,1,0,0,0,.73.3h.69a1,1,0,0,0,1-1V15.41a1,1,0,0,0-.3-.73,1,1,0,0,0-.73-.3h-.69a1,1,0,0,0-1,1V38.09A1,1,0,0,0,16.05,38.82Zm3.4-33.55L17.81,7.5H30.19L28.55,5.27a1.35,1.35,0,0,0-1.11-.52H20.56A1.35,1.35,0,0,0,19.45,5.27Zm3.48,33.55a1,1,0,0,0,.73.3h.68a1,1,0,0,0,.73-.3,1,1,0,0,0,.3-.73V15.41a1,1,0,0,0-1-1h-.68a1,1,0,0,0-.73.3,1,1,0,0,0-.3.73V38.09A.94.94,0,0,0,22.93,38.82Zm6.87,0a1,1,0,0,0,.73.3h.69a1,1,0,0,0,1-1V15.41a1,1,0,0,0-.3-.73,1,1,0,0,0-.73-.3h-.69a1,1,0,0,0-1,1V38.09A1,1,0,0,0,29.8,38.82Z" />
            </>
        ),
    },
    'triangle-down': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M44.76,12.11A4.32,4.32,0,0,1,46,15.2a4.55,4.55,0,0,1-1.24,3.16L27.16,36a4.65,4.65,0,0,1-6.32,0L3.24,18.36A4.55,4.55,0,0,1,2,15.2a4.32,4.32,0,0,1,1.24-3.09A4.13,4.13,0,0,1,6.4,10.8H41.6A4.13,4.13,0,0,1,44.76,12.11ZM41.6,15.2H6.4L24,32.8Z" />
            </>
        ),
    },
    'triangle-left': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M35.89,44.76A4.32,4.32,0,0,1,32.8,46a4.55,4.55,0,0,1-3.16-1.24L12,27.16a4.65,4.65,0,0,1,0-6.32l17.6-17.6A4.55,4.55,0,0,1,32.8,2a4.32,4.32,0,0,1,3.09,1.24A4.13,4.13,0,0,1,37.2,6.4V41.6A4.13,4.13,0,0,1,35.89,44.76ZM32.8,41.6V6.4L15.2,24Z" />
            </>
        ),
    },
    'triangle-right': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M12.11,3.24A4.32,4.32,0,0,1,15.2,2a4.55,4.55,0,0,1,3.16,1.24L36,20.84a4.65,4.65,0,0,1,0,6.32l-17.6,17.6A4.55,4.55,0,0,1,15.2,46a4.32,4.32,0,0,1-3.09-1.24A4.13,4.13,0,0,1,10.8,41.6V6.4A4.13,4.13,0,0,1,12.11,3.24ZM15.2,6.4V41.6L32.8,24Z" />
            </>
        ),
    },
    'triangle-up': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M3.24,35.89A4.32,4.32,0,0,1,2,32.8a4.55,4.55,0,0,1,1.24-3.16L20.84,12a4.65,4.65,0,0,1,6.32,0l17.6,17.6A4.55,4.55,0,0,1,46,32.8a4.32,4.32,0,0,1-1.24,3.09A4.13,4.13,0,0,1,41.6,37.2H6.4A4.13,4.13,0,0,1,3.24,35.89ZM6.4,32.8H41.6L24,15.2Z" />
            </>
        ),
    },
    'triangle-down-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M44.76,12.11A4.32,4.32,0,0,1,46,15.2a4.55,4.55,0,0,1-1.24,3.16L27.16,36a4.65,4.65,0,0,1-6.32,0L3.24,18.36A4.55,4.55,0,0,1,2,15.2a4.32,4.32,0,0,1,1.24-3.09A4.13,4.13,0,0,1,6.4,10.8H41.6A4.13,4.13,0,0,1,44.76,12.11Z" />
            </>
        ),
    },
    'triangle-left-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M35.89,44.76A4.32,4.32,0,0,1,32.8,46a4.55,4.55,0,0,1-3.16-1.24L12,27.16a4.65,4.65,0,0,1,0-6.32l17.6-17.6A4.55,4.55,0,0,1,32.8,2a4.32,4.32,0,0,1,3.09,1.24A4.13,4.13,0,0,1,37.2,6.4V41.6A4.13,4.13,0,0,1,35.89,44.76Z" />
            </>
        ),
    },
    'triangle-right-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M12.11,3.24A4.32,4.32,0,0,1,15.2,2a4.55,4.55,0,0,1,3.16,1.24L36,20.84a4.65,4.65,0,0,1,0,6.32l-17.6,17.6A4.55,4.55,0,0,1,15.2,46a4.32,4.32,0,0,1-3.09-1.24A4.13,4.13,0,0,1,10.8,41.6V6.4A4.13,4.13,0,0,1,12.11,3.24Z" />
            </>
        ),
    },
    'triangle-up-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M3.24,35.89A4.32,4.32,0,0,1,2,32.8a4.55,4.55,0,0,1,1.24-3.16L20.84,12a4.65,4.65,0,0,1,6.32,0l17.6,17.6A4.55,4.55,0,0,1,46,32.8a4.32,4.32,0,0,1-1.24,3.09A4.13,4.13,0,0,1,41.6,37.2H6.4A4.13,4.13,0,0,1,3.24,35.89Z" />
            </>
        ),
    },
    version: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M41.62,46H29.29a1.74,1.74,0,0,1-1.74-1.74V3.74a1.74,1.74,0,0,1,3-1.23L42.85,14.84a1.78,1.78,0,0,1,.51,1.23V44.26A1.74,1.74,0,0,1,41.62,46ZM31,42.53h8.86V16.79L31,7.93ZM18.72,46H6.38a1.74,1.74,0,0,1-1.74-1.74V16.07a1.76,1.76,0,0,1,.51-1.23L17.49,2.51a1.73,1.73,0,0,1,3,1.23V44.26A1.74,1.74,0,0,1,18.72,46ZM8.12,42.53H17V7.93L8.12,16.79Z" />
            </>
        ),
    },
    'version-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M41.85,46H29.36a1.47,1.47,0,0,1-1.47-1.47V3.47a1.46,1.46,0,0,1,2.5-1l12.5,12.5a1.46,1.46,0,0,1,.43,1V44.53A1.47,1.47,0,0,1,41.85,46ZM18.64,46H6.15a1.47,1.47,0,0,1-1.47-1.47V16a1.46,1.46,0,0,1,.43-1l12.5-12.5a1.46,1.46,0,0,1,2.5,1V44.53A1.47,1.47,0,0,1,18.64,46Z" />
            </>
        ),
    },
    weight: {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M18.19,31A8.53,8.53,0,0,1,15,33.93a8.88,8.88,0,0,1-8.45,0A8.53,8.53,0,0,1,3.42,31,8.94,8.94,0,0,1,2,27a2,2,0,0,1,.41-1.38L9.91,13.49a1,1,0,0,1,.9-.49,1,1,0,0,1,.89.49l7.49,12.09A2,2,0,0,1,19.6,27,8.94,8.94,0,0,1,18.19,31ZM7,31.56a6.44,6.44,0,0,0,3.85,1.24,6.32,6.32,0,0,0,3.81-1.24A6.67,6.67,0,0,0,17,28.4H4.55A6.56,6.56,0,0,0,7,31.56ZM4.62,26.2H17.06l-6.25-10Zm34.6,13.37a.49.49,0,0,1,.17.37V41a.58.58,0,0,1-.55.55H9.16A.58.58,0,0,1,8.61,41v-1.1a.49.49,0,0,1,.17-.37.51.51,0,0,1,.38-.18H22.9V15.07a4.65,4.65,0,0,1-2.37-1.58,4.2,4.2,0,0,1-.93-2.68H9.16a.51.51,0,0,1-.38-.18.49.49,0,0,1-.17-.37V9.16a.58.58,0,0,1,.55-.55H20.22a3.25,3.25,0,0,1,1.31-1.45,4.44,4.44,0,0,1,4.94,0,3.25,3.25,0,0,1,1.31,1.45H38.84a.58.58,0,0,1,.55.55v1.1a.49.49,0,0,1-.17.37.51.51,0,0,1-.38.18H28.4a4.2,4.2,0,0,1-.93,2.68,4.65,4.65,0,0,1-2.37,1.58V39.39H38.84A.51.51,0,0,1,39.22,39.57ZM22.45,12.35a2.2,2.2,0,0,0,3.75-1.54A2.22,2.22,0,0,0,24,8.61a2.1,2.1,0,0,0-1.55.65,2.16,2.16,0,0,0,0,3.09ZM46,27A8.94,8.94,0,0,1,44.58,31a8.53,8.53,0,0,1-3.16,2.92,8.88,8.88,0,0,1-8.45,0A8.53,8.53,0,0,1,29.81,31,8.94,8.94,0,0,1,28.4,27a2,2,0,0,1,.41-1.38L36.3,13.49a1,1,0,0,1,.89-.49,1,1,0,0,1,.9.49l7.49,12.09A2,2,0,0,1,46,27Zm-5,4.6a6.67,6.67,0,0,0,2.37-3.16H30.94a6.56,6.56,0,0,0,2.41,3.16,6.43,6.43,0,0,0,3.84,1.24A6.33,6.33,0,0,0,41,31.56Zm2.44-5.36-6.26-10L31,26.2Z" />
            </>
        ),
    },
    'weight-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M19.6,29.5a3.9,3.9,0,0,1-1.17,2.75,8.15,8.15,0,0,1-3.2,2A12.45,12.45,0,0,1,10.8,35a12.45,12.45,0,0,1-4.43-.76,8.15,8.15,0,0,1-3.2-2A3.9,3.9,0,0,1,2,29.5v-.07a3.46,3.46,0,0,1,.34-1.34c.23-.53,1-2.16,2.41-4.91L7.84,17a3.18,3.18,0,0,1,1.79-1.58,3.36,3.36,0,0,1,2.34,0A3.18,3.18,0,0,1,13.76,17l3.16,6.39q2,3.91,2.34,4.68a3.31,3.31,0,0,1,.34,1.37Zm-8.8-11-5,9.9h9.9ZM38.3,37.2a1.06,1.06,0,0,1,1.1,1.1v2.2a1.06,1.06,0,0,1-1.1,1.1H9.7a1.06,1.06,0,0,1-1.1-1.1V38.3a1.06,1.06,0,0,1,1.1-1.1H21.8V16.92a5.37,5.37,0,0,1-2.06-1.58A5.49,5.49,0,0,1,18.64,13H9.7a1.06,1.06,0,0,1-1.1-1.1V9.7A1.06,1.06,0,0,1,9.7,8.6h9.9a6,6,0,0,1,2-1.62,5.42,5.42,0,0,1,4.88,0,6,6,0,0,1,2,1.62h9.9a1.06,1.06,0,0,1,1.1,1.1v2.2A1.06,1.06,0,0,1,38.3,13H29.36a5.48,5.48,0,0,1-3.16,3.92V37.2ZM46,29.5a3.9,3.9,0,0,1-1.17,2.75,8.15,8.15,0,0,1-3.2,2,13.29,13.29,0,0,1-8.86,0,8.15,8.15,0,0,1-3.2-2A3.9,3.9,0,0,1,28.4,29.5v-.07a3.46,3.46,0,0,1,.34-1.34c.23-.53,1-2.16,2.41-4.91L34.24,17A3.18,3.18,0,0,1,36,15.41a3.36,3.36,0,0,1,2.34,0A3.18,3.18,0,0,1,40.16,17l3.16,6.39q2,3.91,2.34,4.68A3.31,3.31,0,0,1,46,29.43ZM32.25,28.4h9.9L37.2,18.5Z" />
            </>
        ),
    },
    'window-restore': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M41.81,2.08H14.41A4.11,4.11,0,0,0,10.3,6.19V10.3H6.19a4.11,4.11,0,0,0-4.11,4.11v27.4a4.11,4.11,0,0,0,4.11,4.11h27.4a4.11,4.11,0,0,0,4.11-4.11V37.7h4.11a4.11,4.11,0,0,0,4.11-4.11V6.19A4.11,4.11,0,0,0,41.81,2.08Zm-37,12.33A1.37,1.37,0,0,1,6.19,13h27.4A1.37,1.37,0,0,1,35,14.41v6.85H4.82ZM35,41.81a1.37,1.37,0,0,1-1.37,1.37H6.19a1.37,1.37,0,0,1-1.37-1.37V24H35Zm8.22-8.22A1.37,1.37,0,0,1,41.81,35H37.7V14.41a4.11,4.11,0,0,0-4.11-4.11H13V6.19a1.37,1.37,0,0,1,1.37-1.37h27.4a1.37,1.37,0,0,1,1.37,1.37Z" />
            </>
        ),
    },
    'window-restore-solid': {
        width: 48,
        height: 48,
        viewBox: '0 0 48 48',
        svg: (
            <>
                <path d="M46,6.16v24.7A4.12,4.12,0,0,1,41.84,35H37.72V17.14a6.87,6.87,0,0,0-6.86-6.86H13V6.16a4.12,4.12,0,0,1,4.12-4.11h24.7A4.11,4.11,0,0,1,46,6.16Zm-11,11v24.7A4.12,4.12,0,0,1,30.86,46H6.16a4.11,4.11,0,0,1-4.11-4.11V17.14A4.12,4.12,0,0,1,6.16,13h24.7A4.13,4.13,0,0,1,35,17.14Zm-5.84,2.4a1,1,0,0,0-1-1H8.57a1,1,0,0,0-1,1V24h21.6Z" />
            </>
        ),
    },
};

export default Icons;
