import { defineMessages } from 'react-intl';

export default defineMessages({
    riskManagementApprovalTitle: {
        id: 'components.projectInformation.datesStatusReport.RiskManagementApproval',
        defaultMessage: 'Approval by PL-B', // DE: Freigabe durch PL-B
    },

    riskManagementApprovalDescription: {
        id: 'components.projectInformation.datesStatusReport.RiskManagementApprovalDescription',
        defaultMessage:
            'The status report is to be sent to the PL-B for review and approval as an Excel file by the submission deadline (at the latest)!',
        // DE: Der Statusbericht ist jeweils auf den Abgabetermin (spätestens) als Excel an den PL-B zur Kontrolle und Freigabe zu senden!
    },

    labelStatusReport: {
        id: 'components.projectInformation.datesStatusReport.label.statusReport',
        defaultMessage: 'Status report', // DE: Statusbericht
    },

    labelProjectStatusEnd: {
        id: 'components.projectInformation.datesStatusReport.label.projectStatusEnd',
        defaultMessage: 'Project status end', // DE: Projektstand Ende
    },

    labelDueOnSecondTuesday: {
        id: 'components.projectInformation.datesStatusReport.label.dueOnSecondTuesday',
        defaultMessage: 'Due on the second Tuesday', // DE: Fällig am 2ten Dienstag
    },
});
