import { defineMessages } from 'react-intl';

export default defineMessages({
    pageDashboard: {
        id: 'page.dashboard',
        defaultMessage: 'Web services',
    },
    pageArealDashboard: {
        id: 'page.arealDashboard',
        defaultMessage: 'Areal dashboard',
    },
    pageSia416Area: {
        id: 'page.sia416Area',
        defaultMessage: 'SIA 416 Area',
    },
    pageCostManagement: {
        id: 'page.costManagement',
        defaultMessage: 'Cost management',
    },
    pageProjectComparison: {
        id: 'page.projectComparison',
        defaultMessage: 'Project comparison',
    },
    pageProjectManagement: {
        id: 'page.projectManagement',
        defaultMessage: 'Project management',
    },
    pageScheduling: {
        id: 'page.scheduling',
        defaultMessage: '(Scheduling)',
    },
});
