/* tslint:disable */
/* eslint-disable */
/**
 * Armasuisse Dashboard Web Application API
 * API definitions for Armasuisse Dashboard Web Application
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CountryBase } from '../models';
// @ts-ignore
import { CountryBaseResponse } from '../models';
// @ts-ignore
import { CountryDetailResponse } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * CountriesApi - axios parameter creator
 * @export
 */
export const CountriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new Country and returns newly created Country.
         * @summary Creates new Country.
         * @param {CountryBase} [countryBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCountry: async (countryBase?: CountryBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(countryBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Country.
         * @summary Delete Country.
         * @param {number} entityID Country identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCountry: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteCountry', 'entityID', entityID)
            const localVarPath = `/Countries/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Country.
         * @summary Get Country details.
         * @param {number} entityID Country identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryDetails: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getCountryDetails', 'entityID', entityID)
            const localVarPath = `/Countries/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Countries.
         * @summary Get filtered list of Countries.
         * @param {string} [search] Search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryList: async (search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Country and returns the modified Country.
         * @summary Edit Country.
         * @param {number} entityID Country identifier
         * @param {CountryBase} [countryBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCountry: async (entityID: number, countryBase?: CountryBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateCountry', 'entityID', entityID)
            const localVarPath = `/Countries/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(countryBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountriesApi - functional programming interface
 * @export
 */
export const CountriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountriesApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new Country and returns newly created Country.
         * @summary Creates new Country.
         * @param {CountryBase} [countryBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCountry(countryBase?: CountryBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCountry(countryBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Country.
         * @summary Delete Country.
         * @param {number} entityID Country identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCountry(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCountry(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Country.
         * @summary Get Country details.
         * @param {number} entityID Country identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountryDetails(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountryDetails(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Countries.
         * @summary Get filtered list of Countries.
         * @param {string} [search] Search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountryList(search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountryList(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Country and returns the modified Country.
         * @summary Edit Country.
         * @param {number} entityID Country identifier
         * @param {CountryBase} [countryBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCountry(entityID: number, countryBase?: CountryBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCountry(entityID, countryBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountriesApi - factory interface
 * @export
 */
export const CountriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountriesApiFp(configuration)
    return {
        /**
         * Creates new Country and returns newly created Country.
         * @summary Creates new Country.
         * @param {CountryBase} [countryBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCountry(countryBase?: CountryBase, options?: any): AxiosPromise<CountryDetailResponse> {
            return localVarFp.addCountry(countryBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Country.
         * @summary Delete Country.
         * @param {number} entityID Country identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCountry(entityID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCountry(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Country.
         * @summary Get Country details.
         * @param {number} entityID Country identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryDetails(entityID: number, options?: any): AxiosPromise<CountryDetailResponse> {
            return localVarFp.getCountryDetails(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Countries.
         * @summary Get filtered list of Countries.
         * @param {string} [search] Search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryList(search?: string, options?: any): AxiosPromise<Array<CountryBaseResponse>> {
            return localVarFp.getCountryList(search, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Country and returns the modified Country.
         * @summary Edit Country.
         * @param {number} entityID Country identifier
         * @param {CountryBase} [countryBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCountry(entityID: number, countryBase?: CountryBase, options?: any): AxiosPromise<CountryDetailResponse> {
            return localVarFp.updateCountry(entityID, countryBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountriesApi - object-oriented interface
 * @export
 * @class CountriesApi
 * @extends {BaseAPI}
 */
export class CountriesApi extends BaseAPI {
    /**
     * Creates new Country and returns newly created Country.
     * @summary Creates new Country.
     * @param {CountryBase} [countryBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public addCountry(countryBase?: CountryBase, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).addCountry(countryBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Country.
     * @summary Delete Country.
     * @param {number} entityID Country identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public deleteCountry(entityID: number, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).deleteCountry(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Country.
     * @summary Get Country details.
     * @param {number} entityID Country identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public getCountryDetails(entityID: number, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).getCountryDetails(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Countries.
     * @summary Get filtered list of Countries.
     * @param {string} [search] Search query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public getCountryList(search?: string, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).getCountryList(search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Country and returns the modified Country.
     * @summary Edit Country.
     * @param {number} entityID Country identifier
     * @param {CountryBase} [countryBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesApi
     */
    public updateCountry(entityID: number, countryBase?: CountryBase, options?: AxiosRequestConfig) {
        return CountriesApiFp(this.configuration).updateCountry(entityID, countryBase, options).then((request) => request(this.axios, this.basePath));
    }
}
