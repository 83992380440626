import { Alert, Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router';

import pageMessages from '../../pages/messages';
import { AppPage, hasRouteAccess } from '../../pages/PageRouter';
import ModuleCard from '../ModuleCard/ModuleCard';
import messages from './messages';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        // dashboardItem: {
        //     height: "100%",
        //     width: "100%",
        //     display: "flex",
        //     flexFlow: "column nowrap",
        // },
        dashboardItem: {
            height: '300px',
            width: '200px',
            margin: theme.spacing(1),
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'space-between',
            '&:last-child': {
                marginBottom: theme.spacing(4),
            },
            [theme.breakpoints.up('md')]: {
                height: '400px',
                width: '300px',
                margin: theme.spacing(2),
                '&:last-child': {
                    marginBottom: theme.spacing(2),
                },
            },
        },
    }),
);

interface IModuleCardsProps {
    pages?: AppPage[];
}

const getPath = (path: string) => {
    return generatePath(path, {});
};

const ModuleCards: React.FC<IModuleCardsProps> = ({ pages }) => {
    const classes = useStyles();

    const { keycloak } = useKeycloak();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    // translations
    const intl = useIntl();
    const transNoModules = intl.formatMessage({ ...messages.noModules });
    const transTileArealDashboard = intl.formatMessage({ ...pageMessages.pageArealDashboard });
    const transTileSia416Area = intl.formatMessage({ ...pageMessages.pageSia416Area });
    const transTileCostManagement = intl.formatMessage({ ...pageMessages.pageCostManagement });
    const transTileProjectComparison = intl.formatMessage({
        ...pageMessages.pageProjectComparison,
    });
    const transTileProjectManagement = intl.formatMessage({
        ...pageMessages.pageProjectManagement,
    });
    const transTileScheduling = intl.formatMessage({ ...pageMessages.pageScheduling });

    const translateTitle = (page: AppPage): string => {
        switch (page?.title) {
            case 'Areal dashboard':
                return transTileArealDashboard;

            case 'SIA 416 Area':
                return transTileSia416Area;

            case 'Cost management':
                return transTileCostManagement;

            case 'Project comparison':
                return transTileProjectComparison;

            case 'Project management':
                return transTileProjectManagement;

            case '(Scheduling)':
                return transTileScheduling;

            default:
                return page.title;
        }
    };

    const pagesToShow = keycloak && pages?.filter(appPage => hasRouteAccess(keycloak, appPage));

    return (
        <>
            {pagesToShow?.length ? (
                pages?.map((page, index) => (
                    <Box className={classes.dashboardItem} key={index}>
                        <ModuleCard
                            index={index}
                            path={getPath(page?.path as string)}
                            icon={page.icon}
                            iconSize={matches ? 230 : 170}
                            title={translateTitle(page)}
                            disabled={page?.disabled}
                        />
                    </Box>
                ))
            ) : (
                <Alert severity={'warning'}>{transNoModules}</Alert>
            )}
        </>
    );
};
export default ModuleCards;
