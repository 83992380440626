import { OpenInNew, Sync } from '@mui/icons-material';
import {
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    Link,
    MenuItem,
    Skeleton,
    Theme,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { IFCModel } from 'three/examples/jsm/loaders/IFCLoader';
import { IFCPIPEFITTING, IFCWALL, IFCWINDOW } from 'web-ifc';
import { IfcViewerAPI } from 'web-ifc-viewer';

import { ExternalFileInfo, ExternalSystemDetails, ExternalSystemSummary } from '../../generate/api';
import { pocApi } from '../../packages/Api/data/externalSystems/client';
import IfcViewer from './IfcViewer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        systemItem: {
            margin: '0 auto',
            maxWidth: '1200px',
            marginTop: '40px',
            marginBottom: '40px',
            border: '1px solid #B4B4B4',
            boxShadow: '0 3px 6px rgb(0 0 0 / 16%)',
            borderRadius: '10px',
            padding: '1rem',
            display: 'flex',
        },
        systemItemTitle: {
            marginTop: 0,
            fontWeight: 'bold',
            borderBottom: '1px solid #E3E3E3',
            paddingBottom: '1rem',
            marginBottom: '1rem',
        },
        leftBox: {
            flex: 1,
            marginRight: '2rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        infoFileBox: {
            background: '#F6F6F6',
            borderRadius: '6px',
            padding: '1rem',
        },
        viewer: {
            display: 'flex',
            flex: 2,
            aspectRatio: '16/9',
        },
        syncButton: {
            // background: '#DC0018',
            // borderRadius: '0',
            // padding: '0.5rem 1.3rem',
            // color: 'white',
            // '&:hover': {
            //     background: '#b40013',
            // },
            // '& svg': {
            //     marginLeft: '.5rem',
            // },
        },
    }),
);

export const isFileAllowed = (file: ExternalFileInfo) => {
    return file.name?.toLowerCase().endsWith('.ifc');
};

const ExternalSystemInfo: React.FC<{ item: ExternalSystemSummary }> = ({ item }) => {
    const classes = useStyles();
    const [fileData, setFileData] = useState<ExternalFileInfo>();
    const [ifcData, setIfcData] = useState<Blob>();
    const [ifcDataLoading, setIfcDataLoading] = useState<boolean>(false);
    const [fileDataLoading, setFileDataLoading] = useState<boolean>(false);
    const [windowsCount, setWindowsCount] = useState<number>();
    const [detailsData, setDetailsData] = useState<ExternalSystemDetails>();
    const [reloadCounter, setReloadCounter] = useState<number>(0);

    const handleFileChange = (event: SelectChangeEvent) => {
        // clear model data
        setIfcData(undefined);
        setWindowsCount(undefined);

        if (detailsData?.files) {
            const file = detailsData.files.find(f => f.fileID == (event.target.value as string));
            setFileData(file);
        }
    };

    useEffect(() => {
        if (item.code) {
            pocApi.getPocGetExternalSystemDetailsByCode(item.code).then(response => {
                setDetailsData(response.data);
                const firstAllowed = response?.data?.files?.find(f => isFileAllowed(f));
                if (firstAllowed) {
                    setFileData(firstAllowed);
                }
            });
        }
    }, [item.code]);

    useEffect(() => {
        if (fileData && item.code && fileData.fileID) {
            setIfcDataLoading(true);
            // load data
            pocApi
                .getPocGetExternalSystemFileIfcData(item.code, fileData.fileID)
                .then(response => {
                    if (response.data) {
                        setIfcData(new Blob([response.data]));
                    }
                })
                .finally(() => {
                    setIfcDataLoading(false);
                });
        }
    }, [fileData]);

    const handleSync = () => {
        setWindowsCount(undefined);
        setReloadCounter(r => r + 1);
        // const file = item.files[selectedItemIndex];
        // setFileDataLoading(true);
        // externalSystemsFileSync(keycloak.token as string, item.code, file.name, 1)
        //     .then((response: any) => {
        //         setFileData(response.files.find(x => x.name === file.name));
        //     })
        //     .catch(error => {
        //         console.error(error);
        //     })
        //     .finally(() => {
        //         setFileDataLoading(false);
        //     });
    };

    const handleModelLoaded = (model: IFCModel, viewer: IfcViewerAPI) => {
        viewer.IFC.getAllItemsOfType(model.modelID, IFCWINDOW).then(windows => {
            setWindowsCount(windows.length);
        });
    };

    return (
        <div className={classes.systemItem}>
            <div className={classes.leftBox}>
                <div>
                    <h2 className={classes.systemItemTitle}>{item.name}</h2>
                    <div>
                        <strong>Monitored path:</strong>{' '}
                        {detailsData?.folderPath ? (
                            <>
                                {detailsData?.folderPath}
                                {/*{detailsData?.externalLink && (*/}
                                {/*    <Link href={detailsData?.externalLink} target={'_blank'}>*/}
                                {/*        <IconButton size={'small'} aria-label="delete">*/}
                                {/*            <OpenInNew />*/}
                                {/*        </IconButton>*/}
                                {/*    </Link>*/}
                                {/*)}*/}
                            </>
                        ) : (
                            <Skeleton />
                        )}
                    </div>
                    <div>
                        <strong>Number of files in folder:</strong>{' '}
                        {detailsData?.files?.length ?? <Skeleton />}
                    </div>

                    <FormControl fullWidth>
                        <Select value={fileData?.fileID ?? ''} onChange={handleFileChange}>
                            {detailsData?.files?.map((file, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        value={file.fileID}
                                        disabled={!isFileAllowed(file)}
                                    >
                                        {file.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>

                    {fileData && !fileDataLoading && (
                        <div className={classes.infoFileBox}>
                            <div>
                                <strong>Version:</strong> {fileData?.latestVersion ?? 'N/A'}
                            </div>
                            <div>
                                <strong>Number of windows:</strong>{' '}
                                {windowsCount === undefined ? (
                                    <CircularProgress size={15} />
                                ) : (
                                    windowsCount
                                )}
                            </div>
                        </div>
                    )}
                    {fileDataLoading && <div className={classes.infoFileBox}>Loading...</div>}
                </div>

                <div>
                    {fileData && (
                        <>
                            <Button onClick={handleSync} variant="contained" endIcon={<Sync />}>
                                Reload
                            </Button>
                        </>
                    )}
                </div>
            </div>

            <div className={classes.viewer}>
                {fileData && (
                    <IfcViewer
                        key={`viewer-${fileData.fileID}-${reloadCounter}`}
                        ifcData={ifcData}
                        ifcDataLoading={ifcDataLoading}
                        onModelLaded={handleModelLoaded}
                    />
                )}
            </div>
        </div>
    );
};

export default ExternalSystemInfo;
