import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    selectCommonSettings,
    setCommonSettings,
} from '../../../../redux/projectInformation/projectInformationSlice';
import { store } from '../../../../redux/store';
import messages from './messages';

const ShowPreviousVersionSwitcher: React.FC = () => {
    const intl = useIntl();
    const settings = useSelector(selectCommonSettings);
    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked as boolean;

        // check if the value is the same as the one in the store
        if (settings.showPreviousVersion === checked) return;

        store.dispatch(
            setCommonSettings({
                parameterName: 'showPreviousVersion',
                value: event.target.checked as boolean,
            }),
        );
    };

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Switch value={settings.showPreviousVersion} onChange={handleSwitchChange} />
                }
                label={intl.formatMessage({ ...messages.label })}
            />
        </FormGroup>
    );
};

export default ShowPreviousVersionSwitcher;
