import { defineMessages } from 'react-intl';

export default defineMessages({
    projectCredit: {
        id: 'components.projectInformation.finances.projectCredit',
        defaultMessage: 'Project credit', // DE: Projektierungskredit
    },

    projectCreditProject: {
        id: 'components.projectInformation.finances.projectCreditProject',
        defaultMessage: 'Project credit project', // Projektierungskredit
    },

    approvedCredit: {
        id: 'components.projectInformation.finances.approvedCredit',
        defaultMessage: 'Approved credit', // DE: Bewilligter Kredit
    },

    totalDisposes: {
        id: 'components.projectInformation.finances.totalDisposes',
        defaultMessage: 'Total disposes', // DE: Total verfügt (Verträge/ROB)
    },

    totalObligo: {
        id: 'components.projectInformation.finances.totalObligo',
        defaultMessage: 'Total Obligo', // DE: Total Obligo
    },

    totalPayments: {
        id: 'components.projectInformation.finances.totalPayments',
        defaultMessage: 'Total payments', // DE: Total Zahlungen
    },

    remainingCredit: {
        id: 'components.projectInformation.finances.remainingCredit',
        defaultMessage: 'Remaining credit', // DE: Restkredit
    },

    financeTableDescription: {
        id: 'components.projectInformation.finances.financeTableDescription',
        defaultMessage: 'Note on the finance chapter: ALL amounts must be recorded including VAT!', // DE: Hinweis zu Kapitel Finanzen: ALLE Beträge sind inkl. MwSt. zu erfassen!
    },

    financesComment: {
        id: 'components.projectInformation.finances.financesComment',
        defaultMessage: 'Summary / Comment Finances', // DE: Zusammenfassung / Bemerkung Finanzen
    },

    projectPlanCreditComment: {
        id: 'components.projectInformation.finances.projectPlanCreditComment',
        defaultMessage: 'Comment', // DE: Kommentar/Bemerkung
    },

    realizationCreditComment: {
        id: 'components.projectInformation.finances.realizationCreditComment',
        defaultMessage: 'Comment', // DE: Kommentar/Bemerkung
    },

    contractsComment: {
        id: 'components.projectInformation.finances.contractsComment',
        defaultMessage: 'Comment', // DE: Kommentar/Bemerkung
    },

    labelTrade: {
        id: 'components.projectInformation.finances.label.trade',
        defaultMessage: 'Trade', // DE: Gewerk
    },
    placeholderTrade: {
        id: 'components.projectInformation.finances.placeholder.trade',
        defaultMessage: 'Placeholder text', // DE:
    },

    labelAmount: {
        id: 'components.projectInformation.finances.label.amount',
        defaultMessage: 'Amount', // DE: Betrag
    },
    placeholderAmount: {
        id: 'components.projectInformation.finances.placeholder.amount',
        defaultMessage: 'Placeholder text', // DE:
    },

    labelReason: {
        id: 'components.projectInformation.finances.label.reason',
        defaultMessage: 'Reason (recorded at PT-B)', // DE: Begründung (protokolliert an PT-B)
    },
    placeholderReason: {
        id: 'components.projectInformation.finances.placeholder.reason',
        defaultMessage: 'Placeholder text', // DE:
    },

    total: {
        id: 'components.projectInformation.projectTeam.total',
        defaultMessage: 'Total', // DE: Total
    },

    buttonNewRow: {
        id: 'components.projectInformation.finances.button.newRow',
        defaultMessage: 'New row', // DE: Neue Zeile
    },
});
