import { defineMessages } from 'react-intl';

export default defineMessages({
    titleRiskDescription: {
        id: 'components.projectInformation.risks.title.RiskDescription',
        defaultMessage:
            'The risk management is based on the work instruction 20406 AA risk management in projects and the work instruction 20407 AA hazard catalogue.', // DE: Das Risikomanagement richtet sich nach der Arbeitsanweisung 20406 AA Risikomanagement in Projekten und der Arbeitsanweisung 20407 AA Gefahrenkatalog.
    },

    titleRiskManagement: {
        id: 'components.projectInformation.risks.title.RiskManagement',
        defaultMessage: 'Risk management', // DE: Risikomanagement
    },

    titleRiskMitigation: {
        id: 'components.projectInformation.risks.title.RiskMitigation',
        defaultMessage: 'Risk mitigation', // DE: Risikobewältigung
    },

    titleRiskOpportunity: {
        id: 'components.projectInformation.risks.title.RiskOpportunity',
        defaultMessage: 'Risk opportunity', // DE: Chancen entwicklung
    },

    stateVeryUnlikely: {
        id: 'components.projectInformation.risks.state.VeryUnlikely',
        defaultMessage: 'Very unlikely', // DE: sehr unwahrscheinlich
    },

    stateUnlikely: {
        id: 'components.projectInformation.risks.state.Unlikely',
        defaultMessage: 'Unlikely', // DE: unwahrscheinlich
    },

    stateRare: {
        id: 'components.projectInformation.risks.state.Rare',
        defaultMessage: 'Rare', // DE: selten
    },

    statePossible: {
        id: 'components.projectInformation.risks.state.Possible',
        defaultMessage: 'Possible', // DE: möglich
    },

    stateProbable: {
        id: 'components.projectInformation.risks.state.Probable',
        defaultMessage: 'Probable', // DE: wahrscheinlich
    },

    stateVeryLikely: {
        id: 'components.projectInformation.risks.state.VeryLikely',
        defaultMessage: 'Very likely', // DE: sehr wahrscheinlich
    },

    stateVeryHigh: {
        id: 'components.projectInformation.risks.state.VeryHigh',
        defaultMessage: 'Very high', // DE: sehr hoch
    },

    stateHigh: {
        id: 'components.projectInformation.risks.state.High',
        defaultMessage: 'High', // DE: hoch
    },

    stateOccasional: {
        id: 'components.projectInformation.risks.state.Occasional',
        defaultMessage: 'Occasional', // DE: wesentlich
    },

    stateModerate: {
        id: 'components.projectInformation.risks.state.Moderate',
        defaultMessage: 'Moderate', // DE: moderat
    },

    stateLow: {
        id: 'components.projectInformation.risks.state.Low',
        defaultMessage: 'Low', // DE: gering
    },

    stateVeryLow: {
        id: 'components.projectInformation.risks.state.VeryLow',
        defaultMessage: 'Very low', // DE: sehr gering
    },

    labelNo: {
        id: 'components.projectInformation.risks.label.no',
        defaultMessage: 'No.', // DE: Nr.
    },

    labelRisk: {
        id: 'components.projectInformation.risks.label.risk',
        defaultMessage: 'Risk', // DE: Risiko
    },
    placeholderRisk: {
        id: 'components.projectInformation.risks.placeholder.risk',
        defaultMessage: 'Placeholder text', // DE:
    },

    labelDescription: {
        id: 'components.projectInformation.risks.label.description',
        defaultMessage: 'Description', // DE: Beschrieb
    },
    placeholderDescription: {
        id: 'components.projectInformation.risks.placeholder.description',
        defaultMessage: 'Placeholder text', // DE:
    },

    labelProbability: {
        id: 'components.projectInformation.risks.label.probability',
        defaultMessage: 'Probability', // DE: Eintrittwahrscheinlichkeit
    },

    labelImpact: {
        id: 'components.projectInformation.risks.label.impact',
        defaultMessage: 'Impact', // DE: Auswirkung
    },

    labelType: {
        id: 'components.projectInformation.risks.label.type',
        defaultMessage: 'Type', // DE: Typ
    },
    placeholderType: {
        id: 'components.projectInformation.risks.placeholder.type',
        defaultMessage: 'Placeholder text', // DE:
    },

    labelMeasure: {
        id: 'components.projectInformation.risks.label.measure',
        defaultMessage: 'Measure', // DE: Massnahmen text
    },
    placeholderMeasure: {
        id: 'components.projectInformation.risks.placeholder.measure',
        defaultMessage: 'Placeholder text', // DE:
    },

    labelWho: {
        id: 'components.projectInformation.risks.label.who',
        defaultMessage: 'Who', // DE: Wer
    },

    labelStatus: {
        id: 'components.projectInformation.risks.label.status',
        defaultMessage: 'Status', // DE: Status
    },

    labelProbabilityOfOccurrence: {
        id: 'components.projectInformation.risks.label.probabilityOfOccurrence',
        defaultMessage: 'Probability of occurrence', // DE: Eintrittswahrscheinlichkeit
    },

    buttonNewRow: {
        id: 'components.projectInformation.risks.button.newRow',
        defaultMessage: 'New row', // DE: Neue Zeile
    },
});
