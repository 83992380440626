import { FormControl, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { projReportsApi } from '../../../packages/Api/data/projectReports/client';
import {
    selectReport,
    selectReports,
    setReport,
    setReports as reduxSetReports,
} from '../../../redux/projectInformation/projectInformationSlice';
import { store } from '../../../redux/store';

export interface IReportSelectionProps {
    projectID?: number;
}

const ReportSelection: React.FC<IReportSelectionProps> = ({ projectID }) => {
    /**
     * ----------------------------------------
     * Reports
     * ----------------------------------------
     */

    // Reports list
    const reports = useSelector(selectReports);

    // Report detail
    const selectedReport = useSelector(selectReport);

    // Get report detail
    const fetchReport = (projectReportID: number) => {
        if (projectID) {
            store.dispatch(setReport(undefined));
            projReportsApi.getProjectReportDetails(projectReportID, projectID).then(res => {
                store.dispatch(setReport(res.data));
            });
        }
    };

    return (
        <>
            {/* report selection */}
            {selectedReport && (
                <FormControl size="small" fullWidth margin="none">
                    <Select
                        value={selectedReport?.projectReportID}
                        onChange={e => fetchReport(e.target.value as number)}
                        fullWidth
                        size="small"
                    >
                        {reports?.map((report, index) => (
                            <MenuItem key={index} value={report.projectReportID}>
                                {report.name}
                                <span className="text-neutral-400">&nbsp;-&nbsp;</span>
                                <span className="text-primary">
                                    {report.reportingYear} Q{report.reportQuarter}
                                </span>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </>
    );
};

export default ReportSelection;
