/* tslint:disable */
/* eslint-disable */
/**
 * Armasuisse Dashboard Web Application API
 * API definitions for Armasuisse Dashboard Web Application
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompanyBaseResponse } from '../models';
// @ts-ignore
import { CompanyDetailResponse } from '../models';
// @ts-ignore
import { CompanyRequest } from '../models';
// @ts-ignore
import { OrganizationUnitBase } from '../models';
// @ts-ignore
import { OrganizationUnitBaseResponse } from '../models';
// @ts-ignore
import { OrganizationUnitDetailResponse } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new Company and returns newly created Company.
         * @summary Creates new Company.
         * @param {CompanyRequest} [companyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompany: async (companyRequest?: CompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new Organization Unit and returns newly created Organization Unit.
         * @summary Creates new Organization Unit.
         * @param {number} companyID 
         * @param {OrganizationUnitBase} [organizationUnitBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganizationUnit: async (companyID: number, organizationUnitBase?: OrganizationUnitBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyID' is not null or undefined
            assertParamExists('addOrganizationUnit', 'companyID', companyID)
            const localVarPath = `/Companies/{companyID}/OrganizationUnits`
                .replace(`{${"companyID"}}`, encodeURIComponent(String(companyID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationUnitBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Company.
         * @summary Delete Company.
         * @param {number} entityID Company identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteCompany', 'entityID', entityID)
            const localVarPath = `/Companies/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Organization Unit.
         * @summary Delete Organization Unit.
         * @param {number} entityID Organization Unit identifier
         * @param {number} companyID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationUnit: async (entityID: number, companyID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('deleteOrganizationUnit', 'entityID', entityID)
            // verify required parameter 'companyID' is not null or undefined
            assertParamExists('deleteOrganizationUnit', 'companyID', companyID)
            const localVarPath = `/Companies/{companyID}/OrganizationUnits/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"companyID"}}`, encodeURIComponent(String(companyID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Company.
         * @summary Get Company details.
         * @param {number} entityID Company identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyDetails: async (entityID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getCompanyDetails', 'entityID', entityID)
            const localVarPath = `/Companies/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Companies.
         * @summary Get filtered list of Companies.
         * @param {string} [companyTypeCode] 
         * @param {string} [search] Search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyList: async (companyTypeCode?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (companyTypeCode !== undefined) {
                localVarQueryParameter['CompanyTypeCode'] = companyTypeCode;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the Organization Unit.
         * @summary Get Organization Unit details.
         * @param {number} entityID Organization Unit identifier
         * @param {number} companyID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUnitDetails: async (entityID: number, companyID: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('getOrganizationUnitDetails', 'entityID', entityID)
            // verify required parameter 'companyID' is not null or undefined
            assertParamExists('getOrganizationUnitDetails', 'companyID', companyID)
            const localVarPath = `/Companies/{companyID}/OrganizationUnits/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"companyID"}}`, encodeURIComponent(String(companyID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of Organization Units.
         * @summary Get filtered list of Organization Units.
         * @param {number} companyID 
         * @param {string} [search] Search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUnitList: async (companyID: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyID' is not null or undefined
            assertParamExists('getOrganizationUnitList', 'companyID', companyID)
            const localVarPath = `/Companies/{companyID}/OrganizationUnits`
                .replace(`{${"companyID"}}`, encodeURIComponent(String(companyID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Company and returns the modified Company.
         * @summary Edit Company.
         * @param {number} entityID Company identifier
         * @param {CompanyRequest} [companyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (entityID: number, companyRequest?: CompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateCompany', 'entityID', entityID)
            const localVarPath = `/Companies/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Organization Unit and returns the modified Organization Unit.
         * @summary Edit Organization Unit.
         * @param {number} entityID Organization Unit identifier
         * @param {number} companyID 
         * @param {OrganizationUnitBase} [organizationUnitBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationUnit: async (entityID: number, companyID: number, organizationUnitBase?: OrganizationUnitBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityID' is not null or undefined
            assertParamExists('updateOrganizationUnit', 'entityID', entityID)
            // verify required parameter 'companyID' is not null or undefined
            assertParamExists('updateOrganizationUnit', 'companyID', companyID)
            const localVarPath = `/Companies/{companyID}/OrganizationUnits/{entityID}`
                .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
                .replace(`{${"companyID"}}`, encodeURIComponent(String(companyID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationUnitBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates new Company and returns newly created Company.
         * @summary Creates new Company.
         * @param {CompanyRequest} [companyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCompany(companyRequest?: CompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCompany(companyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates new Organization Unit and returns newly created Organization Unit.
         * @summary Creates new Organization Unit.
         * @param {number} companyID 
         * @param {OrganizationUnitBase} [organizationUnitBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrganizationUnit(companyID: number, organizationUnitBase?: OrganizationUnitBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUnitDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrganizationUnit(companyID, organizationUnitBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Company.
         * @summary Delete Company.
         * @param {number} entityID Company identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompany(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompany(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete Organization Unit.
         * @summary Delete Organization Unit.
         * @param {number} entityID Organization Unit identifier
         * @param {number} companyID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganizationUnit(entityID: number, companyID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganizationUnit(entityID, companyID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Company.
         * @summary Get Company details.
         * @param {number} entityID Company identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyDetails(entityID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyDetails(entityID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Companies.
         * @summary Get filtered list of Companies.
         * @param {string} [companyTypeCode] 
         * @param {string} [search] Search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyList(companyTypeCode?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyList(companyTypeCode, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the Organization Unit.
         * @summary Get Organization Unit details.
         * @param {number} entityID Organization Unit identifier
         * @param {number} companyID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationUnitDetails(entityID: number, companyID: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUnitDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationUnitDetails(entityID, companyID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of Organization Units.
         * @summary Get filtered list of Organization Units.
         * @param {number} companyID 
         * @param {string} [search] Search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationUnitList(companyID: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationUnitBaseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationUnitList(companyID, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Company and returns the modified Company.
         * @summary Edit Company.
         * @param {number} entityID Company identifier
         * @param {CompanyRequest} [companyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(entityID: number, companyRequest?: CompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(entityID, companyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Organization Unit and returns the modified Organization Unit.
         * @summary Edit Organization Unit.
         * @param {number} entityID Organization Unit identifier
         * @param {number} companyID 
         * @param {OrganizationUnitBase} [organizationUnitBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationUnit(entityID: number, companyID: number, organizationUnitBase?: OrganizationUnitBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUnitDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationUnit(entityID, companyID, organizationUnitBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * Creates new Company and returns newly created Company.
         * @summary Creates new Company.
         * @param {CompanyRequest} [companyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompany(companyRequest?: CompanyRequest, options?: any): AxiosPromise<CompanyDetailResponse> {
            return localVarFp.addCompany(companyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates new Organization Unit and returns newly created Organization Unit.
         * @summary Creates new Organization Unit.
         * @param {number} companyID 
         * @param {OrganizationUnitBase} [organizationUnitBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganizationUnit(companyID: number, organizationUnitBase?: OrganizationUnitBase, options?: any): AxiosPromise<OrganizationUnitDetailResponse> {
            return localVarFp.addOrganizationUnit(companyID, organizationUnitBase, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Company.
         * @summary Delete Company.
         * @param {number} entityID Company identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany(entityID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCompany(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Organization Unit.
         * @summary Delete Organization Unit.
         * @param {number} entityID Organization Unit identifier
         * @param {number} companyID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationUnit(entityID: number, companyID: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOrganizationUnit(entityID, companyID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Company.
         * @summary Get Company details.
         * @param {number} entityID Company identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyDetails(entityID: number, options?: any): AxiosPromise<CompanyDetailResponse> {
            return localVarFp.getCompanyDetails(entityID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Companies.
         * @summary Get filtered list of Companies.
         * @param {string} [companyTypeCode] 
         * @param {string} [search] Search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyList(companyTypeCode?: string, search?: string, options?: any): AxiosPromise<Array<CompanyBaseResponse>> {
            return localVarFp.getCompanyList(companyTypeCode, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the Organization Unit.
         * @summary Get Organization Unit details.
         * @param {number} entityID Organization Unit identifier
         * @param {number} companyID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUnitDetails(entityID: number, companyID: number, options?: any): AxiosPromise<OrganizationUnitDetailResponse> {
            return localVarFp.getOrganizationUnitDetails(entityID, companyID, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of Organization Units.
         * @summary Get filtered list of Organization Units.
         * @param {number} companyID 
         * @param {string} [search] Search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUnitList(companyID: number, search?: string, options?: any): AxiosPromise<Array<OrganizationUnitBaseResponse>> {
            return localVarFp.getOrganizationUnitList(companyID, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Company and returns the modified Company.
         * @summary Edit Company.
         * @param {number} entityID Company identifier
         * @param {CompanyRequest} [companyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(entityID: number, companyRequest?: CompanyRequest, options?: any): AxiosPromise<CompanyDetailResponse> {
            return localVarFp.updateCompany(entityID, companyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Organization Unit and returns the modified Organization Unit.
         * @summary Edit Organization Unit.
         * @param {number} entityID Organization Unit identifier
         * @param {number} companyID 
         * @param {OrganizationUnitBase} [organizationUnitBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationUnit(entityID: number, companyID: number, organizationUnitBase?: OrganizationUnitBase, options?: any): AxiosPromise<OrganizationUnitDetailResponse> {
            return localVarFp.updateOrganizationUnit(entityID, companyID, organizationUnitBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * Creates new Company and returns newly created Company.
     * @summary Creates new Company.
     * @param {CompanyRequest} [companyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public addCompany(companyRequest?: CompanyRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).addCompany(companyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates new Organization Unit and returns newly created Organization Unit.
     * @summary Creates new Organization Unit.
     * @param {number} companyID 
     * @param {OrganizationUnitBase} [organizationUnitBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public addOrganizationUnit(companyID: number, organizationUnitBase?: OrganizationUnitBase, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).addOrganizationUnit(companyID, organizationUnitBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Company.
     * @summary Delete Company.
     * @param {number} entityID Company identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public deleteCompany(entityID: number, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).deleteCompany(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Organization Unit.
     * @summary Delete Organization Unit.
     * @param {number} entityID Organization Unit identifier
     * @param {number} companyID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public deleteOrganizationUnit(entityID: number, companyID: number, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).deleteOrganizationUnit(entityID, companyID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Company.
     * @summary Get Company details.
     * @param {number} entityID Company identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getCompanyDetails(entityID: number, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getCompanyDetails(entityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Companies.
     * @summary Get filtered list of Companies.
     * @param {string} [companyTypeCode] 
     * @param {string} [search] Search query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getCompanyList(companyTypeCode?: string, search?: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getCompanyList(companyTypeCode, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the Organization Unit.
     * @summary Get Organization Unit details.
     * @param {number} entityID Organization Unit identifier
     * @param {number} companyID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getOrganizationUnitDetails(entityID: number, companyID: number, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getOrganizationUnitDetails(entityID, companyID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of Organization Units.
     * @summary Get filtered list of Organization Units.
     * @param {number} companyID 
     * @param {string} [search] Search query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getOrganizationUnitList(companyID: number, search?: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getOrganizationUnitList(companyID, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Company and returns the modified Company.
     * @summary Edit Company.
     * @param {number} entityID Company identifier
     * @param {CompanyRequest} [companyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public updateCompany(entityID: number, companyRequest?: CompanyRequest, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).updateCompany(entityID, companyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Organization Unit and returns the modified Organization Unit.
     * @summary Edit Organization Unit.
     * @param {number} entityID Organization Unit identifier
     * @param {number} companyID 
     * @param {OrganizationUnitBase} [organizationUnitBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public updateOrganizationUnit(entityID: number, companyID: number, organizationUnitBase?: OrganizationUnitBase, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).updateOrganizationUnit(entityID, companyID, organizationUnitBase, options).then((request) => request(this.axios, this.basePath));
    }
}
